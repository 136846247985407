import React, { useContext } from "react";

import {
  CompanyOrderData,
  Context,
  DataCubitBuilder,
  EmptyComponent,
  getSubT,
} from "@portittech/portit-react-common-components";

import { useTranslation } from "react-i18next";
import {
  StyledDataRow,
  StyledMontserratSubtitle,
  StyledOrderNameWrapper,
} from "./styles/CustomerApplications";
import { MontserratLightGreyDescription } from "../../../shared/styles/title";

export const CustomerApplications = () => {
  const { shopCubit } = useContext(Context);
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "overview");

  return (
    <DataCubitBuilder dataCubit={shopCubit}>
      {(shopState) => {
        const shopData = shopState?.data || [];
        if (shopData.length === 0) {
          return <EmptyComponent style={{ marginLeft: "21px" }} />;
        }

        return shopData
          .filter((it) => it.contract.status !== "COMPLETED")
          .map((order, index) => {
            return (
              <StyledDataRow key={index}>
                <StyledOrderNameWrapper>
                  {order instanceof CompanyOrderData ? (
                    <>
                      <StyledMontserratSubtitle>
                        {order.company.companyName
                          ? order.company.companyName
                          : order.contract.product.description}
                      </StyledMontserratSubtitle>
                      <MontserratLightGreyDescription>
                        {order?.company?.taxIrd?.firstName
                          ? `${tc("request_by")} ${
                              order.company.taxIrd.firstName
                            } ${
                              order?.company?.taxIrd?.lastName
                                ? order.company.taxIrd.lastName
                                : ""
                            }`
                          : ""}
                      </MontserratLightGreyDescription>
                    </>
                  ) : (
                    <>
                      <StyledMontserratSubtitle>
                        {order.contract.product.description}
                      </StyledMontserratSubtitle>
                    </>
                  )}
                </StyledOrderNameWrapper>
                {<span>{order.contract.status}</span>}
              </StyledDataRow>
            );
          });
      }}
    </DataCubitBuilder>
  );
};
