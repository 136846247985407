import { Either, isLeft } from "fp-ts/Either";
import moment from "moment";
import { combineLatest } from "rxjs";
import { debounceTime, distinctUntilChanged, first, map } from "rxjs/operators";

import {
  AccountDetailsEntity,
  BalanceDetailsEntity,
  Failure,
  PaginatedEntity,
  TransactionDetailsEntity,
  TransactionType,
  CustomerCubit,
  CustomerData,
  FieldValidators,
  InfoRequestResolver,
  SelectFieldModel,
  TranslationsSelectFieldModel,
  SingleFieldModel,
  TextFieldModel,
  FormModel,
  FetchedEvent,
  FetchFailedEvent,
  MultiModel,
  BasePageModel,
  ReferencesInfoRequest,
  ReferencesModel,
  fetchBalancesWP,
  fetchTransactionsUseCase,
} from "@portittech/portit-react-common-components";

class TransactionsSearcherFormModel extends FormModel<
  BankAccountStrainer,
  Failure
> {
  private readonly _autoSubmit: boolean;

  readonly balancesFM = new SelectFieldModel<BalanceWithAccountData>({
    name: "balanceFM",
    validators: [FieldValidators.required],
  });
  readonly textFM = new TextFieldModel();
  readonly fromDateFM = new SingleFieldModel<Date>({
    validators: [FieldValidators.required],
  });
  readonly toDateFM = new SingleFieldModel<Date>({
    validators: [FieldValidators.required],
  });

  private readonly referencesModel: ReferencesModel;
  readonly transactionTypesFM =
    new TranslationsSelectFieldModel<TransactionType>();

  constructor({
    autoSubmit = false,
    referencesModel,
  }: {
    autoSubmit?: boolean;
    referencesModel: ReferencesModel;
  }) {
    super({ isLoading: true });
    this._autoSubmit = autoSubmit;
    this.referencesModel = referencesModel;
    this.toDateFM.addValidators([
      FieldValidators.dateRange(() => this.fromDateFM.value),
    ]);
    this.addFieldModels({
      fieldsModels: [
        this.balancesFM,
        this.textFM,
        this.fromDateFM,
        this.transactionTypesFM,
        this.toDateFM,
      ],
    });
  }

  protected async onLoading() {
    const balancesRes: Either<
      Failure,
      PaginatedEntity<BalanceDetailsEntity>
    > = await fetchBalancesWP.run({
      strainer: { status: "enabled" },
    });

    if (isLeft(balancesRes)) {
      this.notifyLoadFailed({ response: balancesRes.left });
      return;
    }

    const balances = balancesRes.right.values;

    if (balances.length <= 0) {
      // Notify load failed because not have account
      this.notifyLoadFailed();
      return;
    }

    this.balancesFM.updateItems(balances);

    const now = moment();
    const previousMonth = now.subtract({ months: 1 });
    this.fromDateFM.updateValue(previousMonth.toDate());
    this.toDateFM.updateValue(new Date());

    this.notifyLoaded();

    if (this._autoSubmit) {
      this.enableAutoSubmit();
    }

    this.transactionTypesFM.updateLoader(
      InfoRequestResolver(
        this.referencesModel,
        ReferencesInfoRequest.payments("transactionTypes")
      )
    );
  }

  // TODO: add "all transactions" option
  enableAutoSubmit(): void {
    this.closer.add(
      combineLatest(
        this.fieldModels().map((fm) =>
          fm.onLastChange.pipe(
            map((model) => (model as SingleFieldModel<any>).value),
            distinctUntilChanged((prev, curr) => prev === curr),
            debounceTime(1500)
          )
        )
      ).subscribe(() => {
        void this.submit();
      })
    );
  }

  protected onSubmitting() {
    this.notifySubmitted({
      canSubmitAgain: true,
      response: {
        balance: this.balancesFM.value,
        text: this.textFM.value,
        fromDate: this.fromDateFM.value,
        toDate: this.toDateFM.value,
        transactionType: this.transactionTypesFM.value as TransactionType,
      },
    });
  }
}

export class TransactionsPageModel extends BasePageModel {
  private _usdAccount: BalanceDetailsEntity;

  readonly transactionsPageSize = 8;
  readonly searcherFormModel: TransactionsSearcherFormModel;
  readonly transactionsModel = new MultiModel<
    TransactionDetailsEntity,
    BankAccountStrainer
  >({ canWaitStrainer: true });

  private readonly referencesModel: ReferencesModel;
  readonly balanceId: number;

  constructor({
    customerCubit,
    balanceId,
    referencesModel,
  }: {
    customerCubit: CustomerCubit;
    balanceId?: number;
    referencesModel: ReferencesModel;
  }) {
    super();
    this.balanceId = balanceId;
    this.referencesModel = referencesModel;
    this.searcherFormModel = new TransactionsSearcherFormModel({
      referencesModel: this.referencesModel,
    });

    // Fetch page with filter
    this.transactionsModel.applyFetcher(async (section, strainer) => {
      // If not exist any account the strainer is undefined
      if (!strainer) return new FetchedEvent([], 0);

      const page = section.toPage(this.transactionsPageSize);
      const res = await fetchTransactionsUseCase.run({
        balanceId: strainer.balance.balance_id,
        strainer: {
          excluded_transaction_subtypes: ["payment_fee"],
          date_from: strainer.fromDate ?? undefined,
          date_to: strainer.toDate ?? undefined,
          keyword: strainer.text ?? undefined,
          transaction_type: strainer.transactionType ?? undefined,
        },
        pagination: {
          page: page,
          size: this.transactionsPageSize,
        },
      });

      if (isLeft(res)) {
        return new FetchFailedEvent(res.left);
      } else {
        return new FetchedEvent(
          res.right.values,
          res.right.pagination.total_entries
        );
      }
    });

    // Update filter when user changes
    this.closer.add(
      this.searcherFormModel.listenOnStatusChange({
        onLoadFailed: () => {
          this.transactionsModel.applyStrainer(undefined);
        },
        onSubmitted: () => {
          this.transactionsModel.applyStrainer(
            this.searcherFormModel.successResponse
          );
        },
      })
    );

    // refresh the component when the user switches profile
    this.closer.add(
      customerCubit.onChanges
        .pipe(distinctUntilChanged(CustomerData.checkProfileChanged))
        .subscribe(() => {
          void this.transactionsModel.reFetch();
        })
    );
  }

  async onLoading() {
    await this.searcherFormModel.onLastChange
      .pipe(first((it) => it.status !== "loading"))
      .toPromise();

    const balancesFM = this.searcherFormModel.balancesFM;

    // select current balance it it exist else select first account
    if (this.balanceId) {
      balancesFM.updateValue(
        balancesFM.items.find((balance) => {
          return balance.balance_id === this.balanceId;
        })
      );
    } else {
      balancesFM.updateValue(balancesFM.items[0]);
    }

    this.searcherFormModel.enableAutoSubmit();

    this.notifyLoaded();
  }
}

export interface BalanceWithAccountData extends BalanceDetailsEntity {
  account: AccountDetailsEntity;
}

interface BankAccountStrainer {
  balance: BalanceWithAccountData;
  text: string;
  toDate: Date;
  fromDate: Date;
  transactionType: TransactionType;
}
