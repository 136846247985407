import { ApiClient } from "@portittech/portit-react-common-components";
import {
  CreateInvestmentRequest,
  CreateInvestmentResponse,
  GroupedInvestmentsRequest,
  GroupedInvestmentsResponse,
  InvestmentAmountConversionRequest,
  InvestmentRequest,
  InvestmentsByAssetsClassResponse,
  InvestmentsByOpportunityRequest,
  InvestmentsByOpportunityResponse,
  InvestmentsCompletedAndPending,
  InvestmentsResponse,
} from "../../../domain/features/investment/entities";
import { InvestmentRepository } from "../../../domain/features/investment/repository";

/**
 * Data bridge between Investment REST endpoints and the domain layer
 */
export class InvestmentRepositoryImpl implements InvestmentRepository {
  constructor(private readonly client: ApiClient) {}

  getInvestments({
    pagination,
  }: InvestmentRequest = {}): Promise<InvestmentsResponse> {
    return this.client.get<InvestmentsResponse>(
      "/investments/v1/investments",
      "investments",
      pagination
    );
  }

  getInvestmentsConcentrationByOpportunity(
    request: InvestmentsByOpportunityRequest
  ): Promise<InvestmentsByOpportunityResponse> {
    return this.client.get<InvestmentsByOpportunityResponse>(
      "/investments/v1/investments/concentration/opportunity",
      "investments",
      request
    );
  }

  getInvestmentsConcentrationByAssetsClass(): Promise<InvestmentsByAssetsClassResponse> {
    return this.client.get<InvestmentsByAssetsClassResponse>(
      "/investments/v1/investments/concentration/assetClass",
      "investments"
    );
  }

  createInvestment(
    request: CreateInvestmentRequest
  ): Promise<CreateInvestmentResponse> {
    return this.client.post<CreateInvestmentResponse>(
      `/investments/v1/investments`,
      "investments",
      request
    );
  }

  getGroupedInvestments(
    request: GroupedInvestmentsRequest
  ): Promise<GroupedInvestmentsResponse> {
    return this.client.post<GroupedInvestmentsResponse>(
      "/investments/v1/investments/grouped",
      "investments",
      request
    );
  }

  getInvestmentsSum(currency: string): Promise<number> {
    return this.client.get<number>(
      `/investments/v1/investments/sum/${currency}`,
      "investments"
    );
  }

  getInvestmentsCompletedAndPending(): Promise<InvestmentsCompletedAndPending> {
    return this.client.get<InvestmentsCompletedAndPending>(
      "/investments/v1/investments/completedAndPending",
      "investments"
    );
  }

  getInvestmentAmountConverted(
    targetCurrency: string,
    request: InvestmentAmountConversionRequest
  ): Promise<number> {
    return this.client.get<number>(
      `/investments/v1/investments/in/${targetCurrency}`,
      "investments",
      request
    );
  }
}
