import React from "react";
import { useTranslation } from "react-i18next";

import { getSubT } from "@portittech/portit-react-common-components";

import { ReactComponent as InfoWidgetLearnIcon } from "../../assets/icons/learn.svg";

import { InfoWidget } from "./info-widget/infoWidget.component";

import { colorMainDark } from "../../shared/styles/generic";
import { StyledExternalLink } from "./common.styles";

interface GuideWidgetProps {
  digitalGoldTitle?: boolean;
}

export const GuideWidget = ({ digitalGoldTitle }: GuideWidgetProps) => {
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "info_widget");
  return (
    <InfoWidget
      title={
        digitalGoldTitle ? tc("digital_gold_label") : tc("learn_tokens_label")
      }
      icon={<InfoWidgetLearnIcon fill={colorMainDark} stroke={colorMainDark} />}
    >
      <StyledExternalLink
        href={
          digitalGoldTitle
            ? process.env.REACT_APP_DIGITAL_GOLD_ARTICLE
            : process.env.REACT_APP_STORE_TOKENS_ARTICLE
        }
        target="_blank"
      >
        {tc("view_guide")}
      </StyledExternalLink>
    </InfoWidget>
  );
};
