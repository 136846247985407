export const footerNavItemsData = [
  {
    href: process.env.REACT_APP_RISK_DISCLOSURE,
    text: "risk_disclosure",
  },
  {
    href: process.env.REACT_APP_QUID_TERMS_AND_CONDITION_LINK,
    text: "terms",
  },
  {
    href: process.env.REACT_APP_PRIVACY_LINK,
    text: "privacy",
  },
  {
    href: process.env.REACT_APP_EPHELIA_CAPITAL,
    text: "ephelia",
  },
  {
    href: process.env.REACT_APP_QUID_CONTACT_LINK,
    text: "contact",
  },
];
