import React from "react";
import { useTranslation } from "react-i18next";
import { SweetAlert2 } from "sweetalert2-react-content";
import {
  FundingInfoEntity,
  getSubT,
  NunitoGreyDescription,
} from "@portittech/portit-react-common-components";

import { SquareButton } from "../../shared/styles/button";
import {
  DepositModalMainWrapper,
  StyledDepositCloseBtnWrapper,
} from "./deposit.styles";
import { FundingInfoComponent } from "./fundingInfo.component";
import { isEmpty } from "ramda";

interface DepositProps {
  swalWithReact: SweetAlert2;
  fundingInfos: Array<FundingInfoEntity>;
}

export const Deposit = ({ swalWithReact, fundingInfos }: DepositProps) => {
  const { t: pt } = useTranslation(["dashboard"]);
  const t = getSubT(pt, "wallets.funding_info");

  const closeDepositModal = () => {
    swalWithReact.clickConfirm();
  };

  return (
    <DepositModalMainWrapper>
      {isEmpty(fundingInfos) ? (
        <NunitoGreyDescription>
          {t("noInformation_description")}
        </NunitoGreyDescription>
      ) : (
        fundingInfos.map((info, index) => (
          <FundingInfoComponent key={index} fundingInfo={info} />
        ))
      )}
      <StyledDepositCloseBtnWrapper>
        <SquareButton onClick={closeDepositModal}>
          {t("close_button")}
        </SquareButton>
      </StyledDepositCloseBtnWrapper>
    </DepositModalMainWrapper>
  );
};
