import React from "react";
import { useTranslation } from "react-i18next";

import { getSubT } from "@portittech/portit-react-common-components";

import {
  StyledOpportunitiesCardWrapper,
  StyledOpportunityColumnText,
  StyledOpportunityFlexOneWrapper,
} from "../common.styles";

import { StyledRouterLink } from "../common.styles";

import { ElviriaPages } from "../../../shared/pages";
import { OpportunityResponse } from "../../../domain/features/opportunities/entities";
import { formatCurrencyNumber } from "../../../shared/helpers/formatCurrencyNumber";
import { Logo } from "../../logo/logo.component";

export const OpportunitiesCard = ({
  id,
  logoImageId,
  name,
  assetClass,
  tokenPrice,
  noToken,
  currency
}: OpportunityResponse) => {
  const { t } = useTranslation(["dashboard"]);
  const { t: tApi } = useTranslation(["apiText"]);
  const tc = getSubT(t, "investment_opportunities_widget");

  return (
    <StyledOpportunitiesCardWrapper withBigFont>
      <StyledOpportunityFlexOneWrapper>
        <Logo logoId={logoImageId} />
      </StyledOpportunityFlexOneWrapper>
      <StyledOpportunityColumnText>{name}</StyledOpportunityColumnText>
      <StyledOpportunityColumnText isAssetClass>
        {tApi(assetClass)}
      </StyledOpportunityColumnText>
      <StyledOpportunityColumnText>
        {formatCurrencyNumber(tokenPrice, currency)}
      </StyledOpportunityColumnText>
      <StyledOpportunityColumnText>{noToken}</StyledOpportunityColumnText>
      <StyledOpportunityFlexOneWrapper>
        <StyledRouterLink to={`${ElviriaPages.OPPORTUNITIES}/${id}`}>
          {tc("view_link")}
        </StyledRouterLink>
      </StyledOpportunityFlexOneWrapper>
    </StyledOpportunitiesCardWrapper>
  );
};
