import { Either } from "fp-ts/Either";

import {
  SingleModel,
  AccountTransactionDetailsEntity,
  Failure,
  readRelatedTransactionDetailsUseCase,
  readTransactionDetailsUseCase,
} from "@portittech/portit-react-common-components";

export class TransactionModel extends SingleModel<AccountTransactionDetailsEntity> {
  private readonly balanceId: number;
  private readonly transactionId: number;

  constructor({
    balanceId,
    transactionId,
  }: {
    balanceId: number;
    transactionId: number;
  }) {
    super();
    this.balanceId = balanceId;
    this.transactionId = transactionId;
  }

  async onFetch(): Promise<Either<Failure, AccountTransactionDetailsEntity>> {
    // Retrieve information about the single transaction
    return await readTransactionDetailsUseCase.run({
      balanceId: this.balanceId,
      transactionId: this.transactionId,
    });
  }
}

export class RelatedTransactionModel extends SingleModel<AccountTransactionDetailsEntity> {
  private readonly balanceId: number;
  private readonly relatedTransactionId: string;

  constructor({
    balanceId,
    relatedTransactionId,
  }: {
    balanceId: number;
    relatedTransactionId: string;
  }) {
    super();
    this.balanceId = balanceId;
    this.relatedTransactionId = relatedTransactionId;
  }

  async onFetch(): Promise<Either<Failure, AccountTransactionDetailsEntity>> {
    return await readRelatedTransactionDetailsUseCase.run({
      balanceId: this.balanceId,
      uniqueId: this.relatedTransactionId,
    });
  }
}
