import moment from "moment";
import { combineLatest } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

import {
  Failure,
  TransactionType,
  FieldValidators,
  TranslationsSelectFieldModel,
  SingleFieldModel,
  TextFieldModel,
  FormModel,
} from "@portittech/portit-react-common-components";

import { InvestmentsStrainer } from "./types";


export class InvestmentsSearcherFormModel extends FormModel<
  InvestmentsStrainer,
  Failure
> {
  private readonly _autoSubmit: boolean;

  readonly textFM = new TextFieldModel();
  readonly fromDateFM = new SingleFieldModel<Date>({
    validators: [FieldValidators.required],
  });
  readonly toDateFM = new SingleFieldModel<Date>({
    validators: [FieldValidators.required],
  });

  readonly transactionTypesFM = new TranslationsSelectFieldModel<TransactionType>({
    items: ["Inbound funds", "Buy", "Sell", "Withdrawal"]
  });

  constructor({
    autoSubmit = false,
  }: {
    autoSubmit?: boolean;
  }) {
    super({ isLoading: true });
    this._autoSubmit = autoSubmit;
    this.toDateFM.addValidators([
      FieldValidators.dateRange(() => this.fromDateFM.value),
    ]);
    this.addFieldModels({
      fieldsModels: [
        this.textFM,
        this.fromDateFM,
        this.transactionTypesFM,
        this.toDateFM,
      ],
    });
  }

  protected async onLoading() {
    const now = moment();
    const previousMonth = now.subtract({ months: 1 });
    this.fromDateFM.updateValue(previousMonth.toDate());
    this.toDateFM.updateValue(new Date());

    this.notifyLoaded();

    if (this._autoSubmit) {
      this.enableAutoSubmit();
    }
  }

  // TODO: add "all transactions" option
  enableAutoSubmit(): void {
    this.closer.add(
      combineLatest(
        this.fieldModels().map((fm) =>
          fm.onLastChange.pipe(
            map((model) => (model as SingleFieldModel<any>).value),
            distinctUntilChanged((prev, curr) => prev === curr),
            debounceTime(1500)
          )
        )
      ).subscribe(() => {
        void this.submit();
      })
    );
  }

  protected onSubmitting() {
    this.notifySubmitted({
      canSubmitAgain: true,
      response: {
        text: this.textFM.value,
        fromDate: this.fromDateFM.value,
        toDate: this.toDateFM.value,
        transactionType: this.transactionTypesFM.value as TransactionType,
      },
    });
  }
}