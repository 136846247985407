import styled from "styled-components";
import {
  colorMainWhite,
  fontFamilyEncodeSansExpanded,
} from "../../shared/styles/generic";

export const Styled404Text = styled.p`
  font-size: 16rem;
  font-family: ${fontFamilyEncodeSansExpanded};
  color: ${colorMainWhite};
  margin-bottom: 0;
  line-height: 1.5;
`;
