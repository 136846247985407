import styled from "styled-components";
import { colorLightGrey } from "../../../../shared/styles/generic";
import { devices } from "../../../config/devices";

export const StyledBigImgWidgetMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.75rem;
`;

export const StyledBigImgWidgetWrapper = styled.div`
  height: 18rem;
  width: 100%;

  @media ${devices.smallLaptop} {
    height: auto;
  }
`;

export const StyledBigImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  object-position: 0 0;
`;

export const StyledUnderDueDiligenceAndPreMarketing = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 10.625rem;

  @media ${devices.laptop} {
    height: 7.625rem;
  }

  @media ${devices.tablet} {
    height: 6.625rem;
  }
`;

export const StyledPlaceholderBigImage = styled.div`
  background-color: ${colorLightGrey};
  margin-bottom: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
