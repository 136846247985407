import styled from "styled-components";

import { fontFamilyMontserrat } from "../../../../shared/styles/generic";
import { devices } from "../../../config/devices";

export const StyledSorryPageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${devices.tablet} {
    padding: 1.25rem;
  }
`;

export const StyledMessage = styled.p`
  font-family: ${fontFamilyMontserrat};
  font-size: 1.25rem;
  line-height: 1.625rem;
  text-align: center;
`;

export const StyledBoldMessage = styled(StyledMessage)`
  font-weight: 700;
`;

export const StyledWarningIcon = styled.img`
  margin-bottom: 1.4375rem;
`;
