import React from "react";

import {
  IterableModel,
  IterableModelBuilder,
} from "@portittech/portit-react-common-components";
import { OpportunityResponse } from "../../domain/features/opportunities/entities";
import { StyledInvestmentsList } from "../investments-page/components/investments-list/investmentsList.styles";
import { groupBy } from "ramda";
import { OpportunitiesList } from "./components/opportunitiesList";
import { ViewType } from "./types";
import { OpportunitiesCardView } from "./components/opportunitiesCardView.component";

interface OpportunitiesBuilderProps {
  opportunitiesModel: IterableModel<OpportunityResponse>;
  viewType: ViewType;
}

export const OpportunitiesModelBuilder = ({
  opportunitiesModel,
  viewType,
}: OpportunitiesBuilderProps): React.ReactElement => {
  return (
    <StyledInvestmentsList>
      <IterableModelBuilder iterableModel={opportunitiesModel}>
        {(opportunities: Array<OpportunityResponse>) => {
          const dailyOpportunities: {
            [p: string]: Array<OpportunityResponse>;
          } = groupBy((opportunity: OpportunityResponse) => {
            return opportunity.assetClass;
          }, opportunities);

          return Object.keys(dailyOpportunities).map((assetClass) => {
            return (
              <div key={assetClass}>
                {viewType === ViewType.list ? (
                  <OpportunitiesList
                    assetClass={assetClass}
                    opportunities={opportunities}
                  />
                ) : (
                  <OpportunitiesCardView
                    assetClass={assetClass}
                    opportunities={opportunities}
                  />
                )}
              </div>
            );
          });
        }}
      </IterableModelBuilder>
    </StyledInvestmentsList>
  );
};
