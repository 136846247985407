import styled from "styled-components";

import {
  colorDarkGrey,
  colorMainDark,
  fontFamilyMontserrat,
} from "../../../shared/styles/generic";

export const StyledEmptyPortfolioText = styled.p`
  color: ${colorDarkGrey};
  font-family: ${fontFamilyMontserrat};
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 0.625rem 0 3.125rem;
`;

export const StyledPortfolioSum = styled.p`
  font-family: ${fontFamilyMontserrat};
  font-weight: 600;
  font-size: 2rem;
  color: ${colorMainDark};
  margin-top: 0.9375rem 0 3.125rem;
`;

export const StyledSelectFieldWrapper = styled.div`
  width: 10rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;

  input {
    box-shadow: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: transparent;
  }

  .ant-select-arrow {
    svg {
      width: 10px;
    }
  }

  .ant-select-single {
    width: auto !important;
    font-size: 1rem;
  }
`;
