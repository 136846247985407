import React from "react";

import { MyPortfolioWidget } from "../widgets/my-portfolio/myPortfolio.component";
import { OpportunitiesExplorerWidget } from "../widgets/opportunities-explorer/opportunitiesExplorer.widget";

import { InvestmentOpportunitiesWidget } from "../widgets/investment-opportunities-widget/investmentOpportunitiesWidget.component";
import { InterestedInvestmentWidget } from "../widgets/interested-investment-widget/interestedInvestmentWidget.component";

import {
  StyledAdminInnerPageWrapper,
  StyledGrid,
} from "../../shared/styles/page.styles";
import { HelpWidget } from "../widgets/helpWidget";
import { MyPreferencesWidget } from "../widgets/myPreferencesWidget";
import { GuideWidget } from "../widgets/guideWidget";
import { AccountBalanceWidget } from "../widgets/account-balance-widget/accountBalanceWidget.component";
import { StyledDashboardAccountBalanceAndHistoricWrapper } from "./dashboard.styles";
import { ExistingHistoricalTradeWidget } from "../widgets/existing-historical-trade-widget/existingHistoricalTradeWidget.component";
import { WatchlistWidget } from "../widgets/watchlist-widget/watchlistWidget.component";
import { LoadingComponent } from "@portittech/portit-react-common-components";
import { useOpportunitiesStore } from "../opportunitiesStore";
import { useInvestments } from "../investments-page/useInvestments";

export const DashboardPage: React.FC = () => {
  const { withPortfolio, areInvestmentsFetching } = useInvestments();

  const { isLoading } = useOpportunitiesStore();

  if (isLoading || areInvestmentsFetching) return <LoadingComponent />;

  return (
    <>
      <StyledAdminInnerPageWrapper>
        <StyledGrid>
          {withPortfolio ? (
            <>
              <StyledDashboardAccountBalanceAndHistoricWrapper>
                <AccountBalanceWidget withWithdrawButton />
                <ExistingHistoricalTradeWidget />
              </StyledDashboardAccountBalanceAndHistoricWrapper>
              <OpportunitiesExplorerWidget />
              <div>
                <InterestedInvestmentWidget />

                <InvestmentOpportunitiesWidget withPortfolio={withPortfolio} />
              </div>
              <div>
                <MyPortfolioWidget />

                <WatchlistWidget />
                <HelpWidget />
                <GuideWidget digitalGoldTitle />
                <MyPreferencesWidget />
              </div>
            </>
          ) : (
            <>
              <AccountBalanceWidget fundingInfoWidget />
              <OpportunitiesExplorerWidget />
              <div>
                <InterestedInvestmentWidget />
                <InvestmentOpportunitiesWidget withPortfolio={withPortfolio} />
              </div>
              <div>
                <MyPortfolioWidget />
                <WatchlistWidget />
                <HelpWidget />
                <GuideWidget digitalGoldTitle />
                <MyPreferencesWidget />
              </div>
            </>
          )}
        </StyledGrid>
      </StyledAdminInnerPageWrapper>
    </>
  );
};
