import React, { Suspense, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Context,
  AppContext,
  useModelCreatorEffect,
  LoadingComponent,
  NunitoText,
  SelectFieldModelBuilder,
  FlagIcon,
  SubmitButtonFormModelBuilder,
  useModelChanges,
  useFormModelListener,
} from "@portittech/portit-react-common-components";
import {
  StyledNewCurrencyColumnWrapper,
  StyledNewCurrencyPageWrapper,
  StyledSelectFieldWrapper,
  StyledSubmitButtonWrapper,
} from "./newCurrencyWallet.styles";
import {
  StyledAdminInnerPageTitle,
  StyledGrid,
} from "../../shared/styles/page.styles";
import { StyledStickyRightWidgetsWrapper } from "../widgets/common.styles";
import { HelpWidget } from "../widgets/helpWidget";
import { MyPreferencesWidget } from "../widgets/myPreferencesWidget";
import { GuideWidget } from "../widgets/guideWidget";
import { NewCurrencyWalletModel } from "./newCurrencyWalletModel";
import { useHistory } from "react-router-dom";
import { ElviriaPages } from "../../shared/pages";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { AddNewCurrencyModal } from "./addNewCurrencyModal.componet";

export const NewCurrencyWallet = (): React.ReactElement => {
  const { t } = useTranslation(["addNewCurrencyPage"]);
  const history = useHistory();
  const SwalWithReact = withReactContent(Swal);

  const { shopCubit } = useContext<AppContext>(Context);

  const model = useModelCreatorEffect({
    creator: () => {
      return new NewCurrencyWalletModel(shopCubit);
    },
  });

  useModelChanges(model);

  useFormModelListener({
    formModel: model,
    onSubmitFailed: () => {
      SwalWithReact.fire({
        html: (
          <Suspense fallback={LoadingComponent}>
            <AddNewCurrencyModal swalWithReact={SwalWithReact} failed />
          </Suspense>
        ),
        icon: "error",
        iconColor: "red",

        showConfirmButton: false,
        width: "40.625rem",
        showClass: {
          backdrop: "swal2-noanimation", // disable backdrop animation
        },
      }).then((result) => {
        if (result) {
          history.push(ElviriaPages.ACCOUNT);
        }
      });
    },
    onSubmitted: () => {
      SwalWithReact.fire({
        html: (
          <Suspense fallback={LoadingComponent}>
            <AddNewCurrencyModal swalWithReact={SwalWithReact} />
          </Suspense>
        ),
        icon: "success",
        iconColor: "green",

        showConfirmButton: false,
        width: "40.625rem",
        showClass: {
          backdrop: "swal2-noanimation", // disable backdrop animation
        },
      }).then((result) => {
        if (result) {
          history.push(ElviriaPages.ACCOUNT);
        }
      });
    },
  });

  if (!model) return <LoadingComponent />;

  return (
    <StyledNewCurrencyPageWrapper>
      <StyledAdminInnerPageTitle>
        <div>{t("add_new_currency")}</div>
      </StyledAdminInnerPageTitle>
      <StyledGrid>
        <StyledNewCurrencyColumnWrapper>
          <NunitoText>{t("currency_description")}</NunitoText>
          <StyledSelectFieldWrapper>
            <SelectFieldModelBuilder selectFieldModel={model.currencyFM}>
              {(item) => (
                <>
                  <FlagIcon currency={item} flagSize={16} /> {item}
                </>
              )}
            </SelectFieldModelBuilder>
            <StyledSubmitButtonWrapper>
              <SubmitButtonFormModelBuilder
                formModel={model}
                style={{
                  display: "block",
                  margin: "1.25rem auto",
                  borderRadius: 0,
                  marginInline: 0,
                  minWidth: "17rem",
                }}
              >
                {t("add_currency")}
              </SubmitButtonFormModelBuilder>
            </StyledSubmitButtonWrapper>
          </StyledSelectFieldWrapper>
        </StyledNewCurrencyColumnWrapper>
        <div>
          <StyledStickyRightWidgetsWrapper>
            <HelpWidget />
            <MyPreferencesWidget />
            <GuideWidget digitalGoldTitle />
          </StyledStickyRightWidgetsWrapper>
        </div>
      </StyledGrid>
    </StyledNewCurrencyPageWrapper>
  );
};
