import {
  Context,
  RoundedButton,
} from "@portittech/portit-react-common-components";
import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";

import warningIcon from "../../../../assets/warning_icon.svg";

import {
  StyledSorryPageWrapper,
  StyledBoldMessage,
  StyledMessage,
  StyledWarningIcon,
} from "./sorryMessage.style";

export const SorryMessage = () => {
  const { dispatchLogout } = useContext(Context);
  const { t } = useTranslation(["firstLoginKyc"]);
  return (
    <StyledSorryPageWrapper>
      <StyledWarningIcon src={warningIcon} alt="warning-icon" />
      <StyledMessage>
        <Trans
          i18nKey="sorry_message_text"
          t={t}
          components={{ bold: <StyledBoldMessage /> }}
        />
      </StyledMessage>
      <RoundedButton onClick={dispatchLogout}>Exit</RoundedButton>
    </StyledSorryPageWrapper>
  );
};
