import React from "react";
import Chart from "react-apexcharts";

import { useTranslation } from "react-i18next";

import { Tabs } from "antd";
import {
  StyledPortfolioChartsMainWrapper,
  StyledTabWrapper,
} from "./portfolioCharts.styles";
import { getSubT } from "@portittech/portit-react-common-components";

import { fontFamilyMontserrat } from "../../../../shared/styles/generic";
import { formatCurrencyNumber } from "../../../../shared/helpers/formatCurrencyNumber";
import {
  InvestmentByAssetsClass,
  InvestmentByOpportunity,
} from "../../../../domain/features/investment/entities";
import { isEmpty } from "ramda";
import { EmptyPortfolio } from "../../empty-portfolio/emptyPorfolio.component";

interface PortfolioChartsProps {
  investmentsByOpportunity: Array<InvestmentByOpportunity>;
  investmentsByAssetClass: Array<InvestmentByAssetsClass>;
}

export const PortfolioCharts = ({
  investmentsByOpportunity,
  investmentsByAssetClass,
}: PortfolioChartsProps) => {
  const { t } = useTranslation(["portfolio"]);
  const tc = getSubT(t, "portfolio_charts_component");

  const { TabPane } = Tabs;


  const colorsByAssetClass = investmentsByAssetClass.map((invest) => invest.color);

  const labelsByAssetClass = investmentsByAssetClass.map(
    (investment) => investment.assetClass
  );

  const seriesByAssetClass = investmentsByAssetClass.map(
    (invest) => invest.noTokens
  );

  const chartDataByAssetClass = {
    options: {
      series: seriesByAssetClass,
      labels: labelsByAssetClass,
      colors: colorsByAssetClass,
      plotOptions: {
        pie: {
          donut: {
            size: "45px",
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                formatter: () => String(labelsByAssetClass.length),
              },
            },
          },
        },
      },
    },
    series: seriesByAssetClass,
  };

  const colors = investmentsByOpportunity.map((invest) => invest.color);

  const series = investmentsByOpportunity.map((invest) =>
    Number(invest.knownValue)
  );

  const labelsByOpportunity = investmentsByOpportunity.map(
    (invest) => invest.opportunity.name
  );

  const chartDataByOpportunity = {
    options: {
      series,
      labels: labelsByOpportunity,
      colors,
      plotOptions: {
        pie: {
          donut: {
            size: "45px",
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                formatter: () => String(labelsByOpportunity.length), // To do here should be the length of the labels
              },
            },
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "0.85rem",
          fontFamily: fontFamilyMontserrat,
        },
        y: {
          formatter: (value: string | number) => formatCurrencyNumber(value),
        },
      },
    },
    series,
  };

  return (
    <StyledPortfolioChartsMainWrapper>
      <Tabs type="card" destroyInactiveTabPane>
        <TabPane tab={tc("portfolio_concentration_tab")} key={1}>
          <StyledTabWrapper>
            {
              isEmpty(investmentsByOpportunity)
                ? <EmptyPortfolio emptyInvestments />
                : <Chart
                  options={chartDataByOpportunity.options}
                  series={chartDataByOpportunity.series}
                  type="donut"
                  width={550}
                />
            }

          </StyledTabWrapper>
        </TabPane>
        <TabPane tab={tc("asset_class_tab")} key={2}>
          <StyledTabWrapper>
            {
              isEmpty(investmentsByAssetClass)
                ? <EmptyPortfolio emptyInvestments circleText="assetClass" />
                : <Chart
                  options={chartDataByAssetClass.options}
                  series={chartDataByAssetClass.series}
                  type="donut"
                  width={450}
                />
            }
          </StyledTabWrapper>
        </TabPane>
      </Tabs>
    </StyledPortfolioChartsMainWrapper>
  );
};
