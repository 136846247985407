import React, { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";

export const PrivacyComponent = () => {
  const history = useHistory();
  useEffect(() => {
    window.open(process.env.REACT_APP_PRIVACY_LINK, "_blank");
  }, []);
  return <Redirect to={(history.location?.state as any)?.from ?? "/"} />;
};
