import styled from "styled-components";
import { colorGrey, colorMainDark } from "../../shared/styles/generic";

export const StyledFooter = styled.div`
  margin-top: 4.0625rem;
  grid-column: 1/-1;
  justify-content: center;
  text-align: center;
  padding: 1.875rem 0;
  border-top: 1px solid ${colorGrey};
  font-size: 0.75rem;
`;

export const StyledFooterNavbar = styled.div`
  margin: auto;
`;

export const StyledFooterLink = styled.a`
  margin: 0.4375rem;
  padding-right: 0.9375rem;
  color: ${colorMainDark};
  border-right: 1px solid ${colorMainDark};
  font-size: 1rem;
  &:last-child {
    border-right: none;
  }
`;

export const StyledFooterDescription = styled.div`
  margin-top: 1.5625rem;
`;
