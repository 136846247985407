import { isLeft } from "fp-ts/Either";
import { distinctUntilChanged, first } from "rxjs/operators";

import {
  CustomerCubit,
  CustomerData,
  FetchedEvent,
  MultiModel,
  BasePageModel,
} from "@portittech/portit-react-common-components";

import { getInvestments } from "../../../shared/usecases";
import { InvestmentResponse } from "../../../domain/features/investment/entities";
import { InvestmentsSearcherFormModel } from "./investmentsSearcherFormModel";
import { InvestmentsStrainer } from "./types";

export class InvestmentsPageModel extends BasePageModel {
  readonly investmentsPageSize = 8;
  readonly searcherFormModel: InvestmentsSearcherFormModel;
  readonly investmentsModel = new MultiModel<
    InvestmentResponse,
    InvestmentsStrainer
  >({ canWaitStrainer: true });

  constructor({ customerCubit }: { customerCubit: CustomerCubit }) {
    super();
    this.searcherFormModel = new InvestmentsSearcherFormModel({});

    // Fetch page with filter
    this.investmentsModel.applyFetcher(async (section, strainer) => {
      // If not exist any account the strainer is undefined
      if (!strainer) return new FetchedEvent([], 0);

      const page = section.toPage(this.investmentsPageSize);

      const res = await getInvestments.run({
        pagination: {
          //todo Add date to, date from and type here when Api is ready to receive them
          opportunityName: strainer.text ?? undefined,
          page: page,
          size: this.investmentsPageSize,
        },
      });

      if (isLeft(res)) {
        // Note: replaced FetchFailedEvent(res.left); with FetchedEvent([]);
        // because res.left is returning 404 error from server instead of empty array, which we require in IterableModelBuilder to render component from emptyBuilder's prop
        return new FetchedEvent([]);
      } else {
        return new FetchedEvent(
          res.right.investmentsList,
          res.right.pagination.total_entries
        );
      }
    });

    // Update filter when user changes
    this.closer.add(
      this.searcherFormModel.listenOnStatusChange({
        onLoadFailed: () => {
          this.investmentsModel.applyStrainer(undefined);
        },
        onSubmitted: () => {
          this.investmentsModel.applyStrainer(
            this.searcherFormModel.successResponse
          );
        },
      })
    );

    // refresh the component when the user switches profile
    this.closer.add(
      customerCubit.onChanges
        .pipe(distinctUntilChanged(CustomerData.checkProfileChanged))
        .subscribe(() => {
          void this.investmentsModel.reFetch();
        })
    );
  }

  async onLoading() {
    await this.searcherFormModel.onLastChange
      .pipe(first((it) => it.status !== "loading"))
      .toPromise();

    this.searcherFormModel.enableAutoSubmit();

    this.notifyLoaded();
  }
}
