import {
  Failure,
  TryUseCase,
} from "@portittech/portit-react-common-components";
import { Either, right } from "fp-ts/lib/Either";
import { PreferencesReferenceDataResponse } from "./entities";
import { PreferencesReferenceDataRepository } from "./repository";

/**
 * Use this usecase to retrieve the possible values of the investment preferences
 */
export class GetPreferencesReferenceData extends TryUseCase<
  void,
  PreferencesReferenceDataResponse
> {
  constructor(private readonly repository: PreferencesReferenceDataRepository) {
    super();
  }

  protected async tryRun(
    params: void
  ): Promise<Either<Failure, PreferencesReferenceDataResponse>> {
    return right(await this.repository.getPreferencesReferenceData());
  }
}
