import {
  AuthState,
  useAppStore,
} from "@portittech/portit-react-common-components";
import { useState, useEffect, useCallback } from "react";
import { OpportunityResponse } from "../../domain/features/opportunities/entities";
import { useRequest } from "../../shared/hooks/useRequest";
import {
  getWatchlistOpportunities,
  addOpportunityToWatchlist,
  removeOpportunityFromWatchlist,
} from "../../shared/usecases";

export const useWatchlist = () => {
  const { authState } = useAppStore();
  const [watchlistOpportunities, setWatchlistOpportunities] =
    useState<Array<OpportunityResponse>>();

  const { request: requestWatchlistOpportunities, isFetching } = useRequest(
    getWatchlistOpportunities
  );

  const { request: addToWatchlist } = useRequest(addOpportunityToWatchlist);

   const { request: removeFromWatchlist } = useRequest(removeOpportunityFromWatchlist);

  const fetchOpportunities = useCallback(
    () =>
      requestWatchlistOpportunities().then((res) =>
        setWatchlistOpportunities(res.opportunityList)
      ),
    [requestWatchlistOpportunities]
  );

 

  const onToggleOppToWatchlist = (opportunityId: number) => {
    const opportunityAlreadyAdded = watchlistOpportunities.some(
    (opp) => opp.id === opportunityId
    );
    if (opportunityAlreadyAdded) {
      removeFromWatchlist({ opportunityId }).then((res) =>
      setWatchlistOpportunities(res.opportunityList)
      )
    } else {
      addToWatchlist({ opportunityId }).then((res) =>
      setWatchlistOpportunities(res.opportunityList)
    )}
    
  };




  useEffect(() => {
    if (authState === AuthState.LOGGED_IN) {
      fetchOpportunities();
    }
  }, [fetchOpportunities, authState]);

  return {
    isWatchListLoading: isFetching,
    watchlistOpportunities,
    onToggleOppToWatchlist
  };
};
