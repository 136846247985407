import React, { useContext, useState } from "react";

import {
  Context,
  PageModelBuilder,
  useModelChanges,
  useModelCreatorEffect,
  MessageEntity,
} from "@portittech/portit-react-common-components";

import { MessageDetails } from "./components/messageDetails.component";
import { MessagesList } from "./components/messagesList.component";
import { MessagingPageModel } from "./models/messagingPageModel";
import { MessagingPageStyle } from "./style/messagingPage.style";
import { StyledAdminInnerPageTitle } from "../../shared/styles/page.styles";
import { useTranslation } from "react-i18next";
import { StyledSingleMessageSideWidgetsWrapper } from "./style/singleMessage.style";
import { HelpWidget } from "../widgets/helpWidget";
import { GuideWidget } from "../widgets/guideWidget";
import { MyPreferencesWidget } from "../widgets/myPreferencesWidget";

export const MessagingPage = (): React.ReactElement => {
  const { customerCubit } = useContext(Context);
  const { t } = useTranslation(["messaging"]);

  const model = useModelCreatorEffect(() => {
    return new MessagingPageModel(customerCubit);
  });

  const [currentMessage, setCurrentMessage] = useState<
    undefined | MessageEntity
  >(undefined);

  useModelChanges(model?.selectedProfileModel);

  return (
    <PageModelBuilder pageModel={model}>
      {() => {
        if (currentMessage) {
          return (
            <MessagingPageStyle>
              <StyledAdminInnerPageTitle>
                {t("title")}
              </StyledAdminInnerPageTitle>

              <MessageDetails
                message={currentMessage}
                onBackClick={() => setCurrentMessage(undefined)}
              />
              <StyledSingleMessageSideWidgetsWrapper>
                <HelpWidget />
                <GuideWidget digitalGoldTitle />
                <MyPreferencesWidget />
              </StyledSingleMessageSideWidgetsWrapper>
            </MessagingPageStyle>
          );
        }

        return (
          <MessagingPageStyle>
            <StyledAdminInnerPageTitle>{t("title")}</StyledAdminInnerPageTitle>
            <MessagesList
              messagingPageModel={model}
              onMessageClick={setCurrentMessage}
            />

            <StyledSingleMessageSideWidgetsWrapper>
              <HelpWidget />
              <GuideWidget digitalGoldTitle />
              <MyPreferencesWidget />
            </StyledSingleMessageSideWidgetsWrapper>
          </MessagingPageStyle>
        );
      }}
    </PageModelBuilder>
  );
};
