import React from "react";
import { AccountTransactionDetailsEntity } from "@portittech/portit-react-common-components";

interface TransactionTypeNotFoundProps {
  accountTransaction: AccountTransactionDetailsEntity;
}

export const TransactionTypeNotFound = ({
  accountTransaction,
}: TransactionTypeNotFoundProps): React.ReactElement => {
  return (
    <>
      Missing ui information of {accountTransaction.transaction.type}.
      {accountTransaction.transaction.sub_type}
      transaction
    </>
  );
};
