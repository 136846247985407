import { Link } from "react-router-dom";
import styled from "styled-components";
import { TextButton } from "../../../../shared/styles/button";
import {
  colorBlue,
  colorMain,
  colorMainWhite,
  fontFamilyEncodeSansExpanded,
  fontFamilyMontserrat,
} from "../../../../shared/styles/generic";

export const StyledListMessage = styled.div`
  padding: 4rem 0 3.2rem 3.2rem;
  box-shadow: -5px 5px 40px rgba(0, 0, 0, 0.07);
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: space-between;
  width: 35%;
  background-color: ${colorMainWhite};
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;

  .collapsible {
    flex: 1;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  .ant-btn {
    padding-left: 0.75rem;
  }
`;

export const StyledSpanServicesBadge = styled.div`
  font-family: ${fontFamilyMontserrat};
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 1.25rem;
  letter-spacing: 0.0625rem;
  color: #37383c;
`;

export const StyledMessagesText = styled.div`
  font-family: "Comfortaa", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 2.8125rem;
  letter-spacing: 0.125rem;
  color: ${colorMain};
`;

export const StyledSettingsPanelText = styled(StyledMessagesText)`
  padding-left: 0.75rem;
`;

export const StyledLogOutWrapper = styled.div`
  position: absolute;
  bottom: 2.5rem;
  left: 0.5rem;
`;

export const StyledLogoutTextButton = styled(TextButton)`
  color: ${colorBlue};

  svg {
    margin-right: 0.3125rem;
  }
`;

export const StyledLeftPanelLink = styled(Link)`
  font-family: ${fontFamilyEncodeSansExpanded};
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  color: ${colorMain};
  display: block;
  cursor: pointer;

  :not(:first-of-type) {
    margin-top: 2rem;
  }
`;
