import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { OpportunityResponse } from "../../../domain/features/opportunities/entities";
import { ElviriaPages } from "../../../shared/pages";
import { Logo } from "../../logo/logo.component";

import {
  StyledInterestedInvestmentCardAssetClass,
  StyledInterestedInvestmentCardAssetName,
} from "../interested-investment-widget/interestedInvestmentWidget.styles";
import { WatchlistCardWrapper } from "./watchlistWidget.style";

export const WatchListCard = ({
  logoImageId,
  id,
  name,
  assetClass,
}: OpportunityResponse) => {
  const { t: tApi } = useTranslation(["apiText"]);
  const history = useHistory();

  return (
    <WatchlistCardWrapper
      onClick={() => history.push(`${ElviriaPages.OPPORTUNITIES}/${id}`)}
    >
      <Logo logoId={logoImageId} size={4} />
      <div>
        <StyledInterestedInvestmentCardAssetName>
          {name}
        </StyledInterestedInvestmentCardAssetName>
        <StyledInterestedInvestmentCardAssetClass>
          {tApi(assetClass)}
        </StyledInterestedInvestmentCardAssetClass>
      </div>
    </WatchlistCardWrapper>
  );
};
