import React from "react";
import { useTranslation } from "react-i18next";

import { getSubT } from "@portittech/portit-react-common-components";

import { OpportunityResponse } from "../../../domain/features/opportunities/entities";
import { formatCurrencyNumber } from "../../../shared/helpers/formatCurrencyNumber";
import { getHorizonString } from "../../../shared/helpers/getHorizonString";
import { ElviriaPages } from "../../../shared/pages";
import { Logo } from "../../logo/logo.component";
import { StyledRouterLink } from "../../widgets/common.styles";
import {
  StyledOpportunitySingleCardMainWrapper,
  StyledOpportunitySingleCardTitle,
  StyledOpportunitySingleCardDetailRowWrapper,
  StyledOpportunitySingleCardDetailTitle,
  StyledOpportunitySingleCardViewLiknWrapper,
  StyledOpportunitySingleCardImgAndTitleWrapper,
  StyledOpportunitySingleCardDetailValue,
} from "./opportunitiesCardView.styles";

export const InvestmentOpportunitySingleCard = ({
  id,
  name,
  sector,
  assetClass,
  minRequested,
  maxRequested,
  horizon,
  stage,
  logoImageId,
  currency
}: OpportunityResponse) => {
  const { t: tApi } = useTranslation(["apiText"]);

  const { t } = useTranslation(["opportunitiesPage"]);
  const tc = getSubT(t, "opportunities_widget");

  return (
    <StyledOpportunitySingleCardMainWrapper>
      <StyledOpportunitySingleCardImgAndTitleWrapper>
        <Logo logoId={logoImageId} size={3.75} />
        <StyledOpportunitySingleCardTitle>
          {name}
        </StyledOpportunitySingleCardTitle>
      </StyledOpportunitySingleCardImgAndTitleWrapper>
      <StyledOpportunitySingleCardDetailRowWrapper>
        <div>
          <StyledOpportunitySingleCardDetailTitle>
            {tc("asset_class_title")}
          </StyledOpportunitySingleCardDetailTitle>
          <StyledOpportunitySingleCardDetailValue>
            {tApi(assetClass)}
          </StyledOpportunitySingleCardDetailValue>
        </div>

        <div>
          <StyledOpportunitySingleCardDetailTitle>
            {tc("invest_goal_title")}
          </StyledOpportunitySingleCardDetailTitle>
          <StyledOpportunitySingleCardDetailValue>
            {formatCurrencyNumber(maxRequested, currency)}
          </StyledOpportunitySingleCardDetailValue>
        </div>
      </StyledOpportunitySingleCardDetailRowWrapper>
      <StyledOpportunitySingleCardDetailRowWrapper>
        <div>
          <StyledOpportunitySingleCardDetailTitle>
            {tc("min_invest_title")}
          </StyledOpportunitySingleCardDetailTitle>
          <StyledOpportunitySingleCardDetailValue>
            {formatCurrencyNumber(minRequested, currency)}
          </StyledOpportunitySingleCardDetailValue>
        </div>

        <div>
          <StyledOpportunitySingleCardDetailTitle>
            {tc("invest_horizon_title")}
          </StyledOpportunitySingleCardDetailTitle>
          <StyledOpportunitySingleCardDetailValue>
            {getHorizonString(horizon)}
          </StyledOpportunitySingleCardDetailValue>
        </div>
      </StyledOpportunitySingleCardDetailRowWrapper>

      <StyledOpportunitySingleCardDetailRowWrapper>
        <div>
          <StyledOpportunitySingleCardDetailTitle>
            {tc("sector_title")}
          </StyledOpportunitySingleCardDetailTitle>
          <StyledOpportunitySingleCardDetailValue>
            {tApi(sector)}
          </StyledOpportunitySingleCardDetailValue>
        </div>

        <div>
          <StyledOpportunitySingleCardDetailTitle>
            {tc("invest_stage_title")}
          </StyledOpportunitySingleCardDetailTitle>
          <StyledOpportunitySingleCardDetailValue>
            {stage ? tApi(stage) : "N/A"}
          </StyledOpportunitySingleCardDetailValue>
        </div>
      </StyledOpportunitySingleCardDetailRowWrapper>

      <StyledOpportunitySingleCardViewLiknWrapper>
        <StyledRouterLink to={`${ElviriaPages.OPPORTUNITIES}/${id}`}>
          {tc("view_link")}
        </StyledRouterLink>
      </StyledOpportunitySingleCardViewLiknWrapper>
    </StyledOpportunitySingleCardMainWrapper>
  );
};
