import styled from "styled-components";
import { colorMain, colorMainDark } from "../../shared/styles/generic";
import { devices } from "../config/devices";

export const StyledPendingVerificationPage = styled.div`
  padding: 5rem;
  height: 100vh;

  @media ${devices.tablet} {
    padding: 1.875rem 1.25rem;
  }
`;

export const StyledPendingVerificationTitle = styled.div`
  padding-top: 4rem;
  font-family: Encode Sans Expanded;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 4.375rem;
  letter-spacing: 0rem;
  text-align: center;
  color: ${colorMain};

  @media ${devices.tablet} {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`;

export const StyledPendingVerificationSubtitle = styled.div`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.25rem;
  text-align: center;
  color: ${colorMainDark};
  text-transform: uppercase;
  padding: 2rem;

  @media ${devices.tablet} {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
`;

export const StyledPendingVerificationText = styled.div`
  font-family: "Montserrat";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: 0rem;
  text-align: center;
  color: ${colorMainDark};

  @media ${devices.tablet} {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
`;
export const StyledPendingVerificationLogo = styled.div``;

export const StyledPendingVerificationLogoCenter = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2rem;

  @media ${devices.tablet} {
    img {
      width: 6.25rem;
    }
  }
`;

export const StyledPendingVerificationButton = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
`;
