/**
 * This component is copied entirely from quid/toonie
 * We need this only Temporary until the workflow for investment contract will be fixed in BE
 */

import { isLeft } from "fp-ts/Either";

import {
  ShopCubit,
  AccountOrderCubit,
  Failure,
  UnknownSwitchTypeError,
  NamedStepContent,
  NamedStepModel,
  createBalance,
  readProducts,
} from "@portittech/portit-react-common-components";

export const stages = ["kyc", "wallet"] as const;
export type Stage = typeof stages[number];

export type WalletOpeningStatus =
  | "loading"
  | "loaded"
  | "loadFailed"
  | "failed"
  | "completed";

export type WalletOpeningSubStatus = "accountAndWallet" | "wallet";

export class WalletOpeningModel extends NamedStepModel<
  NamedStepContent<Stage>
> {
  private _status: WalletOpeningStatus = "loading";
  private _subStatus: WalletOpeningSubStatus;
  private _failure: Failure;
  private _shopCubit: ShopCubit;
  private _orderCubit: AccountOrderCubit;
  private _currency?: string;
  private _productId?: number;

  get status(): WalletOpeningStatus {
    return this._status;
  }

  get subStatus(): WalletOpeningSubStatus {
    return this._subStatus;
  }

  get failure(): Failure {
    return this._failure;
  }

  get currency(): string {
    return this._currency;
  }

  constructor({ shopCubit }: { shopCubit: ShopCubit }) {
    super(
      stages.map(name => ({ name })),
      "kyc"
    );
    this._shopCubit = shopCubit;
    void Promise.resolve().then(() => this.onLoading());
  }

  async onLoading(): Promise<void> {
    await this._shopCubit.wait();
    const res = await this._shopCubit.read();

    if (isLeft(res)) {
      this._failure = res.left;
      this._status = "failed";
      this.notifyChanges();
      return;
    }

    // Temporary until the workflow for investment contract will be fixed in BE
    const prodRes = await readProducts.run();
    if (isLeft(prodRes)) {
      this._failure = prodRes.left
      return
    }
    const product = prodRes.right.find(prod => prod.description === "Account");
    this._productId = product.id;


    this._orderCubit = this._shopCubit.findOrDelegateAccountOrder();
    this.closer.add(this._orderCubit);

    this._status = "loaded";
    this.notifyChanges();
  }

  async completeKycStage() {
    await this.goTo("wallet");
  }

  async completeWalletStage(currency: string): Promise<void> {
    this._currency = currency;

    const orderData = this._orderCubit.state.data;

    // If account/wallet not exist create it
    if (!orderData?.account) {
      const res = await this._orderCubit.create({ currency, productId: this._productId });
      if (isLeft(res)) {
        this._failure = res.left;
        this._status = "failed";
        this.notifyChanges();
        return;
      }
      this._failure = undefined;
      this._status = "completed";
      this._subStatus = "accountAndWallet";
      this.notifyChanges();
      return;
    }

    switch (orderData.account.status) {
      case "pending":
      case "pending_provider":
        this._subStatus = "accountAndWallet";
        this._status = "completed";
        this.notifyChanges();
        return;
      // Confirm creation of the current wallet
      case "pending_customer": {
        const res = await this._orderCubit.confirm();
        if (isLeft(res)) {
          this._failure = res.left;
          this._status = "failed";
          this.notifyChanges();
          return;
        }
        this._failure = undefined;
        this._status = "completed";
        this._subStatus = "accountAndWallet";
        this.notifyChanges();
        return;
      }
      // Create a new wallet
      case "enabled": {
        const res = await createBalance.run({
          accountId: orderData.account.account_id,
          currency,
        });

        if (isLeft(res)) {
          this._failure = res.left;
          this._status = "failed";
          this.notifyChanges();
          return;
        }
        this._failure = undefined;
        this._status = "completed";
        this._subStatus = "wallet";
        this.notifyChanges();
        return;
      }
      default:
        // TODO: Manage all account status in WalletOpeningModel
        throw new UnknownSwitchTypeError(
          "Account.status",
          orderData.account.status
        );
    }
  }
}
