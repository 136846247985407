import React from "react";
import { useTranslation } from "react-i18next";

import { SweetAlert2 } from "sweetalert2-react-content";

import {
  StyledExternalLink,
  StyledSmallWidgetTitle,
} from "../widgets/common.styles";

interface AddNewCurrencyModalProps {
  swalWithReact: SweetAlert2;
  failed?: boolean;
}

export const AddNewCurrencyModal = ({
  swalWithReact,
  failed,
}: AddNewCurrencyModalProps) => {
  const { t } = useTranslation(["addNewCurrencyPage"]);

  return (
    <>
      <StyledSmallWidgetTitle>
        {failed ? t("modal_failed") : t("modal_success")}
      </StyledSmallWidgetTitle>
      <StyledExternalLink onClick={swalWithReact.clickConfirm}>
        {t("close_button")}
      </StyledExternalLink>
    </>
  );
};
