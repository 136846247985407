import { getSubT } from "@portittech/portit-react-common-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoWidgetHelpIcon } from "../../assets/icons/help.svg";

import { ElviriaPages } from "../../shared/pages";

import { InfoWidget } from "./info-widget/infoWidget.component";

import { StyledExternalLink, StyledRouterLink } from "./common.styles";
import { colorMainDark } from "../../shared/styles/generic";

export const HelpWidget = () => {
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "info_widget");
  return (
    <InfoWidget
      title={tc("need_help_link")}
      icon={<InfoWidgetHelpIcon fill={colorMainDark} stroke={colorMainDark} />}
    >
      <StyledRouterLink to={ElviriaPages.HELP}>
        {tc("contact_us_link")}
      </StyledRouterLink>
      <span> {tc("or_read")} </span>
      <StyledExternalLink href={process.env.REACT_APP_FAQ_LINK} target="_blank">
        {tc("faq_link")}
      </StyledExternalLink>
    </InfoWidget>
  );
};
