import styled from "styled-components";

import {
  colorMainDark,
  fontFamilyMontserrat,
} from "../../../shared/styles/generic";

export const StyledDataRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.875rem;
`;

export const StyledDataWrapper = styled.div`
  font-family: ${fontFamilyMontserrat};
  color: ${colorMainDark};
  cursor: pointer;
`;

export const StyledDescription = styled.div`
  font-family: ${fontFamilyMontserrat};
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5rem;
`;
