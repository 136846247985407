import { of } from "rxjs";

import {
  CustomerCubit,
  Failure,
  FieldValidators,
  FormModel,
  TextFieldModel,
} from "@portittech/portit-react-common-components";

import { BooleanFieldModel } from "../../shared/models/booleanFieldModel";
import { LastStepType } from "../investmentsInfo/types";
import { createInvestmentsKycInfo } from "../../shared/usecases";
import { CreateInvestmentKycInfoRequest } from "../../domain/features/investmentsKycInfo/entities";
import { isLeft } from "fp-ts/lib/Either";

export class InvestmentsInfoKycModel extends FormModel<any, Failure> {
  readonly customerCubit: CustomerCubit;

  setLastStep: (step: string) => void;

  readonly isProfessionalInvestorFM = new BooleanFieldModel({
    validators: [FieldValidators.required],
  });

  readonly globalPortfolioSizeFM = new TextFieldModel({
    validators: [FieldValidators.required],
  });

  readonly investedInIlliquidFM = new BooleanFieldModel({
    validators: [FieldValidators.required],
  });

  readonly investedTokenizationFM = new BooleanFieldModel({
    validators: [FieldValidators.required],
  });

  readonly digitalWalletOwnFM = new BooleanFieldModel({
    validators: [FieldValidators.required],
  });

  readonly haveIdeaHowMuchToAllocateFM = new BooleanFieldModel({
    validators: [FieldValidators.required],
  });

  readonly approximatelyInvestSumFM = new TextFieldModel({
    validators: [FieldValidators.required],
  });

  readonly mostLikedAssetsFM = new TextFieldModel({
    validators: [FieldValidators.required],
  });

  readonly acceptsPolicyFM = new BooleanFieldModel({
    validators: [FieldValidators.required, FieldValidators.mustBeTrue],
  });

  constructor({
    setLastStep,
    customerCubit,
  }: {
    setLastStep: (step: LastStepType) => void;
    customerCubit: CustomerCubit;
  }) {
    super({ isLoading: true });
    this.setLastStep = setLastStep;
    this.customerCubit = customerCubit;

    this.notifyLoaded();

    this.addFieldModels({
      fieldsModels: [this.isProfessionalInvestorFM],
    });

    this.addSubscription(
      this.isProfessionalInvestorFM.listenValueChange({
        onData: (prev, current) => {
          if (current) {
            this.addFieldModels({
              fieldsModels: [
                this.globalPortfolioSizeFM,
                this.investedInIlliquidFM,
              ],
            });
          } else {
            this.removeFieldModels({
              fieldsModels: [
                this.globalPortfolioSizeFM,
                this.investedInIlliquidFM,
                this.investedTokenizationFM,
                this.digitalWalletOwnFM,
                this.haveIdeaHowMuchToAllocateFM,
                this.mostLikedAssetsFM,
                this.approximatelyInvestSumFM,
                this.acceptsPolicyFM,
              ],
            });
          }
          return of(undefined);
        },
      })
    );
    this.addSubscription(
      this.investedInIlliquidFM.listenValueChange({
        onData: (prev, current) => {
          if (current) {
            this.addFieldModels({
              fieldsModels: [
                this.investedTokenizationFM,
                this.digitalWalletOwnFM,
                this.haveIdeaHowMuchToAllocateFM,
                this.approximatelyInvestSumFM,
                this.acceptsPolicyFM,
              ],
            });
          } else {
            this.removeFieldModels({
              fieldsModels: [
                this.investedTokenizationFM,
                this.digitalWalletOwnFM,
                this.haveIdeaHowMuchToAllocateFM,
                this.approximatelyInvestSumFM,
                this.acceptsPolicyFM,
              ],
            });
          }
          return of(undefined);
        },
      })
    );

    this.addSubscription(
      this.haveIdeaHowMuchToAllocateFM.listenValueChange({
        onData: (prev, current) => {
          if (current) {
            this.addFieldModels({
              fieldsModels: [
                this.approximatelyInvestSumFM,
                this.acceptsPolicyFM,
              ],
            });
            this.removeFieldModels({
              fieldsModels: [this.mostLikedAssetsFM],
            });
          } else {
            this.removeFieldModels({
              fieldsModels: [this.approximatelyInvestSumFM],
            });
            this.addFieldModels({
              fieldsModels: [this.mostLikedAssetsFM, this.acceptsPolicyFM],
            });
          }
          return of(undefined);
        },
      })
    );
  }

  async onSubmitting() {
    if (this.isProfessionalInvestorFM.value === false) {
      this.setLastStep("incomplete");
    }

    const request: CreateInvestmentKycInfoRequest = {
      investmentInfo: {
        professionalInvestor: this.isProfessionalInvestorFM.value,
        portfolio: this.globalPortfolioSizeFM.value,
        investedIliquid: this.investedInIlliquidFM.value,
        investedTokenizacion: this.investedTokenizationFM.value,
        walletOwner: this.digitalWalletOwnFM.value,
        ideaAllocateInvestment: this.haveIdeaHowMuchToAllocateFM.value,
        quantityAllocateInvestment: this.approximatelyInvestSumFM.value,
        mostLikedAssets: this.mostLikedAssetsFM.value,
      },
    };

    const res = await createInvestmentsKycInfo.run({
      request,
    });

    if (isLeft(res)) {
      this.notifySubmitFailed({ response: res.left });
      return;
    }

    this.notifySubmitted({ canSubmitAgain: false });
  }
}
