import styled from "styled-components";
import {
  colorBlue,
  colorDarkGrey,
  colorMain,
  colorMainDark,
  fontFamilyEncodeSansExpanded,
  fontFamilyMontserrat,
} from "../../shared/styles/generic";

export const StyledSettingsInnerBlockWrapper = styled.div`
  font-family: ${fontFamilyMontserrat};
  color: ${colorMainDark};
  padding: 2.1875rem 2.5rem;
  margin-bottom: 2.125rem;
  box-shadow: -5px 5px 40px 0px rgba(0, 0, 0, 0.1);
`;

export const StyledSettingsInnerBlockTitle = styled.h2`
  font-family: ${fontFamilyEncodeSansExpanded};
  color: ${colorMain};
  font-size: 1.375rem;
  line-height: 110%;
`;

interface SettingsInnerBlockRowProps {
  isBankRow?: boolean;
}

export const StyledSettingsInnerBlockRow = styled.div<SettingsInnerBlockRowProps>`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ isBankRow }) =>
    isBankRow ? "1fr 3fr 3fr 1fr" : "3fr 3fr 1fr"};
  line-height: 1.875rem;
  gap: ${({ isBankRow }) => (isBankRow ? "1.25rem" : 0)};

  .ant-input {
    font-weight: 600;
    /* border: none; */
  }

  .ant-input:disabled {
    cursor: default;
    background-color: #fff;
    color: ${colorMainDark};
    border: none;
  }
`;

export const StyledSettingsEditLinkWrapper = styled.div`
  text-align: right;
`;

export const StyledSettingsEditLink = styled.a`
  font-weight: 400;
  text-decoration: underline;
  color: ${colorBlue};
  font-size: 0.875rem;
`;

export const StyledSettingsSwiftWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
`;

export const StyledPasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.688rem;
  font-weight: 600;
  font-size: 0.875rem;

  .ant-btn-circle:hover {
    svg {
      color: ${colorDarkGrey} !important;
    }
  }
`;
