import { InvestmentsPreferencesRepositoryImpl } from "../repositories/features/preference";
import { InvestmentsKycInfoRepositoryImpl } from "../repositories/features/investmentsKycInfo";
import { OpportunitiesRepositoryImpl } from "../repositories/features/opportunities";
import {
  apiClient,
  StripeShopRepository,
} from "@portittech/portit-react-common-components";
import {
  CreateInvestmentsInfo,
  CreatePreferences,
  GetInvestmentsInfoData,
  GetPreferences,
  UpdatePreferences,
} from "../domain";
import {
  AddOpportunityToWatchlist,
  GetInterestedOpportunities,
  GetOpportunitiesData,
  GetOpportunitiesImg,
  GetOpportunityDataFile,
  GetSingleOpportunity,
  GetWatchlistOpportunities,
  RemoveOpportunityFromWatchlist,
} from "../domain/features/opportunities/usecases";
import { NewsRepositoryImpl } from "../repositories/features/news";
import { GetNewsData } from "../domain/features/news/usecases";
import { InvestmentRepositoryImpl } from "../repositories/features/investment";
import {
  CreateInvestment,
  GetInvestmentConvertedAmount,
  GetInvestments,
  GetInvestmentsCompletedAndPending,
  GetInvestmentsConcentrationByAssetsClass,
  GetInvestmentsConcentrationByOpportunity,
  GetInvestmentsSum,
  OpenInvestmentContract,
} from "../domain/features/investment/usecases";
import { PreferencesReferenceDataRepositoryImpl } from "../repositories/features/reference-data";
import { GetPreferencesReferenceData } from "../domain/features/reference-data/usecases";

/* user Preferences data at first login */

const investmentsRepository = new InvestmentsPreferencesRepositoryImpl(
  apiClient
);
export const getInvestmentsPreferences = new GetPreferences(
  investmentsRepository
);
export const createInvestmentsPreferences = new CreatePreferences(
  investmentsRepository
);
export const updateInvestmentsPreferences = new UpdatePreferences(
  investmentsRepository
);

/* References Data for preferences */

const referencesDataRepository = new PreferencesReferenceDataRepositoryImpl(
  apiClient
);

export const getInvestmentsPreferencesReferenceData =
  new GetPreferencesReferenceData(referencesDataRepository);

/* user Investments Kyc data at first login */

const investmentsKycInfoRepository = new InvestmentsKycInfoRepositoryImpl(
  apiClient
);

export const createInvestmentsKycInfo = new CreateInvestmentsInfo(
  investmentsKycInfoRepository
);

export const getInvestmentsKycInfoData = new GetInvestmentsInfoData(
  investmentsKycInfoRepository
);

/* Opportunities data */

const opportunitiesRepository = new OpportunitiesRepositoryImpl(apiClient);

export const getOpportunitiesData = new GetOpportunitiesData(
  opportunitiesRepository
);

export const getOpportunityImg = new GetOpportunitiesImg(
  opportunitiesRepository
);

export const getInterestedOpportunities = new GetInterestedOpportunities(
  opportunitiesRepository
);

export const getSingleOpportunity = new GetSingleOpportunity(
  opportunitiesRepository
);

export const getOpportunityDataFile = new GetOpportunityDataFile(
  opportunitiesRepository
);

export const addOpportunityToWatchlist = new AddOpportunityToWatchlist(
  opportunitiesRepository
);

export const removeOpportunityFromWatchlist =
  new RemoveOpportunityFromWatchlist(opportunitiesRepository);

export const getWatchlistOpportunities = new GetWatchlistOpportunities(
  opportunitiesRepository
);

/* News data */

const newsRepository = new NewsRepositoryImpl(apiClient);

export const getNewsData = new GetNewsData(newsRepository);

/* Investments Data */

const investmentRepository = new InvestmentRepositoryImpl(apiClient);

export const getInvestments = new GetInvestments(investmentRepository);

export const getInvestmentsConcentrationByOpportunity =
  new GetInvestmentsConcentrationByOpportunity(investmentRepository);

export const getInvestmentsConcentrationByAssetClass =
  new GetInvestmentsConcentrationByAssetsClass(investmentRepository);

export const createInvestment = new CreateInvestment(investmentRepository);

/* Open investment contract wen sending Kyc */
export const openInvestmentContract = new OpenInvestmentContract(
  new StripeShopRepository(apiClient)
);

export const getInvestmentsSum = new GetInvestmentsSum(investmentRepository);

export const getInvestmentsCompletedAndPending =
  new GetInvestmentsCompletedAndPending(investmentRepository);

export const getInvestmentConvertedAmount = new GetInvestmentConvertedAmount(
  investmentRepository
);
