import { useState } from "react";

export const useElviria = () => {
  const [areInvestmentPreferencesCreated, setAreInvestmentPreferencesCreated] =
    useState<boolean>(false);

  return {
    areInvestmentPreferencesCreated,
    setAreInvestmentPreferencesCreated,
  };
};
