import {
  Failure,
  TryUseCase,
} from "@portittech/portit-react-common-components";
import { CreatePreferenceRequest, PreferenceResponse } from "./entities";
import { Either, right } from "fp-ts/Either";
import { InvestmentsPreferencesRepository } from "./repository";

/**
 * Use this usecase to retrieve the possible values of the investment preferences
 */
export class GetPreferencesParams {
  partyId: number;
}

export class GetPreferences extends TryUseCase<
  GetPreferencesParams,
  PreferenceResponse
> {
  constructor(private readonly repository: InvestmentsPreferencesRepository) {
    super();
  }

  protected async tryRun(
    params: GetPreferencesParams
  ): Promise<Either<Failure, PreferenceResponse>> {
    return right(await this.repository.getPreferences(params.partyId));
  }
}

/**
 * Use this usecase to create a set of investment preferences
 */
export type CreatePreferencesParams = CreatePreferenceRequest;

export class CreatePreferences extends TryUseCase<
  CreatePreferencesParams,
  PreferenceResponse
> {
  constructor(private readonly repository: InvestmentsPreferencesRepository) {
    super();
  }

  protected async tryRun(
    params: CreatePreferencesParams
  ): Promise<Either<Failure, PreferenceResponse>> {
    return right(await this.repository.createPreferences(params));
  }
}

/**
 * Use this usecase to update a set of investment preferences
 */
export interface UpdatePreferencesParams {
  preferenceId: number;
  request: CreatePreferenceRequest;
}

export class UpdatePreferences extends TryUseCase<
  UpdatePreferencesParams,
  PreferenceResponse
> {
  constructor(private readonly repository: InvestmentsPreferencesRepository) {
    super();
  }

  protected async tryRun({
    preferenceId,
    request,
  }: UpdatePreferencesParams): Promise<Either<Failure, PreferenceResponse>> {
    return right(
      await this.repository.updatePreferences(preferenceId, request)
    );
  }
}
