import { getSubT } from "@portittech/portit-react-common-components";
import { Progress } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { colorMain } from "../../../../shared/styles/generic";
import {
  StyledDaysLeftBarDaysLeftText,
  StyledDaysLeftBarMainWrapper,
  StyledDaysLeftBarStartAndEndDate,
  StyledDaysLeftBarStartAndEndDateWrapper,
} from "./daysLeftBar.styles";

interface DaysLeftBarProps {
  startDate: string;
  endDate: string;
}

export const DaysLeftBar = ({ startDate, endDate }: DaysLeftBarProps) => {
  const { t } = useTranslation(["opportunityPage"]);
  const tc = getSubT(t, "days_left_bar_widget");

  const calculateDaysLeft = () => {
    let startDateMoment = moment(startDate);
    let currentDateMoment = moment(new Date());
    let endDateMoment = moment(endDate);

    let endDateGap = Math.ceil(
      endDateMoment.diff(startDateMoment, "days", true)
    );
    let currDateGap = Math.ceil(
      currentDateMoment.diff(startDateMoment, "days", true)
    );

    return {
      percent: (currDateGap / endDateGap) * 100,
      daysLeft: Math.ceil(endDateMoment.diff(currentDateMoment, "days", true)),
    };
  };

  if (calculateDaysLeft().daysLeft < 0) { 
    return null;
  }

  return (
    <StyledDaysLeftBarMainWrapper>
      <StyledDaysLeftBarDaysLeftText>
        {`${calculateDaysLeft().daysLeft} ${
          calculateDaysLeft().daysLeft === 1
            ? tc("day_left_label")
            : tc("days_left_label")
        }`}
      </StyledDaysLeftBarDaysLeftText>
      <Progress
        strokeColor={colorMain}
        percent={calculateDaysLeft().percent}
        showInfo={false}
      />
      <StyledDaysLeftBarStartAndEndDateWrapper>
        <StyledDaysLeftBarStartAndEndDate>
          {moment(startDate).format("DD MMM YYYY")}
        </StyledDaysLeftBarStartAndEndDate>

        <StyledDaysLeftBarStartAndEndDate>
          {moment(endDate).format("DD MMM YYYY")}
        </StyledDaysLeftBarStartAndEndDate>
      </StyledDaysLeftBarStartAndEndDateWrapper>
    </StyledDaysLeftBarMainWrapper>
  );
};
