import {
  getSubT,
  LoadingComponent,
  TextFieldModelBuilder,
  useModelCreatorEffect,
  FieldDecoration,
  DateRangeFieldModelBuilder,
  SelectFieldModelBuilder,
  useModelChanges,
  Context,
  PaginationModelBuilder,
} from "@portittech/portit-react-common-components";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ElviriaPages } from "../../../../shared/pages";

import { StyledAdminInnerPageTitle } from "../../../../shared/styles/page.styles";

import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";

import {
  StyledSortTypeSelectWrapper,
  StyledTransactionsWidgetDateAndDownloadRow,
  StyledTransactionsWidgetSearchAndFilterWrapper,
  StyledTransactionsWidgetWrapper,
} from "./transactionsWidget.styles";
import { TransactionsPageModel } from "./transactionsPageModel";
import { colorMainDark } from "../../../../shared/styles/generic";
import {
  StyledSearchFieldWrapper,
  StyledRouterLink,
  StyledSmallText,
} from "../../../widgets/common.styles";
import { TransactionsModelBuilder } from "../../builders/transactionsModelBuilder";

export const TransactionsWidget = () => {
  const { customerCubit, referencesModel } = useContext(Context);

  const { t } = useTranslation(["accountPage"]);
  const tc = getSubT(t, "transactions_widget");

  const pageModel = useModelCreatorEffect({
    creator: () => {
      return new TransactionsPageModel({
        customerCubit,
        // removed balance_id for now as we are only using USD account for now.
        referencesModel: referencesModel,
      });
    },
  });

  const dateFrom = useModelChanges({
    model: pageModel?.searcherFormModel?.fromDateFM,
    lens: (m) => m.value,
  });

  const dateTo = useModelChanges({
    model: pageModel?.searcherFormModel?.toDateFM,
    lens: (m) => m.value,
  });

  if (!pageModel) return <LoadingComponent />;
  return (
    <StyledTransactionsWidgetWrapper>
      <StyledAdminInnerPageTitle>
        {tc("widget_title")}
      </StyledAdminInnerPageTitle>
      <StyledTransactionsWidgetSearchAndFilterWrapper>
        <StyledTransactionsWidgetDateAndDownloadRow>
          <StyledSmallText>
            {dateFrom &&
              dateTo &&
              `${tc("from_label")} ${dateFrom.toLocaleDateString()} ${tc(
                "to_label"
              )} ${dateTo.toLocaleDateString()}`}
          </StyledSmallText>
          <div>
            <StyledRouterLink to={ElviriaPages.ACCOUNT}>
              {tc("download_button_label")}
            </StyledRouterLink>
            <DownloadIcon stroke={colorMainDark} fill={colorMainDark} />
          </div>
        </StyledTransactionsWidgetDateAndDownloadRow>
        {/* Search bar and drop downs */}
        <StyledSearchFieldWrapper isFullWidth>
          <TextFieldModelBuilder
            textFieldModel={pageModel.searcherFormModel.textFM}
            decoration={FieldDecoration.search({
              label: tc("search_label"),
            })}
          />
        </StyledSearchFieldWrapper>

        <DateRangeFieldModelBuilder
          fromInputFieldBloc={pageModel.searcherFormModel.fromDateFM}
          toInputFieldBloc={pageModel.searcherFormModel.toDateFM}
        >
          <StyledSortTypeSelectWrapper>
            <SelectFieldModelBuilder
              selectFieldModel={pageModel.searcherFormModel.transactionTypesFM}
              decoration={FieldDecoration.custom({
                label: tc("sort_by_label"),
              })}
            >
              {(item) => item}
            </SelectFieldModelBuilder>
          </StyledSortTypeSelectWrapper>
        </DateRangeFieldModelBuilder>
      </StyledTransactionsWidgetSearchAndFilterWrapper>

      <TransactionsModelBuilder
        transactionsModel={pageModel.transactionsModel}
      />

      <PaginationModelBuilder
        multiModel={pageModel.transactionsModel}
        pageSize={pageModel.transactionsPageSize}
      />

      {/* Searchbar and drop downs end */}
    </StyledTransactionsWidgetWrapper>
  );
};
