import { combineLatest } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

import {
  Failure,
  TranslationsSelectFieldModel,
  SingleFieldModel,
  TextFieldModel,
  FormModel,
} from "@portittech/portit-react-common-components";

import { OpportunitiesSortType, OpportunitiesStrainer } from "./types";

export class OpportunitiesSearcherFormModel extends FormModel<
  OpportunitiesStrainer,
  Failure
> {
  private readonly _autoSubmit: boolean;

  readonly textFM = new TextFieldModel();

  readonly sortByFM = new TranslationsSelectFieldModel<OpportunitiesSortType>({
    items: ["Newest Listed", "Oldest Listed", "Asset Class", "Name"],
  });

  constructor({ autoSubmit = false }: { autoSubmit?: boolean }) {
    super({ isLoading: true });
    this._autoSubmit = autoSubmit;
    this.addFieldModels({
      fieldsModels: [this.textFM, this.sortByFM],
    });
  }

  mapSortTypeToOrderType = (sortType) => {
    switch (sortType) {
      case "Newest Listed":
        return "desc";
      case "Oldest Listed":
        return "asc";
      default:
        return "";
    }
  };

  mapSortTypeToSortBy = (sortBy) => {
    switch (sortBy) {
      case "Asset Class":
        return "assetClass";
      case "Name":
        return "name";
      default:
        return "creationDate";
    }
  };

  protected async onLoading() {
    this.notifyLoaded();

    if (this._autoSubmit) {
      this.enableAutoSubmit();
    }
  }

  enableAutoSubmit(): void {
    this.closer.add(
      combineLatest(
        this.fieldModels().map((fm) =>
          fm.onLastChange.pipe(
            map((model) => (model as SingleFieldModel<any>).value),
            distinctUntilChanged((prev, curr) => prev === curr),
            debounceTime(1500)
          )
        )
      ).subscribe(() => {
        void this.submit();
      })
    );
  }

  protected onSubmitting() {
    this.notifySubmitted({
      canSubmitAgain: true,
      response: {
        name: this.textFM.value,
        orderType: this.mapSortTypeToOrderType(
          this.sortByFM.value
        ) as OpportunitiesStrainer["orderType"],
        sortBy: this.mapSortTypeToSortBy(this.sortByFM.value),
      },
    });
  }
}
