import React from "react";

import { Preferences } from "../preferences/preferences.component";
import { DashboardHeader } from "../dashboard/header/dashboardHeader.component";
import { PreferencesProps } from "./types";

export const PreferencesPage = ({ withAdminPage }: PreferencesProps) => {
  return (
        <>
          {!withAdminPage && <DashboardHeader />}
          <Preferences withAdminPage={withAdminPage} />
        </>
  );
};
