import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import {
  colorBlue,
  colorGreyishWhite,
  colorMainWhite,
  fontFamilyMontserrat,
  gradientBackground,
} from "../../../shared/styles/generic";

export const StyledNavWrapper = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 15vw;
  min-width: 12.5rem;
  box-shadow: 0px 17px 20px -2px rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  padding-top: 2.8rem;
  padding-bottom: 1.875rem;
  align-items: center;
  background: ${gradientBackground};

  .ant-btn-icon-only:hover,
  .ant-btn-icon-only:focus,
  .ant-btn-icon-only:active {
    color: ${colorBlue};
    border-color: ${colorBlue};
  }
`;

export const StyledNavigationContent = styled.div`
  flex: 1;
  padding: 1.875rem 0;

  a {
    color: rgb(130, 130, 130);
  }
`;

export const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.25rem;
  text-align: center;
  line-height: normal;
  height: 100%;

  a {
    margin-bottom: 2.2rem;

    :nth-of-type(5) {
      margin-bottom: 3.75rem;
    }

    &:not(:first-of-type) {
      svg {
        fill: ${colorMainWhite};
      }
    }
  }

  .spacer {
    flex: 1;
  }

  div.miniButton {
    display: block;
    height: 1.875rem;
    width: 1.875rem;
    border-radius: 50%;
    background: #f4f4f4;
  }
`;

export const StyledLogoImg = styled.img`
  width: 8.75rem;
  height: 2.3rem;
  margin-bottom: 2.5rem;
`;

interface NavItemProps {
  secondary?: boolean;
}

export const StyledNavItem = styled.div<NavItemProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${colorGreyishWhite};
  font-size: 1rem;
  font-family: ${fontFamilyMontserrat};
  transition: 0.3s;

  svg {
    width: 1.625rem;
    height: 1.625rem;
    margin-right: 1rem;
    stroke: ${colorGreyishWhite};
    transition: 0.3s;
  }

  &[data-current="true"],
  &:hover,
  &:active {
    color: ${colorMainWhite};
  }

  &:last-of-type {
    margin-top: auto;
  }

  ${({ secondary }) =>
    secondary &&
    css`
      font-size: 0.8rem;

      svg {
        width: 1.3rem;
        height: 1.3rem;
      }
    `}
`;

export const StyledInnerNavWrapper = styled.div`
  position: sticky;
  top: 1.875rem;
  bottom: 0;
`;

export const StyledAccountSubtitle = styled.div`
  font-family: ${fontFamilyMontserrat};
  font-size: 0.625rem;
  text-transform: uppercase;
  text-align: left;
  color: ${colorMainWhite};
  mix-blend-mode: normal;
  opacity: 0.5;
  margin-bottom: 1.3rem;
`;

export const StyledSecondaryLink = styled(Link)`
  margin-bottom: 1.3rem !important;
`;
