import styled from "styled-components";
import {
  colorBlue,
  colorMainDark,
  fontFamilyMontserrat,
} from "../../../shared/styles/generic";
import { devices } from "../../config/devices";

/* Styles for Whole widget  */
export const StyledIInterestedInvestmentMainWrapper = styled.div`
  grid-column: 1/2;
  font-family: ${fontFamilyMontserrat};
  color: ${colorMainDark};
`;

export const StyledInterestedInvestmentContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3.125rem;

  @media ${devices.smallLaptop} {
    grid-template-columns: 1fr 1fr;
    gap: 0.625rem 3.125rem;
  }
`;

export const StyledViewAllLinkWrapper = styled.div`
  text-align: right;
`;

/* Styles for card */
export const StyledIInterestedInvestmentCardWrapper = styled.div`
  margin: 2.5rem 0 1.875rem 0;
  box-shadow: -5px 5px 40px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

export const StyledInterestedInvestmentCardImage = styled.img`
  max-height: 15.625rem;
  width: 100%;
  object-fit: cover;
  position: relative;
  object-position: 0 0;
`;

export const StyledInterestedInvestmentCardBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const StyledInterestedInvestmentCardContentWrapper = styled.div`
  padding: 2.2rem 1.25rem;
`;

export const StyledInterestedInvestmentCardAssetName = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;

  @media ${devices.smallLaptop} {
    font-size: 1.1rem;
  }
`;

export const StyledInterestedInvestmentCardAssetClass = styled.p`
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
`;

export const StyledInterestedInvestmentCardDescription = styled.p`
  font-size: 0.875rem;
`;

export const StyledAssetName = styled.div`
  font-size: 1.25rem;
  line-height: 2.5rem;
  font-weight: 600;
  color: ${colorBlue};
`;

export const StyledAssetClass = styled.div`
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 0.25rem;
  line-height: 1.125rem;
`;

export const StyledImgPlaceholder = styled.div`
  height: 15.625rem;
`;
