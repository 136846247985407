import { useEffect, useState } from "react";
import { useRequest } from "./useRequest";
import { InvestmentAmountConversionParams } from "../../domain/features/investment/entities";
import { getInvestmentConvertedAmount } from "../usecases";

/**
 *
 * @returns investmentConvertedAmount string, isConvertedAmountFetching boolean, investmentAmountConversionError error
 *
 * @example
 *  const { investmentConvertedAmount, isConvertedAmountFetching, investmentAmountConversionError} = useInvestmentAmountConversion()
 */

export const useInvestmentConvertedAmount = ({
  targetCurrency,
  baseCurrency,
  moneyInvested,
}: InvestmentAmountConversionParams) => {
  const {
    request: requestInvestmentConvertedAmount,
    isFetching: isConvertedAmountFetching,
    error: investmentAmountConversionError,
  } = useRequest(getInvestmentConvertedAmount);

  const [investmentConvertedAmount, setInvestmentConvertedAmount] = useState(0);

  useEffect(() => {
    requestInvestmentConvertedAmount({
      targetCurrency,
      baseCurrency,
      moneyInvested,
    }).then(setInvestmentConvertedAmount);

    return () => {
      setInvestmentConvertedAmount(0);
    };
  }, [
    targetCurrency,
    baseCurrency,
    moneyInvested,
    requestInvestmentConvertedAmount,
  ]);

  return {
    investmentConvertedAmount,
    isConvertedAmountFetching,
    investmentAmountConversionError,
  };
};
