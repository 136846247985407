import React, { useContext } from "react";
import {
  StyledPendingVerificationButton,
  StyledPendingVerificationLogo,
  StyledPendingVerificationLogoCenter,
  StyledPendingVerificationPage,
  StyledPendingVerificationSubtitle,
  StyledPendingVerificationText,
  StyledPendingVerificationTitle,
} from "./pendingVerification.style";
import logo from "../../assets/elviria_dark_logo.svg";
import pendingLogo from "../../assets/pending.svg";
import {
  Context,
  RoundedButton,
  useCubitBuilder,
} from "@portittech/portit-react-common-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const PendingVerificationPage = () => {
  const { dispatchLogout } = useContext(Context);
  const history = useHistory();
  const { customerCubit } = useContext(Context);

  const performLogout = () => {
    dispatchLogout();
    setTimeout(() => {
      history.replace("/login");
    }, 1000);
  };

  const { t } = useTranslation(["pendingVerification"]);
  const customerState = useCubitBuilder({
    cubit: customerCubit,
  });
  const customerData = customerState?.tryData;

  return (
    <StyledPendingVerificationPage>
      <StyledPendingVerificationLogo>
        <img src={logo as string} alt={"Elviria logo"} />
      </StyledPendingVerificationLogo>
      <StyledPendingVerificationLogoCenter>
        <img src={pendingLogo as string} alt={"pending verification"} />
      </StyledPendingVerificationLogoCenter>
      <StyledPendingVerificationTitle>
        {t("pending_verification_title")}
      </StyledPendingVerificationTitle>
      <StyledPendingVerificationSubtitle>
        {t("pending_verification_subTitle", {
          replace: { nominative: customerData?.customer.name },
        })}
      </StyledPendingVerificationSubtitle>
      <StyledPendingVerificationText>
        {t("pending_verification_content")}
      </StyledPendingVerificationText>
      <StyledPendingVerificationButton>
        <RoundedButton onClick={performLogout}>
          {t("pending_logout_action")}
        </RoundedButton>
      </StyledPendingVerificationButton>
    </StyledPendingVerificationPage>
  );
};
