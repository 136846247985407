import styled from "styled-components";

export const StyledAccountPageWrapper = styled.div`
  padding: 0 4vw 1.875rem;
`;

export const StyledAccountBalanceRoweWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
