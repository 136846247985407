import React from "react";

import { useTranslation } from "react-i18next";
import { getSubT } from "@portittech/portit-react-common-components";

import {
  StyledMyInvestmentCardInner,
  StyledMyInvestmentCardWrapper,
  StyledMyInvestmentConcentrationCircle,
  StyledMyInvestmentConcentrationWrapper,
  StyledMyInvestmentsColumnTitle,
  StyledMyInvestmentsContentWrapper,
  StyledMyInvestmentsMainWrapper,
  StyledMyInvestmentsTitleRowWrapper,
} from "./investmentsByOpportunity.styles";
import { formatCurrencyNumber } from "../../../../shared/helpers/formatCurrencyNumber";

import {
  InvestmentByOpportunity,
  InvestmentStatus,
} from "../../../../domain/features/investment/entities";
import { StyledSmallText } from "../../../widgets/common.styles";
import { isEmpty } from "ramda";
import { ConvertedAmount } from "./convertedAmount";

interface MyInvestmentsProps {
  investmentsByOpportunity: Array<InvestmentByOpportunity>;
  status: InvestmentStatus;
}

export const InvestmentsByOpportunity = ({
  investmentsByOpportunity,
  status,
}: MyInvestmentsProps) => {
  const { t } = useTranslation(["portfolio"]);
  const tc = getSubT(t, "my_investments_component");

  const areDoneInvestments = status === "Done";

  return (
    <StyledMyInvestmentsMainWrapper>
      {isEmpty(investmentsByOpportunity) ? (
        <StyledSmallText textAlign="center">
          {tc(`no_${status}_investments`)}
        </StyledSmallText>
      ) : (
        <>
          <StyledMyInvestmentsContentWrapper>
            <StyledMyInvestmentsTitleRowWrapper>
              <StyledMyInvestmentsColumnTitle>
                {tc("name")}
              </StyledMyInvestmentsColumnTitle>
              <StyledMyInvestmentsColumnTitle>
                {tc("asset_class")}
              </StyledMyInvestmentsColumnTitle>
              <StyledMyInvestmentsColumnTitle>
                {tc("sector")}
              </StyledMyInvestmentsColumnTitle>
              {areDoneInvestments ? (
                <StyledMyInvestmentsColumnTitle>
                  {tc("concentration")}
                </StyledMyInvestmentsColumnTitle>
              ) : null}
              <StyledMyInvestmentsColumnTitle>
                {tc("no_of_tokens")}
              </StyledMyInvestmentsColumnTitle>
              <StyledMyInvestmentsColumnTitle>
                {tc("known_value")}
              </StyledMyInvestmentsColumnTitle>
            </StyledMyInvestmentsTitleRowWrapper>

            {investmentsByOpportunity.map(
              (investment: InvestmentByOpportunity, index: number) => (
                <StyledMyInvestmentCardWrapper key={index}>
                  <StyledMyInvestmentCardInner isUnderlined>
                    {investment.opportunity.name}
                  </StyledMyInvestmentCardInner>
                  <StyledMyInvestmentCardInner>
                    {investment.opportunity.assetClass}
                  </StyledMyInvestmentCardInner>
                  <StyledMyInvestmentCardInner>
                    {investment.opportunity.sector}
                  </StyledMyInvestmentCardInner>
                  {areDoneInvestments ? (
                    <StyledMyInvestmentCardInner>
                      <StyledMyInvestmentConcentrationWrapper>
                        <StyledMyInvestmentConcentrationCircle
                          color={investment.color}
                        />
                        {investment.concentration}
                      </StyledMyInvestmentConcentrationWrapper>
                    </StyledMyInvestmentCardInner>
                  ) : null}
                  <StyledMyInvestmentCardInner>
                    {investment.noTokens}
                  </StyledMyInvestmentCardInner>
                  <StyledMyInvestmentCardInner>
                    {formatCurrencyNumber(
                      investment.knownValue,
                      investment.opportunity.currency
                    )}
                    {/* Display the equivalent amount in USD */}
                    {investment.opportunity.currency !== "USD" && (
                      <ConvertedAmount
                        amountToConvert={investment.knownValue.toString()}
                        baseCurrency={investment.opportunity.currency}
                        targetCurrency={"USD"}
                      />
                    )}
                  </StyledMyInvestmentCardInner>
                </StyledMyInvestmentCardWrapper>
              )
            )}
          </StyledMyInvestmentsContentWrapper>
        </>
      )}
    </StyledMyInvestmentsMainWrapper>
  );
};
