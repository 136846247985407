import type { ReactNode } from "react";
import React, { createContext, useContext } from "react";

import { useOpportunities } from "./useOpportunities";

type Context = ReturnType<typeof useOpportunities>;
type Props = { children: ReactNode };

const OpportunitiesContext = createContext({} as Context);

export const OpportunitiesStore = ({ children }: Props) => {
  const value = useOpportunities();
  return (
    <OpportunitiesContext.Provider value={value}>
      {children}
    </OpportunitiesContext.Provider>
  );
};

export const useOpportunitiesStore = () => useContext(OpportunitiesContext);
