import React from "react";
import { useTranslation } from "react-i18next";

import { getSubT } from "@portittech/portit-react-common-components";

import { ReactComponent as PreferencesIcon } from "../../assets/icons/preferences.svg";

import { ElviriaPages } from "../../shared/pages";

import { InfoWidget } from "./info-widget/infoWidget.component";

import { colorMainDark } from "../../shared/styles/generic";
import { StyledRouterLink } from "./common.styles";

export const MyPreferencesWidget = () => {
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "info_widget");
  return (
    <InfoWidget
      title={tc("preferences_label")}
      icon={<PreferencesIcon fill={colorMainDark} stroke={colorMainDark} />}
    >
      <StyledRouterLink to={ElviriaPages.PREFERENCES_DASHBOARD}>
        {tc("modify")}
      </StyledRouterLink>
    </InfoWidget>
  );
};
