import styled from "styled-components";

export const MessagingPageStyle = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: 0 4vw 1.875rem;
  gap: 0 2.75rem;
`;

export const StyledMessagesContent = styled.div`
  display: flex;
`;
