import { isNil } from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  TransactionDetailsEntity,
  FormattedCurrency,
  ComfortaaP,
  NunitoDescription,
  NunitoGreyDescription,
  NunitoText,
  getSubT,
  toCamelCase,
} from "@portittech/portit-react-common-components";

import {
  BankAccountTransactionsStyle,
  SingleDailyTransactionsStyle,
  SingleTransactionStyle,
  StyledTransactionsHeaderTotalAmount,
} from "./transactionsStyles";
import { ElviriaPages } from "../../../../shared/pages";

export interface DailyTransaction {
  dayName: string;
  dayTotal: number;
  currency: string;
  transactions: Array<TransactionDetailsEntity>;
}

interface TransactionsProps {
  transactions: Array<DailyTransaction>;
}

export const Transactions = ({
  transactions,
}: TransactionsProps): React.ReactElement => {
  return (
    <BankAccountTransactionsStyle className={"transactions"}>
      {transactions.map((day, index) => {
        return (
          <div key={index} style={{ width: "100%" }}>
            <SingleDailyTransactionsStyle className={"transactions-header"}>
              <NunitoDescription style={{ fontSize: 18 }}>
                {day.dayName}
              </NunitoDescription>
              <StyledTransactionsHeaderTotalAmount>
                <FormattedCurrency
                  value={day.dayTotal}
                  currency={day.currency}
                />
              </StyledTransactionsHeaderTotalAmount>
            </SingleDailyTransactionsStyle>
            {day.transactions.map((t, index) => {
              return <SingleTransaction transaction={t} key={index} />;
            })}
          </div>
        );
      })}
    </BankAccountTransactionsStyle>
  );
};

interface SingleTransactionProps {
  transaction: TransactionDetailsEntity;
}

const SingleTransaction = ({ transaction }: SingleTransactionProps) => {
  const { t: pt } = useTranslation(["accountPage"]);
  const t = getSubT(pt, "singleTransaction");
  const tType = getSubT(t, "type");

  const history = useHistory();

  const renderType = () => {
    switch (transaction.type) {
      case "payment":
      case "conversion":
      case "inbound_funds":
        return tType(`${toCamelCase(transaction.type)}_label`);
      case "transfer":
        switch (transaction.sub_type) {
          case "fx_return":
            return tType(`${toCamelCase(transaction.sub_type)}_label`);
          case "investment":
            return tType(`${toCamelCase(transaction.sub_type)}_label`);
          default:
            return tType("genericTransfer_label");
        }
      default:
        return tType("genericTransaction_label");
    }
  };

  const renderFeeAmount = () => {
    const canRenderFee =
      transaction.type === "payment" &&
      !isNil(transaction.fee_info) &&
      transaction.fee_info.fee_amount > 0;

    if (!canRenderFee) {
      return null;
    }
    return (
      <NunitoGreyDescription style={{ fontSize: 15 }}>
        {t("smallFee_label")}
        <FormattedCurrency
          value={transaction.fee_info?.fee_amount}
          currency={transaction.currency}
        />
      </NunitoGreyDescription>
    );
  };

  return (
    <SingleTransactionStyle
      onClick={() =>
        history.push(
          `${ElviriaPages.ACCOUNT}/${transaction.balance_id}/transactions/${transaction.id}`
        )
      }
    >
      <span className="icon" data-name={transaction.name.substring(0, 1)} />
      <div className="description">
        <NunitoText className="transaction-name">{transaction.name}</NunitoText>
        <NunitoGreyDescription>{renderType()}</NunitoGreyDescription>
      </div>
      <div className={"amounts"}>
        <ComfortaaP
          className={`amount ${
            transaction.direction === "credit" ? "credit" : "debit"
          }`}
        >
          {transaction.direction === "credit" ? t("plus_label") : ""}
          <FormattedCurrency
            value={Math.abs(transaction.amount)}
            currency={transaction.currency}
          />
        </ComfortaaP>
        {renderFeeAmount()}
      </div>
    </SingleTransactionStyle>
  );
};
