import styled from "styled-components";

export const SingleMessageStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledMessageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.625rem;
`;

export const StyledMessageBody = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2.625rem;
  line-height: 2.5rem;
`;

export const StyledSingleMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: default;
`;

export const StyledSingleMessageBottomPart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: normal;
  width: inherit;

  p {
    line-height: 1.25rem;
  }
`;

export const StyledSingleMessageMainWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 72px;
`;

export const StyledSingleMessageSideWidgetsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;

interface SingleMessageListTitleProps {
  read?: boolean;
}
export const StyledSingleMessageListTitle = styled.h3<SingleMessageListTitleProps>`
  margin-bottom: 0;
  font-weight: ${({ read }) => (!read ? 600 : 400)};
`;
