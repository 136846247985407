import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getSubT } from "@portittech/portit-react-common-components";

import { OpportunitiesWidgetCard } from "./opportunitiesWidgetCard.component";
import { useOpportunitiesStore } from "../../opportunitiesStore";
import { ElviriaPages } from "../../../shared/pages";
import { OpportunityResponse } from "../../../domain/features/opportunities/entities";

import { SquareButton } from "../../../shared/styles/button";
import {
  StyledOpportunitiesMainWrapper,
  StyledOpportunitiesContentWrapper,
  StyledOpportunityFlexOneWrapper,
  StyledSmallWidgetTitle,
  StyledOpportunitiesTitleRowWrapper,
  StyledOpportunityColumnTitle,
  StyledViewAllBtnWrapper,
} from "../common.styles";

interface OpportunitiesWidgetProps {
  widgetTitle?: string;
  assetClass?: string;
  withViewAllButton?: boolean;
}

export const OpportunitiesWidget = ({
  widgetTitle,
  assetClass,
  withViewAllButton,
}: OpportunitiesWidgetProps) => {
  const history = useHistory();
  const { t } = useTranslation(["opportunitiesPage"]);
  const { t: tApi } = useTranslation(["apiText"]);
  const tc = getSubT(t, "opportunities_widget");
  const { opportunitiesList } = useOpportunitiesStore();

  let opportunities: Array<OpportunityResponse>;

  switch (true) {
    case Boolean(assetClass):
      // if assetClass is present group opportunities by Asset Class
      opportunities = opportunitiesList.filter(
        (opp) => opp.assetClass === assetClass
      );
      break;
    case withViewAllButton:
      // if showAllButton is present show only first 5 opportunities
      opportunities = opportunitiesList.slice(0, 5);
      break;
    default:
      opportunities = opportunitiesList;
  }

  return (
    <StyledOpportunitiesMainWrapper>
      {
        // if asset class is available in props then render it otherwise not, so that it can be used on different pages (for instance Dshboard Empty Portfolio)
        assetClass && (
          <StyledSmallWidgetTitle>
            {tc("asset_class_title")} : <span>{tApi(assetClass)}</span>
          </StyledSmallWidgetTitle>
        )
      }

      {!assetClass && widgetTitle && (
        <StyledSmallWidgetTitle fontSize={2}>
          {widgetTitle}
        </StyledSmallWidgetTitle>
      )}

      <StyledOpportunitiesContentWrapper>
        <StyledOpportunitiesTitleRowWrapper>
          <StyledOpportunityFlexOneWrapper />
          <StyledOpportunityColumnTitle>
            {tc("name_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("asset_class_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("sector_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("min_invest_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("invest_goal_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("invest_horizon_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("invest_stage_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityFlexOneWrapper />
        </StyledOpportunitiesTitleRowWrapper>
        {opportunities.map((investmentOpportunity) => (
          <OpportunitiesWidgetCard
            key={investmentOpportunity.name}
            {...investmentOpportunity}
          />
        ))}
      </StyledOpportunitiesContentWrapper>
      {withViewAllButton && (
        <StyledViewAllBtnWrapper
          onClick={() => history.push(ElviriaPages.OPPORTUNITIES)}
        >
          <SquareButton>{tc("view_all_btn")}</SquareButton>
        </StyledViewAllBtnWrapper>
      )}
    </StyledOpportunitiesMainWrapper>
  );
};
