import React, { useContext } from "react";

import { useTranslation } from "react-i18next";

import {
  Context,
  LoadingComponent,
  useModelChanges,
  useModelCreatorEffect,
} from "@portittech/portit-react-common-components";

import {
  StyledAdminInnerPageTitle,
  StyledAdminInnerPageWrapper,
  StyledGrid,
} from "../../shared/styles/page.styles";
import { GuideWidget } from "../widgets/guideWidget";
import { HelpWidget } from "../widgets/helpWidget";
import { MyPreferencesWidget } from "../widgets/myPreferencesWidget";
import { SettingsPageModel } from "./settingsPage.model";
import {
  StyledSettingsInnerBlockTitle,
  StyledSettingsInnerBlockWrapper,
} from "./settingsPage.styles";
import { SettingsInputCard } from "./components/settingsInputCard.component";
import { StyledContactUsTitle } from "../help-page/helpPage.styles";
import {
  StyledRouterLink,
  StyledStickyRightWidgetsWrapper,
} from "../widgets/common.styles";
import { ElviriaPages } from "../../shared/pages";
// import { IbanSwiftInputCard } from "./components/ibanSwiftInputCard.component";

export const SettingsPage = () => {
  const { customerCubit } = useContext(Context);

  const { t } = useTranslation(["settingsPage"]);

  const model = useModelCreatorEffect({
    creator: () => {
      return new SettingsPageModel({ customerCubit });
    },
  });

  useModelChanges(model);

  const saveModel = () => {
    model.submit();
  };

  if (!model) return <LoadingComponent />;

  return (
    <StyledAdminInnerPageWrapper>
      <StyledAdminInnerPageTitle>{t("settings")}</StyledAdminInnerPageTitle>
      <StyledGrid>
        <div>
          {/* Account Details Block starts */}
          <StyledSettingsInnerBlockWrapper>
            <StyledSettingsInnerBlockTitle>
              {t("account_details")}
            </StyledSettingsInnerBlockTitle>

            {/* for email input */}
            <SettingsInputCard
              saveModel={saveModel}
              fieldModel={model?.emailFM}
              fieldLabel={t("email")}
            />

            {/* for username input */}
            <SettingsInputCard
              saveModel={saveModel}
              fieldModel={model?.usernameFM}
              fieldLabel={t("username")}
            />

            {/* for password input */}
            <SettingsInputCard
              saveModel={saveModel}
              fieldModel={model?.passwordFM}
              fieldLabel={t("password")}
              isPassword
            />
          </StyledSettingsInnerBlockWrapper>

          {/* Account Details Block ends */}

          {/* Address Details Block starts */}
          {/* <StyledSettingsInnerBlockWrapper>
            <StyledSettingsInnerBlockTitle>
              {t("address")}
            </StyledSettingsInnerBlockTitle>
            
            <SettingsInputCard
              saveModel={saveModel}
              fieldModel={model?.nameFM}
              fieldLabel={t("name")}
            />

            <SettingsInputCard
              saveModel={saveModel}
              fieldModel={model?.addressLine1FM}
              fieldLabel={t("address_line_1")}
            />

            <SettingsInputCard
              saveModel={saveModel}
              fieldModel={model?.addressLine2FM}
              fieldLabel={t("address_line_2")}
            />

            <SettingsInputCard
              saveModel={saveModel}
              fieldModel={model?.countryFM}
              fieldLabel={t("country")}
            />

            <SettingsInputCard
              saveModel={saveModel}
              fieldModel={model?.cityFM}
              fieldLabel={t("city")}
            />

            <SettingsInputCard
              saveModel={saveModel}
              fieldModel={model?.postcodeFM}
              fieldLabel={t("postcode")}
            />
          </StyledSettingsInnerBlockWrapper> */}
          {/* Address Details Block ends */}

          {/* Bank Details Block starts */}
          {/* <StyledSettingsInnerBlockWrapper>
            <StyledSettingsInnerBlockTitle>
              {t("bank_details")}
            </StyledSettingsInnerBlockTitle>

            <IbanSwiftInputCard
              saveModel={saveModel}
              ibanFieldModel={model?.ibanFM}
              swiftFieldModel={model?.swiftFM}
            />
          </StyledSettingsInnerBlockWrapper> */}
          {/* Bank Details Block ends */}

          <div>
            <StyledContactUsTitle>
              {t("need_to_update_account")}
            </StyledContactUsTitle>
            <StyledRouterLink to={ElviriaPages.HELP}>
              {t("contact_us_link")}
            </StyledRouterLink>
          </div>
        </div>
        <div>
          <StyledStickyRightWidgetsWrapper>
            <HelpWidget />
            <GuideWidget digitalGoldTitle />
            <MyPreferencesWidget />
          </StyledStickyRightWidgetsWrapper>
        </div>
      </StyledGrid>
    </StyledAdminInnerPageWrapper>
  );
};
