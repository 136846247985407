import type { ReactNode } from "react";
import React, { createContext, useContext } from "react";

import { useWatchlist } from "./useWatchlist";

type Context = ReturnType<typeof useWatchlist>;
type Props = { children: ReactNode };

const WatchlistContext = createContext({} as Context);

export const WatchlistStore = ({ children }: Props) => {
  const value = useWatchlist();
  return (
    <WatchlistContext.Provider value={value}>
      {children}
    </WatchlistContext.Provider>
  );
};

export const useWatchlistStore = () => useContext(WatchlistContext);
