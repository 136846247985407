import {
  AppContext,
  AuthState,
  Context,
} from "@portittech/portit-react-common-components";
import React, { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { ElviriaRoutes } from "../shared/pages";

// Checks if user Logged in
export const SecuredRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const context: AppContext = useContext(Context);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (context.authState !== AuthState.LOGGED_IN) {
          const isSignOut = context.prevAuthState !== context.authState;

          return (
            <Redirect
              to={ElviriaRoutes.login({
                redirect: isSignOut ? undefined : location.pathname,
              })}
            />
          );
        }

        return children;
      }}
    />
  );
};
