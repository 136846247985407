import React from "react";
import { useTranslation } from "react-i18next";

import {
  getSubT,
  useModelChanges,
} from "@portittech/portit-react-common-components";
import { StyledSmallWidgetTitle } from "../../widgets/common.styles";
import { StyledInvestStepWrapper } from "../investment.styles";
import { PaymentsFormModel } from "../investmentPage.model";
import {
  ReviewStepMainWrapper,
  ReviewStepRow,
  ReviewStepRowValue,
} from "./reviewStep.styles";
import { formatCurrencyNumber } from "../../../shared/helpers/formatCurrencyNumber";

interface ReviewStepProps {
  investmentModel: PaymentsFormModel;
}

export const ReviewStep = ({ investmentModel }: ReviewStepProps) => {
  const { t } = useTranslation(["investment"]);
  const tc = getSubT(t, "summary_step");

  const investmentOptionsType = useModelChanges({
    model: investmentModel?.investmentOptionsFM,
    lens: (m) => m.value,
  });


  useModelChanges(investmentModel);

  const remainingBalancePostInvestment = investmentModel.sourceFM.translation.available_amount - investmentModel.totalValueFM.value

  return (
    <StyledInvestStepWrapper>
      <StyledSmallWidgetTitle>{t("summary_title_step")}</StyledSmallWidgetTitle>
      <ReviewStepMainWrapper>
        <ReviewStepRow>
          <p>{tc("investment")}</p>
          <ReviewStepRowValue>
            {investmentModel.currentOpportunity.name}
          </ReviewStepRowValue>
        </ReviewStepRow>
        <ReviewStepRow>
          <p>{tc("amount")}</p>
          <ReviewStepRowValue>
            {`${investmentModel.noOfTokensFM.value} ${
              investmentModel.noOfTokensFM.value > 1
                ? tc(investmentOptionsType)
                : tc(`${investmentOptionsType}_single_label`)
            }`}
          </ReviewStepRowValue>
        </ReviewStepRow>
        <ReviewStepRow>
          <p>{t("token_price")}</p>
          <ReviewStepRowValue>
            {formatCurrencyNumber(
              investmentModel.currentOpportunity.tokenPrice,
              investmentModel.currentOpportunity.currency
            )}
          </ReviewStepRowValue>
        </ReviewStepRow>
        <ReviewStepRow>
          <p>{tc("total")}</p>
          <ReviewStepRowValue isHighlighted>
            {formatCurrencyNumber(investmentModel.totalValueFM.value, investmentModel.currentOpportunity.currency)}
          </ReviewStepRowValue>
        </ReviewStepRow>
        <ReviewStepRow>
          <p>{tc("balance_post_operation")}</p>
          <ReviewStepRowValue>
            {formatCurrencyNumber(
              remainingBalancePostInvestment, 
              investmentModel.sourceFM.translation.currency     
            )}
          </ReviewStepRowValue>
        </ReviewStepRow>
      </ReviewStepMainWrapper>
    </StyledInvestStepWrapper>
  );
};
