import { LoadingComponent } from "@portittech/portit-react-common-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { NewsListResponse } from "../../../../domain/features/news/entities";
import { useRequest } from "../../../../shared/hooks/useRequest";
import { getNewsData } from "../../../../shared/usecases";
import { StyledSmallWidgetTitle } from "../../../widgets/common.styles";
import {
  StyledNewsCardWrapper,
  StyledNewsSectionTitleWrapper,
} from "../../opportunityPage.style";
import { NewsCard } from "./newsCard.component";

interface NewsSectionProps {
  readonly opportunityId: number;
}

export const NewsSection = ({ opportunityId }: NewsSectionProps) => {
  const [news, setNews] = useState<Array<NewsListResponse>>([]);
  const { t } = useTranslation(["opportunityPage"]);

  const { request: requestNews, isFetching: isNewsFetching } =
    useRequest(getNewsData);

  useEffect(() => {
    requestNews({ opportunityId }).then((res) => setNews(res.newsList));
  }, [requestNews, opportunityId]);

  const renderContent = () => {
    if (isNewsFetching) {
      return <LoadingComponent />;
    }
    return (
      <StyledNewsCardWrapper>
        {news.map((news) => (
          <NewsCard key={news.id} {...news} />
        ))}
      </StyledNewsCardWrapper>
    );
  };

  if (!isNewsFetching && news.length === 0) return null;

  return (
    <div>
      <StyledNewsSectionTitleWrapper>
        <StyledSmallWidgetTitle>{t("news")}</StyledSmallWidgetTitle>
      </StyledNewsSectionTitleWrapper>
      {renderContent()}
    </div>
  );
};
