import React, { ReactNode } from "react";
import {
  StyledInfoIconWrapper,
  StyledInfoRightWrapper,
  StyledInfoWidgetDescription,
  StyledInfoWidgetTitle,
  StyledInfoWidgetWrapper,
} from "./infoWidget.styles";

interface InfoWidgetProps {
  title: string;
  children: ReactNode;
  icon: ReactNode;
}

export const InfoWidget = ({ title, children, icon }: InfoWidgetProps) => {
  return (
    <StyledInfoWidgetWrapper>
      <div>
        <StyledInfoWidgetTitle>{title}</StyledInfoWidgetTitle>

        <StyledInfoWidgetDescription>{children}</StyledInfoWidgetDescription>
      </div>
      <StyledInfoRightWrapper>
        <StyledInfoIconWrapper>{icon}</StyledInfoIconWrapper>
      </StyledInfoRightWrapper>
    </StyledInfoWidgetWrapper>
  );
};
