import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import {
  getSubT,
  LoadingComponent,
  SelectFieldModelBuilder,
  useModelChanges,
  useModelCreatorEffect,
} from "@portittech/portit-react-common-components";

import { ReactComponent as PortfolioIcon } from "../../../assets/icons/portfolio.svg";

import {
  StyledSmallWidgetTitle,
  StyledSmallWidgetTitleRow,
  StyledSmallWidgetWrapper,
} from "../common.styles";
import { colorMainDark } from "../../../shared/styles/generic";
import {
  StyledEmptyPortfolioText,
  StyledPortfolioSum,
  StyledSelectFieldWrapper,
} from "./myPortfolio.styles";
import { SquareButton } from "../../../shared/styles/button";

import { ElviriaPages } from "../../../shared/pages";
import { formatCurrencyNumber } from "../../../shared/helpers/formatCurrencyNumber";
import { useInvestmentsSum } from "../../../shared/hooks/useInvestmentsSum";
import { AccountWidgetModel } from "../account-balance-widget/accountWidget.model";

export const MyPortfolioWidget = () => {
  const history = useHistory();
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "portfolio_widget");
  const { pathname } = useLocation();

  const isPortfolioPage = pathname === ElviriaPages.PORTFOLIO;

  const {
    investmentsSum: usdInvestmentSum,
    isInvestmentSumFetching,
    withPortfolio,
  } = useInvestmentsSum({ currency: "USD" });

  const model = useModelCreatorEffect({
    creator: () => new AccountWidgetModel(),
  });

  const investmentsSumByCurrency = useModelChanges({
    model,
    lens: (m) => m.investmentsSum,
  });

  const selectedCurrency = useModelChanges({
    model,
    lens: (m) => m.selectedCurrency,
  });

  if (isInvestmentSumFetching || !model) return <LoadingComponent />;

  return (
    <StyledSmallWidgetWrapper isHorizontal={!isPortfolioPage}>
      <StyledSmallWidgetTitleRow>
        <StyledSmallWidgetTitle>{tc("my_portfolio")}</StyledSmallWidgetTitle>
        <PortfolioIcon fill={colorMainDark} stroke={colorMainDark} />
      </StyledSmallWidgetTitleRow>
      {!withPortfolio ? (
        <StyledEmptyPortfolioText>
          {tc("empty_portfolio")}
        </StyledEmptyPortfolioText>
      ) : (
        <StyledPortfolioSum>
          {formatCurrencyNumber(usdInvestmentSum)}
        </StyledPortfolioSum>
      )}
      {isPortfolioPage && (
        <StyledSelectFieldWrapper>
          {formatCurrencyNumber(investmentsSumByCurrency, selectedCurrency)}
          <SelectFieldModelBuilder
            selectFieldModel={model.currencies}
            useSearch={false}
          >
            {(item) => item}
          </SelectFieldModelBuilder>
        </StyledSelectFieldWrapper>
      )}

      {!isPortfolioPage && (
        <SquareButton onClick={() => history.push(ElviriaPages.PORTFOLIO)}>
          {tc("btn_portfolio")}
        </SquareButton>
      )}
    </StyledSmallWidgetWrapper>
  );
};
