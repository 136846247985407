import { isLeft } from "fp-ts/lib/Either";
import { useState, useEffect, useCallback } from "react";
import { getInvestmentsKycInfoData } from "../usecases";

export const useUserInvestmentsData = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sentInvestmentKyc, setSentInvestmentKyc] = useState<boolean>();

  const getInvestmentsData = useCallback(async () => {
    setIsLoading(true);
    const res = await getInvestmentsKycInfoData.run();

    if (isLeft(res)) {
      setIsLoading(false);
      return setSentInvestmentKyc(false);
    }
    if (!res.right.investmentInfo) {
      setIsLoading(false);
      return setSentInvestmentKyc(false);
    }
    if (res.right.investmentInfo) {
      setSentInvestmentKyc(true);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getInvestmentsData();
  }, [getInvestmentsData]);

  return { sentInvestmentKyc, isLoading };
};
