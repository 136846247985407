import styled from "styled-components";

import markImg from "../../assets/elviria-mark.svg";

import {
  colorDarkGrey,
  colorLightGrey,
  colorMain,
  colorMainDark,
  fontFamilyMontserrat,
} from "../../shared/styles/generic";
import { StyledAdminInnerPageTitle } from "../../shared/styles/page.styles";
import { devices } from "../config/devices";

export const StyledPreferencesWrapper = styled.div`
  text-align: center;
  padding-bottom: 3.125rem;

  input[type="checkbox"]:checked + label::after {
    background-image: url(${markImg});
    background-position: cover;
    border: 0;
    transition: all 0.3s ease-in-out;
  }

  input[type="checkbox"]:checked + label {
    border: 1px solid ${colorMain};
    transition: border 0.2s;
  }

  input[type="checkbox"] {
    position: absolute;
    z-index: 100;
    opacity: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  button {
    margin: auto;
  }

  @media ${devices.tablet} {
    padding: 1.25rem;
  }
`;

interface StyledCheckBoxListProps {
  isInvestmentOptions?: boolean;
}

export const StyledCheckBoxList = styled.ul<StyledCheckBoxListProps>`
  list-style: none;
  padding: 1.25rem 0;
  max-width: 59.375rem;
  display: grid;
  grid-template-columns: ${({ isInvestmentOptions }) =>
    isInvestmentOptions ? "repeat(2, 1fr)" : "repeat(3, 1fr)"};
  grid-row-gap: 1.5625rem;
  grid-column-gap: 2.5rem;

  @media ${devices.tablet} {
    display: block;
  }
`;

export const StyledRowWrapper = styled.div`
  max-width: 59.375rem;
  margin: 0 auto;
  text-align: left;
  margin-top: 1.875rem;
`;

export const StyledCheckBoxLi = styled.li`
  display: block;
  position: relative;
`;

export const StyledCheckBoxLabel = styled.label`
  display: inline-block;
  border: 1px solid #d5d5d5;
  color: #b3b3b3;
  white-space: nowrap;
  margin: 0.1875rem 0rem;

  transition: all 0.2s;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  width: 100%;
  position: relative;

  &::after {
    position: absolute;
    right: 1.125rem;
    width: 1rem;
    top: 50%;
    transform: translateY(-50%);
    height: 1rem;
    border-radius: 50%;
    border: 1px solid ${colorLightGrey};
    content: "";
    transition: transform 0.3s ease-in-out;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.875rem;

  .sc-eLgNKc {
    width: 18.125rem;
  }

  .sc-eLgNKc + .sc-eLgNKc {
    margin-left: 1.25rem;
  }
`;

export const StyledPreferenceTitle = styled.h6`
  font-family: ${fontFamilyMontserrat};
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.875rem;
  color: ${colorMainDark};
  width: 8.8125rem;
`;

export const StyledRangeRaw = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-slider {
    width: 70%;
  }

  .ant-slider-handle {
    background-color: ${colorMain};
    border: solid 2px ${colorMain};
    height: 1.5rem;
    width: 1.5rem;
    margin-top: -0.5rem;

    @media ${devices.tablet} {
      height: 1rem;
      width: 1rem;
      margin-top: -0.25rem;
    }
  }

  .ant-slider-track {
    height: 0.5rem;
    background-color: ${colorMain};
  }

  .ant-slider:hover .ant-slider-track {
    background-color: ${colorMain};
  }

  .ant-slider-step {
    height: 0.5rem;
  }

  .ant-slider-rail {
    height: 0.5rem;
    background-color: ${colorLightGrey};
  }
`;

export const StyledSumWrapper = styled.div`
  width: 7.8125rem;
  height: 3.125rem;
  border: 1px solid #d5d5d5;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .ant-input-number,
  input {
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
  }

  .ant-input-number-input-wrap {
    height: 100%;
  }
  @media ${devices.tablet} {
    width: 6.25rem;
  }
`;

export const StyledUsdLabel = styled.div`
  position: absolute;
  top: -0.5rem;
  left: 0.1875rem;
  padding: 0 0.3125rem;
  color: ${colorDarkGrey};
  font-size: 0.8125rem;
  line-height: 0.75rem;
  background-color: #fff;
  font-family: ${fontFamilyMontserrat};
  z-index: 10;

  @media ${devices.tablet} {
    font-size: 0.5rem;
  }
`;

export const StyledInfoBlock = styled.div`
  max-width: 25rem;
  margin: 0 auto;
  border: 1px solid ${colorMain};
  padding: 1em 2em;
`;

export const StyledCustomInnerPageTitle = styled(StyledAdminInnerPageTitle)`
  text-align: left;
  padding-left: 4vw;
`;
