import { ComfortaaSubtitle } from "@portittech/portit-react-common-components";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  colorBlue,
  colorMain,
  colorMainWhite,
  fontFamilyEncodeSansExpanded,
  fontFamilyMontserrat,
} from "../../shared/styles/generic";
import { StyledAdminInnerPageTitle } from "../../shared/styles/page.styles";

export const StyledWithdrawPageWrapper = styled.div`
  // steps bar styles
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after,
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: ${colorBlue};
  }

  .ant-steps-item-process svg {
    fill: ${colorBlue};
  }

  .ant-steps-item-title {
    font-family: ${fontFamilyMontserrat};
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: ${colorBlue} !important;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title,
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${colorBlue};
  }

  .ant-steps-item-finish .ant-steps-item-icon,
  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: transparent;
    border-color: ${colorBlue};
  }

  .ant-steps-item-finish .ant-steps-item-icon svg path {
    fill: ${colorBlue};
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon,
  .ant-steps-item-icon .ant-steps-icon {
    font-family: ${fontFamilyMontserrat};
    color: ${colorBlue};
  }

  .ant-steps-item-process .ant-steps-item-icon {
    border-color: ${colorBlue};
    background: ${colorBlue};
  }

  // end pf steps bar styles

  ${ComfortaaSubtitle} {
    font-family: ${fontFamilyEncodeSansExpanded};
    font-weight: 400;
  }

  h4,
  h3,
  h2 {
    color: ${colorBlue};
  }

  .stage-page {
    h1 {
      color: red !important;
    }
  }

  .bottom-bar__back-btn {
    color: ${colorBlue};

    :hover {
      border: transparent;
    }
  }

  // Step 2 "destination"

  .destination-cards {
    .destination-card_label {
      color: ${colorBlue};
      font-weight: 400;
      font-family: ${fontFamilyEncodeSansExpanded};
    }

    svg {
      path {
        fill: ${colorBlue};
      }
    }

    > div:hover,
    .selected {
      background-color: ${colorMain};

      .destination-card_label {
        color: ${colorMainWhite};
      }

      svg {
        path {
          fill: ${colorMainWhite};
        }
      }
    }
  }

  // Remaining info step

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${colorMain};
  }

  .ant-tabs-ink-bar {
    background: ${colorMain};
  }
`;

export const StyledPageTitle = styled(StyledAdminInnerPageTitle)`
  padding: 0 3.75rem;
`;

export const StyledMyAccountLink = styled(Link)`
  text-decoration: underline;
  display: block;
  padding: 0 3.75rem 0.625rem;
`;
