export const fontFamilyEncodeSansExpanded =
  "'Encode Sans Expanded', sans-serif";

export const fontFamilyMontserrat = "'Montserrat', sans-serif;";

export const colorMainDark = "#3C3C3C";
export const colorMainAccentBlack = "#323C47";
export const colorMain = "#47C8CB";
export const colorDarkGrey = "#989898";
export const colorSliderGrey = "#2f2f2f";
export const colorLightGrey = "#D5D5D5";
export const colorMainWhite = "#fff";
export const colorGreyishWhite = `rgba(255, 255, 255, 0.7)`;
export const orangeColor = "#ff6f0f";
export const colorDarkBlue = "#00476D";
export const colorBlue = "#122948";
export const colorBackgroundGrey = "#F2F2F2";
export const colorGrey = "#E0E0E0";
export const colorLightGrey100 = "#F5F5F7";

export const gradientBackground = `linear-gradient(
    270.03deg,
    ${colorDarkBlue} 1.52%,
    ${colorBlue} 98.82%
  );`;
