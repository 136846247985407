import styled from "styled-components";
import {
  colorDarkGrey,
  colorMain,
  fontFamilyEncodeSansExpanded,
  fontFamilyMontserrat,
} from "../../shared/styles/generic";

export const DepositModalMainWrapper = styled.div`
  padding: 3.125rem 1.525rem;

  svg {
    fill: ${colorMain};
  }
`;

export const StyledFundingInfoMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fontFamilyMontserrat};
  margin-bottom: 3.75rem;
`;

export const StyledFundingInfoHeading = styled.h2`
  display: flex;
  justify-content: center;
  font-family: ${fontFamilyEncodeSansExpanded};
  font-size: 1.375rem;
  line-height: 110%;
  color: ${colorMain};
  text-transform: uppercase;
  margin-bottom: 2.4375rem;
`;

export const StyledFundingInfoRow = styled.div`
  display: flex;
  text-align: left;
  gap: 0.625rem;

  > div {
    flex: 1;
  }
`;

export const StyledFundingInfoTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.875rem;
  text-align: left;
`;

export const StyledFundingInfoValue = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.875rem;
  text-align: left;
`;

export const StyledNoteWrapper = styled.div`
  display: flex;
  width: 45%;
  text-align: left;
`;

export const StyledDepositNote = styled.p`
  font-size: 0.6875rem;
  line-height: 1.0625rem;
`;

interface DepositCircleSymbolProps {
  isHeading?: boolean;
}

export const StyledDepositCircleSymbol = styled.span<DepositCircleSymbolProps>`
  display: inline-flex;

  justify-content: center;
  align-items: center;

  color: ${colorMain};
  width: 0.9375rem;
  height: 0.9375rem;
  font-size: 0.5625rem;
  text-align: center;
  margin: ${({ isHeading }) =>
    isHeading ? "0 0 0 0.3125rem" : "0 0.75rem 0 0"};

  border: 0.0625rem solid ${colorMain};
  border-radius: 50%;
`;

export const StyledDepositCloseBtnWrapper = styled.div`
  max-width: 9.375rem;
  margin: 0 auto;
`;

export const StyledNoAccountTitle = styled.div`
  text-align: center;
  color: ${colorDarkGrey};
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;
