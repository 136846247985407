import React from "react";
import { useTranslation } from "react-i18next";

import {
  MessageEntity,
  NunitoGreyDescription,
  getSubT,
  VoidCallBack,
  formatDateToLocalString,
} from "@portittech/portit-react-common-components";
import {
  StyledSingleMessageBottomPart,
  StyledSingleMessageListTitle,
  StyledSingleMessageWrapper,
} from "../style/singleMessage.style";
import { StyledExternalLink } from "../../widgets/common.styles";

interface SingleMessageProps {
  message: MessageEntity;
  onMoreClick: VoidCallBack;
}

export const SingleMessage = ({
  message,
  onMoreClick,
}: SingleMessageProps): React.ReactElement => {
  const { t: pt } = useTranslation(["messaging"]);
  const t = getSubT(pt, "components.single_message");

  return (
    <StyledSingleMessageWrapper>
      <StyledSingleMessageListTitle read={message.message_status === "READ"}>
        {message.subject_text}
      </StyledSingleMessageListTitle>

      <StyledSingleMessageBottomPart>
        <StyledExternalLink onClick={onMoreClick}>
          {t("learn_more_action")}
        </StyledExternalLink>
        <NunitoGreyDescription>
          {formatDateToLocalString(message.created)}
        </NunitoGreyDescription>
      </StyledSingleMessageBottomPart>
    </StyledSingleMessageWrapper>
  );
};
