import { getSubT } from "@portittech/portit-react-common-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ReactComponent as OpportunitiesIcon } from "../../../assets/icons/opportunities.svg";
import { ElviriaPages } from "../../../shared/pages";
import { colorMainDark } from "../../../shared/styles/generic";
import { useOpportunitiesStore } from "../../opportunitiesStore";
import {
  StyledDescription,
  StyledNumber,
  StyledSmallWidgetTitle,
  StyledSmallWidgetTitleRow,
  StyledSmallWidgetWrapper,
} from "../common.styles";

import {
  StyledDataWrapper,
  StyledDataRow,
} from "./opportunitiesExplorer.styles";

export const OpportunitiesExplorerWidget = () => {
  const history = useHistory();
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "opportunities_widget");

  const { opportunitiesViewed, totalOpportunities, newOpportunities } = useOpportunitiesStore();

  return (
    <StyledSmallWidgetWrapper isHorizontal>
      <StyledSmallWidgetTitleRow>
        <StyledSmallWidgetTitle>
          {tc("explore_opportunities")}
        </StyledSmallWidgetTitle>
        <OpportunitiesIcon fill={colorMainDark} stroke={colorMainDark} />
      </StyledSmallWidgetTitleRow>
      <StyledDataRow>
        <StyledDataWrapper>
          <StyledNumber
            withAccentColor
            onClick={() => history.push(ElviriaPages.OPPORTUNITIES)}
          >
           {newOpportunities} 
          </StyledNumber>
          <StyledDescription>{tc("new")}</StyledDescription>
        </StyledDataWrapper>
        <StyledDataWrapper>
          <StyledNumber>{opportunitiesViewed}</StyledNumber>
          <StyledDescription>{tc("viewed")}</StyledDescription>
        </StyledDataWrapper>
        <StyledDataWrapper>
          <StyledNumber>{totalOpportunities}</StyledNumber>
          <StyledDescription>{tc("total")}</StyledDescription>
        </StyledDataWrapper>
      </StyledDataRow>
    </StyledSmallWidgetWrapper>
  );
};
