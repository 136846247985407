import React from "react";
import { useTranslation } from "react-i18next";

import {
  StyledAdminInnerPageTitle,
  StyledGrid,
} from "../../shared/styles/page.styles";
import { StyledStickyRightWidgetsWrapper } from "../widgets/common.styles";
import { GuideWidget } from "../widgets/guideWidget";
import { HelpWidget } from "../widgets/helpWidget";
import { MyPreferencesWidget } from "../widgets/myPreferencesWidget";
import {
  StyledBalancesColumnWrapper,
  StyledBalancesPageWrapper,
  TitleContainer,
  StyledFlexContainer,
  StyledAddCurrencyWrapper,
} from "./balancesPage.style";
import {
  LoadingComponent,
  useModelChanges,
  useModelCreatorEffect,
} from "@portittech/portit-react-common-components";
import { AccountWidgetModel } from "../widgets/account-balance-widget/accountWidget.model";
import { BalanceCard } from "./components/balanceCard.component";
import { Link } from "react-router-dom";
import { ElviriaPages } from "../../shared/pages";
import { SquareButton } from "../../shared/styles/button";

export const BalancesPage = () => {
  const { t } = useTranslation("balancesPage");

  const model = useModelCreatorEffect({
    creator: () => new AccountWidgetModel(),
  });

  const balances = useModelChanges({
    model,
    lens: (m) => m.balances,
  });

  if (!model || !balances) return <LoadingComponent />;

  return (
    <StyledBalancesPageWrapper>
      <StyledAdminInnerPageTitle>
        <TitleContainer>
          <StyledFlexContainer>
            <div>{t("page_title")}</div>
            <Link to={ElviriaPages.ADD_NEW_CURRENCY}>
              <StyledAddCurrencyWrapper>
                <SquareButton>{t("add_new_currency")}</SquareButton>
              </StyledAddCurrencyWrapper>
            </Link>
          </StyledFlexContainer>
        </TitleContainer>
      </StyledAdminInnerPageTitle>
      <StyledGrid>
        <StyledBalancesColumnWrapper>
          {balances?.map((balance) => (
            <BalanceCard key={balance.balance_id} balanceDetails={balance} />
          ))}
        </StyledBalancesColumnWrapper>
        <div>
          <StyledStickyRightWidgetsWrapper>
            <HelpWidget />
            <MyPreferencesWidget />
            <GuideWidget digitalGoldTitle />
          </StyledStickyRightWidgetsWrapper>
        </div>
      </StyledGrid>
    </StyledBalancesPageWrapper>
  );
};
