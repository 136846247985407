import { useParams } from "react-router-dom";

interface Params {
  pageId: string;
}

/**
 *
 * @returns id of the page from route
 *
 * @example
 *  const pageId = usePageId()
 */

export const usePageId = () => {
  const { pageId } = useParams<Params>();

  return Number(pageId);
};
