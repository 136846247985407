import { isLeft } from "fp-ts/lib/Either";

import {
  AccountOrderCubit,
  allRawValuesPagination,
  createBalance,
  Failure,
  fetchBalancesWP,
  fetchPaymentsReferencesUseCase,
  FieldValidators,
  FormModel,
  getAccountWP,
  SelectFieldModel,
  ShopCubit,
} from "@portittech/portit-react-common-components";

export class NewCurrencyWalletModel extends FormModel<string, Failure> {
  readonly currencyFM = new SelectFieldModel<string>({
    validators: [FieldValidators.required],
  });

  private _selectableCurrencies: Array<string>;
  private _shopCubit: ShopCubit;
  private _orderCubit: AccountOrderCubit;

  get userCurrencies(): Array<string> {
    return this._selectableCurrencies;
  }

  constructor(shopCubit: ShopCubit) {
    super({ isLoading: true });

    this._shopCubit = shopCubit;
    this.addFieldModels({
      fieldsModels: [this.currencyFM],
    });
  }

  protected async onLoading() {
    const availableCurrency = await fetchPaymentsReferencesUseCase.run({
      type: "availableCurrencies",
      orderBy: [{ key: "status" }],
    });

    if (isLeft(availableCurrency)) {
      this.notifyLoadFailed({ response: availableCurrency.left });
      return;
    }

    const balances = await fetchBalancesWP.run({
      strainer: {
        status: "enabled",
      },
      pagination: allRawValuesPagination,
    });
    if (isLeft(balances)) {
      this.notifyLoadFailed({ response: balances.left });
      return;
    }

    const allMyCurrencies = balances.right.values.map((it) => it.currency);
    const allCurrencies = availableCurrency.right.map((it) => it.code);

    this._selectableCurrencies = allCurrencies.filter(
      (it) => !allMyCurrencies.includes(it)
    );

    this.currencyFM.updateItems(this._selectableCurrencies);

    this.currencyFM.updateInitialValue(undefined);

    this._orderCubit = this._shopCubit.findOrDelegateAccountOrder();
    this.closer.add(this._orderCubit);

    this.notifyLoaded();
  }

  protected async onSubmitting(): Promise<void> {
    const accountRes = await getAccountWP.run({});

    if (isLeft(accountRes)) {
      this.notifySubmitFailed({ response: accountRes.left });
      return;
    }

    const accountId = accountRes.right.account_id;

    const res = await createBalance.run({
      // TODO: we use accountId from getAccount endpoint until the flow will be fixed in common components library
      // accountId: this._orderCubit.state.data.account.account_id,
      accountId: accountId,
      currency: this.currencyFM.value,
    });

    if (isLeft(res)) {
      this.notifySubmitFailed({ response: res.left });
      return;
    }

    this.notifySubmitted({
      response: this.currencyFM.value,
    });
  }
}
