import { ApiClient } from "@portittech/portit-react-common-components";
import {
  GetNewsRequest,
  NewsResponse,
} from "../../../domain/features/news/entities";
import { NewsRepository } from "../../../domain/features/news/repository";

/**
 * Data bridge between Investment REST endpoints and the domain layer
 */
export class NewsRepositoryImpl implements NewsRepository {
  constructor(private readonly client: ApiClient) {}

  getNews({
    opportunityId,
    pagination,
  }: GetNewsRequest = {}): Promise<NewsResponse> {
    return this.client.get<NewsResponse>(
      "/investments/v1/news",
      "investments",
      {
        opportunityId,
        ...pagination,
      }
    );
  }
}
