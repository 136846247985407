import {
  KycForm,
  LoadingComponent,
  useAppStore,
  useCubitBuilder,
  useModelCreatorEffect,
  useProducts,
} from "@portittech/portit-react-common-components";
import React, { Suspense } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import userIcon from "../../assets/elviria_user.svg";
import { ElviriaPages } from "../../shared/pages";

import {colorBlue, colorMain} from "../../shared/styles/generic";
import { StyledKycPageWrapper } from "./kycPage.styles";
import { KycSuccessModal } from "./kycSuccessModal.component";
import { WalletOpeningModel } from "./walletOpeningModel";
import {openInvestmentContract} from "../../shared/usecases";
import {isLeft} from "fp-ts/Either";
import {isRight} from "fp-ts/lib/Either";

export const KycPage = () => {
  const { customerCubit, shopCubit, dispatchLogout } = useAppStore();
  const SwalWithReact = withReactContent(Swal);

  const history = useHistory();

  const customerState = useCubitBuilder({
    cubit: customerCubit,
  });

  const { products, productsLoading } = useProducts();
  const product = products.find((prod) => prod.description === "Investment");

  const walletOpeningModel = useModelCreatorEffect({
    waitDeps: [shopCubit],
    creator: () => {
      return new WalletOpeningModel({
        shopCubit,
      });
    },
  });

  if (productsLoading || !product) return <LoadingComponent />;

  return (
    <StyledKycPageWrapper>
      <KycForm
        saveAndExitRedirect="logout"
        partyId={customerState?.tryData?.profile.partyId}
        productId={product.id}
        onCompleted={async () => {
          const res = await openInvestmentContract.run({
            productId: product.id,
          });

          if (isLeft(res)) {
            SwalWithReact.fire({
              title: "Error",
              text: "Ups something went wrong",
              icon: "error",
              iconColor: colorMain,
              showConfirmButton: true,
              confirmButtonText: "OK",
              width: "40.625rem",
              showClass: {
                backdrop: "swal2-noanimation", // disable backdrop animation
              },
            }).then(() => {
              dispatchLogout();
              history.push(ElviriaPages.LOGIN);
            });
          }

          // Todo change this back to  openInvestmentContract when workflow on BE is ready
          await walletOpeningModel.completeWalletStage("USD");

          if (isRight(res)) {
            SwalWithReact.fire({
              html: (
                <Suspense fallback={<LoadingComponent />}>
                  <KycSuccessModal swalWithReact={SwalWithReact} />
                </Suspense>
              ),
              icon: "success",
              iconColor: colorMain,

              showConfirmButton: false,
              width: "40.625rem",
              showClass: {
                backdrop: "swal2-noanimation", // disable backdrop animation
              },
            }).then(() => {
              history.push(ElviriaPages.PENDING_VERIFICATION);
            });
          }

          if (walletOpeningModel.status === "failed") {
            // Todo add a designed modal for this case
            SwalWithReact.fire({
              title: "Error",
              text: "Ups something went wrong",
              icon: "error",
              iconColor: colorBlue,
              showConfirmButton: true,
              confirmButtonText: "OK",
              width: "40.625rem",
              showClass: {
                backdrop: "swal2-noanimation", // disable backdrop animation
              },
            }).then(dispatchLogout);
          }
        }}
        userIcon={<img src={userIcon} alt="user-icon" />}
      />
    </StyledKycPageWrapper>
  );
};
