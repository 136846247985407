import { LoadingComponent } from "@portittech/portit-react-common-components";
import Linkify from "linkify-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { OpportunityResponse } from "../../domain/features/opportunities/entities";

import { usePageId } from "../../shared/hooks/usePageId";

import { useRequest } from "../../shared/hooks/useRequest";

import { SquareButton } from "../../shared/styles/button";

import {
  StyledAdminInnerPageTitle,
  StyledAdminInnerPageWrapper,
  StyledGrid,
} from "../../shared/styles/page.styles";
import { getSingleOpportunity } from "../../shared/usecases";

import { Logo } from "../logo/logo.component";

import { useOpportunitiesStore } from "../opportunitiesStore";
import { StyledStickyRightWidgetsWrapper } from "../widgets/common.styles";
import { DataRoomWidget } from "../widgets/dataRoomWidget";

import { HelpWidget } from "../widgets/helpWidget";
import { AddToWatchListButton } from "../widgets/interested-investment-widget/addToWatchListButton";

import { OpportunityBigImgBanner } from "./components/bigImg/bigImg.component";
import { DaysLeftBar } from "./components/days-left-bar/daysLeftBar.component";
import { NewsSection } from "./components/news-card/newsSection.component";
import { OpportunityValueWidget } from "./components/opportunity-value-widget/opportunityValueWidget.component";

import {
  DetailWrapper,
  StyledDescriptionHeader,
  StyledDetailDescription,
  StyledDetailTitle,
  StyledHeaderCategories,
  StyledHeaderTitle,
  StyledInvestInButtonWrapper,
  StyledKeyFactsDescription,
  StyledKeyFactsTitle,
  StyledKeyFactsWrapper,
} from "./opportunityPage.style";

export const OpportunityPage = () => {
  const { t } = useTranslation(["opportunityPage"]);
  const { t: tApi } = useTranslation(["apiText"]);
  const pageId = usePageId();
  const history = useHistory();
  const location = useLocation();
  const { fetchOpportunities } = useOpportunitiesStore();

  const [opportunity, setOpportunity] = useState<OpportunityResponse>();

  const { request: requestOpportunity, isFetching: isOpportunityFetching } =
    useRequest(getSingleOpportunity);

  useEffect(() => {
    requestOpportunity({ opportunityId: pageId }).then(setOpportunity);
  }, [pageId, requestOpportunity, fetchOpportunities]);

  useEffect(() => {
    if (opportunity) {
      fetchOpportunities();
    }
  }, [opportunity, fetchOpportunities]);

  if (isOpportunityFetching || !opportunity) return <LoadingComponent />;

  const investClickHandler = () => history.push(`${location.pathname}/invest`);

  return (
    <StyledAdminInnerPageWrapper>
      <StyledHeaderCategories isSmall>
        {tApi(opportunity.sector)} | {tApi(opportunity.assetClass)}
      </StyledHeaderCategories>
      <StyledAdminInnerPageTitle>{opportunity.name}</StyledAdminInnerPageTitle>

      <OpportunityBigImgBanner
        bigImageId={opportunity.bigImageId}
        step={opportunity?.step}
      />
      <StyledGrid>
        <div>
          <StyledDescriptionHeader>
            <Logo logoId={opportunity.logoImageId} size={6.25} />
            <div>
              <StyledHeaderCategories>
                {tApi(opportunity.sector)} | {tApi(opportunity.assetClass)}
              </StyledHeaderCategories>
              <StyledHeaderTitle>{opportunity.name}</StyledHeaderTitle>
            </div>
          </StyledDescriptionHeader>

          {/* Show Bar only when both start and end dates are present in API response*/}
          {opportunity.startDate && opportunity.endDate ? (
            <DaysLeftBar
              startDate={opportunity.startDate}
              endDate={opportunity.endDate}
            />
          ) : null}

          {opportunity.details.map((detail) => {
            return (
              <DetailWrapper key={detail.title}>
                <StyledDetailTitle
                  isMainTitle={detail.title === "COMPANY PROFILE"}
                >
                  {detail.title}
                </StyledDetailTitle>
                <StyledDetailDescription>
                  <Linkify
                    options={{
                      target: "_blank",
                      format: (value: string) =>
                        value.replace(/(^\w+:|^)\/\//, ""), // removing http::// from link that user sees
                    }}
                  >
                    {detail.description}
                  </Linkify>
                </StyledDetailDescription>
              </DetailWrapper>
            );
          })}

          {opportunity?.keyFacts?.length >= 1 && (
            <>
              <StyledDetailTitle>{t("key_facts")}</StyledDetailTitle>
              <StyledKeyFactsWrapper>
                {opportunity?.keyFacts?.map((keyFact) => (
                  <div key={keyFact.title}>
                    <StyledKeyFactsTitle>{keyFact.title}</StyledKeyFactsTitle>
                    <StyledKeyFactsDescription>
                      {keyFact.description}
                    </StyledKeyFactsDescription>
                  </div>
                ))}
              </StyledKeyFactsWrapper>
            </>
          )}

          <NewsSection opportunityId={opportunity.id} />

          <StyledInvestInButtonWrapper>
            <SquareButton
              onClick={investClickHandler}
              disabled={opportunity.status !== "Active"}
            >
              {t("investment_button")}
            </SquareButton>
          </StyledInvestInButtonWrapper>

          <AddToWatchListButton opportunityId={opportunity.id} />
        </div>
        <div>
          <StyledStickyRightWidgetsWrapper>
            <OpportunityValueWidget
              investClickHandler={investClickHandler}
              startDate={opportunity.startDate}
              endDate={opportunity.endDate}
              {...opportunity}
            />
            <DataRoomWidget opportunityId={pageId} />
            <HelpWidget />
          </StyledStickyRightWidgetsWrapper>
        </div>
      </StyledGrid>
    </StyledAdminInnerPageWrapper>
  );
};
