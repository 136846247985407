import {
  Failure,
  ShopRepository,
  TryUseCase,
  UndefinedParamFailure,
} from "@portittech/portit-react-common-components";
import { Either, left, right } from "fp-ts/lib/Either";
import {
  CreateInvestmentRequest,
  CreateInvestmentResponse,
  GetInvestmentsSumParams,
  GroupedInvestmentsRequest,
  GroupedInvestmentsResponse,
  InvestmentAmountConversionParams,
  InvestmentRequest,
  InvestmentsByAssetsClassResponse,
  InvestmentsByOpportunityRequest,
  InvestmentsByOpportunityResponse,
  InvestmentsCompletedAndPending,
  InvestmentsResponse,
  OpenInvestmentParams,
  OpenInvestmentResponse,
} from "./entities";
import { InvestmentRepository } from "./repository";

export class GetInvestments extends TryUseCase<
  InvestmentRequest | void,
  InvestmentsResponse
> {
  constructor(private readonly repository: InvestmentRepository) {
    super();
  }

  protected async tryRun(
    params: InvestmentRequest
  ): Promise<Either<Failure, InvestmentsResponse>> {
    return right(await this.repository.getInvestments(params));
  }
}

export class GetInvestmentsConcentrationByOpportunity extends TryUseCase<
  InvestmentsByOpportunityRequest,
  InvestmentsByOpportunityResponse
> {
  constructor(private readonly repository: InvestmentRepository) {
    super();
  }

  protected async tryRun(
    params: InvestmentsByOpportunityRequest
  ): Promise<Either<Failure, InvestmentsByOpportunityResponse>> {
    return right(
      await this.repository.getInvestmentsConcentrationByOpportunity(params)
    );
  }
}

export class GetInvestmentsConcentrationByAssetsClass extends TryUseCase<
  void,
  InvestmentsByAssetsClassResponse
> {
  constructor(private readonly repository: InvestmentRepository) {
    super();
  }

  protected async tryRun(): Promise<
    Either<Failure, InvestmentsByAssetsClassResponse>
  > {
    return right(
      await this.repository.getInvestmentsConcentrationByAssetsClass()
    );
  }
}

export class GetGroupedInvestments extends TryUseCase<
  GroupedInvestmentsRequest,
  GroupedInvestmentsResponse
> {
  constructor(private readonly repository: InvestmentRepository) {
    super();
  }

  protected async tryRun(
    params: GroupedInvestmentsRequest
  ): Promise<Either<Failure, GroupedInvestmentsResponse>> {
    return right(await this.repository.getGroupedInvestments(params));
  }
}

export class CreateInvestment extends TryUseCase<
  CreateInvestmentRequest,
  CreateInvestmentResponse
> {
  constructor(private readonly repository: InvestmentRepository) {
    super();
  }

  protected async tryRun(
    params: CreateInvestmentRequest
  ): Promise<Either<Failure, CreateInvestmentResponse>> {
    return right(await this.repository.createInvestment(params));
  }
}

export class OpenInvestmentContract extends TryUseCase<
  OpenInvestmentParams,
  OpenInvestmentResponse
> {
  private readonly shopRepo: ShopRepository;

  constructor(shopRepo: ShopRepository) {
    super();
    this.shopRepo = shopRepo;
  }

  async tryRun(
    params: OpenInvestmentParams
  ): Promise<Either<Failure, OpenInvestmentResponse>> {
    if (!params.productId) {
      return left(new UndefinedParamFailure("contractId", Error().stack));
    }

    const contractRes = await this.shopRepo.createContract(params.productId);

    return right({
      contract: contractRes,
    });
  }
}

export class GetInvestmentsSum extends TryUseCase<
  GetInvestmentsSumParams,
  number
> {
  constructor(private readonly repository: InvestmentRepository) {
    super();
  }

  protected async tryRun({
    currency,
  }: GetInvestmentsSumParams): Promise<Either<Failure, number>> {
    return right(await this.repository.getInvestmentsSum(currency));
  }
}

export class GetInvestmentsCompletedAndPending extends TryUseCase<
  void,
  InvestmentsCompletedAndPending
> {
  constructor(private readonly repository: InvestmentRepository) {
    super();
  }

  protected async tryRun(): Promise<
    Either<Failure, InvestmentsCompletedAndPending>
  > {
    return right(await this.repository.getInvestmentsCompletedAndPending());
  }
}

export class GetInvestmentConvertedAmount extends TryUseCase<
  InvestmentAmountConversionParams,
  number
> {
  constructor(private readonly repository: InvestmentRepository) {
    super();
  }

  protected async tryRun({
    baseCurrency,
    moneyInvested,
    targetCurrency,
  }: InvestmentAmountConversionParams): Promise<Either<Failure, number>> {
    return right(
      await this.repository.getInvestmentAmountConverted(targetCurrency, {
        baseCurrency,
        moneyInvested,
      })
    );
  }
}
