import styled from "styled-components";
import {
  colorDarkGrey,
  fontFamilyEncodeSansExpanded,
  fontFamilyMontserrat,
} from "../../../../shared/styles/generic";

export const StyledNewsCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 10.375rem;
  width: 100%;
  padding: 1rem 1.5rem;
  box-shadow: -5px 5px 40px rgba(0, 0, 0, 0.07);
`;

export const StyledNewsCardDate = styled.p`
  font-family: ${fontFamilyMontserrat};
  font-size: 0.875rem;
  color: ${colorDarkGrey};
  margin-bottom: 0;
`;

export const StyledNewsCardTitle = styled.h4`
  font-family: ${fontFamilyEncodeSansExpanded};
  font-size: 1.375rem;
  line-height: 110%;
`;
