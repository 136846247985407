import { LinkButton } from "@portittech/portit-react-common-components";
import styled from "styled-components";
import { colorBlue, colorMain } from "../../shared/styles/generic";

export const StyledInvestmentPageWrapper = styled.div`
  max-width: 60%;

  .ant-progress-line {
    margin-left: 80px;
  }

  .ant-steps {
    overflow-x: visible !important;
  }
`;

export const StyledInvestmentPageTitle = styled.p`
  text-decoration: underline;
  display: block;
  padding: 0 3.75rem 0.625rem;
`;

export const StyledUDDText = styled.p`
  padding-left: 3.75rem;
`;

export const StyledNoAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2em 0;
`;

export const StyledBottomBarWrapper = styled.div`
  margin: 3.0625rem auto;
  max-width: 57.5rem;
  padding-left: 3rem;
`;

export const StyledStepsBarWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

export const StyledLinkButton = styled(LinkButton)`
  color: ${colorBlue};

  &:hover {
    color: ${colorMain};
  }
`;

export const StyledInvestStepWrapper = styled.div`
  margin-top: 3rem;
  padding-left: 3rem;
`;

export const StyledInvestmentDetailCard = styled.div`
  display: flex;
  align-items: center;
  padding: 1.625rem;
  margin-bottom: 2.9375rem;
  box-shadow: -0.3125rem 0.3125rem 2.5rem rgba(0, 0, 0, 0.07);
`;

export const StyledInvestmentDetailCardTitleAndAssetWrapper = styled.div`
  margin-left: 1.625rem;
  flex: 1;
`;

export const StyledInvestmentTitle = styled.p`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2.5rem;
  margin-bottom: 0;
`;

export const StyledInvestmentAssetClass = styled.p`
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.25rem;
`;

export const StyledInvestmentDetailCardPriceWrapper = styled.div`
  align-self: flex-end;
`;

export const StyledInvestmentDetailCardPrice = styled.p`
  font-weight: 600;
  font-size: 1.625rem;
  line-height: 1.3125rem;
  margin-bottom: 0;
`;

export const StyledInvestmentDetailCardPriceLabel = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.875rem;
`;

export const StyledInvestmentAvailableBalance = styled.p`
  font-size: 0.875rem;
  line-height: 1.4375rem;
`;

// bottomBar styles
export const BottomBarCancelButtonWrapper = styled.div`
  margin-top: 1.3125rem;
`;
