import { Either, right } from "fp-ts/Either";

import {
  Failure,
  TryUseCase,
} from "@portittech/portit-react-common-components";

import {
  CreateInvestmentKycInfoRequest,
  InvestmentKycInfoResponse,
  CreateInvestmentKycInfoParams,
} from "./entities";

import { InvestmentsKycInfoRepository } from "./repository";

/**
 * Use this usecase to retrieve data for user investment preferences
 */
export class GetInvestmentsInfoData extends TryUseCase<
  void,
  InvestmentKycInfoResponse
> {
  constructor(private readonly repository: InvestmentsKycInfoRepository) {
    super();
  }

  protected async tryRun(): Promise<
    Either<Failure, InvestmentKycInfoResponse>
  > {
    return right(await this.repository.getInvestmentsKycInfo());
  }
}

/**
 * Use this usecase to create user investment preferences info at first login kyc
 */

export class CreateInvestmentsInfo extends TryUseCase<
  CreateInvestmentKycInfoParams,
  InvestmentKycInfoResponse
> {
  constructor(private readonly repository: InvestmentsKycInfoRepository) {
    super();
  }

  protected async tryRun(params: {
    request: CreateInvestmentKycInfoRequest;
  }): Promise<Either<Failure, InvestmentKycInfoResponse>> {
    return right(
      await this.repository.createInvestmentsKycInfo(params.request)
    );
  }
}
