import styled, { css } from "styled-components";
import {
  colorMain,
  fontFamilyMontserrat,
} from "../../../../shared/styles/generic";

export const StyledInvestmentDetailPageMainWrapper = styled.div`
  padding: 1.9063rem 2.5313rem;
`;

export const StyledInvestmentDetailPageDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

interface InvestmentDetailPageDetailTextProps {
  isbold?: boolean;
  isColored?: boolean;
}

export const StyledInvestmentDetailPageDetailText = styled.p<InvestmentDetailPageDetailTextProps>`
  font-size: 1rem;
  line-height: 1.875rem;
  font-family: ${fontFamilyMontserrat};
  font-weight: ${({ isbold }) => (isbold ? "600" : "400")};
  ${({ isColored }) =>
    isColored &&
    css`
      color: ${colorMain};
    `}
`;

export const StyledInvestmentDetailPageBottomLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.9688rem;
`;
