import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Context, getSubT } from "@portittech/portit-react-common-components";

import { ReactComponent as LogoutIcon } from "../../../assets/icons/logout-icon.svg";

import {
  StyledLeftPanelLink,
  StyledListMessage,
  StyledLogoutTextButton,
  StyledLogOutWrapper,
} from "./styles/LeftPanelDrawer.styles";
import { ElviriaPages } from "../../../shared/pages";

interface LeftPanelDrawerProps {
  onHideModal: () => void;
}

export const LeftPanelDrawer = ({
  onHideModal,
}: LeftPanelDrawerProps): React.ReactElement => {
  const { dispatchLogout } = useContext(Context);
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "overview.drawer");

  return (
    <>
      <StyledListMessage>
        <div className="collapsible">
          <StyledLeftPanelLink
            onClick={onHideModal}
            to={ElviriaPages.MESSAGING}
          >
            {tc("messages_label")}
          </StyledLeftPanelLink>
          <StyledLeftPanelLink
            onClick={onHideModal}
            to={ElviriaPages.DASHBOARD}
          >
            {tc("news_label")}
          </StyledLeftPanelLink>
          <StyledLogOutWrapper>
            <StyledLogoutTextButton onClick={dispatchLogout}>
              <LogoutIcon />
              {tc("logout_action")}
            </StyledLogoutTextButton>
          </StyledLogOutWrapper>
        </div>
      </StyledListMessage>
    </>
  );
};
