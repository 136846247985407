import styled from "styled-components";
import { Button } from "antd";

import {
  colorDarkGrey,
  colorLightGrey100,
  colorMain,
  colorMainWhite,
  fontFamilyMontserrat,
} from "./generic";

export const RoundedButton = styled(Button)`
  --height: 2.875rem;
  height: var(--height);
  color: white;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  background: ${colorMain};
  line-height: var(--height);
  border: none;
  border-radius: var(--height);
  border: 0.125rem solid white;
  padding: 0 4vw;
  font-family: ${fontFamilyMontserrat};
  transition: background-color 0.3s;

  &.ant-btn-circle,
  &.ant-btn-icon-only {
    padding: 0;
    min-width: var(--height);
  }

  &:hover {
    cursor: pointer;
    background-color: white;
    border-color: ${colorMain};
  }

  &.ant-btn-loading.ant-btn[disabled],
  &.ant-btn-loading.ant-btn[disabled]:hover,
  &.ant-btn-loading.ant-btn[disabled]:focus,
  &.ant-btn-loading.ant-btn[disabled]:active,
  &:active,
  &:focus {
    outline: none;
    box-shadow: 1px 1px 5px white;
    background: white;
  }
  &.ant-btn-loading.ant-btn[disabled],
  &.ant-btn-loading.ant-btn[disabled]:hover,
  &.ant-btn-loading.ant-btn[disabled]:focus,
  &.ant-btn-loading.ant-btn[disabled]:active,
  &:active,
  &:hover,
  &:focus {
    color: ${colorMain};
    svg path {
      fill: ${colorMain};
    }
  }

  svg path {
    fill: white;
  }
`;

export const RoundedOutlinedButton = styled(RoundedButton)`
  border: 1px solid ${colorMain};
  background-color: white;
  color: ${colorMain};

  &:hover,
  &:focus {
    color: white;
    background-color: ${colorMain};
    border-color: white;
  }
  svg path {
    fill: ${colorMain};
  }
  &:hover {
    color: white;
    svg path {
      fill: white;
    }
  }
`;

export const PostfixRoundedButton = styled(RoundedButton)`
  &:hover {
    cursor: pointer;
    background: ${colorMain};
  }
`;
export const ButtonsBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const CenterButtons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StepPrevButton = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  .ant-btn-primary {
    color: #42206d;
    background: none;
    border-style: none;
    box-shadow: 0px 0px;
  }
  [disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
`;

export const StepNextButton = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  .ant-btn-primary {
    color: #42206d;
    background: none;
    border-style: none;
    box-shadow: 0px 0px;
  }
`;

/**
 * Flat/Text design
 */
export const TextButton = styled(Button)`
  font-family: ${fontFamilyMontserrat};
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 2.4375rem;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  text-transform: lowercase;
  color: ${colorMain};
  border: none;

  &:hover {
    cursor: pointer;
    color: #42206d;
  }
`;

export const SquareButton = styled.button`
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  background-color: transparent;
  font-family: ${fontFamilyMontserrat};
  font-weight: 600;
  font-size: 0.825rem;
  line-height: 1rem;
  padding: 0.75rem 0;
  width: 100%;
  letter-spacing: 0.06rem;
  transition: 0.3s;
  color: ${colorMain};
  border: 1px solid ${colorMain};

  :hover {
    background-color: ${colorMain};
    color: ${colorMainWhite};
    border: 1px solid ${colorMain};
  }

  :disabled {
    background-color: ${colorLightGrey100};
    color: ${colorDarkGrey};
    border: 1px solid ${colorDarkGrey};
    pointer-events: none;
    cursor: default;
  }
`;
