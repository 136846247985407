import { TryUseCase } from "@portittech/portit-react-common-components";
import { Either, isLeft, isRight } from "fp-ts/lib/Either";
import { useCallback, useState } from "react";

const useRequestState = () => {
  const [isFetching, setFetchingState] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const switchState = useCallback((promise: Promise<Either<any, any>>) => {
    setFetchingState(true);
    setError(null);
    return promise
      .then((res) => {
        if (isLeft(res)) {
          setError(res.left);
           return Promise.reject(res.left);
        }
        if (isRight(res)) {
          return res.right;
        }
      })
      .catch((err) => {
        setError(err);
        return Promise.reject(err);
      })
      .finally(() => setFetchingState(false));
  }, []);

  return {
    error,
    isFetching,
    switchState,
  };
};

/**
 *
 * @param asyncFunction
 *
 * @example
 *  const {request: requestOpportunities, isFetching, error} = useRequest(getOpportunitiesData)
 */

export const useRequest = <Params, Value>(
  asyncFunction: TryUseCase<Params, Value>
) => {
  const { error, isFetching, switchState } = useRequestState();

  const request = useCallback(
    (requestParams: Params) => {
      return switchState(asyncFunction.run(requestParams));
    },
    [switchState, asyncFunction]
  );

  return { error, isFetching, request };
};
