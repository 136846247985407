import React, { useContext, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import {
  Context,
  LoadingComponent,
  TextFieldModelBuilder,
  SubmitButtonFormModelBuilder,
  useModelChanges,
  useModelCreatorEffect,
  FieldDecoration,
  useFormModelListener,
  failuresStore,
  TextFilter,
  CheckBoxFieldModelBuilder,
  useCubitBuilder,
} from "@portittech/portit-react-common-components";

import { ElviriaRadioAnswerFieldModelBuilder } from "../../shared/modelBuilders/common/elviriaRadionAnswerFieldModel.builder";
import { InvestmentsInfoKycModel } from "./investmentsInfoKyc.model";
import { LastStepType } from "../investmentsInfo/types";

import {
  StyledBoldTitleText,
  StyledInvestmentsInfoKycWrapper,
  StyledTitle,
  StyledTitleTextCustom,
  StyledQuestion,
  StyledSubmitButtonWrapper,
  StyledQuestionWrapper,
  StyledButtonsContainer,
  StyledSmallButton,
  StyledDollarSign,
  StyledQuestionsBlock,
  StyledSmallOutlinedButton,
  StyledLink,
} from "./investmentsInfoKyc.style";

interface InvestmentsInfoKycProps {
  setLastStep: (step: LastStepType) => void;
}

export const InvestmentsInfoKyc = ({
  setLastStep,
}: InvestmentsInfoKycProps) => {
  const { t } = useTranslation(["firstLoginKyc"]);
  const { customerCubit } = useContext(Context);

  const customerState = useCubitBuilder({ cubit: customerCubit });

  const [positiveAnswer, setPositiveAnswer] = useState<boolean>(false);

  const model = useModelCreatorEffect({
    creator: () => {
      return new InvestmentsInfoKycModel({ setLastStep, customerCubit });
    },
  });

  useFormModelListener({
    formModel: model,
    onSubmitFailed: () => {
      failuresStore.next({
        domainFailure: model.failureResponse,
      });
    },
    onSubmitted: () => {
      setLastStep("completed");
    },
  });

  const isProfessionalInvestor = useModelChanges({
    model: model?.isProfessionalInvestorFM,
    lens: (m) => m.value,
  });

  const hasInvestedInIlliquidAssets = useModelChanges({
    model: model?.investedInIlliquidFM,
    lens: (m) => m.value,
  });

  const haveIdeaHowMuchToAllocate = useModelChanges({
    model: model?.haveIdeaHowMuchToAllocateFM,
    lens: (m) => m.value,
  });

  const handleNoClick = () => {
    setLastStep("incomplete");
    model.submit();
  };

  if (!model || !customerState || customerState.status !== "read")
    return <LoadingComponent />;

  const profileType = customerState.data?.profile.type;

  return (
    <StyledInvestmentsInfoKycWrapper>
      <StyledTitle>{t("hello")}</StyledTitle>
      <StyledBoldTitleText>{t("bold_subtitle")}</StyledBoldTitleText>
      <StyledTitleTextCustom>
        {t(`title_${profileType}_text`)}
      </StyledTitleTextCustom>
      <ElviriaRadioAnswerFieldModelBuilder
        booleanFieldModel={model.isProfessionalInvestorFM}
      >
        <StyledQuestion>{t("professional_investor_question")}</StyledQuestion>
      </ElviriaRadioAnswerFieldModelBuilder>
      {isProfessionalInvestor && (
        <StyledQuestionsBlock showBlock={isProfessionalInvestor}>
          <StyledQuestionWrapper>
            <StyledQuestion style={{ marginBottom: "1em" }}>
              {t("portfolio_size_question")}
            </StyledQuestion>
            <TextFieldModelBuilder
              suffix={<StyledDollarSign>$</StyledDollarSign>}
              textFieldModel={model.globalPortfolioSizeFM}
              textFilters={[TextFilter.whiteRegExp(/^[1-9][0-9]*$/)]}
              decoration={FieldDecoration.custom({
                label: t("USD"),
              })}
            />
          </StyledQuestionWrapper>

          <ElviriaRadioAnswerFieldModelBuilder
            booleanFieldModel={model.investedInIlliquidFM}
          >
            <StyledQuestion>
              {t("illiquid_investments_question")}
            </StyledQuestion>
          </ElviriaRadioAnswerFieldModelBuilder>
          {hasInvestedInIlliquidAssets === false && (
            <StyledQuestionsBlock
              showBlock={hasInvestedInIlliquidAssets === false}
            >
              <StyledQuestion>{t("illiquid_assets_waring")}</StyledQuestion>
              <StyledQuestion>{t("continue_question")}</StyledQuestion>
              <StyledButtonsContainer>
                <StyledSmallButton onClick={() => setPositiveAnswer(true)}>
                  {t("yes_answer")}
                </StyledSmallButton>
                <StyledSmallOutlinedButton onClick={handleNoClick}>
                  {t("no_answer")}
                </StyledSmallOutlinedButton>
              </StyledButtonsContainer>
            </StyledQuestionsBlock>
          )}
          {(hasInvestedInIlliquidAssets || positiveAnswer) && (
            <StyledQuestionsBlock
              showBlock={hasInvestedInIlliquidAssets || positiveAnswer}
            >
              <ElviriaRadioAnswerFieldModelBuilder
                booleanFieldModel={model.investedTokenizationFM}
              >
                <StyledQuestion>
                  {t("tokenization_investments_question")}
                </StyledQuestion>
              </ElviriaRadioAnswerFieldModelBuilder>
              <ElviriaRadioAnswerFieldModelBuilder
                booleanFieldModel={model.digitalWalletOwnFM}
              >
                <StyledQuestion>{t("digitalWallet_question")}</StyledQuestion>
              </ElviriaRadioAnswerFieldModelBuilder>
              <ElviriaRadioAnswerFieldModelBuilder
                booleanFieldModel={model.haveIdeaHowMuchToAllocateFM}
              >
                <StyledQuestion>
                  {t("how_much_to_allocate_question")}
                </StyledQuestion>
              </ElviriaRadioAnswerFieldModelBuilder>
            </StyledQuestionsBlock>
          )}
          {haveIdeaHowMuchToAllocate && (
            <StyledQuestionsBlock showBlock={haveIdeaHowMuchToAllocate}>
              <StyledQuestionWrapper>
                <StyledQuestion style={{ marginBottom: "1em" }}>
                  {t("approx_question")}
                </StyledQuestion>
                <TextFieldModelBuilder
                  suffix={<StyledDollarSign>$</StyledDollarSign>}
                  textFieldModel={model.approximatelyInvestSumFM}
                  textFilters={[TextFilter.whiteRegExp(/^[1-9][0-9]*$/)]}
                  decoration={FieldDecoration.custom({
                    label: t("USD"),
                  })}
                />
              </StyledQuestionWrapper>
            </StyledQuestionsBlock>
          )}
          {haveIdeaHowMuchToAllocate === false && (
            <StyledQuestionsBlock
              showBlock={haveIdeaHowMuchToAllocate === false}
            >
              <StyledQuestionWrapper>
                <StyledQuestion style={{ marginBottom: "1em" }}>
                  {t("assets_question")}
                </StyledQuestion>
                <TextFieldModelBuilder
                  textFieldModel={model.mostLikedAssetsFM}
                  decoration={FieldDecoration.custom({
                    label: t("Assets"),
                  })}
                />
              </StyledQuestionWrapper>
            </StyledQuestionsBlock>
          )}
          {typeof haveIdeaHowMuchToAllocate !== "undefined" && (
            <StyledQuestionWrapper>
              <CheckBoxFieldModelBuilder
                booleanFieldModel={model.acceptsPolicyFM}
              >
                <StyledQuestion>
                  <Trans
                    i18nKey="policy_question"
                    t={t}
                    components={{
                      a: (
                        <StyledLink
                          href="https://www.elviria.ch/privacy-policy"
                          target="_blank"
                        />
                      ),
                    }}
                  />
                </StyledQuestion>
              </CheckBoxFieldModelBuilder>
            </StyledQuestionWrapper>
          )}
        </StyledQuestionsBlock>
      )}

      <StyledSubmitButtonWrapper
        shouldIncreaseMarginTop={!isProfessionalInvestor}
      >
        <SubmitButtonFormModelBuilder formModel={model}>
          {t("submit")}
        </SubmitButtonFormModelBuilder>
      </StyledSubmitButtonWrapper>
    </StyledInvestmentsInfoKycWrapper>
  );
};
