import { LoadingComponent } from "@portittech/portit-react-common-components";
import React from "react";
import { StyledSecondaryText } from "./investmentsByOpportunity.styles";
import { formatCurrencyNumber } from "../../../../shared/helpers/formatCurrencyNumber";
import { useInvestmentConvertedAmount } from "../../../../shared/hooks/useInvestmentConvertedAmount";

interface ConvertedAmountProps {
  baseCurrency: string;
  targetCurrency: string;
  amountToConvert: string;
}

export const ConvertedAmount = ({
  baseCurrency,
  targetCurrency,
  amountToConvert,
}: ConvertedAmountProps) => {
  const { investmentConvertedAmount, isConvertedAmountFetching } =
    useInvestmentConvertedAmount({
      baseCurrency,
      targetCurrency,
      moneyInvested: amountToConvert,
    });

  if (isConvertedAmountFetching) return <LoadingComponent />;

  return (
    <StyledSecondaryText>
      {formatCurrencyNumber(investmentConvertedAmount)}
    </StyledSecondaryText>
  );
};
