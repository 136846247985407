import styled from "styled-components";
import {
  colorGrey,
  fontFamilyMontserrat,
} from "../../../shared/styles/generic";

export const StyledDataRoomFileWrapper = styled.div`
  display: flex;
  padding: 1rem 1.5rem;
  margin-bottom: 0.9375rem;
  border: 1px solid ${colorGrey};
  font-family: ${fontFamilyMontserrat};
  align-items: center;
`;

export const StyledDataRoomFileDetailsWrapper = styled.div`
  flex: 1;
`;

export const StyledDataRoomFileDownloadIcon = styled.img`
  height: 1.4688rem;
  width: 1.3125rem;
  cursor: pointer;
`;

export const StyledDataRoomFileName = styled.p`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.4375rem;
`;

export const StyledDataRoomFileDescription = styled.p`
  font-size: 12px;
`;

export const StyledDataRoomFileDateAndSizeWrapper = styled.div`
  display: flex;
  gap: 2.5rem;
  margin-bottom: 0.625rem;
`;

export const StyledDataRoomFileDateAndSize = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0;
`;
