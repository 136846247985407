import React from "react";
import { useParams } from "react-router-dom";

import {
  LoadingComponent,
  tryParseInt,
  useModelChanges,
  useModelCreatorEffect,
} from "@portittech/portit-react-common-components";

import {
  StyledAdminInnerPageWrapper,
  StyledGrid,
} from "../../../../shared/styles/page.styles";
import { HelpWidget } from "../../../widgets/helpWidget";
import { GuideWidget } from "../../../widgets/guideWidget";
import { MyPreferencesWidget } from "../../../widgets/myPreferencesWidget";
import { TransactionModel } from "../transactions-widget/transaction.model";
import { TransferTransactionInvestment } from "./transactions-types/transferTransactionInvestment.component";
import { TransactionTypeNotFound } from "./transactions-types/transactionTypeNotFound";
import { StyledStickyRightWidgetsWrapper } from "../../../widgets/common.styles";

export const TransactionDetails = () => {
  const { balanceId, transactionId } = useParams<{
    balanceId;
    transactionId;
  }>();

  const transactionModel = useModelCreatorEffect({
    waitDeps: [balanceId, transactionId],
    creator: () => {
      return new TransactionModel({
        balanceId: tryParseInt(balanceId),
        transactionId: tryParseInt(transactionId),
      });
    },
  });

  useModelChanges(transactionModel);
  const accountTransaction = transactionModel?.value;

  if (!accountTransaction) return <LoadingComponent />;

  const renderTransaction = () => {
    const accountTransaction = transactionModel.value;

    switch (accountTransaction.transaction.type) {
      // TODO: replace TransactionTypeNotFound with relevant transaction components once the different transactions and its representation is added in database and defined
      case "conversion":
        return (
          <TransactionTypeNotFound accountTransaction={accountTransaction} />
        );
      case "payment":
        return (
          <TransactionTypeNotFound accountTransaction={accountTransaction} />
        );
      case "transfer":
        switch (accountTransaction.transaction.sub_type) {
          case "investment":
            return (
              <TransferTransactionInvestment
                accountTransaction={accountTransaction}
              />
            );
          case "monthly_subscription":
            return (
              <TransactionTypeNotFound
                accountTransaction={accountTransaction}
              />
            );
          case "account_opening_fee":
            return (
              <TransactionTypeNotFound
                accountTransaction={accountTransaction}
              />
            );
          case "transaction":
            return (
              <TransactionTypeNotFound
                accountTransaction={accountTransaction}
              />
            );
          case "fx_return":
            return (
              <TransactionTypeNotFound
                accountTransaction={accountTransaction}
              />
            );
          default:
            return (
              <TransactionTypeNotFound
                accountTransaction={accountTransaction}
              />
            );
        }
      case "inbound_funds":
        return (
          <TransactionTypeNotFound accountTransaction={accountTransaction} />
        );
      default:
        return (
          <TransactionTypeNotFound accountTransaction={accountTransaction} />
        );
    }
  };

  return (
    <StyledAdminInnerPageWrapper>
      <StyledGrid>
        {renderTransaction()}
        <div>
          <StyledStickyRightWidgetsWrapper>
            <HelpWidget />
            <GuideWidget digitalGoldTitle />
            <MyPreferencesWidget />
          </StyledStickyRightWidgetsWrapper>
        </div>
      </StyledGrid>
    </StyledAdminInnerPageWrapper>
  );
};
