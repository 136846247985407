import { useEffect, useState } from "react";
import { getOpportunityImg } from "../usecases";
import { useRequest } from "./useRequest";

/**
 * @param imgId
 *
 * @returns src string of the img, isFetching boolean and error
 *
 * @example
 *  const [logoImg, isFetching, error] = useImage(opportunity.logoImgId)
 */

export const useImage = (imgId: number) => {
  const [imgSrc, setImgSrc] = useState(undefined);
  const {
    request: requestImg,
    isFetching,
    error,
  } = useRequest(getOpportunityImg);

  useEffect(() => {
    if (imgId) requestImg({ imgId }).then(setImgSrc);

    //cleanup
    return () => {
      setImgSrc(undefined);
    };
  }, [requestImg, imgId]);

  return [imgSrc, isFetching, error];
};
