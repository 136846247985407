import React from "react";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { getSubT } from "@portittech/portit-react-common-components";

import moment from "moment";
import { formatCurrencyNumber } from "../../../../shared/helpers/formatCurrencyNumber";
import {
  StyledExternalLink,
  StyledSmallWidgetTitle,
  StyledSmallWidgetWrapper,
} from "../../../widgets/common.styles";
import {
  StyledInvestmentDetailPageBottomLinks,
  StyledInvestmentDetailPageDetailText,
  StyledInvestmentDetailPageDetailsWrapper,
  StyledInvestmentDetailPageMainWrapper,
} from "./investmentDetailPage.styles";
import { InvestmentResponse } from "../../../../domain/features/investment/entities";

interface InvestmentDetailPageProps { 
  investment: InvestmentResponse
}

export const InvestmentDetailPage = ({ investment }: InvestmentDetailPageProps) => {
  const history = useHistory();
  const { t } = useTranslation(["investment"]);
  const tc = getSubT(t, "investment_detail_page");

  return (
    <>
      <StyledSmallWidgetWrapper>
        <StyledInvestmentDetailPageMainWrapper>
          <StyledSmallWidgetTitle>
            {tc("your_investment_title")}
          </StyledSmallWidgetTitle>
          <StyledInvestmentDetailPageDetailsWrapper>
            <StyledInvestmentDetailPageDetailText>
              {tc("investment_name_label")}
            </StyledInvestmentDetailPageDetailText>
            <StyledInvestmentDetailPageDetailText isbold>
              {investment?.opportunity?.name}
            </StyledInvestmentDetailPageDetailText>

            <StyledInvestmentDetailPageDetailText>
              {tc("date_label")}
            </StyledInvestmentDetailPageDetailText>
            <StyledInvestmentDetailPageDetailText isbold>
              {moment(investment?.date).format("DD/MM/YYYY")}
            </StyledInvestmentDetailPageDetailText>

            <StyledInvestmentDetailPageDetailText>
              {tc("no_of_tokens_label")}
            </StyledInvestmentDetailPageDetailText>
            <StyledInvestmentDetailPageDetailText isbold>
              {investment?.noToken}
            </StyledInvestmentDetailPageDetailText>

            <StyledInvestmentDetailPageDetailText>
              {tc("token_price_label")}
            </StyledInvestmentDetailPageDetailText>
            <StyledInvestmentDetailPageDetailText isbold>
              {formatCurrencyNumber(investment?.tokenPrice, investment?.currency)}
            </StyledInvestmentDetailPageDetailText>

            <StyledInvestmentDetailPageDetailText>
              {tc("order_reference_label")}
            </StyledInvestmentDetailPageDetailText>
            <StyledInvestmentDetailPageDetailText isbold>
              {investment?.reference}
            </StyledInvestmentDetailPageDetailText>

            <StyledInvestmentDetailPageDetailText>
              {tc("status_label")}
            </StyledInvestmentDetailPageDetailText>
            <StyledInvestmentDetailPageDetailText isbold>
              {investment?.status}
            </StyledInvestmentDetailPageDetailText>

            <StyledInvestmentDetailPageDetailText>
              {tc("amount_label")}
            </StyledInvestmentDetailPageDetailText>
            <StyledInvestmentDetailPageDetailText isbold>
              {formatCurrencyNumber(
                parseFloat(investment?.tokenPrice) * investment?.noToken, investment.currency
              )}
            </StyledInvestmentDetailPageDetailText>

            <StyledInvestmentDetailPageDetailText>
              {tc("total_label")}
            </StyledInvestmentDetailPageDetailText>
            <StyledInvestmentDetailPageDetailText isbold isColored>
              {formatCurrencyNumber(investment?.totalPrice, investment?.currency)}
            </StyledInvestmentDetailPageDetailText>
          </StyledInvestmentDetailPageDetailsWrapper>
        </StyledInvestmentDetailPageMainWrapper>
      </StyledSmallWidgetWrapper>

      <StyledInvestmentDetailPageBottomLinks>
        <StyledExternalLink
          type="link"
          onClick={() => {
            history.goBack();
          }}
        >
          {tc("back_button_text")}
        </StyledExternalLink>
        <StyledExternalLink type="link">
          {tc("view_investment_button_text")}
        </StyledExternalLink>
      </StyledInvestmentDetailPageBottomLinks>
    </>
  );
};
