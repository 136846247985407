import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import {
  getSubT,
  LoadingComponent,
  tryParseInt,
  useModelChanges,
  useModelCreatorEffect,
} from "@portittech/portit-react-common-components";

import moment from "moment";

import { formatCurrencyNumber } from "../../../../shared/helpers/formatCurrencyNumber";
import { ElviriaPages } from "../../../../shared/pages";
import {
  StyledAdminInnerPageTitle,
  StyledAdminInnerPageWrapper,
  StyledGrid,
} from "../../../../shared/styles/page.styles";
import {
  StyledInvestmentDetailPageDetailsWrapper,
  StyledInvestmentDetailPageDetailText,
  StyledInvestmentDetailPageMainWrapper,
  StyledInvestmentDetailPageBottomLinks,
} from "../../../investments-page/components/investment-detail-page/investmentDetailPage.styles";
import {
  StyledExternalLink,
  StyledRouterLink,
  StyledSmallWidgetTitle,
  StyledSmallWidgetWrapper,
  StyledStickyRightWidgetsWrapper,
} from "../../../widgets/common.styles";
import { GuideWidget } from "../../../widgets/guideWidget";
import { HelpWidget } from "../../../widgets/helpWidget";
import { MyPreferencesWidget } from "../../../widgets/myPreferencesWidget";
import { TransactionModel } from "../transactions-widget/transaction.model";
import { StyledTransactionReceiptInnerWrapper } from "../transactions/transactionsStyles";

export const TransactionReceipt = () => {
  const { balanceId, transactionId } = useParams<{
    balanceId;
    transactionId;
  }>();

  const history = useHistory();

  const { t } = useTranslation(["accountPage"]);
  const tc = getSubT(t, "transaction_receipt");

  const transactionModel = useModelCreatorEffect({
    waitDeps: [balanceId, transactionId],
    creator: () => {
      return new TransactionModel({
        balanceId: tryParseInt(balanceId),
        transactionId: tryParseInt(transactionId),
      });
    },
  });

  useModelChanges(transactionModel);
  const accountTransaction = transactionModel?.value;

  if (!accountTransaction) return <LoadingComponent />;

  return (
    <StyledAdminInnerPageWrapper>
      <StyledRouterLink to={ElviriaPages.ACCOUNT} isMargin>
        {tc("transactions_link_text")}
      </StyledRouterLink>
      <StyledAdminInnerPageTitle>{tc("title")}</StyledAdminInnerPageTitle>
      <StyledGrid>
        <StyledTransactionReceiptInnerWrapper>
          <StyledSmallWidgetWrapper>
            <StyledInvestmentDetailPageMainWrapper>
              <StyledSmallWidgetTitle>
                {tc("receipt_title")}
              </StyledSmallWidgetTitle>

              <StyledInvestmentDetailPageDetailsWrapper>
                <StyledInvestmentDetailPageDetailText>
                  {/* TODO: Add translations once what data have to shown is decided */}
                  {"Amount"}
                </StyledInvestmentDetailPageDetailText>
                <StyledInvestmentDetailPageDetailText isbold>
                  {formatCurrencyNumber(
                    accountTransaction.transfer_detail.amount,
                    accountTransaction.transfer_detail.currency
                  )}
                </StyledInvestmentDetailPageDetailText>

                <StyledInvestmentDetailPageDetailText>
                  {/* TODO: Add translations once what data have to shown is decided */}
                  {"Date"}
                </StyledInvestmentDetailPageDetailText>
                <StyledInvestmentDetailPageDetailText isbold>
                  {moment(accountTransaction.transaction.created_at).format(
                    "DD/MM/YYYY"
                  )}
                </StyledInvestmentDetailPageDetailText>

                <StyledInvestmentDetailPageDetailText>
                  {/* TODO: Add translations once what data have to shown is decided */}
                  {"Reason"}
                </StyledInvestmentDetailPageDetailText>
                <StyledInvestmentDetailPageDetailText isbold>
                  {accountTransaction.transaction.reason}
                </StyledInvestmentDetailPageDetailText>

                <StyledInvestmentDetailPageDetailText>
                  {/* TODO: Add translations once what data have to shown is decided */}
                  {"Reference"}
                </StyledInvestmentDetailPageDetailText>
                <StyledInvestmentDetailPageDetailText isbold>
                  {accountTransaction.transaction.reference}
                </StyledInvestmentDetailPageDetailText>

                <StyledInvestmentDetailPageDetailText>
                  {/* TODO: Add translations once what data have to shown is decided */}
                  {"Status"}
                </StyledInvestmentDetailPageDetailText>
                <StyledInvestmentDetailPageDetailText isbold>
                  {accountTransaction.transfer_detail.status}
                </StyledInvestmentDetailPageDetailText>
              </StyledInvestmentDetailPageDetailsWrapper>
            </StyledInvestmentDetailPageMainWrapper>
          </StyledSmallWidgetWrapper>

          <StyledSmallWidgetWrapper>
            <StyledInvestmentDetailPageMainWrapper>
              <StyledSmallWidgetTitle>
                {tc("payer_title")}
              </StyledSmallWidgetTitle>
              <StyledInvestmentDetailPageDetailsWrapper>
                <StyledInvestmentDetailPageDetailText>
                  {/* TODO: Add translations once what data have to shown is decided */}
                  {"Customer"}
                </StyledInvestmentDetailPageDetailText>
                <StyledInvestmentDetailPageDetailText isbold>
                  {accountTransaction.transaction.customer_name}
                </StyledInvestmentDetailPageDetailText>
              </StyledInvestmentDetailPageDetailsWrapper>
            </StyledInvestmentDetailPageMainWrapper>
          </StyledSmallWidgetWrapper>

          <StyledInvestmentDetailPageBottomLinks>
            <StyledExternalLink
              type="link"
              onClick={() => {
                history.goBack();
              }}
            >
              {tc("back_action")}
            </StyledExternalLink>
          </StyledInvestmentDetailPageBottomLinks>
        </StyledTransactionReceiptInnerWrapper>
        <div>
          <StyledStickyRightWidgetsWrapper>
            <HelpWidget />
            <GuideWidget digitalGoldTitle />
            <MyPreferencesWidget />
          </StyledStickyRightWidgetsWrapper>
        </div>
      </StyledGrid>
    </StyledAdminInnerPageWrapper>
  );
};
