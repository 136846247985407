import styled from "styled-components";

export const StyledDataRoomRightWidgetsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4375rem;
`;

export const StyledDataFilesSectionMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
`;

export const StyledDataFilesSectionOuterWrapper = styled.div`
  margin-bottom: 2.8125rem;
`;

export const StyledDataRoomInvestButtonWrapper = styled.div`
  max-width: 18.125rem;
  margin-bottom: 1.375rem;
`;

export const StyledNoDataWrapper = styled.div`
  min-height: 15rem;
  display: flex;
  justify-content: center;
  text-align: center;
`;
