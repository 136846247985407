import styled from "styled-components";
import {
  colorMain,
  colorMainDark,
  fontFamilyMontserrat,
} from "../../../../shared/styles/generic";

export const StyledDaysLeftBarMainWrapper = styled.div`
  margin-top: 2rem;
`;

export const StyledDaysLeftBarDaysLeftText = styled.p`
  color: ${colorMain};
  font-size: 1.25rem;
  font-family: ${fontFamilyMontserrat};
  font-weight: 600;
  line-height: 1.3125rem;
`;

export const StyledDaysLeftBarStartAndEndDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.3125rem;
`;

export const StyledDaysLeftBarStartAndEndDate = styled.p`
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-family: ${fontFamilyMontserrat};
  color: ${colorMainDark};
`;
