import {
  Failure,
  TryUseCase,
} from "@portittech/portit-react-common-components";

import { Either, right } from "fp-ts/Either";
import { NewsResponse } from "./entities";
import { NewsRepository } from "./repository";

interface GetNewsDataParams {
  readonly opportunityId?: number;
}

/**
 * Use this usecase to retrieve the news data
 */
export class GetNewsData extends TryUseCase<GetNewsDataParams, NewsResponse> {
  constructor(private readonly repository: NewsRepository) {
    super();
  }

  protected async tryRun(
    params: GetNewsDataParams
  ): Promise<Either<Failure, NewsResponse>> {
    return right(await this.repository.getNews(params));
  }
}
