import styled from "styled-components";
import { colorMainDark } from "../../../../shared/styles/generic";
import { devices } from "../../../config/devices";

export const StyledInvestmentCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  margin-bottom: 0.625rem;
  align-items: center;
  padding: 1.25rem 0.8125rem;
  box-shadow: -5px 5px 40px rgba(0, 0, 0, 0.07);
  color: ${colorMainDark};
  column-gap: 0.625rem;
  cursor: pointer;
`;

export const StyledFirstColumn = styled.div`
  display: flex;
`;
export const StyledTitleWrapper = styled.div`
  margin-left: 0.875rem;
`;

export const StyledTitle = styled.div`
  font-size: 1.125rem;
  line-height: 1.5rem;
`;

export const StyledDate = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  color: #989898;
`;

export const StyledTokenWrapper = styled.div``;

export const StyledTokens = styled.div``;

export const StyledDesc = styled.div`
  font-size: 0.75rem;
`;

export const StyledTotalPrice = styled.div`
  font-size: 1.375rem;
  line-height: 1.875rem;
  font-weight: 700;

  @media ${devices.smallLaptop} {
    font-size: 1.25rem;
  }
`;

export const StyledTitleDate = styled.div`
  font-size: 0.875rem;
  line-height: 1.0625rem;
  margin-bottom: 1.375rem;
`;

export const StyledInvestmentsBlock = styled.div`
  margin-bottom: 1.25rem;
`;
