import { isLeft } from "fp-ts/Either";
import { first } from "rxjs/operators";

import {
  FetchedEvent,
  FetchFailedEvent,
  MultiModel,
  BasePageModel,
} from "@portittech/portit-react-common-components";

import { getOpportunitiesData } from "../../../shared/usecases";
import { OpportunitiesSearcherFormModel } from "./opportunitiesSearcherFormModel";
import { OpportunitiesStrainer } from "./types";
import { OpportunityResponse } from "../../../domain/features/opportunities/entities";

export class OpportunitiesPageModel extends BasePageModel {
  readonly opportunitiesPageSize = 8;
  readonly searcherFormModel: OpportunitiesSearcherFormModel;
  readonly opportunitiesModel = new MultiModel<
    OpportunityResponse,
    OpportunitiesStrainer
  >({ canWaitStrainer: true });

  constructor() {
    super();
    this.searcherFormModel = new OpportunitiesSearcherFormModel({});

    // Fetch page with filter
    this.opportunitiesModel.applyFetcher(async (section, strainer) => {
      // If not exist any account the strainer is undefined
      if (!strainer) return new FetchedEvent([], 0);

      const page = section.toPage(this.opportunitiesPageSize);

      const res = await getOpportunitiesData.run({
        pagination: {
          name: strainer.name ?? undefined,
          page: page,
          size: this.opportunitiesPageSize,
          orderType: strainer.orderType,
          order: strainer.sortBy,
        },
      });

      if (isLeft(res)) {
        return new FetchFailedEvent(res.left);
      } else {
        return new FetchedEvent(
          res.right.opportunityList,
          res.right.pagination.total_entries
        );
      }
    });

    // Update filter when user changes
    this.closer.add(
      this.searcherFormModel.listenOnStatusChange({
        onLoadFailed: () => {
          this.opportunitiesModel.applyStrainer(undefined);
        },
        onSubmitted: () => {
          this.opportunitiesModel.applyStrainer(
            this.searcherFormModel.successResponse
          );
        },
      })
    );
  }

  async onLoading() {
    await this.searcherFormModel.onLastChange
      .pipe(first((it) => it.status !== "loading"))
      .toPromise();

    this.searcherFormModel.enableAutoSubmit();

    this.notifyLoaded();
  }
}
