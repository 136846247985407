import { useAppStore, useCubitBuilder } from "@portittech/portit-react-common-components";
import React, { useState, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { ElviriaPages } from "../../shared/pages";
import { InvestmentsInfoKyc } from "../investmentsInfoKyc/investmentsInfoKyc.component";
import { SorryMessage } from "./components/sorry-message/sorryMessage.component";
import { LastStepType } from "./types";

export const InvestmentsInfo = () => {
  const [lastStep, setLastStep] = useState<LastStepType>();
  const {customerCubit} = useAppStore()
  const customerState = useCubitBuilder({ cubit: customerCubit });

  const renderContent = () => {
    switch (lastStep) {
      case "completed":
        if (customerState.data.customer.type === 'INDIVIDUAL') {
          return <Redirect to={ElviriaPages.KYC} />
        } 
        return <Redirect to={ElviriaPages.PENDING_VERIFICATION} />;
      case "incomplete":
        return <SorryMessage />;
      default:
        return <InvestmentsInfoKyc setLastStep={setLastStep} />;
    }
  };

  return <Fragment>{renderContent()}</Fragment>;
};
