import styled, { css } from "styled-components";
import {
  colorDarkGrey,
  colorMainDark,
  fontFamilyMontserrat,
} from "../../../../shared/styles/generic";

export const investmentsTabStyles = css`
  .ant-tabs-nav-list {
    gap: 0.9375rem;
  }
  .ant-tabs-tab {
    background: none !important;
    .ant-tabs-tab-btn {
      font-size: 1rem;
      font-weight: 600;
      color: ${colorDarkGrey};
    }
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${colorMainDark};
  }
`;

export const StyledPortfolioChartsMainWrapper = styled.div`
  font-family: ${fontFamilyMontserrat};

  ${investmentsTabStyles}
`;

export const StyledTabWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
`;
