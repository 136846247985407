import styled from "styled-components";
import { fontFamilyMontserrat } from "../../shared/styles/generic";
import { FlagIcon } from "@portittech/portit-react-common-components";

export const StyledNewCurrencyPageWrapper = styled.div`
  padding: 0 4vw 1.875rem;
`;

export const StyledNewCurrencyColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
  font-family: ${fontFamilyMontserrat};
`;

export const StyledDropdown = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledFlagIcon = styled(FlagIcon)`
  width: 19px;
  height: 19px;
  display: block;
  margin-right: 10px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const StyledSelectFieldWrapper = styled.div`
  width: 8rem;
  input {
    box-shadow: none;
  }
`;

export const StyledSubmitButtonWrapper = styled.div`
  margin-top: 4rem;
`;
