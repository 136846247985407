import styled from "styled-components";

import {
  colorMainDark,
  fontFamilyMontserrat,
} from "../../../../shared/styles/generic";

export const StyledInvestmentsListWrapper = styled.div`
  font-family: ${fontFamilyMontserrat};
  color: ${colorMainDark};
`;

export const StyledInnerWrap = styled.div`
  margin: 0 auto;
  padding: 0 3em 2em 0;
`;

export const StyledInvestmentTypeSelectWrapper = styled.div`
  flex-grow: 1;
`;

export const StyledInvestmentsList = styled.div`
  margin: 2em 0;
`;
