import styled from "styled-components";

export const StyledAdminPageWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(12.5rem, 15vw) 1fr;
`;

export const StyledContent = styled.div`
  height: 100%;
  flex: 1;
  /* Commenting it out to make the right sidebar widgets sticky, otherwise with overflow property set to any ancestors, sticky property won't work */
  /* See: https://stackoverflow.com/questions/43707076/how-does-the-position-sticky-property-work/49080822#49080822 */
  /* overflow: auto; */
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .body {
    flex: 1;
  }
`;
