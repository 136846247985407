import React from "react";
import { ElviriaCheckboxStyle } from "./elviriaCheckbox.style";

interface ElviriaCheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange: (event) => void;
  value?: string | number;
}

export const ElviriaCheckbox = ({
  checked,
  disabled,
  indeterminate,
  onChange,
  value,
}: ElviriaCheckboxProps): React.ReactElement => {
  return (
    <ElviriaCheckboxStyle>
      <input
        className={indeterminate ? "indeterminate" : ""}
        type={"checkbox"}
        value={value}
        checked={checked ?? false}
        onChange={onChange}
        disabled={disabled ?? false}
      />
      <span className={`checkmark`} />
    </ElviriaCheckboxStyle>
  );
};
