import React from "react";
import moment from "moment";

import { useTranslation } from "react-i18next";

import { NewsListResponse } from "../../../../domain/features/news/entities";
import { ElviriaPages } from "../../../../shared/pages";
import { StyledRouterLink } from "../../../widgets/common.styles";
import {
  StyledNewsCard,
  StyledNewsCardDate,
  StyledNewsCardTitle,
} from "./newsCard.styles";

export const NewsCard = ({ date, title }: NewsListResponse) => {
  const { t } = useTranslation(["opportunityPage"]);

  return (
    <StyledNewsCard>
      <div>
        <StyledNewsCardDate>
          {moment(date).format("DD/MM/YYYY")}
        </StyledNewsCardDate>
        <StyledNewsCardTitle>{title}</StyledNewsCardTitle>
      </div>
      <div>
        <StyledRouterLink
          to={ElviriaPages.DASHBOARD /*`${ElviriaPages.NEWS}/${id}`*/}
        >
          {t("read_more")}
        </StyledRouterLink>
      </div>
    </StyledNewsCard>
  );
};
