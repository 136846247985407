import React from "react";
import { useTranslation } from "react-i18next";

import { getSubT } from "@portittech/portit-react-common-components";

import { ReactComponent as WatchlistIcon } from "../../../assets/icons/watchlist.svg";

import { colorMainDark } from "../../../shared/styles/generic";
import { StyledWatchlistLinkWrapper } from "../common.styles";
import { useWatchlistStore } from "../../watchlistStore";

interface AddToWatchListProps {
  opportunityId: number;
}

export const AddToWatchListButton = ({
  opportunityId,
}: AddToWatchListProps) => {
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "interested_investment_widget");

  const { onToggleOppToWatchlist, watchlistOpportunities } = useWatchlistStore();

  const opportunityAlreadyAdded = watchlistOpportunities.some(
    (opp) => opp.id === opportunityId
  );

  return (
    <StyledWatchlistLinkWrapper
      onClick={() => onToggleOppToWatchlist(opportunityId)}
    >
      <WatchlistIcon fill={colorMainDark} stroke={colorMainDark} />
      {!opportunityAlreadyAdded ? (
        <span>{tc("watchlist_link")}</span>
      ) : (
        tc("added")
      )}
    </StyledWatchlistLinkWrapper>
  );
};
