import styled from "styled-components";
import { MontserratSubtitle } from "../../../../shared/styles/title";

export const StyledMontserratSubtitle = styled(MontserratSubtitle)`
  color: #37383c;
  margin: 0;
  text-align: left;
  line-height: 1.125rem;
`;

export const StyledDataRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const StyledOrderNameWrapper = styled.div`
  flex: 1;
`;
