import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Styled = styled.div``;

export const TermsAndConditionsPage = (): React.ReactElement => {
  const { t } = useTranslation("termsAndConditions");
  return <Styled dangerouslySetInnerHTML={{ __html: t("page") }} />;
};
