import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  colorBlue,
  colorMain,
  colorMainWhite,
} from "../../shared/styles/generic";

interface BoUserBannerWrapperProps {
  active: boolean;
}

export const BoUserBannerWrapper = styled.div<BoUserBannerWrapperProps>`
  position: fixed;
  top: 0.625rem;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  min-height: 3.125rem;
  padding: ${({ active }) => (active ? "0.5em 2em" : 0)};
  background-color: ${colorMain};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: -5px 5px 40px 0px rgba(0, 0, 0, 0.3);
`;

export const StyledBoUserKycLink = styled(Link)`
  display: block;
  text-decoration: underline;
  color: ${colorBlue};
  cursor: pointer;

  :hover {
    color: ${colorMainWhite};
  }
`;
