import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  FlagIcon,
  getSubT,
  LoadingComponent,
  SelectFieldModelBuilder,
  useModelChanges,
  useModelCreatorEffect,
} from "@portittech/portit-react-common-components";

import { ReactComponent as FundingIcon } from "../../../assets/icons/funding.svg";

import { ElviriaPages } from "../../../shared/pages";

import {
  StyledAccountBalanceWidgetWrapper,
  StyledButtonWrapper,
  StyledFundingIconWrapper,
  StyledFundingWidgetTitle,
  StyledFundingWrapper,
  StyledSelectFieldWrapper,
  StyledSum,
  StyledSumDescription,
} from "./accountBalanceWidget.style";
import { StyledAccountBalanceRoweWrapper } from "../../account-page/accountPage.style";
import { colorMainDark, colorMainWhite } from "../../../shared/styles/generic";
import { SquareButton } from "../../../shared/styles/button";
import {
  StyledDescription,
  StyledDoubleButtonsWrapper,
  StyledNumber,
} from "../common.styles";
import { formatCurrencyNumber } from "../../../shared/helpers/formatCurrencyNumber";

import { useDeposit } from "../../deposit-modal/useDeposit";
import { AccountWidgetModel } from "./accountWidget.model";
import { Spin } from "antd";

interface AccountBalanceWidgetProps {
  withFundingIcon?: boolean;
  withWithdrawButton?: boolean;
  fundingInfoWidget?: boolean;
  selectedBalanceId?: number;
}

export const AccountBalanceWidget = ({
  withFundingIcon,
  withWithdrawButton,
  fundingInfoWidget,
  selectedBalanceId,
}: AccountBalanceWidgetProps) => {
  const model = useModelCreatorEffect({
    creator: () => new AccountWidgetModel(selectedBalanceId),
  });
  const { t } = useTranslation(["accountPage"]);
  const tc = getSubT(t, "account_balance_widget");
  const history = useHistory();

  const account = useModelChanges({
    model,
    lens: (m) => m.account,
  });

  const [showFundingInfo] = useDeposit(account?.funding_info);

  if (!model) return <LoadingComponent />;

  return fundingInfoWidget ? (
    <StyledFundingWrapper>
      <div>
        <StyledFundingWidgetTitle>
          {tc("add_funds_to_your_acc")}
        </StyledFundingWidgetTitle>
        {account === undefined ? ( // when account info is undefined means loading
          <Spin />
        ) : account === null ? ( // when account info is loaded but is null means there is no wallet or balance then show 0
          <StyledSum>{formatCurrencyNumber(0)}</StyledSum>
        ) : (
          // otherwise when account info is loaded and has some amount in account
          <StyledSum>
            {formatCurrencyNumber(account?.available_amount)}
          </StyledSum>
        )}
        <StyledSumDescription>{tc("total_balance")}</StyledSumDescription>
      </div>
      <div>
        <StyledFundingIconWrapper>
          <FundingIcon fill={colorMainWhite} stroke={colorMainWhite} />
        </StyledFundingIconWrapper>
        <StyledButtonWrapper>
          <SquareButton onClick={showFundingInfo} color={colorMainWhite}>
            {tc("deposit_button")}
          </SquareButton>
        </StyledButtonWrapper>
      </div>
    </StyledFundingWrapper>
  ) : (
    <StyledAccountBalanceWidgetWrapper>
      <StyledAccountBalanceRoweWrapper>
        <div>
          {account === undefined ? ( // when account info is undefined means loading
            <LoadingComponent />
          ) : account === null ? ( // when account info is loaded but is null means there is no wallet or balance then show 0
            <StyledNumber>{formatCurrencyNumber(0)}</StyledNumber>
          ) : (
            // otherwise when account info is loaded and has some amount in account
            <StyledNumber>
              {formatCurrencyNumber(
                account?.available_amount,
                account?.currency
              )}
            </StyledNumber>
          )}
          <StyledDescription>{tc("available_balance")}</StyledDescription>
        </div>

        {!selectedBalanceId && (
          <StyledSelectFieldWrapper>
            <SelectFieldModelBuilder
              selectFieldModel={model.currencies}
              useSearch={false}
            >
              {(item) => (
                <>
                  <FlagIcon currency={item} flagSize={16} /> {item}
                </>
              )}
            </SelectFieldModelBuilder>
          </StyledSelectFieldWrapper>
        )}

        {withFundingIcon && (
          <FundingIcon fill={colorMainDark} stroke={colorMainDark} />
        )}
      </StyledAccountBalanceRoweWrapper>
      <StyledDoubleButtonsWrapper>
        <SquareButton onClick={showFundingInfo}>
          {tc("deposit_button")}
        </SquareButton>

        {/* withdraw button based if it to be visible or not*/}
        {withWithdrawButton && (
          // withdraw button color will be different based on user has money in his/her account or not
          <SquareButton
            onClick={() => history.push(ElviriaPages.ACCOUNT_PAYMENT)}
            disabled={account?.available_amount === 0}
          >
            {tc("withdraw_button")}
          </SquareButton>
        )}
      </StyledDoubleButtonsWrapper>
    </StyledAccountBalanceWidgetWrapper>
  );
};
