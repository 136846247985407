import React, { Suspense, useContext } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  Context,
  EnumUtils,
  FormStepBarStyled,
  LoadingComponent,
  StepsBarFormModelBuilder,
  StepsFormModelBuilder,
  useModelChanges,
  useModelCreatorEffect,
} from "@portittech/portit-react-common-components";

import { ElviriaPages } from "../../shared/pages";

import { BottomBar } from "./bottomBar.component";
import { PaymentsFormModel } from "./investmentPage.model";

import { InvestSteps } from "./config";

import { StyledPageTitle } from "../withdraw-page/withdraw.styles";

import {
  StyledBottomBarWrapper,
  StyledInvestmentPageTitle,
  StyledInvestmentPageWrapper,
  StyledStepsBarWrapper,
  StyledUDDText,
} from "./investment.styles";

import { usePageId } from "../../shared/hooks/usePageId";
import { InvestmentModal } from "./investmentModal.component";
import { useHistory } from "react-router-dom";
import { InvestStep } from "./invest-step/investStep.component";
import { ReviewStep } from "./review-step/reviewStep.component";
import { ScrollToTop } from "../../shared/hooks/useScrollToTop";

export const InvestmentPage = () => {
  const { customerCubit } = useContext(Context);
  const { t } = useTranslation(["investment"]);
  const pageId = usePageId();
  const SwalWithReact = withReactContent(Swal);
  const history = useHistory();

  const onLastStepSubmit = (isError: boolean = false) => {
    SwalWithReact.fire({
      html: (
        <Suspense fallback={LoadingComponent}>
          <InvestmentModal swalWithReact={SwalWithReact} isError={isError} />
        </Suspense>
      ),

      showConfirmButton: false,
      width: "40.625rem",
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "", // disable popup animation
        icon: "", // disable icon animation
      },
    }).then((result) => {
      if (result) {
        history.push(ElviriaPages.DASHBOARD);
      }
    });
  };

  const investmentModel = useModelCreatorEffect(() => {
    return new PaymentsFormModel({
      pageId,
      onLastStepSubmit,
      customerCubit,
    });
  });

  const currentOpportunity = useModelChanges({
    model: investmentModel,
    lens: (m) => m?.currentOpportunity,
  });

  useModelChanges(investmentModel);

  if (!investmentModel) return <LoadingComponent />;

  return (
    <StyledInvestmentPageWrapper>
      <StyledInvestmentPageTitle>{t("page_title")}</StyledInvestmentPageTitle>
      {currentOpportunity ? (
        <>
          <StyledPageTitle>{currentOpportunity?.name}</StyledPageTitle>

          {currentOpportunity?.status !== "Active" ? (
            <StyledUDDText>{t("udd_text")}</StyledUDDText>
          ) : (
            <>
              <StepsFormModelBuilder
                formModel={investmentModel}
                topBar={
                  <StyledStepsBarWrapper>
                    <FormStepBarStyled>
                      <StepsBarFormModelBuilder
                        formModel={investmentModel}
                        steps={new EnumUtils(InvestSteps).keys.map((name) => {
                          return {
                            title: t(`${name}_title_step`),
                          };
                        })}
                      />
                    </FormStepBarStyled>
                  </StyledStepsBarWrapper>
                }
              >
                {(step) => {
                  switch (step) {
                    case InvestSteps.invest:
                      return <InvestStep investmentModel={investmentModel} />;
                    case InvestSteps.review:
                      return (
                        <ScrollToTop>
                          <ReviewStep investmentModel={investmentModel} />
                        </ScrollToTop>
                      );
                  }
                }}
              </StepsFormModelBuilder>
              <StyledBottomBarWrapper>
                <BottomBar formModel={investmentModel} />
              </StyledBottomBarWrapper>
            </>
          )}
        </>
      ) : null}
    </StyledInvestmentPageWrapper>
  );
};
