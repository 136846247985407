import {
  CustomerCubit,
  existEmailUseCase,
  existUsernameUseCase,
  Failure,
  FieldValidatorErrors,
  FieldValidators,
  FormModel,
  TextFieldModel,
  validatePersonEntityUseCase,
} from "@portittech/portit-react-common-components";
import { isLeft, isRight } from "fp-ts/lib/Either";

export class SettingsPageModel extends FormModel<any, Failure> {
  readonly emailFM = new TextFieldModel({
    validators: [FieldValidators.required, FieldValidators.email],
    asyncValidators: [
      async () => {
        const res = await existEmailUseCase.run({ email: this.emailFM.value });
        if (isRight(res)) {
          return FieldValidatorErrors.emailExists;
        }

        return undefined;
      },
    ],
  });

  readonly usernameFM = new TextFieldModel({
    validators: [FieldValidators.required],
    asyncValidators: [
      async () => {
        const resExistUsername = await existUsernameUseCase.run({
          username: this.usernameFM.value,
        });
        if (isRight(resExistUsername)) {
          return FieldValidatorErrors.usernameExists;
        }
        const resValidatePersonEntity = await validatePersonEntityUseCase.run({
          username: this.usernameFM.value,
        });
        if (isLeft(resValidatePersonEntity)) {
          return FieldValidatorErrors.invalidUsername;
        }
        return undefined;
      },
    ],
  });

  readonly passwordFM = new TextFieldModel({
    validators: [FieldValidators.required, FieldValidators.password],
  });

  readonly nameFM = new TextFieldModel({
    validators: [FieldValidators.required],
  });

  readonly addressLine1FM = new TextFieldModel({
    validators: [FieldValidators.required],
  });

  readonly addressLine2FM = new TextFieldModel({
    validators: [FieldValidators.required],
  });

  readonly countryFM = new TextFieldModel({
    validators: [FieldValidators.required],
  });

  readonly cityFM = new TextFieldModel({
    validators: [FieldValidators.required],
  });

  readonly postcodeFM = new TextFieldModel({
    validators: [FieldValidators.required],
  });

  readonly ibanFM = new TextFieldModel({
    validators: [FieldValidators.required],
  });

  readonly swiftFM = new TextFieldModel({
    validators: [FieldValidators.required],
  });

  readonly customerCubit: CustomerCubit;

  constructor({ customerCubit }: { customerCubit: CustomerCubit }) {
    super({ isLoading: true });
    this.customerCubit = customerCubit;

    //Todo: restructure this
    this.passwordFM.updateInitialValue("password123");
  }

  async onLoading() {
    await this.customerCubit.read();

    this.emailFM.updateInitialValue(
      this.customerCubit?.state?.data?.customer?.email
    );

    this.usernameFM.updateInitialValue(
      this.customerCubit?.state?.data?.customer?.user?.username
    );

    this.nameFM.updateInitialValue(
      this.customerCubit?.state?.data?.profile?.name
    );

    // Temporary initial values for the fields starts here
    // later on this date will also come from API

    this.addressLine1FM.updateInitialValue("10 Cornflower Court");

    this.addressLine2FM.updateInitialValue("Sovereign Way");

    this.countryFM.updateInitialValue("United Kingdom");

    this.cityFM.updateInitialValue("Tonbridge");

    this.postcodeFM.updateInitialValue("TN9 1FY");

    this.ibanFM.updateInitialValue("GBMGDD121469346363");
    this.swiftFM.updateInitialValue("GBMGDD");
    // Temporary initial values for the fields ends here
  }

  async onSubmitting() {
    console.log("Submitting..");
  }
}
