import { Either, isLeft, isRight } from "fp-ts/lib/Either";

import {
  CustomerCubit,
  Failure,
  FieldValidators,
  FormModel,
  MultiSelectFieldModel,
  SelectFieldModel,
  SingleFieldModel,
} from "@portittech/portit-react-common-components";

import {
  createInvestmentsPreferences,
  getInvestmentsPreferences,
  getInvestmentsPreferencesReferenceData,
  updateInvestmentsPreferences,
} from "../../shared/usecases";
import { CreatePreferenceRequest } from "../../domain";
import { RangeFieldModelValue } from "./RangeFieldModelBuilder";
import {
  Opportunity,
  Sector,
  EquityStage,
} from "../../domain/features/reference-data/entities";
import { maxInputRangeSum, minInputRangeSum } from "../config/data";
import { getHorizonValueString } from "../../shared/helpers/getHorizonValue";

export class PreferencesModel extends FormModel<any, Failure> {
  preferenceId: number | null = null;

  readonly investMentOpportunitiesFM = new MultiSelectFieldModel<Opportunity>({
    validators: [FieldValidators.required],
  });
  readonly sectorFM = new MultiSelectFieldModel<Sector>({
    validators: [FieldValidators.required],
  });
  readonly equityStageFM = new MultiSelectFieldModel<EquityStage>({
    validators: [FieldValidators.required],
  });
  readonly equityRangeFM = new SingleFieldModel<RangeFieldModelValue>({
    value: { min: minInputRangeSum, max: maxInputRangeSum },
    validators: [FieldValidators.required, FieldValidators.mustBeTrue],
  });

  readonly debtRangeFM = new SingleFieldModel<RangeFieldModelValue>({
    value: { min: minInputRangeSum, max: maxInputRangeSum },
    validators: [FieldValidators.required],
  });

  readonly loanRangeFM = new SingleFieldModel<RangeFieldModelValue>({
    value: { min: minInputRangeSum, max: maxInputRangeSum },
    validators: [FieldValidators.required],
  });

  readonly HorizonFM = new SelectFieldModel<string>({
    validators: [FieldValidators.required],
  });

  readonly customerCubit: CustomerCubit;

  constructor({ customerCubit }: { customerCubit: CustomerCubit }) {
    super({ isLoading: true });
    this.customerCubit = customerCubit;

    this.addFieldModels({
      step: 0,
      fieldsModels: [
        this.investMentOpportunitiesFM,
        this.sectorFM,
        this.equityStageFM,
        this.equityRangeFM,
        this.debtRangeFM,
        this.loanRangeFM,
        this.HorizonFM,
      ],
    });
  }

  getRight<TRight>(either: Either<Failure, TRight>): undefined | TRight {
    return isRight(either) ? either.right : undefined;
  }

  async onLoading() {
    const res = await getInvestmentsPreferencesReferenceData.run();

    if (isLeft(res)) return this.notifyLoadFailed;

    this.investMentOpportunitiesFM.updateItems(res.right.opportunities);
    this.sectorFM.updateItems(res.right.sectors);
    this.equityStageFM.updateItems(res.right.equityStages);
    this.HorizonFM.updateItems(["short", "medium", "long"]);

    const partyId = this.customerCubit;

    const preferenceRes = await getInvestmentsPreferences.run({
      partyId: partyId.state.data.profile.partyId,
    });

    const preference = this.getRight(preferenceRes);

    if (preference != null) {
      const {
        assetClasses,
        minHorizon,
        maxHorizon,
        id,
        sectors,
        maxEquity,
        minEquity,
        maxDebt,
        minDebt,
        maxLoan,
        minLoan,
        equityStages,
      } = preference;

      const horizonValue = getHorizonValueString(minHorizon, maxHorizon);

      this.preferenceId = id;
      this.investMentOpportunitiesFM.updateInitialValue(assetClasses);
      this.sectorFM.updateInitialValue(sectors);
      this.equityRangeFM.updateInitialValue({
        max: maxEquity,
        min: minEquity,
      });
      this.debtRangeFM.updateInitialValue({
        max: maxDebt,
        min: minDebt,
      });
      this.loanRangeFM.updateInitialValue({
        max: maxLoan,
        min: minLoan,
      });
      this.HorizonFM.updateInitialValue(horizonValue);
      this.equityStageFM.updateValue(equityStages);
    }

    this.notifyLoaded();
  }

  async onSubmitting() {
    let minHorizon: number;
    let maxHorizon: number | null;
    switch (this.HorizonFM.value) {
      case "short":
        minHorizon = 0;
        maxHorizon = 36;
        break;
      case "medium":
        minHorizon = 36;
        maxHorizon = 60;
        break;
      case "long":
        minHorizon = 60;
        maxHorizon = null;
        break;
    }
    const request: CreatePreferenceRequest = {
      assetClasses: this.investMentOpportunitiesFM.value,
      sectors: this.sectorFM.value,
      equityStages: this.equityStageFM.value,
      maxDebt: this.debtRangeFM.value.max,
      maxEquity: this.equityRangeFM.value.max,
      maxHorizon: maxHorizon,
      maxLoan: this.loanRangeFM.value.max,
      minDebt: this.debtRangeFM.value.min,
      minEquity: this.equityRangeFM.value.min,
      minHorizon: minHorizon,
      minLoan: this.loanRangeFM.value.min,
    };

    if (this.preferenceId) {
      const res = await updateInvestmentsPreferences.run({
        preferenceId: this.preferenceId,
        request,
      });

      if (isLeft(res)) {
        this.notifySubmitFailed({ response: res.left });
        return;
      }

      this.notifySubmitted({ canSubmitAgain: false });
    }

    if (!this.preferenceId) {
      const res = await createInvestmentsPreferences.run(request);

      if (isLeft(res)) {
        this.notifySubmitFailed({ response: res.left });
        return;
      }

      this.notifySubmitted({ canSubmitAgain: false });
    }
  }
}
