import { horizonData } from "../../components/config/data";

/**
 * transforms horizon min max intro a string value;
 *
 * @param min
 * @param max
 * @returns "short" | "medium" | "long"
 */

type GetHorizonStringReturnType = "short" | "medium" | "long" | "";

export const getHorizonValueString = (
  min: number,
  max: number | null
): GetHorizonStringReturnType => {
  const { shortMin, mediumMin, mediumMax, longMax } = horizonData;
  switch (true) {
    case min === shortMin && max === mediumMin:
      return "short";
    case min === mediumMin && max === mediumMax:
      return "medium";
    case min === mediumMax && max === longMax:
      return "long";
    default:
      return "";
  }
};
