import { ApiClient } from "@portittech/portit-react-common-components";

import {
  InvestmentsKycInfoRepository,
  InvestmentKycInfoResponse,
  CreateInvestmentKycInfoRequest,
} from "../../../domain";

export class InvestmentsKycInfoRepositoryImpl
  implements InvestmentsKycInfoRepository
{
  constructor(private readonly client: ApiClient) {}

  getInvestmentsKycInfo(): Promise<InvestmentKycInfoResponse> {
    return this.client.get<InvestmentKycInfoResponse>(
      `/investments/v1/infos/me`,
      "investments"
    );
  }

  createInvestmentsKycInfo(
    request: CreateInvestmentKycInfoRequest
  ): Promise<InvestmentKycInfoResponse> {
    return this.client.post<InvestmentKycInfoResponse>(
      `/investments/v1/infos/me`,
      "investments",
      request
    );
  }
}
