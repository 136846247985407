import { isLeft, isRight } from "fp-ts/Either";

import {
  BalanceDetailsEntity,
  BasePageModel,
  fetchBalancesWP,
  SelectFieldModel,
} from "@portittech/portit-react-common-components";
import { of } from "rxjs";
import { isNil } from "ramda";
import { getInvestmentsSum } from "../../../shared/usecases";

export class AccountWidgetModel extends BasePageModel {
  private _balances: BalanceDetailsEntity[];
  private _availableCurrencies = new SelectFieldModel<string>({
    items: [],
  });
  private _selectedCurrency: string;
  private readonly _selectedBalanceId: undefined | number;
  private _investmentsSum: number;

  constructor(selectedBalanceId?: number) {
    super();
    if (!isNil(selectedBalanceId)) {
      this._selectedBalanceId = selectedBalanceId;
    }
    this._availableCurrencies.listenValueChange({
      onData: async (prev, cur) => {
        this._selectedCurrency = cur;

        const res = await getInvestmentsSum.run({ currency: cur });

        if (isLeft(res)) {
          this._investmentsSum = 0;
        }

        if (isRight(res)) {
          this._investmentsSum = res.right;
        }

        this.notifyChanges();

        return of(undefined);
      },
    });
  }

  async onLoading() {
    const res = await fetchBalancesWP.run({
      strainer: { status: "enabled" },
    });

    if (isLeft(res)) {
      return this.notifyLoadFailed();
    }

    if (isRight(res)) {
      this._balances = res.right.values;
      this._availableCurrencies.updateItems(
        res.right.values.map((it) => it.currency)
      );
      if (!isNil(this._selectedBalanceId)) {
        const currency = res.right.values.find(
          (it) => it.balance_id === this._selectedBalanceId
        )?.currency;

        if (currency) this._availableCurrencies.updateInitialValue(currency);
      } else {
        this._availableCurrencies.updateInitialValue(
          res.right.values[0]?.currency
        );
      }

      this.notifyChanges();
      this.notifyLoaded();
    }
  }

  get account() {
    return this._balances?.find((it) => it.currency === this._selectedCurrency);
  }

  get currencies() {
    return this._availableCurrencies;
  }

  get balances() {
    return this._balances;
  }

  get investmentsSum() {
    return this._investmentsSum;
  }

  get selectedCurrency() {
    return this._selectedCurrency;
  }
}
