import { isNil } from "ramda";

/**
 *
 * @param value
 * @param currency
 * @returns currency and formatted comma separated string with two decimal places
 *
 * @example
 *
 * formatCurrencyNumber(2000) => "$2,000.00"
 * formatCurrencyNumber(2000, EUR) => "€2,000.00"
 *
 */

export const formatCurrencyNumber = (
  value: number | string,
  currency: string = "USD"
) => {
  // if value from api is null or undefined we show N/A
  if (isNil(value)) return "N/A";

  try {
    // Ensure we have a number value
    const numberValue = Number(value);

    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    });

    return currencyFormatter.format(numberValue);
  } catch (e) {
    console.error("Error occurred trying to format an amount: ", e.message);
    return "N/A";
  }
};
