import {
  ForgotPasswordPage,
  LoginPage,
  RegistrationPage,
  VerifyEmailPage,
  VerifyPhoneNumberPage,
  WelcomePage,
} from "@portittech/portit-react-common-components";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { AccountPage } from "../components/account-page/accountPage.component";
import { TransactionDetails } from "../components/account-page/components/transaction-details/transactionDetails.component";
import { TransactionReceipt } from "../components/account-page/components/transaction-details/transactionReceipt.component";
import { DashboardPage } from "../components/dashboard/dashboard.component";
import { DataRoomPage } from "../components/data-room-page/dataRoomPage.component";
import { ExchangePage } from "../components/exchange-page/exchangePage.component";
import { HelpPage } from "../components/help-page/helpPage.component";
import { InvestmentPage } from "../components/investment-page/investmentPage.component";
import { InvestmentsPage } from "../components/investments-page/investmentPage.component";
import { InvestmentsInfo } from "../components/investmentsInfo/InvestmentsInfo.component";
import { KycPage } from "../components/kyc-page/kycPage.component";
import { MessagingPage } from "../components/messaging/messagingPage.component";
import { OpportunitiesPage } from "../components/opportunities-page/opportunitiesPage.component";
import { OpportunityPage } from "../components/opportunity-page/opportunityPage.component";
import PageNotFound from "../components/page-not-found/PageNotFound.component";
import { PendingVerificationPage } from "../components/pending-verification/pendingVerification.component";
import { PortfolioPage } from "../components/portfolio-page/portfolioPage.component";
import { PreferencesPage } from "../components/preferences-page/preferencesPage.component";
import { PrivacyComponent } from "../components/privacy/privacy.component";
import { SettingsPage } from "../components/settings-page/settingsPage.component";
import { TermsAndConditionsPage } from "../components/terms-and-conditions/termsAndConditions.component";
import { WithdrawPage } from "../components/withdraw-page/withdraw.component";
import { ElviriaPages } from "../shared/pages";
import {
  FullWidthPublicPage,
  PublicPage,
} from "../shared/styles/publicPage.style";
import { EnsureKycSecuredRoute } from "./EnsureSecuredRoute";
import { KycSecuredRoute } from "./KycPageRouteGuard";
import { SecuredRoute } from "./SecuredRoute";

import elviriaAvatar from "../assets/elviria_robot_avatar.svg";
import userAvatar from "../assets/elviria_user_avatar.svg";
import backgroundImg from "../assets/confirmation_icon_background.svg";
import { AdminPage } from "../components/admin-page/adminPage.component";
import { BalancesPage } from "../components/balances-page/balancesPage.component";
import { NewCurrencyWallet } from "../components/add-new-currency/newCurrencyWallet.component";

export const Routes = () => {
  return (
    <Switch>
      <EnsureKycSecuredRoute path={ElviriaPages.DASHBOARD}>
        <AdminPage>
          <DashboardPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute path={ElviriaPages.PORTFOLIO}>
        <AdminPage>
          <PortfolioPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute exact path={ElviriaPages.OPPORTUNITIES}>
        <AdminPage>
          <OpportunitiesPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute
        path={`${ElviriaPages.ACCOUNT_PAYMENT}/:balanceId?/:beneficiaryId?`}
      >
        <AdminPage>
          <WithdrawPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute
        exact
        path={`${ElviriaPages.OPPORTUNITIES}/:pageId`}
      >
        <AdminPage>
          <OpportunityPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute
        exact
        path={`${ElviriaPages.OPPORTUNITIES}/:pageId/invest`}
      >
        <AdminPage>
          <InvestmentPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute exact path={`${ElviriaPages.DATA_ROOM}/:pageId`}>
        <AdminPage>
          <DataRoomPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute path={ElviriaPages.MESSAGING}>
        <AdminPage>
          <MessagingPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute exact path={ElviriaPages.INVESTMENTS}>
        <AdminPage>
          <InvestmentsPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute exact path={`${ElviriaPages.INVESTMENTS}/:pageId`}>
        <AdminPage>
          <InvestmentsPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute path={ElviriaPages.EXCHANGE}>
        <AdminPage>
          <ExchangePage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute exact path={ElviriaPages.ACCOUNT}>
        <AdminPage>
          <BalancesPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute exact path={`${ElviriaPages.ACCOUNT}/:accountId`}>
        <AdminPage>
          <AccountPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute exact path={ElviriaPages.ADD_NEW_CURRENCY}>
        <AdminPage>
          <NewCurrencyWallet />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute
        exact
        path={`${ElviriaPages.ACCOUNT}/:balanceId/transactions/:transactionId`}
      >
        <AdminPage>
          <TransactionDetails />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute
        exact
        path={`${ElviriaPages.ACCOUNT}/:balanceId/transactions/:transactionId/receipt`}
      >
        <AdminPage>
          <TransactionReceipt />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute path={ElviriaPages.SETTINGS}>
        <AdminPage>
          <SettingsPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute path={ElviriaPages.HELP}>
        <AdminPage>
          <HelpPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <KycSecuredRoute path={ElviriaPages.KYC}>
        <KycPage />
      </KycSecuredRoute>
      <SecuredRoute path={ElviriaPages.INVESTMENTS_KYC}>
        <InvestmentsInfo />
      </SecuredRoute>
      <EnsureKycSecuredRoute path={ElviriaPages.PREFERENCES}>
        <PreferencesPage />
      </EnsureKycSecuredRoute>
      <EnsureKycSecuredRoute path={ElviriaPages.PREFERENCES_DASHBOARD}>
        <AdminPage>
          <PreferencesPage withAdminPage />
        </AdminPage>
      </EnsureKycSecuredRoute>
      <Route exact path="/">
        <PublicPage>
          <WelcomePage />
        </PublicPage>
      </Route>
      <Route exact path={ElviriaPages.REGISTRATION}>
        <PublicPage>
          <RegistrationPage
            robotImg={elviriaAvatar}
            userImg={userAvatar}
            backgroundImg={backgroundImg}
          />
        </PublicPage>
      </Route>
      <Route exact path={ElviriaPages.VERIFY_EMAIL}>
        <PublicPage>
          <VerifyEmailPage />
        </PublicPage>
      </Route>
      <Route exact path={ElviriaPages.FORGOT_PASSWORD}>
        <PublicPage>
          <ForgotPasswordPage />
        </PublicPage>
      </Route>
      <Route exact path={ElviriaPages.VERIFY_PHONE_NUMBER}>
        <PublicPage>
          <VerifyPhoneNumberPage />
        </PublicPage>
      </Route>
      <Route exact path={ElviriaPages.LOGIN}>
        <PublicPage>
          <LoginPage />
        </PublicPage>
      </Route>

      <Route exact path={ElviriaPages.PRIVACY}>
        <FullWidthPublicPage>
          <PrivacyComponent />
        </FullWidthPublicPage>
      </Route>
      <Route exact path={ElviriaPages.TERMS_AND_CONDITIONS}>
        <FullWidthPublicPage>
          <TermsAndConditionsPage />
        </FullWidthPublicPage>
      </Route>

      <SecuredRoute exact path={ElviriaPages.PENDING_VERIFICATION}>
        <PendingVerificationPage />
      </SecuredRoute>
      <Route>
        <PublicPage>
          <PageNotFound />
        </PublicPage>
      </Route>
    </Switch>
  );
};
