import React from "react";

import {
  IterableModel,
  IterableModelBuilder,
} from "@portittech/portit-react-common-components";

import { InvestmentResponse } from "../../../../domain/features/investment/entities";
import { InvestmentCard } from "../investmentCard/investmentCard.component";
import { StyledInvestmentsList } from "./investmentsList.styles";
import moment from "moment";
import { groupBy } from "ramda";
import { Link } from "react-router-dom";
import { ElviriaPages } from "../../../../shared/pages";
import { StyledSmallText } from "../../../widgets/common.styles";
import { NoInvestments } from "./noInvestments.component";
interface InvestmentsBuilderProps {
  investmentsModel: IterableModel<InvestmentResponse>;
}

export const InvestmentsModelBuilder = ({
  investmentsModel,
}: InvestmentsBuilderProps): React.ReactElement => {
  return (
    <StyledInvestmentsList>
      <IterableModelBuilder
        iterableModel={investmentsModel}
        emptyBuilder={() => <NoInvestments />}
      >
        {(investments: Array<InvestmentResponse>) => {
          const dailyInvestments: {
            [p: string]: Array<InvestmentResponse>;
          } = groupBy((t: InvestmentResponse) => {
            const format = "DD/MM/YYYY";
            return moment(t.date).format(format);
          }, investments);

          return Object.keys(dailyInvestments).map((date) => {
            return (
              <div key={date}>
                <StyledSmallText>{date}</StyledSmallText>
                {investments
                  .filter(
                    (invest) =>
                      moment(invest.date).format("DD/MM/YYYY") === date
                  )
                  .map((invest) => (
                    <Link
                      to={`${ElviriaPages.INVESTMENTS}/${invest.id}`}
                      key={invest.id}
                    >
                      <InvestmentCard key={invest.id} {...invest} />
                    </Link>
                  ))}
              </div>
            );
          });
        }}
      </IterableModelBuilder>
    </StyledInvestmentsList>
  );
};
