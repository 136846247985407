import styled from "styled-components";

import {
  colorMain,
  colorMainDark,
  fontFamilyEncodeSansExpanded,
  fontFamilyMontserrat,
} from "../../shared/styles/generic";

export const StyledDescriptionHeader = styled.div`
  display: flex;
`;

interface HeaderCategoriesProps {
  isSmall?: boolean;
}

export const StyledHeaderCategories = styled.div<HeaderCategoriesProps>`
  display: flex;
  font-weight: ${({ isSmall }) => (isSmall ? 400 : 600)};
  font-size: ${({ isSmall }) => (isSmall ? 0.875 : 1)}rem;
  line-height: ${({ isSmall }) => (isSmall ? 1.4375 : 1.125)}rem;
  color: ${colorMainDark};
  margin-left: ${({ isSmall }) => (isSmall ? 0 : 1.375)}rem;
  margin-bottom: ${({ isSmall }) => (isSmall ? 0.9375 : 0)}rem;
  letter-spacing: ${({ isSmall }) => (isSmall ? 0 : 0.5)}rem;
  text-transform: uppercase;
`;

export const StyledHeaderTitle = styled.h3`
  font-family: ${fontFamilyEncodeSansExpanded};
  color: ${colorMain};
  font-size: 3.75rem;
  line-height: 4.375rem;
  font-weight: 400;
  margin-left: 1.375rem;
  margin-top: 1rem;
`;

export const DetailWrapper = styled.div`
  font-family: ${fontFamilyMontserrat};
  color: ${colorMainDark};

  margin-bottom: 3rem;
`;

interface DetailTitleProps {
  isMainTitle?: boolean;
}

export const StyledDetailTitle = styled.h4<DetailTitleProps>`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.4375rem;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
`;

export const StyledDetailDescription = styled.h4`
  font-size: 1rem;
  font-weight: normal;
  line-height: 2.5rem;
  text-align: justify;

  a {
    text-decoration: underline;
    cursor: pointer;

    :hover {
      color: ${colorMain};
    }
  }
`;

export const StyledInvestInButtonWrapper = styled.div`
  max-width: 17.9375rem;
  margin: 0 auto;
`;

export const StyledKeyFactsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  padding: 1rem 2rem;
  margin-bottom: 1rem;

  div {
    flex-basis: 33.33%;
    text-align: center;
  }
`;

export const StyledKeyFactsTitle = styled.p`
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.0625rem;
  color: ${colorMain};
  margin-bottom: 0;
  text-transform: uppercase;
`;

export const StyledKeyFactsDescription = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2.5rem;
  padding: 0 1.2em;
`;

export const StyledNewsSectionTitleWrapper = styled.div`
  margin-bottom: 2.625rem;
  margin-top: 3.125rem;
`;

export const StyledNewsCardWrapper = styled.div`
  display: flex;
  gap: 2.75rem;
  margin-bottom: 3.75rem;
`;
