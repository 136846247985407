import { Slider, InputNumber } from "antd";
import React from "react";

import {
  ValueComponentBuilder,
  FieldModelBuilder,
  SingleFieldModel,
} from "@portittech/portit-react-common-components";
import {
  StyledRangeRaw,
  StyledSumWrapper,
  StyledUsdLabel,
} from "./preferences.styles";
import { maxInputRangeSum, minInputRangeSum } from "../config/data";

export interface RangeFieldModelValue {
  min: number;
  max: number;
}

interface RangeFieldModelBuilderProps<V> {
  singleFieldModel: SingleFieldModel<RangeFieldModelValue>;
  children?: ValueComponentBuilder<V>;
  withSelectAll?: boolean;
  isDisabled?: boolean;
}

export const RangeFieldModelBuilder = <V extends unknown>({
  singleFieldModel,
  isDisabled,
}: RangeFieldModelBuilderProps<V>): React.ReactElement => {
  return (
    <FieldModelBuilder<SingleFieldModel<RangeFieldModelValue>>
      fieldModel={singleFieldModel}
    >
      {(model, { isSubmitting }) => {
        return (
          <StyledRangeRaw>
            <StyledSumWrapper>
              <StyledUsdLabel>Min USD</StyledUsdLabel>
              <InputNumber
                disabled={isDisabled}
                min={minInputRangeSum}
                max={maxInputRangeSum}
                controls={false}
                value={model.value.min}
                onChange={(value) =>
                  model.updateValue({
                    max: model.value?.max,
                    min: value,
                  })
                }
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </StyledSumWrapper>
            <Slider
              value={[model.value.min, model.value.max]}
              disabled={isSubmitting || isDisabled}
              max={maxInputRangeSum}
              min={minInputRangeSum}
              range
              defaultValue={[5, 10000]}
              tooltipVisible={false}
              onChange={(value) => {
                model.updateValue({ min: value[0], max: value[1] });
              }}
            />
            <StyledSumWrapper>
              <StyledUsdLabel>Max USD</StyledUsdLabel>
              <InputNumber
                disabled={isDisabled}
                min={0}
                max={maxInputRangeSum}
                controls={false}
                value={model.value.max}
                onChange={(value) =>
                  model.updateValue({
                    min: model.value.min,
                    max: value,
                  })
                }
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </StyledSumWrapper>
          </StyledRangeRaw>
        );
      }}
    </FieldModelBuilder>
  );
};
