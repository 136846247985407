import styled from "styled-components";
import { devices } from "../../components/config/devices";

import {
  colorMain,
  colorMainAccentBlack,
  colorMainDark,
  fontFamilyEncodeSansExpanded,
  fontFamilyMontserrat,
} from "./generic";

export const StyledPageTitle = styled.h2`
  font-family: ${fontFamilyEncodeSansExpanded};
  font-style: normal;
  font-weight: 600;
  font-size: 3.75rem;
  line-height: 4.375rem;
  color: ${colorMain};

  @media ${devices.tablet} {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`;

export const StyledTitleText = styled.p`
  font-family: ${fontFamilyMontserrat};
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 2.1875rem;
  color: ${colorMainDark};
  max-width: 37.5rem;
  margin: 0 auto;
  text-align: center;

  @media ${devices.tablet} {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
`;

export const StyledSubtitle = styled.h4`
  font-family: ${fontFamilyMontserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: ${colorMainAccentBlack};
`;

export const StyledAdminInnerPageTitle = styled.h1`
  font-family: ${fontFamilyEncodeSansExpanded};
  font-weight: 400;
  font-size: 2rem;
  line-height: 110%;
  color: ${colorMain};
  margin-bottom: 3.4rem;
  grid-column: 1/-1;
  text-align: left;
`;

export const StyledAdminInnerPageWrapper = styled.div`
  padding: 0 4vw 1.875rem;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2.375rem;
`;

export const StyledLoaderWrapper = styled.div`
  padding-top: 3.5rem;
`;
