import { useCallback, useContext, useEffect } from "react";

import { Context } from "@portittech/portit-react-common-components";

export const useBackOffice = () => {
  const { customerCubit } = useContext(Context);
  // eslint-disable-next-line no-restricted-globals
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const profileId = params?.profileId?.match(/\d/g).join(""); // ensure to get only the number from url

  const isBackOfficeUser = !!profileId;

  const readCubit = useCallback(async () => {
    if (isBackOfficeUser) {
      await customerCubit?.read();
      customerCubit?.changeProfile(Number(profileId));
    }
  }, [customerCubit, profileId, isBackOfficeUser]);

  useEffect(() => {
    readCubit();
  }, [readCubit]);

  const saveProfileIdToLocalStorage = (profileId: string) => {
    localStorage.setItem("backOfficeProfileId", profileId);
  };

  if (isBackOfficeUser) {
    // persist backOffice profileId
    saveProfileIdToLocalStorage(profileId);

    const profileIdFromBackOffice = localStorage.getItem("backOfficeProfileId");

    if (profileIdFromBackOffice) {
      // remove profile Id from url
    }
  }

  const removeBackOfficeIdFromLocalStorage = () => {
    localStorage.removeItem("backOfficeProfileId");
  };

  return {
    isBackOfficeUser,
    backOfficeUserProfileId: Number(profileId),
    removeBackOfficeIdFromLocalStorage,
    saveProfileIdToLocalStorage,
  };
};
