import {
  SearchParamsUtils,
  LoginPageSearchParams,
} from "@portittech/portit-react-common-components";
import { History } from "history";

export enum ElviriaPages {
  ACCOUNT = "/account",
  ACCOUNT_PAYMENT = "/account-payment",
  DASHBOARD = "/dashboard",
  EXCHANGE = "/exchange",
  HELP = "/help",
  FORGOT_PASSWORD = "/forgot-password",
  LOGIN = "/login",
  MESSAGING = "/messaging",
  OPPORTUNITIES = "/opportunities",
  PENDING_VERIFICATION = "/pending-verification",
  PORTFOLIO = "/portfolio",
  PREFERENCES = "/preferences",
  PREFERENCES_DASHBOARD = "/user-preferences",
  PRIVACY = "/privacy",
  REGISTRATION = "/registration",
  SETTINGS = "/settings",
  VERIFY_EMAIL = "/verify-email",
  VERIFY_PHONE_NUMBER = "/verify-phone-number",
  TERMS_AND_CONDITIONS = "/terms-and-conditions",
  INVESTMENTS = "/investments",
  KYC = "/kyc",
  NEWS = "/news",
  DATA_ROOM = "/data-room",
  INVESTMENTS_KYC = "/investments-kyc",
  ADD_NEW_CURRENCY = "/add-currency",
}

export class ElviriaRoutes {
  static login(
    searchParams: LoginPageSearchParams = {}
  ): History.LocationDescriptor {
    return {
      pathname: "/login",
      search: SearchParamsUtils.stringify(searchParams),
    };
  }
}
