import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import { Context, getSubT } from "@portittech/portit-react-common-components";

import logo from "../../../assets/elviria_logo_white.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/logout.svg";

import { NavigationItem, NavigationModel } from "./navigationModel";

import {
  StyledInnerWrapper,
  StyledNavigationContent,
  StyledNavWrapper,
  StyledLogoImg,
  StyledNavItem,
  StyledInnerNavWrapper,
  StyledAccountSubtitle,
  StyledSecondaryLink,
} from "./navigation.styles";
import { ElviriaPages } from "../../../shared/pages";

export interface NavigationModelBuilderProps {
  navigationModel: NavigationModel;
}
export const NavigationModelBuilder = ({
  navigationModel,
}: NavigationModelBuilderProps): React.ReactElement => {
  const { dispatchLogout } = useContext(Context);

  const { t: pt } = useTranslation(["adminPage"]);
  const t = getSubT(pt, "navigation_items");

  const history = useHistory();

  const cancelListeningRef = useRef<undefined | VoidFunction>();

  useEffect(() => {
    if (cancelListeningRef.current) cancelListeningRef.current();
    cancelListeningRef.current = history.listen((location) => {
      const selectedItem: NavigationItem = navigationModel.items.find(
        (it) => it.link === location.pathname
      );
      if (!selectedItem) return;
      navigationModel.setCurrentItem(selectedItem);
    });
    return () => cancelListeningRef.current();
  }, [navigationModel, history, cancelListeningRef]);

  return (
    <StyledNavWrapper>
      <StyledInnerNavWrapper>
        <Link to={ElviriaPages.DASHBOARD}>
          <StyledLogoImg src={logo as string} alt="Elviria logo" />
        </Link>
        <StyledNavigationContent>
          <StyledInnerWrapper>
            {navigationModel.items
              .filter((item) => item.type === "main")
              .map((item, index) => {
                return (
                  <Link key={index} to={item.link}>
                    <StyledNavItem data-current={item.status === "selected"}>
                      {item.icon}
                      {t(`${item.description}_label`)}
                    </StyledNavItem>
                  </Link>
                );
              })}
            <StyledAccountSubtitle>Account</StyledAccountSubtitle>
            {navigationModel.items
              .filter((item) => item.type === "secondary")
              .map((item, index) => {
                return (
                  <StyledSecondaryLink key={index} to={item.link}>
                    <StyledNavItem
                      secondary
                      data-current={item.status === "selected"}
                    >
                      {item.icon}
                      {t(`${item.description}_label`)}
                    </StyledNavItem>
                  </StyledSecondaryLink>
                );
              })}
            <StyledNavItem secondary onClick={dispatchLogout}>
              <LogoutIcon />
              {t("logout_label")}
            </StyledNavItem>
          </StyledInnerWrapper>
        </StyledNavigationContent>
      </StyledInnerNavWrapper>
    </StyledNavWrapper>
  );
};
