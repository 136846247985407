import { getSubT } from "@portittech/portit-react-common-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledSmallText } from "../../../widgets/common.styles";

export const NoTransactions = () => {
  const { t } = useTranslation(["accountPage"]);
  const tc = getSubT(t, "transactions_widget");
  return (
    <StyledSmallText textAlign="center">
      {tc("no_transactions")}
    </StyledSmallText>
  );
};
