import React from "react";

import { useTranslation } from "react-i18next";
import { getSubT } from "@portittech/portit-react-common-components";

import { SquareButton } from "../../../../shared/styles/button";
import {
  StyledOpportunityValueWidgetStartAndEndDateTitle,
  StyledOpportunityValueWidgetStartAndEndDateWrapper,
  StyledOpportunityValueWidgetValuationTitle,
  StyledOpportunityValueWidgetValuationValue,
  StyledOpportunityValueWidgetValuationWrapper,
  StyledOpportunityValueWidgetWrapper,
} from "./opportunityValueWidget.style";

import { OpportunityResponse } from "../../../../domain/features/opportunities/entities";
import { formatCurrencyNumber } from "../../../../shared/helpers/formatCurrencyNumber";
import { AddToWatchListButton } from "../../../widgets/interested-investment-widget/addToWatchListButton";
import moment from "moment";

interface OpportunityValueWidgetProps extends OpportunityResponse {
  investClickHandler: VoidFunction;
}

export const OpportunityValueWidget = ({
  id,
  tokenPrice,
  startDate,
  endDate,
  status,
  investClickHandler,
  currency
}: OpportunityValueWidgetProps) => {
  const { t } = useTranslation(["opportunityPage"]);
  const tc = getSubT(t, "opportunity_value_widget");

  return (
    <StyledOpportunityValueWidgetWrapper>
      <StyledOpportunityValueWidgetValuationWrapper>
        <StyledOpportunityValueWidgetValuationTitle>
          {tc("token_price_label")}
        </StyledOpportunityValueWidgetValuationTitle>
        <StyledOpportunityValueWidgetValuationValue>
          {formatCurrencyNumber(tokenPrice, currency)}
        </StyledOpportunityValueWidgetValuationValue>
      </StyledOpportunityValueWidgetValuationWrapper>

      {/* Show start date and end date if both are present */}
      {startDate && endDate ? (
        <StyledOpportunityValueWidgetStartAndEndDateWrapper>
          <div>
            <StyledOpportunityValueWidgetStartAndEndDateTitle>
              {tc("start_label")}
            </StyledOpportunityValueWidgetStartAndEndDateTitle>
            <StyledOpportunityValueWidgetValuationValue>
              {moment(startDate).format("DD MMM YYYY")}
            </StyledOpportunityValueWidgetValuationValue>
          </div>
          <div>
            <StyledOpportunityValueWidgetStartAndEndDateTitle>
              {tc("end_label")}
            </StyledOpportunityValueWidgetStartAndEndDateTitle>
            <StyledOpportunityValueWidgetValuationValue>
              {moment(endDate).format("DD MMM YYYY")}
            </StyledOpportunityValueWidgetValuationValue>
          </div>
        </StyledOpportunityValueWidgetStartAndEndDateWrapper>
      ) : null}

      <SquareButton disabled={status !== "Active"} onClick={investClickHandler}>
        {tc("invest_link")}
      </SquareButton>

      <AddToWatchListButton opportunityId={id} />
    </StyledOpportunityValueWidgetWrapper>
  );
};
