import { isEmpty } from 'ramda';
import { useEffect, useState } from 'react'

import { InvestmentResponse } from '../../domain/features/investment/entities';
import { useRequest } from '../../shared/hooks/useRequest';
import { getInvestments } from '../../shared/usecases';


export const useInvestments = () => {
  const [investments, setInvestments] = useState<
    Array<InvestmentResponse>
       >([]);
    
  const withPortfolio = investments && !isEmpty(investments);

  const { request: getInvestmentsRequest, isFetching: areInvestmentsFetching } =
    useRequest(getInvestments);

   useEffect(() => {
     getInvestmentsRequest().then(setInvestments);
    }, [getInvestmentsRequest]);
    
    
    return {
        investments,
        areInvestmentsFetching,
        withPortfolio
    }
}

