import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledActionButtonWrapper,
  StyledAmountDescription,
  StyledAmountTitle,
  StyledBalanceCardRowWrapper,
  StyledBalanceCardWrapper,
  StyledCurrencyIconWrapper,
} from "./balanceCard.style";
import {
  BalanceDetailsEntity,
  FlagIcon,
  getSubT,
} from "@portittech/portit-react-common-components";
import { formatCurrencyNumber } from "../../../shared/helpers/formatCurrencyNumber";
import { StyledRouterLink } from "../../widgets/common.styles";
import { ElviriaPages } from "../../../shared/pages";
import { SquareButton } from "../../../shared/styles/button";
import { useHistory } from "react-router-dom";
import { useDeposit } from "../../deposit-modal/useDeposit";

interface BalanceCardProps {
  balanceDetails: BalanceDetailsEntity;
}

export const BalanceCard = ({ balanceDetails }: BalanceCardProps) => {
  const { t: pt } = useTranslation("balancesPage");
  const t = getSubT(pt, "balance_card_widget");
  const history = useHistory();

  const [showFundingInfo] = useDeposit(balanceDetails.funding_info);

  return (
    <StyledBalanceCardWrapper>
      <div>
        <StyledBalanceCardRowWrapper>
          <StyledAmountTitle>
            {formatCurrencyNumber(
              balanceDetails.available_amount,
              balanceDetails.currency
            )}
          </StyledAmountTitle>
          <StyledCurrencyIconWrapper>
            {balanceDetails.currency}
            <FlagIcon currency={balanceDetails.currency} flagSize={22} />
          </StyledCurrencyIconWrapper>
        </StyledBalanceCardRowWrapper>
        <StyledAmountDescription>
          {t("available_balance")}
        </StyledAmountDescription>
      </div>

      <div>
        <StyledBalanceCardRowWrapper alignItems="flex-end">
          <StyledRouterLink
            to={`${ElviriaPages.ACCOUNT}/${balanceDetails.balance_id}`}
          >
            {t("view_button")}
          </StyledRouterLink>
          <StyledActionButtonWrapper>
            <SquareButton
              onClick={showFundingInfo}
              style={{ width: "8.75rem" }}
            >
              {t("deposit_button")}
            </SquareButton>
            <SquareButton
              onClick={() => history.push(ElviriaPages.ACCOUNT_PAYMENT)}
              style={{ width: "8.75rem" }}
            >
              {t("withdraw_button")}
            </SquareButton>
          </StyledActionButtonWrapper>
        </StyledBalanceCardRowWrapper>
      </div>
    </StyledBalanceCardWrapper>
  );
};
