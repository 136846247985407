import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  FieldDecoration,
  LoadingComponent,
  PaginationModelBuilder,
  SelectFieldModelBuilder,
  TextFieldModelBuilder,
  useModelChanges,
  useModelCreatorEffect,
} from "@portittech/portit-react-common-components";

import { ReactComponent as ListIcon } from "../../assets/icons/list-view.svg";
import { ReactComponent as GridIcon } from "../../assets/icons/grid-view.svg";

import { ViewType } from "./types";
import { StyledAdminInnerPageTitle } from "../../shared/styles/page.styles";
import { StyledSearchFieldWrapper } from "../widgets/common.styles";

import {
  StyledOpportunitiesPageWrapper,
  StyledSearchRow,
  StyledSortInputWrapper,
  StyledIconsWrapper,
} from "./opportunitiesPage.styles";
import { OpportunitiesPageModel } from "./models/opportunitiesPageModel";
import { OpportunitiesModelBuilder } from "./opportunititiesModelBuilder";

export const OpportunitiesPage = () => {
  const { t } = useTranslation(["opportunitiesPage"]);
  const [view, setView] = useState<ViewType>(ViewType.list);

  const model = useModelCreatorEffect({
    creator: () => {
      return new OpportunitiesPageModel();
    },
  });

  useModelChanges(model);

  if (!model) return <LoadingComponent />;

  return (
    <StyledOpportunitiesPageWrapper>
      <StyledAdminInnerPageTitle>{t("page_title")}</StyledAdminInnerPageTitle>
      <StyledSearchRow>
        <StyledSearchFieldWrapper>
          <TextFieldModelBuilder
            textFieldModel={model.searcherFormModel.textFM}
            decoration={FieldDecoration.search({
              label: t("search_label"),
            })}
          />
        </StyledSearchFieldWrapper>
        <StyledSortInputWrapper>
          <SelectFieldModelBuilder
            selectFieldModel={model.searcherFormModel.sortByFM}
            decoration={FieldDecoration.custom({
              label: t("sort_by_label"),
            })}
          >
            {(item) => item}
          </SelectFieldModelBuilder>
        </StyledSortInputWrapper>
        <StyledIconsWrapper>
          <ListIcon
            data-current={view === ViewType.list}
            onClick={() => setView(ViewType.list)}
          />
          <GridIcon
            data-current={view === ViewType.grid}
            onClick={() => setView(ViewType.grid)}
          />
        </StyledIconsWrapper>
      </StyledSearchRow>

      <OpportunitiesModelBuilder
        viewType={view}
        opportunitiesModel={model.opportunitiesModel}
      />

      <PaginationModelBuilder
        multiModel={model.opportunitiesModel}
        pageSize={model.opportunitiesPageSize}
      />
    </StyledOpportunitiesPageWrapper>
  );
};
