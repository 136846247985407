import { Tooltip } from "antd";
import styled from "styled-components";

import { fontFamilyMontserrat } from "../../../../shared/styles/generic";
import { investmentsTabStyles } from "../portfolio-charts/portfolioCharts.styles";

export const StyledInvestmentsTabsWrap = styled.div`
  font-family: ${fontFamilyMontserrat};
  grid-column: 1/-1;
  position: relative;

  ${investmentsTabStyles}
`;

export const StyledToolTip = styled(Tooltip)`
  position: absolute;
  right: 0;

  &&.ant-btn-circle {
    border: 0;
  }
`;
