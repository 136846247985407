import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  SubmitButtonFormModelBuilder,
  FormModel,
  getSubT,
  useModelChanges,
} from "@portittech/portit-react-common-components";

import { StyledExternalLink } from "../widgets/common.styles";
import { InvestSteps } from "./config";
import { BottomBarCancelButtonWrapper } from "./investment.styles";

interface BottomBarProps {
  formModel: FormModel<any, any>;
}

export const BottomBar = ({
  formModel,
}: BottomBarProps): React.ReactElement => {
  useModelChanges(formModel);
  const { t: pt } = useTranslation(["payments"]);
  const t = getSubT(pt, "components.bottomBar");

  const history = useHistory();

  return (
    <>
      <SubmitButtonFormModelBuilder
        formModel={formModel}
        style={{
          display: "block",
          margin: "1.25rem auto",
          borderRadius: 0,
          marginInline: 0,
          minWidth: "17rem",
        }}
      >
        {formModel?.currentStep === InvestSteps.review
          ? t("invest_now_action")
          : t("review_order_action")}
      </SubmitButtonFormModelBuilder>

      <BottomBarCancelButtonWrapper>
        <StyledExternalLink
          type="link"
          onClick={() => {
            if (formModel?.isFirstStep) {
              history.goBack();
            } else {
              formModel?.previousStep();
            }
          }}
        >
          {t("cancel_action")}
        </StyledExternalLink>
      </BottomBarCancelButtonWrapper>
    </>
  );
};
