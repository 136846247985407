import React from "react";
import { useTranslation } from "react-i18next";

import { getSubT } from "@portittech/portit-react-common-components";

import {
  StyledOpportunitiesCardWrapper,
  StyledOpportunityColumnText,
  StyledOpportunityFlexOneWrapper,
} from "../common.styles";

import { StyledRouterLink } from "../common.styles";

import { ElviriaPages } from "../../../shared/pages";
import { OpportunityResponse } from "../../../domain/features/opportunities/entities";
import { getHorizonString } from "../../../shared/helpers/getHorizonString";
import { formatCurrencyNumber } from "../../../shared/helpers/formatCurrencyNumber";
import { Logo } from "../../logo/logo.component";

export const OpportunitiesWidgetCard = ({
  id,
  name,
  assetClass,
  minRequested,
  maxRequested,
  horizon,
  sector,
  stage,
  logoImageId,
  currency
}: OpportunityResponse) => {
  const { t } = useTranslation(["dashboard"]);
  const { t: tApi } = useTranslation(["apiText"]);
  const tc = getSubT(t, "investment_opportunities_widget");

  return (
    <StyledOpportunitiesCardWrapper>
      <StyledOpportunityFlexOneWrapper>
        <Logo logoId={logoImageId} />
      </StyledOpportunityFlexOneWrapper>
      <StyledOpportunityColumnText>{name}</StyledOpportunityColumnText>
      <StyledOpportunityColumnText>
        {tApi(assetClass)}
      </StyledOpportunityColumnText>
      <StyledOpportunityColumnText>{tApi(sector)}</StyledOpportunityColumnText>
      <StyledOpportunityColumnText>
        {formatCurrencyNumber(minRequested, currency)}
      </StyledOpportunityColumnText>
      <StyledOpportunityColumnText>
        {formatCurrencyNumber(maxRequested, currency)}
      </StyledOpportunityColumnText>
      <StyledOpportunityColumnText>
        {getHorizonString(horizon)}
      </StyledOpportunityColumnText>
      <StyledOpportunityColumnText>
        {stage ? tApi(stage) : "N/A"}
      </StyledOpportunityColumnText>
      <StyledOpportunityFlexOneWrapper>
        <StyledRouterLink to={`${ElviriaPages.OPPORTUNITIES}/${id}`}>
          {tc("view_link")}
        </StyledRouterLink>
      </StyledOpportunityFlexOneWrapper>
    </StyledOpportunitiesCardWrapper>
  );
};
