import React from "react";
import { useTranslation } from "react-i18next";

import {
  CheckBoxFieldModelBuilder,
  FieldDecoration,
  FieldValidatorNumberError,
  NumberFieldModelBuilder,
  SelectFieldModelBuilder,
  useModelChanges,
  ValueFilter,
} from "@portittech/portit-react-common-components";

import { IncrementFieldModelBuilder } from "../increment-model/IncrementFieldModelBuilder";
import { PaymentsFormModel } from "../investmentPage.model";
import { InvestmentOpportunityCard } from "../investmetOpportunityCard";
import { InvestStepModelBuilder } from "./InvestStepModelBuilder";

import { StyledInvestStepWrapper } from "../investment.styles";
import {
  StyledInvestmentDetailFieldLabel,
  StyledSmallRowWrapper,
  StyledBigRowWrapper,
  StyledInvestmentOptionsCheckBoxWrapper,
  StyledInvestmentOptionsCheckBoxDescription,
} from "./investStep.style";
import {
  StyledExternalLink,
  StyledSmallWidgetTitle,
} from "../../widgets/common.styles";
import { CheckBoxSelectFieldModelBuilder } from "../../preferences/CheckBoxSelectFieldMolderBuilder";
import { StyledCheckBoxLabel } from "../../preferences/preferences.styles";
import { InvestmentOptions } from "../config";
import { formatCurrencyNumber } from "../../../shared/helpers/formatCurrencyNumber";

interface InvestStepProps {
  investmentModel: PaymentsFormModel;
}

export const InvestStep = ({ investmentModel }: InvestStepProps) => {
  const { t } = useTranslation(["investment"]);

  const investmentOptionsType = useModelChanges({
    model: investmentModel?.investmentOptionsFM,
    lens: (m) => m.value,
  });

  const sourceFM = useModelChanges({
    model: investmentModel?.sourceFM,
    lens: (m) => m.value,
  });

  useModelChanges(investmentModel);

  return (
    <StyledInvestStepWrapper>
      <StyledSmallWidgetTitle>{t("invest_now")}</StyledSmallWidgetTitle>
      <InvestmentOpportunityCard {...investmentModel.currentOpportunity} />

      {/* source currency dropdown */}

      <InvestStepModelBuilder sourceFieldModel={investmentModel.sourceFM} />
      {sourceFM && ( // To render rest of the form once the sourceFM (wallet) is selected
        <StyledInvestmentOptionsCheckBoxWrapper>
          <CheckBoxSelectFieldModelBuilder
            selectFieldModel={investmentModel.investmentOptionsFM}
            isInvestmentOptions
            itemDisabler={ValueFilter.check(
              (value) => value === InvestmentOptions.units // Todo remove this disabler when units is handled by BE
            )}
          >
            {(item) => {
              return (
                <>
                  <StyledCheckBoxLabel> {t(`${item}`)} </StyledCheckBoxLabel>
                  <StyledInvestmentOptionsCheckBoxDescription>
                    {t(`${item}_description`)}
                  </StyledInvestmentOptionsCheckBoxDescription>
                </>
              );
            }}
          </CheckBoxSelectFieldModelBuilder>
        </StyledInvestmentOptionsCheckBoxWrapper>
      )}

      {investmentOptionsType && ( // To render rest of the form once the investmentOptionsType (tokens | units) is selected
        <>
          {/* No. of Tokens */}
          <StyledSmallRowWrapper>
            <StyledInvestmentDetailFieldLabel>
              {t(`no_of_${investmentOptionsType}`)}
            </StyledInvestmentDetailFieldLabel>
            <IncrementFieldModelBuilder
              incrementFieldModel={investmentModel.noOfTokensFM}
            />
          </StyledSmallRowWrapper>

          {/* Total Value */}
          <StyledSmallRowWrapper>
            <StyledInvestmentDetailFieldLabel>
              {t(`total_${investmentOptionsType}_value`)}
            </StyledInvestmentDetailFieldLabel>
            <NumberFieldModelBuilder
              numberFieldModel={investmentModel.totalValueFM}
              isDisabled={true}
              decoration={FieldDecoration.custom({
                prefix: "$",
              })}
              errorBuilder={(error) => {
                // check if total investment amount is less than minimum required investment amount or is it more than the account balance and show error respectively
                if (error instanceof FieldValidatorNumberError) {
                  if (error.hasMin && error.hasMax) {
                    if (investmentModel.totalValueFM.value > error.max) {
                      return t("no_enough_money");
                    } else if (investmentModel.totalValueFM.value < error.min) {
                      return t("min_investment_error", {
                        replace: {
                          minRequested: formatCurrencyNumber(
                            error.min,
                            investmentModel.currentOpportunity.currency
                          ),
                        },
                      });
                    }
                  }
                }
              }}
            />
          </StyledSmallRowWrapper>

          {/* Asset Store selection dropdown based on selected investment option*/}
          <StyledBigRowWrapper>
            {investmentOptionsType === InvestmentOptions.tokens ? (
              <SelectFieldModelBuilder
                selectFieldModel={investmentModel.tokensAssetStoreFM}
                itemDisabler={ValueFilter.check(
                  (value) => value === "External Wallet - Coming soon"
                )}
                decoration={FieldDecoration.custom({
                  label: t("asset_store_label"),
                })}
              >
                {(item) => item}
              </SelectFieldModelBuilder>
            ) : (
              <SelectFieldModelBuilder
                selectFieldModel={investmentModel.unitsAssetStoreFM}
                decoration={FieldDecoration.custom({
                  label: t("asset_store_label"),
                })}
              >
                {(item) => item}
              </SelectFieldModelBuilder>
            )}
          </StyledBigRowWrapper>

          <StyledSmallRowWrapper>
            <CheckBoxFieldModelBuilder
              booleanFieldModel={investmentModel.termsAndConditionsFM}
            >
              {t("agree_terms")}
              <StyledExternalLink
                target="_blank"
                href={process.env.REACT_APP_QUID_TERMS_AND_CONDITION_LINK}
              >
                {t("terms_and_conditions_link")}
              </StyledExternalLink>
            </CheckBoxFieldModelBuilder>
          </StyledSmallRowWrapper>
        </>
      )}
    </StyledInvestStepWrapper>
  );
};
