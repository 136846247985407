import {
  AuthState,
  useAppStore,
} from "@portittech/portit-react-common-components";
import { useState, useEffect, useCallback } from "react";
import { useRequest } from "../../shared/hooks/useRequest";

import { getOpportunitiesData } from "../../shared/usecases";

export const useOpportunities = () => {
  const [state, setState] = useState({
    opportunities: [],
    viewed: 0,
    newOpportunities: 0,
    totalOpportunities: 0,
  });

  const { authState } = useAppStore();

  const { request: requestOpportunities, isFetching } =
    useRequest(getOpportunitiesData);

  const fetchOpportunities = useCallback(
    () =>
      requestOpportunities().then((res) =>
        setState({
          opportunities: res.opportunityList,
          viewed: res.viewed,
          newOpportunities: res.newOpportunities,
          totalOpportunities: res.pagination.total_entries,
        })
      ),
    [requestOpportunities]
  );

  useEffect(() => {
    if (authState === AuthState.LOGGED_IN) {
      fetchOpportunities();
    }
  }, [fetchOpportunities, authState]);

  return {
    fetchOpportunities,
    totalOpportunities: state.totalOpportunities,
    opportunitiesList: state.opportunities,
    isLoading: isFetching,
    opportunitiesViewed: state.viewed,
    newOpportunities: state.newOpportunities,
  };
};
