import styled from "styled-components";
import { fontFamilyMontserrat } from "../../shared/styles/generic";

export const StyledBalancesPageWrapper = styled.div`
  padding: 0 4vw 1.875rem;
`;

export const StyledBalancesColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
  font-family: ${fontFamilyMontserrat};
`;

export const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2.375rem;
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledAddCurrencyWrapper = styled.div`
  width: 250px;
`;
