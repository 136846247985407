import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledAdminInnerPageWrapper,
  StyledAdminInnerPageTitle,
  StyledGrid,
} from "../../shared/styles/page.styles";
import { StyledRow } from "../preferences/preferences.styles";
import { GuideWidget } from "../widgets/guideWidget";
import { HelpWidget } from "../widgets/helpWidget";
import { MyPortfolioWidget } from "../widgets/my-portfolio/myPortfolio.component";
import { MyPreferencesWidget } from "../widgets/myPreferencesWidget";
import { OpportunitiesExplorerWidget } from "../widgets/opportunities-explorer/opportunitiesExplorer.widget";
import { ReactComponent as WorkIcon } from "../../assets/icons/work.svg";
import {
  StyledBanner,
  StyledMessage,
  StyledTitle,
  WidgetsWrapper,
} from "./exchangePage.style";
import { StyledRouterLink } from "../widgets/common.styles";
import { ElviriaPages } from "../../shared/pages";

export const ExchangePage = () => {
  const { t } = useTranslation(["exchange"]);

  return (
    <StyledAdminInnerPageWrapper>
      <StyledAdminInnerPageTitle>{t("page_title")}</StyledAdminInnerPageTitle>
      <StyledGrid>
        <div>
          <StyledBanner>
            <StyledRow>
              <StyledTitle>{t("coming_soon")}</StyledTitle>
              <WorkIcon />
            </StyledRow>
            <StyledMessage>{t("message")}</StyledMessage>
          </StyledBanner>
          <StyledRouterLink to={ElviriaPages.DASHBOARD}>
            {t("back")}
          </StyledRouterLink>
        </div>
        <WidgetsWrapper>
          <OpportunitiesExplorerWidget />
          <MyPortfolioWidget />
          <HelpWidget />
          <GuideWidget digitalGoldTitle />
          <MyPreferencesWidget />
        </WidgetsWrapper>
      </StyledGrid>
    </StyledAdminInnerPageWrapper>
  );
};
