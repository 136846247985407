import styled from "styled-components";
import {
  StyledDataRow,
  StyledDataWrapper,
} from "../opportunities-explorer/opportunitiesExplorer.styles";

export const StyledTradeWidgetDataRow = styled(StyledDataRow)`
  justify-content: space-around;
`;

export const StyledTradeWidgetDataWrapper = styled(StyledDataWrapper)`
  text-align: center;
  cursor: pointer;
`;
