import { getSubT } from "@portittech/portit-react-common-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { OpportunityResponse } from "../../../domain/features/opportunities/entities";
import { StyledSmallWidgetTitle } from "../../widgets/common.styles";
import { InvestmentOpportunitySingleCard } from "./investmentOpportunitySingleCard.component";
import { StyledOpportunitiesCardViewInnerWrapper } from "./opportunitiesCardView.styles";

interface OpportunitiesCardViewProps {
  assetClass?: string;
  opportunities: Array<OpportunityResponse>;
}

export const OpportunitiesCardView = ({
  assetClass,
  opportunities,
}: OpportunitiesCardViewProps) => {
  const { t } = useTranslation(["opportunitiesPage"]);
  const { t: tApi } = useTranslation(["apiText"]);
  const tc = getSubT(t, "opportunities_widget");

  const opportunitiesList = opportunities.filter(
    (opp) => opp.assetClass === assetClass
  );

  return (
    <div>
      <StyledSmallWidgetTitle>
        {tc("asset_class_title")} : <span>{tApi(assetClass)}</span>
      </StyledSmallWidgetTitle>

      <StyledOpportunitiesCardViewInnerWrapper>
        {opportunitiesList.map((investmentOpportunity) => (
          <InvestmentOpportunitySingleCard
            key={investmentOpportunity.name}
            {...investmentOpportunity}
          />
        ))}
      </StyledOpportunitiesCardViewInnerWrapper>
    </div>
  );
};
