import styled from "styled-components";
import {
  colorMainDark,
  fontFamilyMontserrat,
} from "../../../../shared/styles/generic";
import { cubicBezier } from "../../../../shared/styles/publicPage.style";

export const BankAccountTransactionsStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
  margin-top: 2rem;
  .balances {
    display: flex;
    margin-bottom: 3em;
  }
`;

export const SingleDailyTransactionsStyle = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 0 1.25rem 0 0;

  p {
    margin-bottom: 0.75em;
  }
`;

export const SingleTransactionStyle = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 33px 73px rgba(32, 32, 35, 0.06);
  border-radius: 5px;
  margin-bottom: 12px;
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ${cubicBezier},
    box-shadow 0.3s ${cubicBezier}, transform 0.3s ${cubicBezier};

  &:hover {
    transform: scale(1.025, 1.025);
  }

  p {
    margin-bottom: 0;
    line-height: normal;
  }
  .icon {
    --dimension: 44px;
    height: var(--dimension);
    width: var(--dimension);
    /* border-radius: var(--dimension); */
    background-color: #ccc;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      content: attr(data-name);
      position: absolute;
      font-size: 18px;
      color: white;
    }
  }

  .description {
    flex: 1;
  }

  .amounts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .amount {
    font-size: 1.125rem;
    font-weight: 400;
    font-family: ${fontFamilyMontserrat};

    &.debit {
      color: ${colorMainDark};
    }

    &.credit {
      color: ${colorMainDark};
    }
  }

  .ant-collapse {
    width: 100%;
    border: none;
    background: transparent;

    .ant-collapse-item {
      width: 100%;
      border: none;

      .ant-collapse-header {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 1.2rem;

        .ant-collapse-arrow {
          display: none;
        }
      }
    }

    .ant-collapse-content {
      border-top: 3px solid #f6f6f6;
      padding: 1rem 4rem;

      img:first-of-type {
        margin-right: 1.75rem;
      }
    }
  }

  .transaction-name {
    font-size: 1.125rem;
    font-family: ${fontFamilyMontserrat};
  }

  &:hover .transaction-name {
    font-weight: 600;
  }
`;

export const StyledTransactionReceiptInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
`;

export const StyledTransactionsHeaderTotalAmount = styled.p`
  font-size: 1.125rem;
  font-weight: 600;
  font-family: ${fontFamilyMontserrat};
`;
