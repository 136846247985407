import styled from "styled-components";
import {
  colorLightGrey,
  colorMainWhite,
  colorBlue,
  colorMainDark,
} from "../../../shared/styles/generic";

export const StyledBalanceCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  color: ${colorMainDark};
  background: ${colorMainWhite};
  box-shadow: -5px 5px 40px rgba(0, 0, 0, 0.07);
  gap: 2rem;
`;

interface RowProps {
  alignItems?: string;
}
export const StyledBalanceCardRowWrapper = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${({ alignItems }) => alignItems ?? "center"};
`;

interface CardTextProps {
  color?: string;
  fontSize?: number;
}
export const StyledAmountTitle = styled.h1<CardTextProps>`
  color: ${({ color }) => color ?? colorBlue};
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize ?? 1.875}rem;
  margin-bottom: 0;
`;

export const StyledAmountDescription = styled.span<CardTextProps>`
  color: ${({ color }) => color ?? colorLightGrey};
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize ?? 1.125}rem; ;
`;

export const StyledCurrencyIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
