export const approvedUserContractId = 4;

export const maxInputRangeSum = 100000000;
export const minInputRangeSum = 5000;

export const portfolioColors = {
  0: "#9CCC65",
  1: "#F6EB3B",
  2: "#27A69A",
  3: "#F8C308",
  4: "#A131B9",
  5: "#FA8E3F",
  6: "#5C6BC0",
  7: "#FF5B5B",
  8: "#133D58",
  9: "#558854",
  10: "#2AB5F3",
  11: "#0474A6",
  12: "#75ECEE",
  13: "#760463",
  14: "#FF60A3",
  15: "#989898",
  16: "#E4AACD",
  17: "#D1D1D1",
};

export const portfolioAssetsColors = {
  0: "#2AB5F3",
  1: "#0474A6",
  2: "#75ECEE",
  3: "#760463",
  4: "#FF60A3",
  5: "#989898",
  6: "#E4AACD",
  7: "#D1D1D1",
};

export const horizonData = {
  shortMin: 0,
  mediumMin: 36,
  mediumMax: 60,
  longMax: null,
};
