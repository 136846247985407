import { ApiClient } from "@portittech/portit-react-common-components";

import { PreferencesReferenceDataResponse } from "../../../domain/features/reference-data/entities";
import { PreferencesReferenceDataRepository } from "../../../domain/features/reference-data/repository";

/**
 * Data bridge between Preferences Data REST endpoints and the domain layer
 */

export class PreferencesReferenceDataRepositoryImpl
  implements PreferencesReferenceDataRepository
{
  constructor(private readonly client: ApiClient) {}

  getPreferencesReferenceData(): Promise<PreferencesReferenceDataResponse> {
    return this.client.get<PreferencesReferenceDataResponse>(
      "investments/v1/referenceData",
      "investments"
    );
  }
}
