import styled from "styled-components";

import calender from "../../../../assets/icons/calender.svg";

import {
  colorMainDark,
  fontFamilyMontserrat,
} from "../../../../shared/styles/generic";

export const StyledTransactionsWidgetWrapper = styled.div`
  font-family: ${fontFamilyMontserrat};
  color: ${colorMainDark};
  margin-top: 3.4375rem;
`;

export const StyledTransactionsWidgetSearchAndFilterWrapper = styled.div`
  padding: 0 0 3.75rem 0;

  .ant-picker-suffix {
    background-image: url(${calender});

    .anticon[role="img"] {
      visibility: hidden;
    }
  }
`;

export const StyledTransactionsWidgetDateAndDownloadRow = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    margin-left: 1rem;
  }
`;

export const StyledSortTypeSelectWrapper = styled.div`
  flex: 1;
`;
