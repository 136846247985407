import React from "react";
import { useTranslation } from "react-i18next";

import { OpportunityResponse } from "../../domain/features/opportunities/entities";
import { formatCurrencyNumber } from "../../shared/helpers/formatCurrencyNumber";

import {
  StyledInvestmentAssetClass,
  StyledInvestmentDetailCard,
  StyledInvestmentDetailCardPrice,
  StyledInvestmentDetailCardPriceLabel,
  StyledInvestmentDetailCardPriceWrapper,
  StyledInvestmentDetailCardTitleAndAssetWrapper,
  StyledInvestmentTitle,
} from "./investment.styles";
import { Logo } from "../logo/logo.component";

export const InvestmentOpportunityCard = ({
  logoImageId,
  name,
  assetClass,
  tokenPrice,
  currency
}: OpportunityResponse) => {
  const { t: tApi } = useTranslation(["apiText"]);
  const { t } = useTranslation(["investment"]);

  return (
    <StyledInvestmentDetailCard>
      <Logo logoId={logoImageId} size={3.75} />
      <StyledInvestmentDetailCardTitleAndAssetWrapper>
        <StyledInvestmentTitle>{name}</StyledInvestmentTitle>
        <StyledInvestmentAssetClass>
          {tApi(assetClass)}
        </StyledInvestmentAssetClass>
      </StyledInvestmentDetailCardTitleAndAssetWrapper>

      <StyledInvestmentDetailCardPriceWrapper>
        <StyledInvestmentDetailCardPrice>
          {formatCurrencyNumber(tokenPrice, currency)}
        </StyledInvestmentDetailCardPrice>
        <StyledInvestmentDetailCardPriceLabel>
          {t("token_price_label")}
        </StyledInvestmentDetailCardPriceLabel>
      </StyledInvestmentDetailCardPriceWrapper>
    </StyledInvestmentDetailCard>
  );
};
