import React from "react";
import { Button, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { getSubT } from "@portittech/portit-react-common-components";
import { InvestmentByOpportunity } from "../../../../domain/features/investment/entities";
import { InvestmentsByOpportunity } from "../investments-by-opportunity/investmentsByOpportunity.component";
import {
  StyledInvestmentsTabsWrap,
  StyledToolTip,
} from "./investmentsTabs.styles";

interface InvestmentsTabsProps {
  doneInvestmentsByOpportunity: Array<InvestmentByOpportunity>;
  pendingInvestmentsByOpportunity: Array<InvestmentByOpportunity>;
}

export const InvestmentsTabs = ({
  doneInvestmentsByOpportunity,
  pendingInvestmentsByOpportunity,
}: InvestmentsTabsProps) => {
  const { t } = useTranslation(["portfolio"]);
  const tc = getSubT(t, "my_investments_component");
  return (
    <StyledInvestmentsTabsWrap>
      <StyledToolTip title="search">
        <Button shape="circle" icon={<SearchOutlined />} size="large" />
      </StyledToolTip>
      <Tabs type="card" destroyInactiveTabPane>
        <Tabs.TabPane tab={tc("done_title")} key={1}>
          <InvestmentsByOpportunity
            investmentsByOpportunity={doneInvestmentsByOpportunity}
            status="Done"
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={tc("pending_title")} key={2}>
          <InvestmentsByOpportunity
            investmentsByOpportunity={pendingInvestmentsByOpportunity}
            status="Pending"
          />
        </Tabs.TabPane>
      </Tabs>
    </StyledInvestmentsTabsWrap>
  );
};
