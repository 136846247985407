import React from "react";
import { useTranslation } from "react-i18next";
import { getSubT } from "@portittech/portit-react-common-components";

import { SweetAlert2 } from "sweetalert2-react-content";

import {
  StyledExternalLink,
  StyledSmallWidgetTitle,
} from "../widgets/common.styles";

interface KycSuccessModalProps {
  swalWithReact: SweetAlert2;
}

export const KycSuccessModal = ({ swalWithReact }: KycSuccessModalProps) => {
  const { t } = useTranslation(["kyc"]);
  const tc = getSubT(t, "modal");

  return (
    <>
      <StyledSmallWidgetTitle>{tc("success_message")}</StyledSmallWidgetTitle>
      <StyledExternalLink onClick={swalWithReact.clickConfirm}>
        {tc("close_label")}
      </StyledExternalLink>
    </>
  );
};
