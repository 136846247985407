import { equals } from "ramda";
import React from "react";
import {
  ValueComponentBuilder,
  MultiSelectFieldModel,
  FieldModelBuilder,
} from "@portittech/portit-react-common-components";

import {
  StyledCheckBoxLabel,
  StyledCheckBoxLi,
  StyledCheckBoxList,
} from "./preferences.styles";
import { useTranslation } from "react-i18next";

interface CheckBoxGroupFieldModelBuilderProps<V> {
  multiSelectFieldModel: MultiSelectFieldModel<V>;
  children: ValueComponentBuilder<V>;
  withSelectAll?: boolean;
  isDisabled?: boolean;
}

export const CheckBoxGroupFieldModelBuilder = <V extends unknown>({
  multiSelectFieldModel,
  withSelectAll,
  children,
  isDisabled,
}: CheckBoxGroupFieldModelBuilderProps<V>): React.ReactElement => {
  const { t } = useTranslation(["preferencesPage"]);
  return (
    <FieldModelBuilder fieldModel={multiSelectFieldModel}>
      {(model: MultiSelectFieldModel<V>, { isSubmitting }) => {
        return (
          <StyledCheckBoxList>
            {withSelectAll && (
              <StyledCheckBoxLi>
                <input
                  type="checkbox"
                  checked={model.areAllValuesSelected}
                  disabled={isSubmitting || isDisabled}
                  onChange={(event: any) => {
                    if (event.target.checked) {
                      model.selectAll();
                    } else {
                      model.deselectAll();
                    }
                  }}
                />
                <StyledCheckBoxLabel> {t("select_all")} </StyledCheckBoxLabel>
              </StyledCheckBoxLi>
            )}
            {model.items.map((item, index) => {
              return (
                <StyledCheckBoxLi key={index}>
                  <input
                    type="checkbox"
                    checked={model.value.some((v) => equals(item, v))}
                    disabled={isSubmitting || isDisabled}
                    onChange={(event: any) => {
                      if (event.target.checked) {
                        model.select(item);
                      } else {
                        model.deselect(item);
                      }
                    }}
                  />
                  {children(item)}
                </StyledCheckBoxLi>
              );
            })}
          </StyledCheckBoxList>
        );
      }}
    </FieldModelBuilder>
  );
};
