import styled from "styled-components";

import { colorGrey, colorMainDark } from "../../shared/styles/generic";

export const StyledOpportunitiesPageWrapper = styled.div`
  padding: 0 4vw 1.875rem;
`;

export const StyledSearchRow = styled.div`
  display: flex;
  margin-bottom: 2.66em;
`;

export const StyledSortInputWrapper = styled.div`
  width: 25%;
  margin-left: 1.5625rem;
`;

export const StyledIconsWrapper = styled.div`
  margin-left: 1.5625rem;
  width: 10%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  svg {
    height: 3rem;
    width: 3rem;
    cursor: pointer;
    stroke: ${colorGrey};
    transition: 0.3s;

    &[data-current="true"],
    &:hover,
    &:active {
      stroke: ${colorMainDark};
    }
  }
`;
