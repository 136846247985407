import { Either, right } from "fp-ts/Either";

import {
  Failure,
  FilePayload,
  TryUseCase,
} from "@portittech/portit-react-common-components";

import { OpportunitiesRepository } from "./repository";
import {
  AddOpportunityToWatchListParams,
  GetOpportunitiesRequest,
  GetOpportunityDataFileParams,
  GetOpportunityImgParams,
  GetSingleOpportunityParams,
  OpportunitiesResponse,
  OpportunityResponse,
  RemoveOpportunityFromWatchListParams,
} from "./entities";

/**
 * Use this usecase to retrieve the opportunities data
 */
export class GetOpportunitiesData extends TryUseCase<
  GetOpportunitiesRequest | void,
  OpportunitiesResponse
> {
  constructor(private readonly repository: OpportunitiesRepository) {
    super();
  }

  protected async tryRun(
    params: GetOpportunitiesRequest
  ): Promise<Either<Failure, OpportunitiesResponse>> {
    return right(await this.repository.getOpportunities(params));
  }
}

/**
 * Use this usecase to retrieve the images for opportunities
 */

export class GetOpportunitiesImg extends TryUseCase<
  GetOpportunityImgParams,
  string
> {
  constructor(private readonly repository: OpportunitiesRepository) {
    super();
  }

  protected async tryRun({
    imgId,
  }: GetOpportunityImgParams): Promise<Either<Failure, string>> {
    return right(await this.repository.getOpportunityImg(imgId));
  }
}

/**
 * Use this usecase to retrieve the opportunities that user maybe interested in
 */
export class GetInterestedOpportunities extends TryUseCase<
  void,
  OpportunitiesResponse
> {
  constructor(private readonly repository: OpportunitiesRepository) {
    super();
  }

  protected async tryRun(
    params: void
  ): Promise<Either<Failure, OpportunitiesResponse>> {
    return right(await this.repository.getInterestedOpportunities());
  }
}

/**
 * use this use case to fetch data for single opportunity
 */
export class GetSingleOpportunity extends TryUseCase<
  GetSingleOpportunityParams,
  OpportunityResponse
> {
  constructor(private readonly repository: OpportunitiesRepository) {
    super();
  }

  protected async tryRun({
    opportunityId,
  }: GetSingleOpportunityParams): Promise<
    Either<Failure, OpportunityResponse>
  > {
    return right(await this.repository.getSingleOpportunity(opportunityId));
  }
}

/**
 * Use this usecase to retrieve the Data File for opportunity
 */

export class GetOpportunityDataFile extends TryUseCase<
  GetOpportunityDataFileParams,
  FilePayload
> {
  constructor(private readonly repository: OpportunitiesRepository) {
    super();
  }

  protected async tryRun({
    fileId,
  }: GetOpportunityDataFileParams): Promise<Either<Failure, FilePayload>> {
    return right(await this.repository.getOpportunityDataFile(fileId));
  }
}

/**
 * Use this usecase to retrieve the watchlist opportunities
 */
export class GetWatchlistOpportunities extends TryUseCase<
  void,
  OpportunitiesResponse
> {
  constructor(private readonly repository: OpportunitiesRepository) {
    super();
  }

  protected async tryRun(): Promise<Either<Failure, OpportunitiesResponse>> {
    return right(await this.repository.getWatchlistOpportunities());
  }
}

/**
 * Use this usecase to add an opportunity to watchlist
 */
export class AddOpportunityToWatchlist extends TryUseCase<
  AddOpportunityToWatchListParams,
  OpportunitiesResponse
> {
  constructor(private readonly repository: OpportunitiesRepository) {
    super();
  }

  protected async tryRun({
    opportunityId,
  }: AddOpportunityToWatchListParams): Promise<
    Either<Failure, OpportunitiesResponse>
  > {
    return right(
      await this.repository.addOpportunityToWatchlist(opportunityId)
    );
  }
}

export class RemoveOpportunityFromWatchlist extends TryUseCase<
  RemoveOpportunityFromWatchListParams,
  OpportunitiesResponse
> {
  constructor(private readonly repository: OpportunitiesRepository) {
    super();
  }

  protected async tryRun({
    opportunityId,
  }: RemoveOpportunityFromWatchListParams): Promise<
    Either<Failure, OpportunitiesResponse>
  > {
    return right(
      await this.repository.removeOpportunityFromWatchlist(opportunityId)
    );
  }
}
