import styled from "styled-components";

import {
  colorMainDark,
  colorMainWhite,
  fontFamilyEncodeSansExpanded,
  fontFamilyMontserrat,
  gradientBackground,
} from "../../../shared/styles/generic";

export const StyledAccountBalanceWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  font-family: ${fontFamilyMontserrat};
  color: ${colorMainDark};
  padding: 1.125rem 1.25rem;
  box-shadow: -5px 5px 40px 0px rgba(0, 0, 0, 0.1);
`;

export const StyledFundingWrapper = styled.div`
  width: 100%;
  padding: 1.125rem 1.25rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: -5px 5px 40px rgba(0, 0, 0, 0.07);
  background: ${gradientBackground};
  color: ${colorMainWhite};
  font-family: ${fontFamilyMontserrat};
`;

export const StyledFundingWidgetTitle = styled.h4`
  font-family: ${fontFamilyEncodeSansExpanded};
  font-size: 1.375rem;
  font-weight: normal;
  max-width: 18.75rem;
  line-height: 110%;
  color: ${colorMainWhite};
`;

export const StyledSum = styled.p`
  font-weight: 600;
  font-size: 2rem;
  margin: 2rem 0 0.625rem;
  line-height: 100%;
`;

export const StyledSumDescription = styled.p`
  font-size: 1.125rem;
  margin-bottom: 0;
`;

export const StyledFundingIconWrapper = styled.div`
  text-align: right;
  display: block;
`;

export const StyledButtonWrapper = styled.div`
  max-width: 18.125rem;
  margin-left: auto;
  height: calc(100% - 2.5rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const StyledSelectFieldWrapper = styled.div`
  width: 8rem;
  input {
    box-shadow: none;
  }
`;
