import React from "react";
import { useTranslation } from "react-i18next";

import { getSubT } from "@portittech/portit-react-common-components";
import { OpportunityResponse } from "../../../domain/features/opportunities/entities";


import { StyledOpportunitiesContentWrapper, StyledOpportunitiesMainWrapper, StyledOpportunitiesTitleRowWrapper, StyledOpportunityColumnTitle, StyledOpportunityFlexOneWrapper, StyledSmallWidgetTitle } from "../../widgets/common.styles";
import { OpportunitiesWidgetCard } from "../../widgets/opportunities-widget/opportunitiesWidgetCard.component";


interface OpportunitiesListProps {
    opportunities: Array<OpportunityResponse>;
    assetClass: string
}

export const OpportunitiesList = ({
  assetClass,
  opportunities
}: OpportunitiesListProps) => {
  const { t } = useTranslation(["opportunitiesPage"]);
  const { t: tApi } = useTranslation(["apiText"]);
  const tc = getSubT(t, "opportunities_widget");

  const opportunitiesList  = opportunities.filter(
        (opp) => opp.assetClass === assetClass
      );
 

  return (
    <StyledOpportunitiesMainWrapper>

          <StyledSmallWidgetTitle>
            {tc("asset_class_title")} : <span>{tApi(assetClass)}</span>
          </StyledSmallWidgetTitle>

      <StyledOpportunitiesContentWrapper>
        <StyledOpportunitiesTitleRowWrapper>
          <StyledOpportunityFlexOneWrapper />
          <StyledOpportunityColumnTitle>
            {tc("name_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("asset_class_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("sector_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("min_invest_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("invest_goal_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("invest_horizon_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("invest_stage_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityFlexOneWrapper />
        </StyledOpportunitiesTitleRowWrapper>
        {opportunitiesList.map((investmentOpportunity) => (
          <OpportunitiesWidgetCard
            key={investmentOpportunity.name}
            {...investmentOpportunity}
          />
        ))}
      </StyledOpportunitiesContentWrapper>
    </StyledOpportunitiesMainWrapper>
  );
};
