import React from "react";
import { useTranslation } from "react-i18next";

import { ElviriaPages } from "../../../shared/pages";

import {
  StyledEmptyChartCircle,
  StyledEmptyChartInnerCircle,
  StyledEmptyPortfolioWrapper,
  StyledText,
  StyledDescriptionWrapper,
  StyledSubtitle,
  StyledParagraph,
  StyledLink,
  StyledNumber,
} from "./emptyPortfolio.styles";

interface EmptyPortfolioProps { 
  emptyInvestments?: boolean
  circleText?: string
}

export const EmptyPortfolio = ({emptyInvestments, circleText}: EmptyPortfolioProps) => {
  const { t } = useTranslation(["portfolio"]);

  return (
    <StyledEmptyPortfolioWrapper>
      <div>
        <StyledEmptyChartCircle>
          <StyledEmptyChartInnerCircle>
            <StyledNumber>0</StyledNumber>
            <StyledText>{t(`${circleText ?? "wallet"}`)}</StyledText>
          </StyledEmptyChartInnerCircle>
        </StyledEmptyChartCircle>
      </div>

      <StyledDescriptionWrapper>
        <StyledSubtitle>{t(`${emptyInvestments ? 'empty_done_investments' : 'empty_portfolio_subtitle'}`)}</StyledSubtitle>
        <StyledParagraph>{t("empty_portfolio_description")}</StyledParagraph>
        <StyledLink to={ElviriaPages.OPPORTUNITIES}>
          {t("explore_link")}
        </StyledLink>
      </StyledDescriptionWrapper>
    </StyledEmptyPortfolioWrapper>
  );
};
