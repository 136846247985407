import { Spin } from "antd";
import React from "react";

import { useImage } from "../../shared/hooks/useImage";

import { StyledLgoImg, StyledLogoSpinWrapper } from "./logo.style";

interface LogoProps {
  logoId: number;
  size?: number;
}

export const Logo = ({ logoId, size }: LogoProps) => {
  const [logoImg, isFetching] = useImage(logoId);
  return (
    <>
      {isFetching ? (
        <StyledLogoSpinWrapper size={size}>
          <Spin />
        </StyledLogoSpinWrapper>
      ) : (
        <StyledLgoImg src={logoImg} size={size} />
      )}
    </>
  );
};
