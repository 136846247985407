import styled from "styled-components";
import { fontFamilyMontserrat } from "../../../shared/styles/generic";
import { devices } from "../../config/devices";

export const StyledOpportunitiesCardViewInnerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.75rem;
  font-family: ${fontFamilyMontserrat};
  margin-bottom: 2rem;

  @media ${devices.smallLaptop} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledOpportunitySingleCardMainWrapper = styled.div`
  box-shadow: -0.3125rem 0.3125rem 2.5rem 0rem rgba(0, 0, 0, 0.1);
  padding: 1.6875rem;
  margin-bottom: 2rem;
`;

export const StyledOpportunitySingleCardImgAndTitleWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const StyledOpportunitySingleCardImg = styled.img`
  height: 3.75rem;
  width: 3.75rem;
`;

export const StyledOpportunitySingleCardTitle = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2.5rem;
  margin-bottom: 0;
`;

export const StyledOpportunitySingleCardDetailRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.1875rem;
  margin-top: 1.5rem;
`;

export const StyledOpportunitySingleCardDetailTitle = styled.p`
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.08em;
  margin-top: 0;
  margin-bottom: 0.2rem;
  text-transform: uppercase;
`;

export const StyledOpportunitySingleCardDetailValue = styled.p`
  font-size: 0.875rem;
  line-height: 1.3125rem;
  margin-bottom: 0;
`;

export const StyledOpportunitySingleCardViewLiknWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5625rem;
`;
