import styled from "styled-components";
import {
  colorMain,
  colorMainDark,
  fontFamilyEncodeSansExpanded,
  fontFamilyMontserrat,
} from "../../shared/styles/generic";

export const StyledHelpPageWrapper = styled.div`
  margin: 0 3.75rem;
`;

export const StyledAdminInnerPageHeading = styled.div`
  font-family: ${fontFamilyEncodeSansExpanded};
  font-weight: 400;
  font-size: 2rem;
  line-height: 110%;
  color: ${colorMain};
  margin-bottom: 1.875rem;
`;

export const StyledHelpPageContent = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2.375rem;
`;

export const StyledContactUsDetails = styled.div`
  display: flex;
`;
export const StyledContactUsInfo = styled.div`
  display: flex;
  margin-right: 1.5rem;
`;

export const StyledContactUsTitle = styled.h3`
  font-family: ${fontFamilyEncodeSansExpanded};
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 110%;
  color: ${colorMain};
  margin: 2.2rem 0 1rem 0;
`;

export const StyledContactUsSubTitle = styled.p`
  font-size: 1rem;
  color: ${colorMainDark};
  line-height: 1.5rem;
  font-family: ${fontFamilyMontserrat};
  max-width: 80%;
`;

export const StyledContactUsWrapper = styled.div`
  margin-top: 1.25rem;

  svg {
    margin-right: 0.5rem;
  }
`;

export const StyledTitleLinks = styled.div`
  margin-bottom: 1.25rem;
`;
