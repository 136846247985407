import {
  IterableModel,
  IterableModelBuilder,
  TransactionDetailsEntity,
} from "@portittech/portit-react-common-components";
import moment from "moment";
import { groupBy, sum } from "ramda";
import React from "react";
import styled from "styled-components";
import { NoTransactions } from "../components/transactions/noTransactions.component";
import {
  DailyTransaction,
  Transactions,
} from "../components/transactions/transactions.component";

const TransactionsStyle = styled.div`
  margin: 2em 0;

  .transactions-header {
    margin-top: 20px;
  }

  .single-transaction {
    box-shadow: 0 33px 73px rgba(32, 32, 35, 0.06);

    & + .single-transaction {
      margin-top: 25px;
    }
  }

  .noTransactions {
    text-align: center;
  }
`;

interface TransactionsBuilderProps {
  transactionsModel: IterableModel<TransactionDetailsEntity>;
}

export const TransactionsModelBuilder = ({
  transactionsModel,
}: TransactionsBuilderProps): React.ReactElement => {
  const getTotal = (t: TransactionDetailsEntity) => {
    switch (t.type) {
      case "transfer":
      case "inbound_funds":
      case "conversion":
        return Math.abs(t.amount);
      case "payment":
        return Math.abs(t.amount) + (t.fee_info?.fee_amount ?? 0);
    }
  };

  return (
    <TransactionsStyle>
      <IterableModelBuilder
        iterableModel={transactionsModel}
        emptyBuilder={() => <NoTransactions />}
      >
        {(transactions: Array<TransactionDetailsEntity>) => {
          const dailyTransactions: {
            [p: string]: Array<TransactionDetailsEntity>;
          } = groupBy((t: TransactionDetailsEntity) => {
            const format = "DD/MM/yyyy";
            return moment(t.created_at).calendar({
              nextDay: format,
              lastDay: format,
              nextWeek: format,
              lastWeek: format,
              sameElse: format,
            });
          }, transactions);
          return (
            <Transactions
              transactions={Object.keys(
                dailyTransactions
              ).map<DailyTransaction>((dayName) => {
                const transactions = dailyTransactions[dayName];
                const transaction = transactions[0];
                return {
                  dayName,
                  dayTotal: sum(transactions.map((t) => getTotal(t))),
                  currency: transaction.currency,
                  transactions: transactions,
                };
              })}
            />
          );
        }}
      </IterableModelBuilder>
    </TransactionsStyle>
  );
};
