import { Radio } from "antd";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import {
  BooleanFieldModel,
  FieldModelBuilder,
  getSubT,
  LinearLayout,
} from "@portittech/portit-react-common-components";

interface RadioAnswerFieldModelBuilderProps {
  booleanFieldModel: BooleanFieldModel;
  isDisabled?: boolean;
  children: ReactNode;
}

export const ElviriaRadioAnswerFieldModelBuilder = ({
  isDisabled,
  booleanFieldModel,
  children,
}: RadioAnswerFieldModelBuilderProps): React.ReactElement => {
  const pt = useTranslation(["components"]);
  const t = getSubT(pt.t, "fields.basic");

  return (
    <LinearLayout direction="vertical">
      <>{children}</>
      <FieldModelBuilder fieldModel={booleanFieldModel}>
        {(model: BooleanFieldModel, { isSubmitting }) => {
          return (
            <Radio.Group
              value={model.value}
              onChange={(event) => model.updateValue(event.target.value)}
              disabled={isDisabled || isSubmitting}
            >
              <Radio value={true}>{t("yes_label")}</Radio>
              <Radio value={false}>{t("no_label")}</Radio>
            </Radio.Group>
          );
        }}
      </FieldModelBuilder>
    </LinearLayout>
  );
};
