import styled from "styled-components";
import {
  colorDarkGrey,
  colorMain,
  colorSliderGrey,
  fontFamilyMontserrat,
} from "../../../shared/styles/generic";

export const WatchlistWidgetWrapper = styled.div`
  margin: 3.75rem 0;
`;
export const WatchlistSlideWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  flex-direction: column;
`;

export const WatchlistCardWrapper = styled.div`
  display: flex;
  gap: 0.9375rem;
  align-items: center;
  box-shadow: -5px 5px 40px 0px rgba(0, 0, 0, 0.1);
  padding: 1.1rem;
  cursor: pointer;
`;

export const WatchlistCardImg = styled.img`
  width: 3.9375rem;
  height: auto;
`;

export const StyledNoOpportunities = styled.p`
  font-family: ${fontFamilyMontserrat};
  font-size: 0.875rem;
  color: ${colorDarkGrey};
`;

export const StyledEmptyWrapper = styled.div`
  margin-top: 3rem;
  text-align: center;
`;

export const WatchlistCardSliderWrapper = styled.div`
  /***
    To resolve unlimited width issue in antd when any ancestor div has display: flex
    https://github.com/kenwheeler/slick/issues/2378
  ***/
  min-width: 100%;
  width: 0;

  .slide-wrapper {
    padding: 1.875rem;
  }

  .slick-dots {
    position: initial;
    display: flex;
    gap: 0.3125rem;
  }

  .slick-slider .slick-list .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-dots li button {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background-color: ${colorSliderGrey};
  }

  .slick-dots li.slick-active {
    width: 1rem;
  }

  .slick-dots li.slick-active button {
    background-color: ${colorMain};
  }
`;
