import React from "react";
import { useTranslation } from "react-i18next";

import {
  StyledAdminInnerPageTitle,
  StyledGrid,
} from "../../shared/styles/page.styles";
import { AccountBalanceWidget } from "../widgets/account-balance-widget/accountBalanceWidget.component";
import {
  StyledExternalLink,
  StyledStickyRightWidgetsWrapper,
} from "../widgets/common.styles";
import { GuideWidget } from "../widgets/guideWidget";
import { HelpWidget } from "../widgets/helpWidget";
import { MyPreferencesWidget } from "../widgets/myPreferencesWidget";
import { StyledAccountPageWrapper } from "./accountPage.style";
import { TransactionsWidget } from "./components/transactions-widget/transactionsWidget.component";
import { useHistory, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

export const AccountPage = () => {
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const { t } = useTranslation(["accountPage"]);
  const history = useHistory();
  return (
    <StyledAccountPageWrapper>
      <StyledExternalLink
        type="link"
        onClick={() => {
          history.goBack();
        }}
      >
        <ArrowLeftOutlined /> {t("back_action")}
      </StyledExternalLink>
      <StyledAdminInnerPageTitle>{t("page_title")}</StyledAdminInnerPageTitle>

      <StyledGrid>
        <div>
          <AccountBalanceWidget
            withFundingIcon
            withWithdrawButton
            selectedBalanceId={parseFloat(accountId)}
          />
          <TransactionsWidget />
        </div>
        <div>
          <StyledStickyRightWidgetsWrapper>
            <HelpWidget />
            <GuideWidget digitalGoldTitle />
            <MyPreferencesWidget />
          </StyledStickyRightWidgetsWrapper>
        </div>
      </StyledGrid>
    </StyledAccountPageWrapper>
  );
};
