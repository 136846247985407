import type { ReactNode } from "react";
import React, { createContext, useContext } from "react";

import { useElviria } from "./useElviriaStore";

type Context = ReturnType<typeof useElviria>;
type Props = { children: ReactNode };

const ElviriaContext = createContext({} as Context);

export const ElviriaStore = ({ children }: Props) => {
  const value = useElviria();
  return (
    <ElviriaContext.Provider value={value}>{children}</ElviriaContext.Provider>
  );
};

export const useElviriaStore = () => useContext(ElviriaContext);
