import React, { useContext } from "react";
import {
  Context,
  CustomerData,
  getSubT,
  ProfileEntity,
} from "@portittech/portit-react-common-components";

import { ReactComponent as CheckIcon } from "../../../assets/check.svg";

import { TextButton } from "../../../shared/styles/button";
import { useTranslation } from "react-i18next";
import {
  StyledProfileWrapper,
  StyledSpanServices,
} from "./styles/CustomerProfile.styles";
import { colorMain } from "../../../shared/styles/generic";

interface CustomerProfileProps {
  customerData: CustomerData;
  profile: ProfileEntity;
}

export const CustomerProfile = ({
  customerData,
  profile,
}: CustomerProfileProps) => {
  const { customerCubit } = useContext(Context);
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "overview");

  const isCurrentProfile = profile.id === customerData?.profile?.id;
  return (
    <StyledProfileWrapper>
      {isCurrentProfile && <CheckIcon fill={colorMain} />}

      <StyledSpanServices>
        {profile.type === "COMPANY" ? "Company" : "Customer"}: {profile.name}
      </StyledSpanServices>
      {!isCurrentProfile && (
        <TextButton onClick={() => customerCubit.changeProfile(profile.id)}>
          {tc("change_profile_action")}
        </TextButton>
      )}
    </StyledProfileWrapper>
  );
};
