import React from "react";
import {
  FieldModelBuilder,
  SelectFieldModel,
  ValueFilter,
} from "@portittech/portit-react-common-components";
import { StyledCheckBoxLi, StyledCheckBoxList } from "./preferences.styles";

interface SelectFieldModelBuilderProps<V> {
  selectFieldModel: SelectFieldModel<V>;
  children: (value: V) => React.ReactNode;
  isDisabled?: boolean;
  isInvestmentOptions?: boolean;
  itemDisabler?: ValueFilter<V>;
}

export const CheckBoxSelectFieldModelBuilder = <V extends unknown>({
  selectFieldModel,
  children,
  isDisabled,
  isInvestmentOptions,
  itemDisabler,
}: SelectFieldModelBuilderProps<V>): React.ReactElement => {
  return (
    <FieldModelBuilder fieldModel={selectFieldModel}>
      {(model: SelectFieldModel<V>, { isSubmitting }) => (
        <StyledCheckBoxList isInvestmentOptions={isInvestmentOptions}>
          {model.items.map((item, index) => {
            return (
              <StyledCheckBoxLi key={index}>
                <input
                  type="checkbox"
                  checked={model.value === item}
                  disabled={
                    isSubmitting || isDisabled || itemDisabler?.check(item)
                  }
                  onChange={(event: any) => {
                    if (event.target.checked) {
                      model.updateValue(item);
                    } else {
                    }
                  }}
                />
                {children(item)}
              </StyledCheckBoxLi>
            );
          })}
        </StyledCheckBoxList>
      )}
    </FieldModelBuilder>
  );
};
