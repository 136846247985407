import React from "react";
import { useTranslation } from "react-i18next";

import {
  useModelChanges,
  getSubT,
  FixedLinearLayout,
} from "@portittech/portit-react-common-components";

import binIcon from "../../../assets/bin_icon.svg";
import closedEnvelope from "../../../assets/closedEnvelope_icon.svg";
import openEnvelope from "../../../assets/openEnvelope_icon.svg";

import { MessagingPageModel } from "../models/messagingPageModel";
import { SingleMessage } from "./singleMessage.component";
import {
  StyledMessageItem,
  StyledMessagesCheckboxGroup,
  StyledMessagesList,
  StyledMessagesListTitle,
  StyledMessagesListTitleRow,
  StyledTopOptions,
  StyledTopOptionsIconsWrapper,
  StyledUnreadMessageCircle,
} from "../style/messagesList.style";
import { ElviriaCheckbox } from "../../../shared/components/fields/checkbox/elviriaCheckbox.component";

interface MessagesListProps {
  messagingPageModel: MessagingPageModel;
  onMessageClick: (message) => void;
}

export const MessagesList = ({
  messagingPageModel,
  onMessageClick,
}: MessagesListProps): React.ReactElement => {
  const { t: pt } = useTranslation(["messaging"]);
  const t = getSubT(pt, "components.messages_list");

  const {
    profilesModel,
    selectedProfileModel,
    selectedMessagesModel,
    messagesModel,
  } = messagingPageModel;

  useModelChanges(profilesModel);
  useModelChanges(selectedMessagesModel);

  return (
    <StyledMessagesList>
      <StyledMessagesListTitleRow>
        <StyledMessagesListTitle>
          {t("title")} <span>{`(${messagesModel.values.length})`}</span>
        </StyledMessagesListTitle>

        <StyledMessagesListTitle>
          {t("deleted_title")} <span>(3)</span>{" "}
          {/* Deleted message count will come from API, mocked for now */}
        </StyledMessagesListTitle>
      </StyledMessagesListTitleRow>
      <StyledTopOptions>
        <ElviriaCheckbox
          checked={
            selectedMessagesModel.values.length ===
              messagesModel.values.length &&
            selectedMessagesModel.values.length !== 0
          }
          indeterminate={
            selectedMessagesModel.values.length > 0 &&
            selectedMessagesModel.values.length < messagesModel.values.length
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
              selectedMessagesModel.update({
                value: messagesModel.values,
              });
            } else {
              selectedMessagesModel.update({ value: [] });
            }
          }}
        />

        <StyledTopOptionsIconsWrapper>
          <img
            className={
              selectedMessagesModel.values.length > 0 ? "" : "disabled"
            }
            src={binIcon as string}
            alt={"Delete"}
            onClick={() => {
              selectedMessagesModel.values.forEach((m) =>
                messagingPageModel.deleteMessage(m)
              );
            }}
          />

          <img
            className={
              selectedMessagesModel.values.length > 0 ? "" : "disabled"
            }
            src={closedEnvelope as string}
            alt={"Unread"}
            onClick={() => {
              selectedMessagesModel.values.forEach((m) =>
                messagingPageModel.updateMessageStatus(m, "NEW")
              );
            }}
          />

          <img
            className={
              selectedMessagesModel.values.length > 0 ? "" : "disabled"
            }
            src={openEnvelope as string}
            alt={"Read"}
            onClick={() => {
              selectedMessagesModel.values.forEach((m) =>
                messagingPageModel.updateMessageStatus(m, "READ")
              );
            }}
          />
        </StyledTopOptionsIconsWrapper>
      </StyledTopOptions>

      <StyledMessagesCheckboxGroup>
        <FixedLinearLayout direction={"vertical"}>
          {profilesModel.values
            .find(({ profile }) => profile.id === selectedProfileModel.value.id)
            .messages.filter((m) => m.message_status !== "DELETED")
            .map((message) => {
              return (
                <StyledMessageItem key={message.message_id}>
                  <StyledUnreadMessageCircle
                    isVisible={message.message_status === "NEW"}
                  />
                  <ElviriaCheckbox
                    value={message.message_id}
                    checked={selectedMessagesModel.values.some(
                      (m) => m.message_id === message.message_id
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.checked) {
                        selectedMessagesModel.update({
                          value: [...selectedMessagesModel.values, message],
                        });
                      } else {
                        selectedMessagesModel.update({
                          value: selectedMessagesModel.values.filter((m) => {
                            return m.message_id !== message.message_id;
                          }),
                        });
                      }
                    }}
                  />

                  <SingleMessage
                    message={message}
                    onMoreClick={() => {
                      void messagingPageModel.updateMessageStatus(
                        message,
                        "READ"
                      );
                      onMessageClick(message);
                    }}
                  />
                </StyledMessageItem>
              );
            })}
        </FixedLinearLayout>
      </StyledMessagesCheckboxGroup>
    </StyledMessagesList>
  );
};
