import React from "react";
import { useTranslation } from "react-i18next";

import {
  FieldTextError,
  TextError,
  TranslationsSelectFieldModelBuilder,
  TranslationsSelectFieldModel,
  useModelChanges,
  FieldDecoration,
  BalanceDetailsEntity,
  NunitoDescription,
} from "@portittech/portit-react-common-components";

import { PaymentsFormModel } from "../investmentPage.model";
import { StyledNoAccountWrapper } from "../investment.styles";
import {
  StyledSmallRowWrapper,
  StyledInvestmentDetailFieldLabel,
} from "./investStep.style";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export interface PaymentsSourceFieldModelBuilderProps {
  sourceFieldModel: TranslationsSelectFieldModel<BalanceDetailsEntity>;
}

export const InvestStepModelBuilder = ({
  sourceFieldModel,
}: PaymentsSourceFieldModelBuilderProps): React.ReactElement => {
  const { t } = useTranslation(["investment"]);

  useModelChanges(sourceFieldModel);

  if (sourceFieldModel.items.length === 0) {
    return (
      <StyledNoAccountWrapper>
        <NunitoDescription>{t("noAccount_label")}</NunitoDescription>
      </StyledNoAccountWrapper>
    );
  }

  return (
    <>
      <StyledInvestmentDetailFieldLabel>
        {t("account_input_label")}{" "}
        <Tooltip title={t("account_input_tooltip")}>
          <InfoCircleOutlined />
        </Tooltip>
      </StyledInvestmentDetailFieldLabel>
      <StyledSmallRowWrapper>
        <TranslationsSelectFieldModelBuilder
          translationsFieldModel={sourceFieldModel}
          decoration={FieldDecoration.custom({})}
          errorBuilder={(error) => {
            switch (error) {
              case PaymentsFormModel.outOfFundsError:
                return <TextError>{t("out_of_funds_error")}</TextError>;
              default:
                return <FieldTextError error={error} />;
            }
          }}
        >
          {(key, value) => `${value.account.account_name} - ${value.currency}`}
        </TranslationsSelectFieldModelBuilder>
      </StyledSmallRowWrapper>
    </>
  );
};
