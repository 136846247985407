import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  MessageEntity,
  NunitoGreyDescription,
  NunitoText,
  getSubT,
  VoidCallBack,
  formatDateToLocalString,
} from "@portittech/portit-react-common-components";

import {
  SingleMessageStyle,
  StyledMessageBody,
  StyledMessageHeader,
} from "../style/singleMessage.style";
import { colorMainDark } from "../../../shared/styles/generic";
import { StyledBackWithIconLinkButton } from "../../widgets/common.styles";

interface SingleMessageProps {
  message: MessageEntity;
  onBackClick: VoidCallBack;
}

export const MessageDetails = ({
  message,
  onBackClick,
}: SingleMessageProps): React.ReactElement => {
  const { t: pt } = useTranslation(["messaging"]);
  const t = getSubT(pt, "components.single_message");

  return (
    <SingleMessageStyle>
      <StyledMessageHeader>
        <NunitoText color={colorMainDark} fontWeight="600">
          {message.subject_text}
        </NunitoText>
        <NunitoGreyDescription>
          {formatDateToLocalString(message.created)}
        </NunitoGreyDescription>
      </StyledMessageHeader>
      <StyledMessageBody>
        <NunitoText>{message.body_text}</NunitoText>
      </StyledMessageBody>

      <StyledBackWithIconLinkButton onClick={onBackClick}>
        <ArrowLeftOutlined /> <span>{t("back_action")}</span>
      </StyledBackWithIconLinkButton>
    </SingleMessageStyle>
  );
};
