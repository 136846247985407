import styled from "styled-components";
import { colorBlue } from "./generic";

export const CalendarStyle = styled.div`
    font-family: "Nunito";
  }

  .ant-picker-header,
  .ant-picker-content th {
    color: #42206d;
  }

  .ant-picker-content td {
    color: #37383c;
  }

  .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 50%;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #ffffff;
    background: ${colorBlue};
  }

  .ant-picker-footer {
    display: none;
  }

  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    display: none;
  }

  .ant-picker-header-prev-btn {
    background-repeat: no-repeat;
    position: center;
    top: 0.9375rem;
    left: 0.625rem;
  }

  .ant-picker-header-next-btn {
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    position: center;
    top: 0.9375rem;
    left: 0.625rem;
  }

  .ant-picker-prev-icon,
  .ant-picker-next-icon {
    display: none;
  }
`;
