import React from "react";
import { useTranslation } from "react-i18next";

import { getSubT } from "@portittech/portit-react-common-components";

import { ReactComponent as InfoWidgetLearnIcon } from "../../assets/icons/folder.svg";

import { InfoWidget } from "./info-widget/infoWidget.component";

import { colorMainDark } from "../../shared/styles/generic";
import { StyledRouterLink } from "./common.styles";
import { ElviriaPages } from "../../shared/pages";

interface DataRoomWidgetProps {
  opportunityId: number;
}

export const DataRoomWidget = ({ opportunityId }: DataRoomWidgetProps) => {
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "info_widget");
  return (
    <InfoWidget
      title={tc("access_key_financials_label")}
      icon={<InfoWidgetLearnIcon fill={colorMainDark} stroke={colorMainDark} />}
    >
      <StyledRouterLink to={`${ElviriaPages.DATA_ROOM}/${opportunityId}`}>
        {tc("data_room_link")}
      </StyledRouterLink>
    </InfoWidget>
  );
};
