import styled from "styled-components";

import markImg from "../../../assets/elviria-mark.svg";
import {
  colorMain,
  colorMainDark,
  colorMainWhite,
  fontFamilyMontserrat,
} from "../../../shared/styles/generic";

export const StyledSmallRowWrapper = styled.div`
  width: 50%;
  margin-bottom: 1.6875rem;

  .ant-input-number-input {
    text-align: center;
    font-weight: 600;
    font-size: 1.25rem;
    background-color: ${colorMainWhite} !important;
    color: ${colorMainDark} !important;
    text-align: center;
    font-weight: 600;
    font-size: 1.25rem;
    padding-right: 28px;
  }

  .ant-input-disabled {
    background-color: ${colorMainWhite} !important;
    color: ${colorMainDark} !important;
    text-align: center;
    font-weight: 600;
    font-size: 1.25rem;
    padding-right: 28px;
  }

  /* to style checkbox */
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colorMain};
    border-color: ${colorMain};
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${colorMain};
  }
`;

export const StyledInputNote = styled.div`
  margin-top: 1.3rem;
  font-family: ${fontFamilyMontserrat};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: ${colorMainDark};
`;

export const StyledInvestmentDetailFieldLabel = styled.p`
  font-size: 1rem;
  line-height: 1.875rem;
  margin-bottom: 0;
`;

export const StyledBigRowWrapper = styled.div`
  margin-top: 3.5rem;
  margin-bottom: 1.6875rem;
`;

export const StyledTotalWrapper = styled.div`
  text-align: center;
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 1.25rem;
  height: 50px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 10px 0 10px;
  background: #ffffff;
  margin-bottom: 3rem;
`;

export const StyledInvestmentOptionsCheckBoxWrapper = styled.div`
  text-align: center;

  input[type="checkbox"]:checked + label::after {
    background-image: url(${markImg});
    background-position: cover;
    border: 0;
    transition: all 0.3s ease-in-out;
  }

  input[type="checkbox"]:checked + label {
    border: 1px solid ${colorMain};
    transition: border 0.2s;
  }

  input[type="checkbox"] {
    position: absolute;
    z-index: 100;
    opacity: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  input[type="checkbox"]:checked ~ p {
    color: ${colorMain};
  }
`;

export const StyledInvestmentOptionsCheckBoxDescription = styled.p`
  text-align: left;
  font-family: ${fontFamilyMontserrat};
  font-size: 0.875rem;
  line-height: 1.4375rem;
`;
