import {
  CreatePreferenceRequest,
  InvestmentsPreferencesRepository,
  PreferenceResponse,
} from "../../../domain";
import { ApiClient } from "@portittech/portit-react-common-components";

/**
 * Data bridge between Investment REST endpoints and the domain layer
 */
export class InvestmentsPreferencesRepositoryImpl
  implements InvestmentsPreferencesRepository
{
  constructor(private readonly client: ApiClient) {}

  getPreferences(partyId: number): Promise<PreferenceResponse> {
    return this.client.get<PreferenceResponse>(
      `/investments/v1/preferences/parties/${partyId}`,
      "investments"
    );
  }

  createPreferences(
    request: CreatePreferenceRequest
  ): Promise<PreferenceResponse> {
    return this.client.post<PreferenceResponse>(
      `/investments/v1/preferences`,
      "investments",
      request
    );
  }

  updatePreferences(
    preferenceId: number,
    request: CreatePreferenceRequest
  ): Promise<PreferenceResponse> {
    return this.client.put<PreferenceResponse>(
      `/investments/v1/preferences/${preferenceId}`,
      "investments",
      request
    );
  }
}
