import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getSubT } from "@portittech/portit-react-common-components";

import { ReactComponent as TradeHistoryIcon } from "../../../assets/icons/trade-history-icon.svg";
import { colorMainDark } from "../../../shared/styles/generic";
import {
  StyledDescription,
  StyledNumber,
  StyledSmallWidgetTitle,
  StyledSmallWidgetTitleRow,
  StyledSmallWidgetWrapper,
} from "../common.styles";

import {
  StyledTradeWidgetDataRow,
  StyledTradeWidgetDataWrapper,
} from "./existingHistoricalTradeWidget.styles";
import { ElviriaPages } from "../../../shared/pages";
import { useInvestmentsCompletedAndPendingCount } from "../../../shared/hooks/useInvestmentsCompletedAndPendingCount";
import { Spin } from "antd";

export const ExistingHistoricalTradeWidget = () => {
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "existing_historical_trade_widget");

  const history = useHistory();

  const clickHandler = () => history.push(ElviriaPages.INVESTMENTS);

  const {
    completedInvestmentsCount,
    pendingInvestmentsCount,
    isInvestmentsCompletedAndPendingCountFetching,
  } = useInvestmentsCompletedAndPendingCount();

  return (
    <StyledSmallWidgetWrapper>
      <StyledSmallWidgetTitleRow>
        <StyledSmallWidgetTitle>{tc("widget_title")}</StyledSmallWidgetTitle>
        <TradeHistoryIcon fill={colorMainDark} stroke={colorMainDark} />
      </StyledSmallWidgetTitleRow>
      <StyledTradeWidgetDataRow>
        <StyledTradeWidgetDataWrapper onClick={clickHandler}>
          <StyledNumber withAccentColor>
            {isInvestmentsCompletedAndPendingCountFetching ? (
              <Spin/>
            ) : (
              completedInvestmentsCount
            )}
          </StyledNumber>
          <StyledDescription>{tc("stored")}</StyledDescription>
        </StyledTradeWidgetDataWrapper>
        <StyledTradeWidgetDataWrapper onClick={clickHandler}>
          <StyledNumber>
            {isInvestmentsCompletedAndPendingCountFetching ? (
              <Spin />
            ) : (
              pendingInvestmentsCount
            )}
          </StyledNumber>
          <StyledDescription>{tc("transferred_out")}</StyledDescription>
        </StyledTradeWidgetDataWrapper>
      </StyledTradeWidgetDataRow>
    </StyledSmallWidgetWrapper>
  );
};
