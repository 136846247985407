import React from "react";

import moment from "moment";
import { Either, isRight } from "fp-ts/lib/Either";

import {
  Failure,
  FilePayload,
} from "@portittech/portit-react-common-components";
import { getOpportunityDataFile } from "../../../shared/usecases";
import { OpportunityDataFilesResponse } from "../../../domain/features/opportunities/entities";
import { Button } from "antd";
import DownloadIcon2 from "../../../assets/icons/download.svg";

import {
  StyledDataRoomFileDateAndSize,
  StyledDataRoomFileDateAndSizeWrapper,
  StyledDataRoomFileDescription,
  StyledDataRoomFileDetailsWrapper,
  StyledDataRoomFileDownloadIcon,
  StyledDataRoomFileName,
  StyledDataRoomFileWrapper,
} from "./dataRoomFileCard.styles";
import Swal from "sweetalert2";
import { colorMain } from "../../../shared/styles/generic";
import { useTranslation } from "react-i18next";

export const DataRoomFileCard = ({
  id,
  originalFileName,
  date,
  description,
  size,
}: OpportunityDataFilesResponse) => {
  const { t } = useTranslation(["dataRoomPage"]);

  const downloadFile = async (fileId, originalFileName) => {
    const res: Either<Failure, FilePayload> = await getOpportunityDataFile.run({
      fileId: fileId,
    });

    if (isRight(res)) {
      const payload: FilePayload = res.right;
      const blob = new Blob(
        typeof payload === "string" ? payload : [(payload as any).data.buffer]
      );
      // content-disposition is stripped out, probably cause of cors
      // see https://stackoverflow.com/questions/37897523/axios-get-access-to-response-header-fields
      const fileName = payload.fileName ?? originalFileName;

      const link = document.createElement("a");
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      Swal.fire({
        title: t("something_went_wrong"),
        icon: "warning",
        allowOutsideClick: false,
        confirmButtonColor: colorMain,
        confirmButtonText: t("close_button_label"),
      });
    }
  };

  return (
    <StyledDataRoomFileWrapper>
      <StyledDataRoomFileDetailsWrapper>
        <div>
          <StyledDataRoomFileName>{originalFileName}</StyledDataRoomFileName>
          <StyledDataRoomFileDateAndSizeWrapper>
            <StyledDataRoomFileDateAndSize>
              {moment(date).format("DD/MM/YYYY")}
            </StyledDataRoomFileDateAndSize>
            <StyledDataRoomFileDateAndSize>{`${size}MB`}</StyledDataRoomFileDateAndSize>
          </StyledDataRoomFileDateAndSizeWrapper>
          <StyledDataRoomFileDescription>
            {description}
          </StyledDataRoomFileDescription>
        </div>
      </StyledDataRoomFileDetailsWrapper>
      <Button
        type="link"
        size="large"
        onClick={() => downloadFile(id, originalFileName)}
      >
        <StyledDataRoomFileDownloadIcon src={DownloadIcon2} />
      </Button>
    </StyledDataRoomFileWrapper>
  );
};
