import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  Context,
  FieldDecoration,
  PaginationModelBuilder,
  PageModelBuilder,
  useModelChanges,
  useModelCreatorEffect,
  DateRangeFieldModelBuilder,
  TextFieldModelBuilder,
  TranslationsSelectFieldModelBuilder,
  getSubT,
  LoadingComponent,
} from "@portittech/portit-react-common-components";

import {
  StyledInnerWrap,
  StyledInvestmentsListWrapper,
  StyledInvestmentTypeSelectWrapper,
} from "./investmentsList.styles";

import { InvestmentsModelBuilder } from "./investmentsModelBuilder";
import { InvestmentsPageModel } from "../../models/investmentsPageModel";
import {
  StyledAdminInnerPageTitle,
  StyledGrid,
} from "../../../../shared/styles/page.styles";
import {
  StyledRouterLink,
  StyledSearchFieldWrapper,
  StyledSmallText,
  StyledStickyRightWidgetsWrapper,
} from "../../../widgets/common.styles";
import { usePageId } from "../../../../shared/hooks/usePageId";
import { ElviriaPages } from "../../../../shared/pages";
import { InvestmentDetailPage } from "../investment-detail-page/investmentDetailPage.component";
import { HelpWidget } from "../../../widgets/helpWidget";
import { GuideWidget } from "../../../widgets/guideWidget";
import { MyPreferencesWidget } from "../../../widgets/myPreferencesWidget";

export const InvestmentsList = (): React.ReactElement => {
  const { customerCubit } = useContext(Context);

  const { t } = useTranslation(["investment"]);
  const tc = getSubT(t, "investment_detail_page");

  const pageId = usePageId();

  const pageModel = useModelCreatorEffect({
    creator: () => {
      return new InvestmentsPageModel({
        customerCubit,
      });
    },
    waitDeps: [customerCubit],
  });

  const dateFrom = useModelChanges({
    model: pageModel?.searcherFormModel?.fromDateFM,
    lens: (m) => m.value,
  });

  const dateTo = useModelChanges({
    model: pageModel?.searcherFormModel?.toDateFM,
    lens: (m) => m.value,
  });

  const investmentList = useModelChanges({
    model: pageModel?.investmentsModel,
    lens: (m) => m.values,
  });
  if (!pageModel) return <LoadingComponent />;

  if (pageId && investmentList) {
    // TODO: if a user comes with URL and the pageId is not in first 8 results, the page will not load the investment details
    // This can be implemented once API for single Investment detail is available
    return (
      <div>
        <StyledRouterLink to={ElviriaPages.INVESTMENTS} isMargin>
          {tc("investments_link_text")}
        </StyledRouterLink>
        <StyledAdminInnerPageTitle>
          {investmentList
            .filter((invest) => invest.id === pageId)
            .map((invest) => invest.opportunity.name)}
        </StyledAdminInnerPageTitle>
        <StyledGrid>
          <div>
            <InvestmentDetailPage
              investment={
                investmentList.filter((invest) => invest.id === pageId)[0]
              }
            />
          </div>
          <div>
            <StyledStickyRightWidgetsWrapper>
              <HelpWidget />
              <GuideWidget digitalGoldTitle />
              <MyPreferencesWidget />
            </StyledStickyRightWidgetsWrapper>
          </div>
        </StyledGrid>
      </div>
    );
  }

  return (
    <StyledInvestmentsListWrapper>
      <PageModelBuilder pageModel={pageModel}>
        {() => {
          // if (accountCount <= 0) {
          //   return <div />; // todo add component for no investment
          // }
          return (
            <StyledInnerWrap>
              {/*Todo add page Title Here */}
              <StyledAdminInnerPageTitle>
                {t("investments_page_title")}
              </StyledAdminInnerPageTitle>
              <StyledGrid>
                <div>
                  <StyledSmallText>
                    {`${t("from_label")} ${dateFrom.toLocaleDateString()} ${t(
                      "to_label"
                    )} ${dateTo.toLocaleDateString()}`}
                  </StyledSmallText>

                  <StyledSearchFieldWrapper isFullWidth>
                    <TextFieldModelBuilder
                      textFieldModel={pageModel.searcherFormModel.textFM}
                      decoration={FieldDecoration.search({
                        label: t("search_label"),
                      })}
                    />
                  </StyledSearchFieldWrapper>

                  <DateRangeFieldModelBuilder
                    fromInputFieldBloc={pageModel.searcherFormModel.fromDateFM}
                    toInputFieldBloc={pageModel.searcherFormModel.toDateFM}
                  >
                    <StyledInvestmentTypeSelectWrapper>
                      <TranslationsSelectFieldModelBuilder
                        translationsFieldModel={
                          pageModel.searcherFormModel.transactionTypesFM
                        }
                        decoration={FieldDecoration.custom({
                          label: t("sort_by_label"),
                        })}
                      >
                        {(key, value) => value}
                      </TranslationsSelectFieldModelBuilder>
                    </StyledInvestmentTypeSelectWrapper>
                  </DateRangeFieldModelBuilder>
                  <InvestmentsModelBuilder
                    investmentsModel={pageModel.investmentsModel}
                  />

                  <PaginationModelBuilder
                    multiModel={pageModel.investmentsModel}
                    pageSize={pageModel.investmentsPageSize}
                  />
                </div>
                <div>
                  <StyledStickyRightWidgetsWrapper>
                    <HelpWidget />
                    <GuideWidget digitalGoldTitle />
                    <MyPreferencesWidget />
                  </StyledStickyRightWidgetsWrapper>
                </div>
              </StyledGrid>
            </StyledInnerWrap>
          );
        }}
      </PageModelBuilder>
    </StyledInvestmentsListWrapper>
  );
};
