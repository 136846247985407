import styled from "styled-components";

import {
  colorBackgroundGrey,
  colorMainDark,
  fontFamilyMontserrat,
} from "../../../shared/styles/generic";

export const StyledInfoWidgetWrapper = styled.div`
  display: flex;
  font-family: ${fontFamilyMontserrat};
  color: ${colorMainDark};
  background-color: ${colorBackgroundGrey};
  padding: 1.125rem 1.25rem;
  justify-content: space-between;

  &:not(:first-of-type) {
    margin-top: 1.25rem;
  }
`;

export const StyledInfoWidgetTitle = styled.h4`
  font-size: 1.125rem;
  font-weight: 600;
`;

export const StyledInfoWidgetDescription = styled.div`
  font-size: 0.875rem;
`;

export const StyledInfoRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const StyledInfoIconWrapper = styled.div`
  height: 3.25rem;
  width: 3.25rem;

  background-color: #fff;
  padding: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: scale(1.1);
  }
`;
