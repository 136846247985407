import { useEffect, useState } from "react";
import { getInvestmentsSum } from "../usecases";
import { useRequest } from "./useRequest";
import { GetInvestmentsSumParams } from "../../domain/features/investment/entities";

/**
 *
 * @returns investmentsSum number, isInvestmentSumFetching boolean, investmentSumError error and withPortfolio boolean
 *
 * @example
 *  const {requestInvestmentsSum, isInvestmentSumFetching, investmentSumError} = useInvestmentsSum()
 */

export const useInvestmentsSum = ({ currency }: GetInvestmentsSumParams) => {
  const {
    request: requestInvestmentsSum,
    isFetching: isInvestmentSumFetching,
    error: investmentSumError,
  } = useRequest(getInvestmentsSum);

  const [investmentsSum, setInvestmentsSum] = useState(0);

  useEffect(() => {
    requestInvestmentsSum({ currency }).then(setInvestmentsSum);

    //cleanup
    return () => {
      setInvestmentsSum(0);
    };
  }, [currency, requestInvestmentsSum]);

  return {
    investmentsSum,
    isInvestmentSumFetching,
    investmentSumError,
    withPortfolio: investmentsSum > 0,
  };
};
