import React, { useState } from "react";

// import { useTranslation } from "react-i18next";

import {
  TextFieldModel,
  TextFieldModelBuilder,
} from "@portittech/portit-react-common-components";

import {
  StyledPasswordWrapper,
  // StyledSettingsEditLink,
  // StyledSettingsEditLinkWrapper,
  StyledSettingsInnerBlockRow,
} from "../settingsPage.styles";

interface SettingsInputCardProps {
  saveModel: Function;
  fieldModel: TextFieldModel;
  fieldLabel: string;
  isPassword?: boolean;
}

export const SettingsInputCard = ({
  // saveModel,
  fieldModel,
  fieldLabel,
  isPassword,
}: SettingsInputCardProps) => {
  const [
    isEditable,
    // setIsEditable
  ] = useState<boolean>(false);

  // const { t } = useTranslation(["settingsPage"]);

  // const onClick = () => {
  //   if (isEditable) {
  //     saveModel();
  //   }
  //   setIsEditable(!isEditable);
  // };

  return (
    <StyledSettingsInnerBlockRow>
      <p>{fieldLabel}</p>
      {isPassword ? (
        <>
          {isEditable ? (
            <TextFieldModelBuilder
              textFieldModel={fieldModel}
              isDisabled={!isEditable}
            />
          ) : (
            <StyledPasswordWrapper>
              {fieldModel?.value
                .split("")
                .map((char) => "*")
                .join("")}
            </StyledPasswordWrapper>
          )}
        </>
      ) : (
        <TextFieldModelBuilder
          textFieldModel={fieldModel}
          isDisabled={!isEditable}
        />
      )}
      {/* <StyledSettingsEditLinkWrapper>
        <StyledSettingsEditLink onClick={onClick}>
          {isEditable ? t("save_button_label") : t("edit_button_label")}
        </StyledSettingsEditLink>
      </StyledSettingsEditLinkWrapper> */}
    </StyledSettingsInnerBlockRow>
  );
};
