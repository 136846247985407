import React from "react";

import { useTranslation } from "react-i18next";
import {
  getSubT,
  LoadingComponent,
} from "@portittech/portit-react-common-components";

import {
  StyledBigImage,
  StyledUnderDueDiligenceAndPreMarketing,
  StyledBigImgWidgetMainWrapper,
  StyledBigImgWidgetWrapper,
  StyledPlaceholderBigImage,
} from "./bigImg.styles";
import { useImage } from "../../../../shared/hooks/useImage";
import { useOpportunityBanner } from "../../../../shared/hooks/useOpportunityBanner";
import { OpportunityStep } from "../../../../domain/features/opportunities/entities";

interface OpportunityBigImgInterface {
  bigImageId: number;
  step: OpportunityStep;
}

export const OpportunityBigImgBanner = ({
  bigImageId,
  step,
}: OpportunityBigImgInterface) => {
  const { t } = useTranslation(["opportunityPage"]);
  const tc = getSubT(t, "opportunity_big_img_widget");

  const [bigImgSrc, isFetching] = useImage(bigImageId);
  const bannerInfoImg = useOpportunityBanner(step);

  return (
    <StyledBigImgWidgetMainWrapper>
      <StyledBigImgWidgetWrapper>
        {/*Show opportunity status/step banner if exists */}

        {bannerInfoImg ? (
          <StyledUnderDueDiligenceAndPreMarketing src={bannerInfoImg} />
        ) : null}

        {bigImgSrc ? (
          <StyledBigImage src={bigImgSrc} />
        ) : (
          <StyledPlaceholderBigImage>
            {isFetching ? <LoadingComponent /> : tc("no_img_placeholder")}
          </StyledPlaceholderBigImage>
        )}
      </StyledBigImgWidgetWrapper>
    </StyledBigImgWidgetMainWrapper>
  );
};
