import React from "react";

import { Model } from "@portittech/portit-react-common-components";

import { ElviriaPages } from "../../../shared/pages";

import { ReactComponent as DashboardIcon } from "../../../assets/icons/dashboard.svg";
import { ReactComponent as PortfolioIcon } from "../../../assets/icons/portfolio.svg";
import { ReactComponent as OpportunitiesIcon } from "../../../assets/icons/opportunities.svg";
import { ReactComponent as TradeIcon } from "../../../assets/icons/trades.svg";
import { ReactComponent as MyAccountIcon } from "../../../assets/icons/my-account.svg";
import { ReactComponent as PreferencesIcon } from "../../../assets/icons/preferences.svg";
import { ReactComponent as SettingIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as HelpIcon } from "../../../assets/icons/help.svg";
import { ReactComponent as InvestmentsIcon } from "../../../assets/icons/investments.svg";

const navItems: Array<NavigationItem> = [
  {
    link: ElviriaPages.DASHBOARD,
    icon: <DashboardIcon />,
    description: "dashboard",
    status: "normal",
    type: "main",
  },
  {
    link: ElviriaPages.PORTFOLIO,
    icon: <PortfolioIcon />,
    description: "portfolio",
    status: "normal",
    type: "main",
  },
  {
    link: ElviriaPages.OPPORTUNITIES,
    icon: <OpportunitiesIcon />,
    description: "opportunities",
    status: "normal",
    type: "main",
  },
  {
    link: ElviriaPages.INVESTMENTS,
    icon: <InvestmentsIcon />,
    description: "investments",
    status: "normal",
    type: "main",
  },
  {
    link: ElviriaPages.EXCHANGE,
    icon: <TradeIcon />,
    description: "exchange",
    status: "normal",
    type: "main",
  },
  {
    link: ElviriaPages.ACCOUNT,
    icon: <MyAccountIcon />,
    description: "my_account",
    status: "normal",
    type: "secondary",
  },
  {
    link: ElviriaPages.PREFERENCES_DASHBOARD,
    icon: <PreferencesIcon />,
    description: "preferences",
    status: "normal",
    type: "secondary",
  },
  {
    link: ElviriaPages.SETTINGS,
    icon: <SettingIcon />,
    description: "settings",
    status: "normal",
    type: "secondary",
  },
  {
    link: ElviriaPages.HELP,
    icon: <HelpIcon />,
    description: "help",
    status: "normal",
    type: "secondary",
  },
];

export interface NavigationItem {
  link: string;
  icon: React.ReactNode;
  description: string;
  status: "normal" | "selected";
  type: "main" | "secondary";
}

export class NavigationModel extends Model {
  private _items: Array<NavigationItem>;

  constructor(currentPath: string) {
    super();
    this._items = navItems;
    const current = navItems.find((it) => it.link === currentPath);
    // const currentPage = location.hash.replace("#/", "");
    // default set to dashboard
    if (current) this._items = this.copyItemsWith(current);
  }

  get items(): Array<NavigationItem> {
    return this._items;
  }

  setCurrentItem(newItem: NavigationItem) {
    this._items = this.copyItemsWith(newItem);
    this.notifyChanges();
  }

  private copyItemsWith(selected: NavigationItem): Array<NavigationItem> {
    return this._items.map((item) => {
      return {
        ...item,
        status: item.link === selected.link ? "selected" : "normal",
      };
    });
  }
}
