import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Deposit } from "./deposit.component";

import {
  FundingInfoEntity,
  getSubT,
  LoadingComponent,
} from "@portittech/portit-react-common-components";
import { SquareButton } from "../../shared/styles/button";
import {
  StyledDepositCloseBtnWrapper,
  StyledNoAccountTitle,
} from "./deposit.styles";

export const useDeposit = (fundingInfos: Array<FundingInfoEntity>) => {
  const SwalWithReact = withReactContent(Swal);
  const { t: pt } = useTranslation(["dashboard"]);
  const t = getSubT(pt, "wallets.funding_info");

  const withAccount = {
    html: (
      <Suspense fallback={LoadingComponent}>
        <Deposit fundingInfos={fundingInfos} swalWithReact={SwalWithReact} />
      </Suspense>
    ),

    showConfirmButton: false,
    width: "40.625rem",
    showClass: {
      backdrop: "swal2-noanimation", // disable backdrop animation
      popup: "", // disable popup animation
      icon: "", // disable icon animation
    },
  };

  const withoutAccount = {
    ...withAccount,
    html: (
      <div>
        <StyledNoAccountTitle>{t("no_account")}</StyledNoAccountTitle>
        <StyledDepositCloseBtnWrapper>
          <SquareButton onClick={SwalWithReact.clickConfirm}>
            {t("close_button")}
          </SquareButton>
        </StyledDepositCloseBtnWrapper>
      </div>
    ),
    icon: "info",
  };

  const fireProps = fundingInfos ? withAccount : withoutAccount;

  const showFundingInfo = () => {
    SwalWithReact.fire(fireProps);
  };

  return [showFundingInfo];
};
