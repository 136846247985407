import styled from "styled-components";
import {
  fontFamilyMontserrat,
  colorMain,
  fontFamilyEncodeSansExpanded,
  colorGreyishWhite,
  colorBlue,
} from "../../../../shared/styles/generic";

import { cubicBezier } from "../../../../shared/styles/publicPage.style";

interface StyledDashBoardHeaderProps {
  withMarginBottom?: boolean;
}

export const StyledDashboardHeader = styled.div<StyledDashBoardHeaderProps>`
  display: flex;
  padding: 1.875rem 4vw 0;
  margin-bottom: ${({ withMarginBottom }) => (withMarginBottom ? "1rem" : 0)};
`;

export const StyledOverview = styled.div`
  flex: auto;
  flex-direction: column;
`;
export const StyledOverviewTitle = styled.div`
  font-family: ${fontFamilyEncodeSansExpanded};
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.5rem;
  color: ${colorMain};
  margin-bottom: 0.625rem;
`;

export const StyledApplicationsTitle = styled(StyledOverviewTitle)`
  padding-left: 1.3125rem;
  margin-top: 3.75rem;
  font-size: 1.375rem;
  line-height: 110%;
`;

export const StyledProfileList = styled.div`
  p {
    display: flex;
    align-content: center;
  }
`;

export const StyledDashboardHeaderSubTitle = styled.div`
  font-family: ${fontFamilyMontserrat};
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  margin-bottom: 3rem;

  color: #37383c;
`;

export const StyledBehalfDashboard = styled.div`
  flex: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const StyledAccountText = styled.div`
  flex: auto;
  flex-direction: column;
  margin-right: 2.5rem;
`;

export const StyledBehalf = styled.div`
  letter-spacing: 1px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.375rem;
  text-align: right;
  color: #929292;
`;

export const StyledCompanyTitle = styled.div`
  font-family: ${fontFamilyMontserrat};
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.125rem;
  text-align: right;
  letter-spacing: 0.0625rem;
  color: #37383c;
`;

export const StyledAccountIcon = styled.div`
  cursor: pointer;
  margin-left: 1.25rem;
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledProfilePhoto = styled.img`
  display: block;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 1.25rem;
`;

export const StyledNotification = styled(StyledAccountIcon)`
  background: #d5d5d5;
  margin-left: 1.1rem;
`;

export const StyledCustomerInitials = styled.div`
  cursor: pointer;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2.4375rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.125rem;
  color: #ffffff;
`;

export const StyledDashboardHeaderDialog = styled.div`
  .modal {
    padding: 0 3.125rem;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    visibility: hidden;
    pointer-events: none;
    perspective: 62.5rem;

    .modal-content {
      transition: opacity 0.3s ${cubicBezier}, transform 0.3s ${cubicBezier};
      opacity: 0;
      transform: rotateY(-15deg) translateX(5vw) translateZ(-5vh);
    }

    &.visible {
      visibility: initial;
      pointer-events: auto;

      .modal-content {
        opacity: 1;
        transform: none;
      }
    }
  }

  .modal-content {
    background-color: #fafafa;
    box-shadow: 1px 1px 30px #00000059;
    border: 1px solid #888;
    max-width: 53.125rem;
    height: 90vh;
    margin: 5vh auto;
    overflow: hidden;
    border-radius: 5px;

    .ant-col-1 {
      display: flex;
      justify-content: start;
      align-items: center;

      img {
        height: 0.625rem;
      }
    }
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 1.75rem;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .ant-btn {
    padding-left: 1.25rem;
    box-shadow: none;
    background: transparent;

    &:focus {
      color: ${colorMain};
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li div {
      white-space: nowrap;
    }
  }
`;
export const StyledDialogOverview = styled.div`
  display: flex;
  height: 100%;
`;

export const StyledProfileDetails = styled.div`
  flex: auto;
  flex-direction: column;
  display: flex;
  background: ${colorGreyishWhite};
  padding: 3%;
  padding-bottom: calc(3% + 50px);
  width: 65%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledUserDetails = styled.div`
  flex: auto;
  flex-direction: column;
  display: flex;
  padding: 0.75rem 0;
  margin-top: 2.8125rem;
  overflow: hidden;

  > div {
    overflow: auto;
    flex: 1;
  }
`;

export const StyledCloseIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 2.2rem;
  right: 2.2rem;
  z-index: 2;
  text-decoration: underline;
  font-family: ${fontFamilyMontserrat};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4rem;
  color: ${colorBlue};
`;

export const StyledOpenMatter = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  > div {
    cursor: pointer;
    padding: 1.25rem;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ${cubicBezier};
    border-radius: 5px;

    &:hover {
      background: #fafafa;
    }
    p {
      margin-bottom: 0;
    }
    span {
      font-family: ${fontFamilyMontserrat};
      font-size: 0.875rem;
      line-height: 0.875rem;
      text-align: right;
      color: ${colorMain};
      text-transform: lowercase;
    }
  }
`;

export const StyledCompanyName = styled.span`
  display: block;
  text-transform: none;
  text-align: left;
  margin-top: 0.3rem;
  font-size: 0.625rem;
`;

export const StyledNotificationIcon = styled.img`
  width: 1.4rem;
  height: 1.875rem;
`;

export const StyledSearch = styled.img`
  width: 1.375rem;
  height: 1.625rem;
  margin: 0.31rem 1.25rem 0 0;
  cursor: pointer;
`;
