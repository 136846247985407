import {
  Context,
  LoadingComponent,
  useCubitBuilder,
  useDataCubitListener,
} from "@portittech/portit-react-common-components";
import React, { useContext } from "react";
import { Redirect, RouteProps } from "react-router-dom";
import { nonLoadingStatuses } from "../shared/constants";
import { ElviriaPages } from "../shared/pages";
import { StyledLoaderWrapper } from "../shared/styles/page.styles";
import { SecuredRoute } from "./SecuredRoute";

// Checks if user has sent the KYC already or he has no product with description "investment"
export const KycSecuredRoute: React.FC<RouteProps> = (props) => {
  const { shopCubit } = useContext(Context);

  const state = useCubitBuilder({ cubit: shopCubit });
  useDataCubitListener({
    dataCubit: shopCubit,
    canRead: true,
  });

  if (!state || !nonLoadingStatuses.includes(state.status)) {
    return (
      <StyledLoaderWrapper>
        <LoadingComponent />
      </StyledLoaderWrapper>
    );
  }

  const investmentContract = state.data.find(
    (it) => it.contract.product.description === "Investment"
  );

  const accountContract = state.data.find(
    (it) => it.contract.product.description === "Account"
  );

  const hasPendingInvestmentContract =
    investmentContract?.contract?.status === "PENDING";
  const hasPendingAccountContract =
    accountContract?.contract?.status === "PENDING";

  //  individual -> fill kyc . company -> pending page
  if (hasPendingInvestmentContract || hasPendingAccountContract) {
    // for companies BO operator should do KYC
    return <Redirect to={ElviriaPages.PENDING_VERIFICATION} />;
  }

  return <SecuredRoute {...props} />;
};
