import React from "react";
import { useTranslation } from "react-i18next";

import { LoadingComponent } from "@portittech/portit-react-common-components";

import { HelpWidget } from "../widgets/helpWidget";
import { MyPreferencesWidget } from "../widgets/myPreferencesWidget";
import { GuideWidget } from "../widgets/guideWidget";

import {
  StyledAdminInnerPageTitle,
  StyledAdminInnerPageWrapper,
  StyledGrid,
} from "../../shared/styles/page.styles";
import { EmptyPortfolio } from "./empty-portfolio/emptyPorfolio.component";
import { StyledFullWidthGridColumn } from "../widgets/common.styles";

import { OpportunitiesWidget } from "../widgets/opportunities-widget/opportunitiesWidget.component";
import { AccountBalanceWidget } from "../widgets/account-balance-widget/accountBalanceWidget.component";
import { StyledDashboardAccountBalanceAndHistoricWrapper } from "../dashboard/dashboard.styles";
import { ExistingHistoricalTradeWidget } from "../widgets/existing-historical-trade-widget/existingHistoricalTradeWidget.component";
import { OpportunitiesExplorerWidget } from "../widgets/opportunities-explorer/opportunitiesExplorer.widget";
import { MyPortfolioWidget } from "../widgets/my-portfolio/myPortfolio.component";
import { PortfolioCharts } from "./components/portfolio-charts/portfolioCharts.component";
import { usePortfolio } from "./usePortfolio";

import { useInvestments } from "../investments-page/useInvestments";
import { InvestmentsTabs } from "./components/investments-tabs/investmentsTabs.component";

export const PortfolioPage = () => {
  const { t } = useTranslation(["portfolio"]);
  const {
    doneInvestmentsByOpportunity,
    pendingInvestmentsByOpportunity,
    isFetching,
    investmentsByAssetClass,
  } = usePortfolio();

  const { areInvestmentsFetching, withPortfolio } = useInvestments();

  if (isFetching || areInvestmentsFetching) return <LoadingComponent />;

  return (
    <StyledAdminInnerPageWrapper>
      <StyledAdminInnerPageTitle>{t("page_title")}</StyledAdminInnerPageTitle>
      <StyledGrid>
        {withPortfolio ? (
          <>
            <StyledDashboardAccountBalanceAndHistoricWrapper>
              <MyPortfolioWidget />
              <ExistingHistoricalTradeWidget />
            </StyledDashboardAccountBalanceAndHistoricWrapper>
            <OpportunitiesExplorerWidget />

            <div>
              <PortfolioCharts
                investmentsByAssetClass={investmentsByAssetClass}
                investmentsByOpportunity={doneInvestmentsByOpportunity}
              />
            </div>

            <div>
              <HelpWidget />
              <GuideWidget digitalGoldTitle />
              <MyPreferencesWidget />
            </div>
            <InvestmentsTabs
              doneInvestmentsByOpportunity={doneInvestmentsByOpportunity}
              pendingInvestmentsByOpportunity={pendingInvestmentsByOpportunity}
            />
          </>
        ) : (
          <>
            <div>
              <AccountBalanceWidget fundingInfoWidget />
              <EmptyPortfolio />
            </div>

            <div>
              <HelpWidget />
              <GuideWidget digitalGoldTitle />
              <MyPreferencesWidget />
            </div>
            <StyledFullWidthGridColumn>
              <OpportunitiesWidget
                widgetTitle={t("opportunities")}
                withViewAllButton
              />
            </StyledFullWidthGridColumn>
          </>
        )}
      </StyledGrid>
    </StyledAdminInnerPageWrapper>
  );
};
