import styled from "styled-components";

import { fontFamilyMontserrat } from "../../shared/styles/generic";
import { StyledSmallWidgetWrapper } from "../widgets/common.styles";

export const WidgetsWrapper = styled.div`
  ${StyledSmallWidgetWrapper} {
    :first-child,
    :nth-child(2) {
      margin-bottom: 2.5rem;
    }
  }
`;

export const StyledBanner = styled.div`
  box-shadow: -5px 5px 40px rgba(0, 0, 0, 0.07);
  padding: 2rem 1.75rem;
  margin-bottom: 3.25rem;
`;

export const StyledRow = styled.div`
  display: flex;
`;

export const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2rem;
  font-family: ${fontFamilyMontserrat};
  margin-right: auto;
`;

export const StyledMessage = styled.div`
  font-size: 1rem;
  line-height: 2.5rem;
`;
