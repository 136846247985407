import React from "react";

import {
  FieldDecoration,
  NumberFieldModel,
  NumberFieldModelBuilder,
  useModelChanges,
} from "@portittech/portit-react-common-components";
import { Button } from "antd";
import { StyledIncrementInputFieldWrapper } from "./IncrementField.style";

interface IncrementFieldProps {
  incrementFieldModel: NumberFieldModel;
}

export const IncrementFieldModelBuilder = ({
  incrementFieldModel,
}: IncrementFieldProps) => {
  useModelChanges(incrementFieldModel);

  return (
    <StyledIncrementInputFieldWrapper>
      <Button
        size="large"
        type="link"
        onClick={() =>
          incrementFieldModel.updateValue(incrementFieldModel.value - 1)
        }
      >
        -
      </Button>
      <NumberFieldModelBuilder
        numberFieldModel={incrementFieldModel}
        decoration={FieldDecoration.custom({})}
        isEditable={true}
      />
      <Button
        size="large"
        type="link"
        onClick={() =>
          incrementFieldModel.updateValue(incrementFieldModel.value + 1)
        }
      >
        +
      </Button>
    </StyledIncrementInputFieldWrapper>
  );
};
