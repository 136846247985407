import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { ElviriaApp } from "./ElviriaApp";
import { CalendarStyle } from "./shared/styles/calendar.style";
import { Integrations } from "@sentry/tracing";
import { BrowserOptions } from "@sentry/browser/dist/backend";
import {
  Config,
  FailureResult,
  LoadingComponent,
} from "@portittech/portit-react-common-components";
import * as Sentry from "@sentry/react";
import { StyledLoaderWrapper } from "./shared/styles/page.styles";
import { ElviriaStore } from "./components/elviriaStore";

const isSingleEndpoint = process.env.REACT_APP_SINGLE_ENDPOINT === "true";

const sentryConfig = {
  dsn: `${process.env.REACT_APP_SENTRY_DNS}`,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
} as BrowserOptions;

if (isSingleEndpoint) {
  sentryConfig.release = process.env.REACT_APP_VERSION;
}
if (!Config.isTestMode) {
  Sentry.init(sentryConfig);
}

class ProfiledApp extends React.Component {
  render() {
    return (
      <ElviriaStore>
        <ElviriaApp />
      </ElviriaStore>
    );
  }
}

// Disable sentry when user is developer
const AppWithProfiler = Sentry.withProfiler(ProfiledApp, {
  disabled: Config.isTestMode,
});

ReactDOM.render(
  <Suspense
    fallback={
      <StyledLoaderWrapper>
        <LoadingComponent />
      </StyledLoaderWrapper>
    }
  >
    <React.StrictMode>
      <CalendarStyle>
        <Sentry.ErrorBoundary
          fallback={() => <FailureResult failures={[]} />}
          showDialog={true}
        >
          <AppWithProfiler />
        </Sentry.ErrorBoundary>
      </CalendarStyle>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
