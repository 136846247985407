import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  getSubT,
  LoadingComponent,
} from "@portittech/portit-react-common-components";

import { ElviriaPages } from "../../../shared/pages";

import { SquareButton } from "../../../shared/styles/button";
import { OpportunityResponse } from "../../../domain/features/opportunities/entities";

import { useImage } from "../../../shared/hooks/useImage";
import { AddToWatchListButton } from "./addToWatchListButton";

import { StyledDoubleButtonsWrapper } from "../common.styles";
import {
  StyledAssetClass,
  StyledAssetName,
  StyledIInterestedInvestmentCardWrapper,
  StyledImgPlaceholder,
  StyledInterestedInvestmentCardBody,
  StyledInterestedInvestmentCardContentWrapper,
  StyledInterestedInvestmentCardDescription,
  StyledInterestedInvestmentCardImage,
} from "./interestedInvestmentWidget.styles";
import { StyledUnderDueDiligenceAndPreMarketing } from "../../opportunity-page/components/bigImg/bigImg.styles";

import noImage from "../../../assets/no-image.png";
import { useOpportunityBanner } from "../../../shared/hooks/useOpportunityBanner";

export const InterestedInvestmentCard = ({
  id,
  assetClass,
  name,
  mediumImageId,
  shortDescription,
  step,
  status,
}: OpportunityResponse) => {
  const history = useHistory();
  const { t: tApi } = useTranslation(["apiText"]);
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "interested_investment_widget");

  const [imgSrc, isFetching] = useImage(mediumImageId);

  const bannerInfoImg = useOpportunityBanner(step);

  const onViewClickHandler = () => {
    history.push(`${ElviriaPages.OPPORTUNITIES}/${id}`);
  };

  const onInvestClickHandler = () => {
    history.push(`${ElviriaPages.OPPORTUNITIES}/${id}/invest`);
  };

  return (
    <StyledIInterestedInvestmentCardWrapper>
      {isFetching ? (
        <StyledImgPlaceholder>
          <LoadingComponent />
        </StyledImgPlaceholder>
      ) : (
        <>
          <StyledInterestedInvestmentCardImage
            src={imgSrc === undefined ? noImage : imgSrc}
          />

          {/*Show opportunity status/step banner if exists */}
          {bannerInfoImg ? (
            <StyledUnderDueDiligenceAndPreMarketing src={bannerInfoImg} />
          ) : null}
        </>
      )}

      <StyledInterestedInvestmentCardBody>
        <StyledInterestedInvestmentCardContentWrapper>
          <StyledAssetClass>{tApi(assetClass)}</StyledAssetClass>
          <StyledAssetName> {name}</StyledAssetName>
          <StyledInterestedInvestmentCardDescription>
            {shortDescription}
          </StyledInterestedInvestmentCardDescription>
        </StyledInterestedInvestmentCardContentWrapper>
        <div>
          <StyledDoubleButtonsWrapper forInterestInvestmentsCard>
            <SquareButton onClick={onViewClickHandler}>
              {tc("view_link")}
            </SquareButton>
            <SquareButton
              disabled={status !== "Active"}
              onClick={onInvestClickHandler}
            >
              {tc("invest_link")}
            </SquareButton>
          </StyledDoubleButtonsWrapper>

          <AddToWatchListButton opportunityId={id} />
        </div>
      </StyledInterestedInvestmentCardBody>
    </StyledIInterestedInvestmentCardWrapper>
  );
};
