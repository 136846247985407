import React from "react";
import { useTranslation } from "react-i18next";

import { getSubT } from "@portittech/portit-react-common-components";

import { ReactComponent as HelpIcon } from "../../assets/icons/help.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/email.svg";

import { InfoWidget } from "../widgets/info-widget/infoWidget.component";
import { MyPreferencesWidget } from "../widgets/myPreferencesWidget";
import { GuideWidget } from "../widgets/guideWidget";

import { colorMainDark } from "../../shared/styles/generic";
import {
  StyledExternalLink,
  StyledStickyRightWidgetsWrapper,
} from "../widgets/common.styles";

import {
  StyledHelpPageWrapper,
  StyledHelpPageContent,
  StyledAdminInnerPageHeading,
  StyledContactUsWrapper,
  StyledContactUsTitle,
  StyledContactUsSubTitle,
  StyledTitleLinks,
  StyledContactUsDetails,
  StyledContactUsInfo,
} from "./helpPage.styles";

export const HelpPage = () => {
  const { t } = useTranslation(["helpPage"]);
  const tc = getSubT(t, "info_widget");

  return (
    <StyledHelpPageWrapper>
      <StyledAdminInnerPageHeading>{t("heading")}</StyledAdminInnerPageHeading>
      <StyledHelpPageContent>
        <StyledContactUsWrapper>
          <StyledContactUsSubTitle>{t("heading_text")}</StyledContactUsSubTitle>
          <StyledContactUsTitle>{t("title")}</StyledContactUsTitle>
          <StyledContactUsDetails>
            <StyledContactUsInfo>
              <PhoneIcon fill={colorMainDark} stroke={colorMainDark} />
              <StyledExternalLink href={`tel:${t("phone_number")}`}>
                {t("phone_number")}
              </StyledExternalLink>
            </StyledContactUsInfo>
            <StyledContactUsInfo>
              <EmailIcon fill={colorMainDark} stroke={colorMainDark} />
              <StyledExternalLink href={`mailto:${t("email")}`}>
                {t("email")}
              </StyledExternalLink>
            </StyledContactUsInfo>
          </StyledContactUsDetails>
        </StyledContactUsWrapper>

        <div>
          <StyledStickyRightWidgetsWrapper>
            <InfoWidget
              title={tc("faq_link")}
              icon={<HelpIcon fill={colorMainDark} stroke={colorMainDark} />}
            >
              <StyledExternalLink
                href="https://www.elviria.ch/post/note-1-crypto-wallets"
                target="_blank"
              ></StyledExternalLink>
              <StyledTitleLinks>
                <StyledExternalLink
                  href={process.env.REACT_APP_FAQ_LINK}
                  target="_blank"
                >
                  {tc("read_all")}
                </StyledExternalLink>
              </StyledTitleLinks>
              <StyledTitleLinks>
                <StyledExternalLink
                  href={process.env.REACT_APP_FAQ_LINK}
                  target="_blank"
                >
                  {tc("subtitle_one")}
                </StyledExternalLink>
              </StyledTitleLinks>
              <StyledTitleLinks>
                <StyledExternalLink
                  href={process.env.REACT_APP_FAQ_LINK}
                  target="_blank"
                >
                  {tc("subtitle_two")}
                </StyledExternalLink>
              </StyledTitleLinks>
              <StyledTitleLinks>
                <StyledExternalLink
                  href={process.env.REACT_APP_FAQ_LINK}
                  target="_blank"
                >
                  {tc("subtitle_three")}
                </StyledExternalLink>
              </StyledTitleLinks>
            </InfoWidget>

            <GuideWidget digitalGoldTitle />
            <MyPreferencesWidget />
          </StyledStickyRightWidgetsWrapper>
        </div>
      </StyledHelpPageContent>
    </StyledHelpPageWrapper>
  );
};
