import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { InvestmentResponse } from "../../../../domain/features/investment/entities";
import { formatCurrencyNumber } from "../../../../shared/helpers/formatCurrencyNumber";
import { Logo } from "../../../logo/logo.component";

import {
  StyledFirstColumn,
  StyledInvestmentCardWrapper,
  StyledTitle,
  StyledTitleWrapper,
  StyledDate,
  StyledDesc,
  StyledTotalPrice,
} from "./investmentCard.styles";

export const InvestmentCard = ({
  currency,
  totalPrice,
  tokenPrice,
  date,
  noToken,
  opportunity: { logoImageId, name },
}: InvestmentResponse) => {

  const { t } = useTranslation(["investment"]);

  return (
    <StyledInvestmentCardWrapper>
      <StyledFirstColumn>
        <Logo logoId={logoImageId} />
        <StyledTitleWrapper>
          <StyledTitle>{name}</StyledTitle>
          <StyledDate> {moment(date).format("DD/MM/YYYY")}</StyledDate>
        </StyledTitleWrapper>
      </StyledFirstColumn>
      <div>
        <StyledTitle>{noToken}</StyledTitle>
        <StyledDesc>{t("nr_tokens")}</StyledDesc>
      </div>
      <div>
        <StyledTitle>{formatCurrencyNumber(tokenPrice, currency)}</StyledTitle>
        <StyledDesc>{t("token_price")}</StyledDesc>
      </div>
      <StyledTotalPrice>{formatCurrencyNumber(totalPrice, currency)}</StyledTotalPrice>
    </StyledInvestmentCardWrapper>
  );
};
