import React from "react";
import styled, { css, keyframes } from "styled-components";
import { devices } from "../../components/config/devices";
import logo from "../../assets/elviria_logo_white.svg";
import sideLogo from "../../assets/elviria.png";
import {
  colorBlue,
  colorMain,
  colorMainWhite,
  fontFamilyMontserrat,
} from "./generic";

export const up = keyframes`
  from {
    opacity: 0.5;
    transform: translateY(5%);
  }
`;
const left = keyframes`
  from {
    opacity: 0;
    transform: translateX(5%);
  }
`;
// animation to slide phones from the right in welcome screen
const right = keyframes`
  from {
    opacity: 0;
    transform: translateX(-25%);
  }
`;

export const cubicBezier = "cubic-bezier(0.62, 0.28, 0.23, 0.99)";

// animation to slide in the home page from left
const slideInLeft = keyframes`
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`;

// animation to slide out the home page to the left
const slideOutLeft = keyframes`
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
`;

const leftAnimation = css`
  ${left} 0.4s ${cubicBezier};
`;

const rightAnimation = css`
  ${right} 0.9s ${cubicBezier};
`;

// const upAnimation = css`
//   ${up} 0.4s ${cubicBezier};
// `;
const slideInLeftAnimation = css`
  ${slideInLeft} 0.2s forwards
`;
const slideOutLeftAnimation = css`
  ${slideOutLeft} 0.2s forwards
`;

const Logo = styled.div`
  animation: ${leftAnimation};

  @media (max-width: 824px) {
    img {
      width: 55%;
    }
  }
`;

const CustomLogo = styled(Logo)`
  padding-top: 5rem;

  @media (max-width: 824px) {
    padding-top: 2rem;
  }
`;

export const PublicPageStyle = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: ${colorBlue};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &:after,
  &:before {
    position: absolute;
    bottom: 0;
    content: "";
    background-size: cover;
  }

  &:before {
    left: 0;
    width: 45rem;
    height: 43.375rem;

    @media ${devices.mobile} {
      height: 9.375rem;
      width: 18.75rem;
    }
  }

  &.page-enter {
    animation: ${slideInLeftAnimation};
  }
  &.page-exit {
    animation: ${slideOutLeftAnimation};
  }

  // styles for ForgotPasswordPage

  .forgot-password-page__wrapper {
    max-width: 25rem;

    input {
      border: 1px solid ${colorMain};
      border-radius: 1px;
      background: ${colorBlue};
      color: ${colorMainWhite};
    }

    label.input-field-label,
    input ~ label {
      color: ${colorMainWhite};
    }

    label[data-value="true"],
    label[data-focus="true"],
    input:focus ~ label,
    input[data-has-value="true"] ~ label,
    input:not(:placeholder-shown) ~ label {
      color: ${colorMain};
      letter-spacing: 0.05rem;
      transform: scale(0.6) translate(2px, -35px);
      background-color: ${colorBlue};
      padding: 0.0625rem 0.1875rem;
    }

    .forgot-password-page__title {
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: ${colorMainWhite};
      font-family: ${fontFamilyMontserrat};
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.25rem;
    }

    .forgot-password-page__subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 1.25rem;
      line-height: 1.875rem;
      color: ${colorMainWhite};
      font-family: ${fontFamilyMontserrat};
      margin-bottom: 4vh;
    }

    .rounded-btn {
      width: 40%;
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      border-radius: 0;
    }

    .forgot-password-page__question {
      color: ${colorMainWhite};
    }
  }

  // all browser autofill inputs should be white
  input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
  }
`;

const EliviraSideLogo = styled.div`
  background-image: url(${sideLogo});
  position: absolute;
  height: 100%;
  width: 100%;
  animation: ${rightAnimation};
  background-repeat: no-repeat;
  background-position: bottom;
  @media ${devices.mobile} {
    display: none;
  }
`;

const PublicGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.75fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  height: 100%;

  @media ${devices.mobile} {
    grid-template-columns: 1fr;
  }
`;

const Content = styled.div`
  grid-column-start: 2;
  grid-column-end: 2;

  @media ${devices.tablet} {
    padding: 0 6%;
  }

  @media ${devices.mobile} {
    padding: 0 10%;
    grid-column-start: 1;
    grid-column-end: 2;
  }
`;

const Aside = styled.div`
  grid-column-start: 1;
  grid-column-end: 1;
  perspective: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 824px) {
    display: none;
  }
`;

const Spacer = styled.div`
  margin: 2vh 5vw 2vh 0;

  @media ${devices.tablet} {
    margin: 2vh;
  }

  @media ${devices.mobile} {
    margin: 0;
  }
`;

const PublicPageStyleNoBottomLeft = styled(PublicPageStyle)`
  &:before {
    display: none;
  }
`;

const PublicCenterStyle = styled.div`
  margin: 5vh 15vw;
  overflow: auto;
  height: calc(100% - 10vh);
  img {
    margin-bottom: 2vh;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`;

export const FullWidthPublicPage: React.FC = (props) => {
  return (
    <PublicPageStyleNoBottomLeft>
      <PublicCenterStyle>
        <Logo>
          <img src={logo as string} alt="Elviria logo" />
        </Logo>
        {props.children}
      </PublicCenterStyle>
    </PublicPageStyleNoBottomLeft>
  );
};

export const PublicPage: React.FC = (props) => {
  return (
    <PublicPageStyle>
      <PublicGrid>
        <Aside>
          <EliviraSideLogo />
        </Aside>
        <Content>
          <CustomLogo>
            <img src={logo as string} alt={"Elviria"} />
          </CustomLogo>
          <Spacer>{props.children}</Spacer>
        </Content>
      </PublicGrid>
    </PublicPageStyle>
  );
};
