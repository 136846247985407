import {
  AppContext,
  AuthState,
  Context,
  LoadingComponent,
  useCubitBuilder,
  useDataCubitListener,
} from "@portittech/portit-react-common-components";
import { isLeft } from "fp-ts/lib/Either";
import React, { useContext, useEffect } from "react";
import { Redirect, RouteProps, useLocation } from "react-router-dom";
import { useElviriaStore } from "../components/elviriaStore";
import { nonLoadingStatuses } from "../shared/constants";
import { useUserInvestmentsData } from "../shared/hooks/useUserInvestmentsData";
import { ElviriaPages } from "../shared/pages";
import { StyledLoaderWrapper } from "../shared/styles/page.styles";
import { getInvestmentsPreferences } from "../shared/usecases";
import { SecuredRoute } from "./SecuredRoute";

// Checks:
// 1. user is logged in
// 2. user has submitted the kyc
// 3. user has a pending kyc
// 1 && 2 && 3 ->  redirect to pending kyc screen
// otherwise show children
export const EnsureKycSecuredRoute: React.FC<RouteProps> = (props) => {
  const context: AppContext = useContext(Context);

  const { areInvestmentPreferencesCreated } = useElviriaStore();

  // load info about kyc state (sent, pending,...)
  const { sentInvestmentKyc, isLoading } = useUserInvestmentsData();
  const [sentPreferences, setSentPreferences] = React.useState<
    boolean | undefined
  >();
  const location = useLocation();
  const { shopCubit, customerCubit } = useContext(Context);

  const state = useCubitBuilder({ cubit: shopCubit });
  useDataCubitListener({
    dataCubit: shopCubit,
    canRead: true,
  });

  const customerState = useCubitBuilder({ cubit: customerCubit });

  const approvedInvestmentContractStatuses = ["SUCCEEDED", "COMPLETED"];

  useEffect(() => {
    const checkSentPreferences = async () => {
      if (customerState?.data?.profile?.partyId) {
        const preferenceRes = await getInvestmentsPreferences.run({
          partyId: customerState.data.profile.partyId,
        });

        if (isLeft(preferenceRes)) {
          return setSentPreferences(false);
        }
        if (preferenceRes !== null) {
          return setSentPreferences(true);
        }
      }
    };

    if (context.authState !== AuthState.LOGGED_OUT) {
      checkSentPreferences();
    }
  }, [
    customerState?.data?.profile?.partyId,
    context.authState,
    sentPreferences,
    areInvestmentPreferencesCreated,
  ]);

  if (context.authState === AuthState.LOGGED_OUT) {
    return <SecuredRoute {...props} />;
  }

  if (
    !state ||
    !nonLoadingStatuses.includes(state.status) ||
    isLoading ||
    !nonLoadingStatuses.includes(customerState.status) ||
    typeof sentPreferences === undefined
  ) {
    return (
      <StyledLoaderWrapper>
        <LoadingComponent />
      </StyledLoaderWrapper>
    );
  }

  const investmentContract = state.data.find(
    (it) => it.contract.product.description === "Investment"
  );

  const accountContract = state.data.find(
    (it) => it.contract.product.description === "Account"
  );
  const isIndividual = customerState.data.customer.type === "INDIVIDUAL";
  const hasApprovedInvestmentContract =
      approvedInvestmentContractStatuses.includes(investmentContract?.contract?.status);
  const hasPendingInvestmentContract =
    investmentContract?.contract?.status === "PENDING";
  const hasPendingAccountContract =
    accountContract?.contract?.status === "PENDING";

  // if Bo Operator Approved Contract redirect to fill preferences
  if (hasApprovedInvestmentContract && sentPreferences === false) {
    if (location.pathname !== ElviriaPages.PREFERENCES) {
      return <Redirect to={ElviriaPages.PREFERENCES} />;
    }
  }

  //  individual -> fill kyc . company -> pending page
  if (sentInvestmentKyc && sentPreferences === false) {
    if (location.pathname !== ElviriaPages.PREFERENCES) {
      if (
        isIndividual &&
        !hasPendingInvestmentContract &&
        !hasPendingAccountContract
      ) {
        return <Redirect to={ElviriaPages.KYC} />;
      }
      // for companies BO operator should do KYC
      return <Redirect to={ElviriaPages.PENDING_VERIFICATION} />;
    }
  }

  //  fill investments kyc questions
  if (!sentInvestmentKyc && sentPreferences === false) {
    return <Redirect to={ElviriaPages.INVESTMENTS_KYC} />;
  }

  return <SecuredRoute {...props} />;
};
