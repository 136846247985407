import { LoadingComponent } from "@portittech/portit-react-common-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { isEmpty } from "ramda";

import { OpportunityResponse } from "../../domain/features/opportunities/entities";
import { usePageId } from "../../shared/hooks/usePageId";
import { useRequest } from "../../shared/hooks/useRequest";
import { ElviriaPages } from "../../shared/pages";
import { SquareButton } from "../../shared/styles/button";
import {
  StyledAdminInnerPageTitle,
  StyledAdminInnerPageWrapper,
  StyledGrid,
} from "../../shared/styles/page.styles";
import { getSingleOpportunity } from "../../shared/usecases";
import { StyledHeaderCategories } from "../opportunity-page/opportunityPage.style";
import {
  StyledExternalLink,
  StyledSmallWidgetTitle,
} from "../widgets/common.styles";
import { GuideWidget } from "../widgets/guideWidget";
import { HelpWidget } from "../widgets/helpWidget";

import { MyPortfolioWidget } from "../widgets/my-portfolio/myPortfolio.component";
import { MyPreferencesWidget } from "../widgets/myPreferencesWidget";
import { OpportunitiesExplorerWidget } from "../widgets/opportunities-explorer/opportunitiesExplorer.widget";
import { DataRoomFileCard } from "./components/dataRoomFileCard.component";
import { DataRoomInvestmentCard } from "./components/dataRoominvestmentCard.component";
import {
  StyledDataFilesSectionMainWrapper,
  StyledDataFilesSectionOuterWrapper,
  StyledDataRoomInvestButtonWrapper,
  StyledDataRoomRightWidgetsWrapper,
  StyledNoDataWrapper,
} from "./dataRoomPage.styles";

export const DataRoomPage = () => {
  const pageId = usePageId();
  const history = useHistory();
  const { t } = useTranslation(["dataRoomPage"]);
  const { t: tApi } = useTranslation(["apiText"]);

  const [opportunity, setOpportunity] = useState<OpportunityResponse>();

  const { request: requestOpportunity, isFetching: isOpportunityFetching } =
    useRequest(getSingleOpportunity);

  useEffect(() => {
    requestOpportunity({ opportunityId: pageId }).then(setOpportunity);
  }, [pageId, requestOpportunity]);

  if (isOpportunityFetching || !opportunity) return <LoadingComponent />;

  const uniqCategories = opportunity?.datafiles
    ? Array.from(new Set(opportunity.datafiles.map(item => item.fileCategory)))
    : [];

  return (
    <StyledAdminInnerPageWrapper>
      <StyledHeaderCategories isSmall>
        {tApi(opportunity.sector)} | {tApi(opportunity.assetClass)} |{" "}
        {opportunity.name}
      </StyledHeaderCategories>
      <StyledAdminInnerPageTitle>{t("page_title")}</StyledAdminInnerPageTitle>
      <StyledGrid>
        <div>
          <DataRoomInvestmentCard {...opportunity} />
          <>
            {isEmpty(uniqCategories) ? (
              <StyledNoDataWrapper>{t("no_data")}</StyledNoDataWrapper>
            ) : (
              <>
                {uniqCategories.map(category => (
                  <StyledDataFilesSectionOuterWrapper>
                    <StyledSmallWidgetTitle>
                      {tApi(category)}
                    </StyledSmallWidgetTitle>
                    <StyledDataFilesSectionMainWrapper>
                      {opportunity.datafiles
                        .filter(it => it.fileCategory === category)
                        .map(item => (
                          <DataRoomFileCard key={item.id} {...item} />
                        ))}
                    </StyledDataFilesSectionMainWrapper>
                  </StyledDataFilesSectionOuterWrapper>
                ))}

                <StyledDataRoomInvestButtonWrapper>
                  <SquareButton
                    onClick={() =>
                      history.push(
                        `${ElviriaPages.OPPORTUNITIES}/${pageId}/invest`
                      )
                    }
                  >
                    {t("invest_button")}
                  </SquareButton>
                </StyledDataRoomInvestButtonWrapper>
              </>
            )}
          </>

          <StyledExternalLink type="link" onClick={() => history.goBack()}>
            {t("back_link")}
          </StyledExternalLink>
        </div>

        <StyledDataRoomRightWidgetsWrapper>
          <OpportunitiesExplorerWidget />
          <MyPortfolioWidget />
          <div>
            <HelpWidget />
            <GuideWidget />
            <MyPreferencesWidget />
          </div>
        </StyledDataRoomRightWidgetsWrapper>
      </StyledGrid>
    </StyledAdminInnerPageWrapper>
  );
};
