import React, { useContext, Suspense } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import {
  failuresStore,
  Context,
  LoadingComponent,
  SubmitButtonFormModelBuilder,
  useFormModelListener,
  useModelChanges,
  useModelCreatorEffect,
} from "@portittech/portit-react-common-components";

import { CheckBoxGroupFieldModelBuilder } from "./CheckBoxGroupFieldModelBuilder";
import { PreferencesModel } from "./preferences.model";

import {
  StyledPageTitle,
  StyledSubtitle,
  StyledTitleText,
} from "../../shared/styles/page.styles";

import {
  StyledPreferencesWrapper,
  StyledCheckBoxLabel,
  StyledRowWrapper,
  StyledPreferenceTitle,
  StyledCustomInnerPageTitle,
} from "./preferences.styles";
import { RangeFieldModelBuilder } from "./RangeFieldModelBuilder";
import { CheckBoxSelectFieldModelBuilder } from "./CheckBoxSelectFieldMolderBuilder";
import { PreferencesProps } from "../preferences-page/types";
import { useHistory } from "react-router-dom";
import { ElviriaPages } from "../../shared/pages";
import { colorMain } from "../../shared/styles/generic";
import { PreferencesModal } from "./preferencesModal.component";
import withReactContent from "sweetalert2-react-content";
import { useElviriaStore } from "../elviriaStore";

export const Preferences = ({ withAdminPage }: PreferencesProps) => {
  const history = useHistory();
  const { t } = useTranslation(["preferencesPage"]);
  const { customerCubit } = useContext(Context);
  const SwalWithReact = withReactContent(Swal);

  const { setAreInvestmentPreferencesCreated } = useElviriaStore();

  const userPreferencesModel = useModelCreatorEffect({
    creator: () => {
      return new PreferencesModel({ customerCubit });
    },
  });

  useModelChanges(userPreferencesModel);

  useFormModelListener({
    formModel: userPreferencesModel,
    onSubmitFailed: () => {
      SwalWithReact.fire({
        html: (
          <Suspense fallback={LoadingComponent}>
            <PreferencesModal swalWithReact={SwalWithReact} failed />
          </Suspense>
        ),
        icon: "error",
        iconColor: colorMain,

        showConfirmButton: false,
        width: "40.625rem",
        showClass: {
          backdrop: "swal2-noanimation", // disable backdrop animation
        },
      });

      failuresStore.next({
        domainFailure: userPreferencesModel.failureResponse,
      });
    },
    onSubmitted: () => {
      setAreInvestmentPreferencesCreated(true);

      SwalWithReact.fire({
        html: (
          <Suspense fallback={LoadingComponent}>
            <PreferencesModal swalWithReact={SwalWithReact} />
          </Suspense>
        ),
        icon: "success",
        iconColor: colorMain,

        showConfirmButton: false,
        width: "40.625rem",
        showClass: {
          backdrop: "swal2-noanimation", // disable backdrop animation
        },
      }).then((result) => {
        if (result) {
          history.push(ElviriaPages.DASHBOARD);
        }
      });
    },
  });

  if (!userPreferencesModel) {
    return <LoadingComponent />;
  }

  return (
    <StyledPreferencesWrapper>
      {withAdminPage ? (
        <StyledCustomInnerPageTitle>
          {t("preferences")}
        </StyledCustomInnerPageTitle>
      ) : (
        <>
          <StyledPageTitle>{t("page_title")}</StyledPageTitle>
          <StyledTitleText>{t("page_title_text")}</StyledTitleText>
        </>
      )}

      <StyledRowWrapper>
        <StyledSubtitle>{t("asset_class")}</StyledSubtitle>
        <CheckBoxGroupFieldModelBuilder
          withSelectAll
          multiSelectFieldModel={userPreferencesModel.investMentOpportunitiesFM}
        >
          {(item) => {
            return <StyledCheckBoxLabel> {t(item)} </StyledCheckBoxLabel>;
          }}
        </CheckBoxGroupFieldModelBuilder>
      </StyledRowWrapper>
      <StyledRowWrapper>
        <StyledSubtitle>{t("sector")}</StyledSubtitle>
        <CheckBoxGroupFieldModelBuilder
          withSelectAll
          multiSelectFieldModel={userPreferencesModel.sectorFM}
        >
          {(item) => {
            return <StyledCheckBoxLabel> {t(item)} </StyledCheckBoxLabel>;
          }}
        </CheckBoxGroupFieldModelBuilder>
      </StyledRowWrapper>
      <StyledRowWrapper>
        <StyledSubtitle>{t("preferences")}</StyledSubtitle>
        <StyledRowWrapper>
          <StyledPreferenceTitle>{t("equity")}</StyledPreferenceTitle>
          <StyledRowWrapper>
            <RangeFieldModelBuilder
              singleFieldModel={userPreferencesModel.equityRangeFM}
            />
          </StyledRowWrapper>
        </StyledRowWrapper>
        <StyledRowWrapper>
          <StyledPreferenceTitle>{t("debt")}</StyledPreferenceTitle>
          <StyledRowWrapper>
            <RangeFieldModelBuilder
              singleFieldModel={userPreferencesModel.debtRangeFM}
            />
          </StyledRowWrapper>
        </StyledRowWrapper>
        <StyledRowWrapper>
          <StyledPreferenceTitle>{t("loan")}</StyledPreferenceTitle>
          <StyledRowWrapper>
            <RangeFieldModelBuilder
              singleFieldModel={userPreferencesModel.loanRangeFM}
            />
          </StyledRowWrapper>
        </StyledRowWrapper>

        <StyledRowWrapper>
          <StyledPreferenceTitle>{t("horizon")}</StyledPreferenceTitle>
          <CheckBoxSelectFieldModelBuilder
            selectFieldModel={userPreferencesModel.HorizonFM}
          >
            {(item) => {
              return (
                <StyledCheckBoxLabel> {t(`${item}`)} </StyledCheckBoxLabel>
              );
            }}
          </CheckBoxSelectFieldModelBuilder>
        </StyledRowWrapper>
        <StyledRowWrapper>
          <StyledPreferenceTitle>{t("equity_stage")}</StyledPreferenceTitle>
          <CheckBoxGroupFieldModelBuilder
            multiSelectFieldModel={userPreferencesModel.equityStageFM}
          >
            {(item) => {
              return <StyledCheckBoxLabel> {t(item)} </StyledCheckBoxLabel>;
            }}
          </CheckBoxGroupFieldModelBuilder>
        </StyledRowWrapper>
      </StyledRowWrapper>

      <SubmitButtonFormModelBuilder formModel={userPreferencesModel}>
        {t("save")}
      </SubmitButtonFormModelBuilder>
    </StyledPreferencesWrapper>
  );
};
