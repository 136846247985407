import styled, { css } from "styled-components";
import {colorGrey, colorLightGrey, colorMain} from "../../../../shared/styles/generic";

export const StyledMyInvestmentsMainWrapper = styled.div`
  padding-top: 2rem;
`;

export const StyledMyInvestmentsContentWrapper = styled.div`
  padding: 1.375rem 0;
`;

export const StyledMyInvestmentsTitleRowWrapper = styled.div`
  display: flex;
  padding: 0 0.9375rem;
`;

interface MyInvestmentsColumnTitleProps {
  isAlignRight?: boolean;
}

export const StyledMyInvestmentsColumnTitle = styled.h4<MyInvestmentsColumnTitleProps>`
  flex: 1;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${colorMain};
  text-transform: uppercase;

  ${({ isAlignRight }) =>
    isAlignRight &&
    css`
      text-align: right;
    `}
`;

export const StyledMyInvestmentCardWrapper = styled.div`
  display: flex;
  border: 0.0625rem solid ${colorGrey};
  justify-content: space-between;
  align-items: center;
  padding: 1.625rem 1.3125rem 1.5rem 1.9375rem;
  margin: 0 0 0.9375rem 0;
  font-weight: 600;

  div {
    flex: 1;
  }
`;

interface MyInvestmentCardInnerProps {
  isAlignRight?: boolean;
  isUnderlined?: boolean;
}

export const StyledMyInvestmentCardInner = styled.div<MyInvestmentCardInnerProps>`
  flex: 1;

  ${({ isAlignRight }) =>
    isAlignRight &&
    css`
      text-align: right;
    `}

  ${({ isUnderlined }) =>
    isUnderlined &&
    css`
      text-decoration: underline;
    `}
`;

export const StyledSecondaryText = styled.div`
  color: ${colorLightGrey};
  font-size: 0.8rem;
`;

export const StyledMyInvestmentConcentrationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface InvestmentCircleProps {
  color: string;
}

export const StyledMyInvestmentConcentrationCircle = styled.span<InvestmentCircleProps>`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.75rem;
  background-color: ${({ color }) => (color ? color : "#bbb")};
`;
