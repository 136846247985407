import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import elviriaLogo from "../../../assets/elviria_dark_logo.svg";
import userLogo from "../../../assets/elviria_user.svg";
import bellSVG from "../../../assets/icons/bell.svg";
import userPlaceholderImg from "../../../assets/user-placeholder.png";

import {
  LoadingComponent,
  Context,
  FailureResult,
  useCubitBuilder,
  getSubT,
} from "@portittech/portit-react-common-components";

import { CustomerProfile } from "./CustomerProfile.component";

import { CustomerApplications } from "./CustomerApplications.component";

import { LeftPanelDrawer } from "./LeftPanelDrawer.component";
import { ElviriaPages } from "../../../shared/pages";

import {
  StyledAccountIcon,
  StyledAccountText,
  StyledBehalf,
  StyledBehalfDashboard,
  StyledCloseIcon,
  StyledCompanyTitle,
  StyledDashboardHeader,
  StyledDashboardHeaderDialog,
  StyledDashboardHeaderSubTitle,
  StyledDialogOverview,
  StyledNotificationIcon,
  StyledOpenMatter,
  StyledOverview,
  StyledOverviewTitle,
  StyledProfileDetails,
  StyledProfileList,
  StyledUserDetails,
  StyledProfilePhoto,
  StyledApplicationsTitle,
} from "./styles/dashboardHeader.style";

interface DashBoardHeaderProps {
  isDashboardPage?: boolean;
  showGreetingMessage?: boolean;
}

export const DashboardHeader = ({
  isDashboardPage,
  showGreetingMessage,
}: DashBoardHeaderProps): React.ReactElement => {
  const { customerCubit } = useContext(Context);
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "overview");

  const customerState = useCubitBuilder({
    cubit: customerCubit,
  });

  const modalEl = useRef<HTMLDivElement>();

  useEffect(() => {
    // Hide modal when a click on thew overlay element is received
    const modal = modalEl.current;
    const onModalClick = (event) => {
      if (event.target === modal) {
        modalEl.current?.classList.remove("visible");
      }
    };
    modalEl.current?.addEventListener("click", onModalClick);

    return () => {
      modal?.removeEventListener("click", onModalClick);
    };
  }, []);

  const onShowModalClick = () => {
    modalEl.current?.classList.add("visible");
  };

  const onHideModal = () => {
    modalEl.current?.classList.remove("visible");
  };

  if (customerState.hasFailure) {
    return <FailureResult failures={customerState.failure} />;
  }

  const customerData = customerState.tryData;

  return (
    <StyledDashboardHeader
      withMarginBottom={isDashboardPage && showGreetingMessage}
    >
      {isDashboardPage && showGreetingMessage && (
        <StyledOverview>
          <StyledOverviewTitle>
            {tc("greet_label", {
              replace: { nominative: customerData?.customerName },
            })}
          </StyledOverviewTitle>
          <StyledDashboardHeaderSubTitle>
            {tc("welcome_back_label")}
          </StyledDashboardHeaderSubTitle>
        </StyledOverview>
      )}
      {!isDashboardPage && <img src={elviriaLogo} alt="elviria-logo" />}

      <StyledBehalfDashboard>
        {customerData?.profile?.type === "COMPANY" && (
          <StyledAccountText>
            <StyledBehalf> {tc("behalf_label")}</StyledBehalf>
            <StyledCompanyTitle>{customerData?.profileName}</StyledCompanyTitle>
          </StyledAccountText>
        )}
        {isDashboardPage && (
          <>
            {/* Todo: uncomment this when search future will be available  <StyledSearch src={searchSVG} /> */}
            <Link to={ElviriaPages.MESSAGING}>
              <StyledNotificationIcon src={bellSVG} />{" "}
            </Link>
          </>
        )}
        {isDashboardPage ? (
          <StyledProfilePhoto
            src={userPlaceholderImg}
            onClick={onShowModalClick}
          />
        ) : (
          <StyledAccountIcon onClick={onShowModalClick}>
            <img src={userLogo} alt="user-avatar" />
          </StyledAccountIcon>
        )}
      </StyledBehalfDashboard>

      <StyledDashboardHeaderDialog>
        <div ref={modalEl} className="modal">
          <StyledDialogOverview className="modal-content">
            <LeftPanelDrawer onHideModal={onHideModal} />
            <StyledProfileDetails>
              <StyledCloseIcon onClick={onHideModal}>Close</StyledCloseIcon>
              <StyledUserDetails>
                <div>
                  <StyledOverviewTitle style={{ paddingLeft: 21 }}>
                    {customerData?.customerNominative}
                  </StyledOverviewTitle>

                  <StyledProfileList>
                    {customerData === undefined && <LoadingComponent />}
                    {customerData?.allProfiles?.map((profile, index) => (
                      <CustomerProfile
                        key={index}
                        profile={profile}
                        customerData={customerData}
                      />
                    ))}
                  </StyledProfileList>

                  <StyledApplicationsTitle>
                    {tc("open_matters_title")}
                  </StyledApplicationsTitle>
                  <StyledOpenMatter>
                    <CustomerApplications />
                  </StyledOpenMatter>
                </div>
              </StyledUserDetails>
            </StyledProfileDetails>
          </StyledDialogOverview>
        </div>
      </StyledDashboardHeaderDialog>
    </StyledDashboardHeader>
  );
};
