import { OpportunityStep } from "../../domain/features/opportunities/entities";
import underDueDiligence from "../../assets/underDueDiligence.svg";
import preMarketing from "../../assets/preMarketing.svg";
import funded from "../../assets/funded.svg";

/**
 * @param step
 *
 * @returns src string of the img, otherwise null
 *
 * @example
 *  const bannerInfoImg = useOpportunityBanner(opportunity.step)
 */

export const useOpportunityBanner = (step: OpportunityStep) => {
  switch (step) {
    case "PreMarketing":
      return preMarketing;

    case "UnderDueDiligence":
      return underDueDiligence;
    
    case "Funded":
      return funded

    default:
      return null;
  }
};
