import { Either, isLeft, right } from "fp-ts/Either";

import {
  allRawValuesPagination,
  BalanceDetailsEntity,
  BooleanFieldModel,
  CustomerCubit,
  Failure,
  fetchBalancesWP,
  FieldValidators,
  FormModel,
  NumberFieldModel,
  SelectFieldModel,
  TranslationsSelectFieldModel,
} from "@portittech/portit-react-common-components";
import { InvestmentOptions, InvestSteps } from "./config";
import { of } from "rxjs";
import { createInvestment, getSingleOpportunity } from "../../shared/usecases";

import { OpportunityResponse } from "../../domain/features/opportunities/entities";
import { CreateInvestmentRequest } from "../../domain/features/investment/entities";
import { isNil } from "ramda";

export class PaymentsFormModel extends FormModel<any, Failure> {
  static readonly outOfFundsError = "out_of_funds";
  customerCubit: CustomerCubit;
  pageId: number;
  onLastStepSubmit: Function;

  private _currentOpportunity: OpportunityResponse;

  // ========== SOURCE (Invest Step) ==========
  readonly sourceFM = new TranslationsSelectFieldModel<BalanceDetailsEntity>({
    validators: [FieldValidators.required],
  });

  readonly investmentOptionsFM = new SelectFieldModel<string>({
    validators: [FieldValidators.required],
  });

  readonly noOfTokensFM = new NumberFieldModel({
    type: "integer",
    validators: [FieldValidators.required, FieldValidators.number("integer")],
  });

  readonly totalValueFM = new NumberFieldModel({
    validators: [FieldValidators.required],
  });

  readonly tokensAssetStoreFM = new SelectFieldModel<string>({
    items: [
      "Elviria Custody - We handle the wallet for you",
      "External Wallet - Coming soon",
    ],
    validators: [FieldValidators.required],
  });

  readonly unitsAssetStoreFM = new SelectFieldModel<string>({
    items: [
      "Elviria Custody - Deposit certificate with us",
      "External Custody - Deposit certificate in your custodian bank",
    ],
    validators: [FieldValidators.required],
  });

  readonly termsAndConditionsFM = new BooleanFieldModel({
    validators: [FieldValidators.required, FieldValidators.mustBeTrue],
  });

  constructor({
    pageId,
    onLastStepSubmit,
    customerCubit,
  }: {
    pageId: number;
    onLastStepSubmit: Function;
    customerCubit: CustomerCubit;
  }) {
    super({ isLoading: true, autoValidate: true });
    this.pageId = pageId;
    this.customerCubit = customerCubit;
    this.onLastStepSubmit = onLastStepSubmit;

    this.addFieldModels({
      step: InvestSteps.invest,
      fieldsModels: [
        this.sourceFM,
        this.noOfTokensFM,
        this.totalValueFM,
        this.investmentOptionsFM,
        this.termsAndConditionsFM,
      ],
    });

    this.addFieldModels({
      step: InvestSteps.review,
      fieldsModels: [],
    });

    this.sourceFM.addValidators([this.validateSource.bind(this)]);

    this.sourceFM.listenValueChange({
      onData: (prev, cur) => {
        this.totalValueFM.updateValidators([
          FieldValidators.number("decimal", {
            min: Number(this._currentOpportunity.minRequested),
            max: this.sourceFM.translation.available_amount,
          }),
        ]);

        return of(undefined);
      },
    });

    // InitialValues
    this.noOfTokensFM.updateInitialValue(0);
    this.totalValueFM.updateInitialValue(0);
    this.termsAndConditionsFM.updateInitialValue(false);

    this.noOfTokensFM.listenValueChange({
      onData: (prev, cur) => {
        if (cur <= 0) {
          this.noOfTokensFM.updateValue(0);
          this.totalValueFM.updateValue(0);
        } else {
          this.totalValueFM.updateValue(
            Number(
              (cur * Number(this._currentOpportunity.tokenPrice)).toFixed(2)
            )
          );
        }

        return of(undefined);
      },
    });

    // set and unset required validator of tokensAssetStoreFM and unitsAssetStoreFM
    this.investmentOptionsFM.listenValueChange({
      onData: (prev, cur) => {
        this.addFieldModels({
          step: InvestSteps.invest,
          fieldsModels: [
            cur === InvestmentOptions.tokens
              ? this.tokensAssetStoreFM
              : this.unitsAssetStoreFM,
          ],
        });

        this.removeFieldModels({
          step: InvestSteps.invest,
          fieldsModels: [
            cur === InvestmentOptions.tokens
              ? this.unitsAssetStoreFM
              : this.tokensAssetStoreFM,
          ],
        });

        return of(undefined);
      },
    });
  }

  protected async onLoading(): Promise<void> {
    const res = await fetchBalancesWP.run({
      strainer: {
        status: "enabled",
      },
      pagination: allRawValuesPagination,
    });
    if (isLeft(res)) {
      this.notifyLoadFailed({ response: res.left });
      return;
    }

    const balances = res.right.values;

    this.investmentOptionsFM.updateItems([
      InvestmentOptions.tokens,
      InvestmentOptions.units,
    ]);

    this.noOfTokensFM.updateInitialValue(0);

    const singleOpportunity = await getSingleOpportunity.run({
      opportunityId: this.pageId,
    });

    if (isLeft(singleOpportunity)) {
      return this.notifyLoadFailed();
    }

    this._currentOpportunity = singleOpportunity.right;

    this.sourceFM.updateTranslationEntries(
      balances
        .filter(
          (balance) => balance.currency === this._currentOpportunity.currency
        )
        .map((it) => {
          return {
            key: `${it.balance_id}`,
            value: it,
          };
        })
    );

    this.notifyLoaded();
  }

  protected async onSubmitting() {
    // call a specific usecase for the submit, depending on the current step
    const submitter = async (): Promise<Either<Failure, any>> => {
      switch (this.currentStep) {
        case InvestSteps.invest:
          return right(null);
        case InvestSteps.review:
          const investmentRequest: CreateInvestmentRequest = {
            currency: this.sourceFM.translation.currency,
            investmentType: "Buy", // for the investment is only buy
            opportunityId: this.currentOpportunity.id,
            tokenPrice: this.currentOpportunity.tokenPrice,
            agreedTandC: this.termsAndConditionsFM.value,
            walletId: this.sourceFM.translation.wallet_id,
            partyId: this.customerCubit.state.data.profile.partyId,
            noToken: this.noOfTokensFM.value,
            streamableType: !isNil(this._currentOpportunity.opportunitySetup),
          };
          const res = await createInvestment.run(investmentRequest);
          if (isLeft(res)) {
            this.notifySubmitFailed({ response: res.left });
            this.onLastStepSubmit(true); // true if there is error to handle in investmentPage.component
          } else {
            this.notifySubmitted();
            // Show Modal
            this.onLastStepSubmit();
          }
          return right(null);
      }
    };

    const submitterResponse = await submitter();
    if (isLeft(submitterResponse)) {
      this.notifySubmitFailed({ response: submitterResponse.left });
    } else {
      this.notifySubmitted();
    }
  }

  private validateSource(balanceId: undefined | string): undefined | string {
    if (!balanceId) return undefined;
    if (this.sourceFM.translations[balanceId].available_amount <= 0) {
      return PaymentsFormModel.outOfFundsError;
    }
    return undefined;
  }

  get currentOpportunity() {
    return this._currentOpportunity;
  }
}
