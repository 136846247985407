import styled from "styled-components";
import { colorMain } from "../../../styles/generic";

export const ElviriaCheckboxStyle = styled.label`
  position: relative;
  margin-right: 1.5em;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: white;
    border-radius: 2px;
    border: 1px solid #ddd;
  }

  &:hover input ~ .checkmark {
    border-color: ${colorMain};
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  & input.indeterminate ~ .checkmark:after {
    display: block;
    left: 2px;
    top: 2px;
    width: 11px;
    height: 11px;
    transform: rotate(0deg);
    border: solid ${colorMain};
    background: ${colorMain};
    border-radius: 2px;
  }

  & input:checked ~ .checkmark:after {
    display: block;
    left: 4px;
    top: 0;
    width: 7px;
    height: 12px;
    border: solid ${colorMain};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
