import { getSubT } from "@portittech/portit-react-common-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { SweetAlert2 } from "sweetalert2-react-content";

import {
  StyledExternalLink,
  StyledSmallWidgetTitle,
} from "../widgets/common.styles";

interface InvestmentModalProps {
  swalWithReact: SweetAlert2;
  isError?: boolean;
}

export const InvestmentModal = ({
  swalWithReact,
  isError,
}: InvestmentModalProps) => {
  const { t } = useTranslation(["investment"]);
  const tc = getSubT(t, "investment_modal");

  return (
    <>
      <StyledSmallWidgetTitle>
        {isError ? tc("error_title") : tc("thanks")}
      </StyledSmallWidgetTitle>
      <p>{isError ? tc("error_message") : tc("thanks_message")}</p>
      <StyledExternalLink onClick={swalWithReact.clickConfirm}>
        {tc("close_button")}
      </StyledExternalLink>
    </>
  );
};
