import React from "react";
import { useTranslation } from "react-i18next";

import {
  StyledWithdrawPageWrapper,
  StyledPageTitle,
  StyledMyAccountLink,
} from "./withdraw.styles";
import { ElviriaPages } from "../../shared/pages";
import { PaymentsStage } from "@portittech/portit-react-common-components";

export const WithdrawPage = () => {
  const { t } = useTranslation(["payments"]);
  return (
    <StyledWithdrawPageWrapper>
      <StyledMyAccountLink to={ElviriaPages.ACCOUNT}>
        {t("my_account")}
      </StyledMyAccountLink>
      <StyledPageTitle>{t("Withdraw")}</StyledPageTitle>
      <PaymentsStage />
    </StyledWithdrawPageWrapper>
  );
};
