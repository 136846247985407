import styled, { css } from "styled-components";
import { colorMain } from "../../../shared/styles/generic";

// Review Step styles
export const ReviewStepMainWrapper = styled.div`
  max-width: 40%;
`;

export const ReviewStepRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface ReviewStepRowValueProps {
  isHighlighted?: boolean;
}

export const ReviewStepRowValue = styled.p<ReviewStepRowValueProps>`
  font-weight: 600;
  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      color: ${colorMain};
    `}
`;
