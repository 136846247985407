import {
  ApiClient,
  FilePayload,
} from "@portittech/portit-react-common-components";

import {
  GetOpportunitiesRequest,
  OpportunitiesResponse,
  OpportunityResponse,
} from "../../../domain/features/opportunities/entities";
import { OpportunitiesRepository } from "../../../domain/features/opportunities/repository";

/**
 * Data bridge between Investment REST endpoints and the domain layer
 */
export class OpportunitiesRepositoryImpl implements OpportunitiesRepository {
  constructor(private readonly client: ApiClient) {}

  getOpportunities({
    pagination,
  }: GetOpportunitiesRequest = {}): Promise<OpportunitiesResponse> {
    return this.client.get<OpportunitiesResponse>(
      "/investments/v1/opportunities",
      "investments",
      pagination
    );
  }

  async getOpportunityImg(imgId: number): Promise<string> {
    const res = await this.client.get<ArrayBuffer>(
      `/investments/v1/opportunities/images/${imgId}`,
      "investments",
      {},
      {
        responseType: "arraybuffer",
      }
    );

    const arrayBufferView = new Uint8Array(res);
    const blob = new Blob([arrayBufferView], { type: "image/png" });
    const urlCreator = window.URL || window.webkitURL;

    return urlCreator.createObjectURL(blob);
  }

  getInterestedOpportunities(): Promise<OpportunitiesResponse> {
    return this.client.get<OpportunitiesResponse>(
      "/investments/v1/opportunities/preferences",
      "investments"
    );
  }

  getSingleOpportunity(opportunityId: number): Promise<OpportunityResponse> {
    return this.client.get<OpportunityResponse>(
      `/investments/v1/opportunities/${opportunityId}`,
      "investments"
    );
  }

  getOpportunityDataFile(fileId: number): Promise<FilePayload> {
    return this.client.get<FilePayload>(
      `/investments/v1/opportunities/datafiles/${fileId}`,
      "investments",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const contentDisposition: string = headers[
            "content-disposition"
          ] as string;
          let fileName: string | undefined;
          if (contentDisposition) {
            // contentDisposition is something like "attachment;"
            const splits = contentDisposition.split("filename=");
            fileName = splits[splits.length - 1];
          }
          return {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            data: {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              buffer: data,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
              contentType: headers["content-type"],
              fileName,
            } as FilePayload,
          };
        },
      }
    );
  }

  addOpportunityToWatchlist(
    opportunityId: number
  ): Promise<OpportunitiesResponse> {
    return this.client.post<OpportunitiesResponse>(
      `/investments/v1/opportunities/${opportunityId}/watchlist`,
      "investments"
    );
  }

   removeOpportunityFromWatchlist(
    opportunityId: number
  ): Promise<OpportunitiesResponse> {
    return this.client.delete<OpportunitiesResponse>(
      `/investments/v1/opportunities/${opportunityId}/watchlist`,
      "investments"
    );
  }

  getWatchlistOpportunities(): Promise<OpportunitiesResponse> {
    return this.client.get<OpportunitiesResponse>(
      "/investments/v1/opportunities/watchlist/parties",
      "investments"
    );
  }
}
