import { useEffect, useState } from "react";

import {
  InvestmentByAssetsClass,
  InvestmentByOpportunity,
} from "../../domain/features/investment/entities";
import { useRequest } from "../../shared/hooks/useRequest";
import {
  getInvestmentsConcentrationByAssetClass,
  getInvestmentsConcentrationByOpportunity,
} from "../../shared/usecases";
import { portfolioAssetsColors, portfolioColors } from "../config/data";

export const usePortfolio = () => {
  const [doneInvestmentsByOpportunity, setDoneInvestmentsByOpportunity] =
    useState<Array<InvestmentByOpportunity>>([]);

  const [pendingInvestmentsByOpportunity, setPendingInvestmentsByOpportunity] =
    useState<Array<InvestmentByOpportunity>>([]);

  const [investmentsByAssetClass, setInvestmentsByAssetClass] = useState<
    Array<InvestmentByAssetsClass>
  >([]);

  const { request: getInvestmentsByOpportunityRequest, isFetching } =
    useRequest(getInvestmentsConcentrationByOpportunity);

  useEffect(() => {
    // get Done Investments by Opportunity and assign colors to them
    getInvestmentsByOpportunityRequest({ investmentStatus: "Done" }).then(
      (res) => {
        if (res?.investments) {
          const investments = res?.investments.map((investment, index) => {
            return {
              ...investment,
              color: portfolioColors[index],
            };
          });

          setDoneInvestmentsByOpportunity(investments);
        }
      }
    );

    // get Done Investments by Opportunity and assign colors to them
    getInvestmentsByOpportunityRequest({ investmentStatus: "Pending" }).then(
      (res) => {
        if (res?.investments) {
          setPendingInvestmentsByOpportunity(res.investments);
        }
      }
    );

    //clean up
    return () => {
      setDoneInvestmentsByOpportunity([]);
      setPendingInvestmentsByOpportunity([]);
    };
  }, [getInvestmentsByOpportunityRequest]);

  const { request: getInvestmentsByAssetsClassRequest } = useRequest(
    getInvestmentsConcentrationByAssetClass
  );

  // get Done Investments by Assets Classes and assign colors to them
  useEffect(() => {
    getInvestmentsByAssetsClassRequest().then((res) => {
      if (res?.investments) {
        const investments = res?.investments.map((investment, index) => {
          return {
            ...investment,
            color: portfolioAssetsColors[index],
          };
        });
        setInvestmentsByAssetClass(investments);
      }
    });

    //clean up
    return () => {
      setInvestmentsByAssetClass([]);
    };
  }, [getInvestmentsByAssetsClassRequest]);

  return {
    doneInvestmentsByOpportunity,
    isFetching,
    investmentsByAssetClass,
    pendingInvestmentsByOpportunity,
  };
};
