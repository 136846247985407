import React from "react";
import { useTranslation } from "react-i18next";
import { isEmpty, splitEvery } from "ramda";

import { getSubT } from "@portittech/portit-react-common-components";

import { WatchListCard } from "./watchListCard.component";

import { StyledRouterLink, StyledSmallWidgetTitle } from "../common.styles";
import {
  StyledEmptyWrapper,
  StyledNoOpportunities,
  WatchlistCardSliderWrapper,
  WatchlistSlideWrapper,
  WatchlistWidgetWrapper,
} from "./watchlistWidget.style";
import { useWatchlistStore } from "../../watchlistStore";
import { ElviriaPages } from "../../../shared/pages";
import { Carousel } from "antd";

export const WatchlistWidget = () => {
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "watchlist_widget");

  const { watchlistOpportunities } = useWatchlistStore();

  // divide opportunities array into array of arrays having maximum two opportunities each
  const opportunitiesListChunks = splitEvery(2, watchlistOpportunities);

  return (
    <WatchlistWidgetWrapper>
      <StyledSmallWidgetTitle>{tc("widget_heading")}</StyledSmallWidgetTitle>
      {!isEmpty(watchlistOpportunities) ? (
        <>
          <WatchlistCardSliderWrapper>
            <Carousel>
              {opportunitiesListChunks.map((chunk, index) => (
                <div key={index} className="slide-wrapper">
                  <WatchlistSlideWrapper>
                    {chunk.map(
                      (opportunity) =>
                        opportunity && (
                          <WatchListCard
                            key={opportunity.id}
                            {...opportunity}
                          />
                        )
                    )}
                  </WatchlistSlideWrapper>
                </div>
              ))}
            </Carousel>
          </WatchlistCardSliderWrapper>
        </>
      ) : (
        <StyledEmptyWrapper>
          <StyledNoOpportunities>{tc("no_items")}</StyledNoOpportunities>
          <StyledRouterLink to={ElviriaPages.OPPORTUNITIES}>
            {tc("link")}
          </StyledRouterLink>
        </StyledEmptyWrapper>
      )}
    </WatchlistWidgetWrapper>
  );
};
