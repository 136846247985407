import styled from "styled-components";

import { fontFamilyMontserrat } from "../../../../shared/styles/generic";

export const StyledSpanServices = styled.div`
  font-family: ${fontFamilyMontserrat};
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 1.25rem 0;
  letter-spacing: 0.0625rem;
  color: #37383c;
`;

export const StyledProfileWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.6rem;
  }
`;
