import { useEffect, useState } from "react";
import { getInvestmentsCompletedAndPending } from "../usecases";
import { useRequest } from "./useRequest";

/**
 * @param void
 *
 * @returns completedInvestmentsCount number, pendingInvestmentsCount number, isInvestmentsCompletedAndPendingCountFetching boolean, and InvestmentsCompletedAndPendingCountError error
 *
 * @example
 *  const {completedInvestmentsCount, pendingInvestmentsCount, isInvestmentsCompletedAndPendingCountFetching, InvestmentsCompletedAndPendingCountError} = useInvestmentsCompletedAndPendingCount()
 */

export const useInvestmentsCompletedAndPendingCount = () => {
  const {
    request: requestInvestmentsCompletedAndPendingCount,
    isFetching: isInvestmentsCompletedAndPendingCountFetching,
    error: InvestmentsCompletedAndPendingCountError,
  } = useRequest(getInvestmentsCompletedAndPending);

  const [completedInvestmentsCount, setCompletedInvestmentsCount] = useState(0);
  const [pendingInvestmentsCount, setPendingInvestmentsCount] = useState(0);

  useEffect(() => {
    requestInvestmentsCompletedAndPendingCount().then((res) => {
      setCompletedInvestmentsCount(res.completedInvestments.length);
      setPendingInvestmentsCount(res.pendingInvestments.length);
    });

    //cleanup
    return () => {
      setCompletedInvestmentsCount(0);
      setPendingInvestmentsCount(0);
    };
  }, [requestInvestmentsCompletedAndPendingCount]);

  return {
    completedInvestmentsCount,
    pendingInvestmentsCount,
    isInvestmentsCompletedAndPendingCountFetching,
    InvestmentsCompletedAndPendingCountError,
  };
};
