import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IntlProvider } from "react-intl";
import { HashRouter } from "react-router-dom";
import "./app.scss";
import { ContextStateComponent } from "@portittech/portit-react-common-components";

import { BoUserBanner } from "./components/bo-user-banner/boUserBanner.component";
import { ScrollToTop } from "./shared/hooks/useScrollToTop";
import { OpportunitiesStore } from "./components/opportunitiesStore";
import { WatchlistStore } from "./components/watchlistStore";
import { Routes } from "./routes/routes";

export const ElviriaApp: React.FC = () => {
  const { t, i18n } = useTranslation(["welcome"]);

  // Apply title page
  useEffect(() => {
    document.head.querySelector("title").text = t("page_title");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IntlProvider locale={i18n.language}>
      <div className="app">
        <HashRouter>
          <ScrollToTop>
            <ContextStateComponent>
              <OpportunitiesStore>
                <WatchlistStore>
                  <BoUserBanner />
                  <Routes />
                </WatchlistStore>
              </OpportunitiesStore>
            </ContextStateComponent>
          </ScrollToTop>
        </HashRouter>
      </div>
    </IntlProvider>
  );
};
