import styled, { keyframes, css } from "styled-components";

import {
  RoundedButton,
  RoundedOutlinedButton,
} from "../../shared/styles/button";

import {
  colorMain,
  fontFamilyEncodeSansExpanded,
} from "../../shared/styles/generic";
import { StyledTitleText } from "../../shared/styles/page.styles";
import { devices } from "../config/devices";

export const StyledInvestmentsInfoKycWrapper = styled.div`
  padding: 3.125rem 0;
  max-width: 56.25rem;
  margin: 0 auto;

  @media ${devices.tablet} {
    padding: 1.875rem 1.25rem;
  }
`;

export const StyledTitle = styled.h2`
  font-family: ${fontFamilyEncodeSansExpanded};
  font-style: normal;
  font-weight: 400;
  font-size: 3.75rem;
  line-height: 4.375rem;
  color: ${colorMain};
  text-align: center;

  @media ${devices.tablet} {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
`;

export const StyledTitleTextCustom = styled(StyledTitleText)`
  line-height: 1.625rem;
  max-width: 56.25rem;

  @media ${devices.tablet} {
    font-size: 0.9375rem;
    line-height: 1.25rem;
  }
`;

export const StyledBoldTitleText = styled(StyledTitleText)`
  font-weight: 700;
  line-height: 1.5rem;
  font-size: 1.25rem;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  max-width: 56.25rem;
  margin-bottom: 1.6875rem;

  @media ${devices.tablet} {
    font-size: 0.9375rem;
    line-height: 1.0625rem;
  }
`;

export const StyledQuestion = styled(StyledTitleText)`
  text-align: left;
  font-size: 1.25rem;
  line-height: 1.625rem;
  max-width: 100%;
  margin-top: 2.5rem;

  @media ${devices.tablet} {
    font-size: 0.9375rem;
    line-height: 1.0625rem;
  }
`;

type SubmitButtonWrapperProps = {
  shouldIncreaseMarginTop?: boolean;
};

export const StyledSubmitButtonWrapper = styled.div<SubmitButtonWrapperProps>`
  text-align: center;
  margin-top: ${({ shouldIncreaseMarginTop }) =>
    shouldIncreaseMarginTop ? 9.375 : 1.875}rem;
`;

export const StyledQuestionWrapper = styled.div`
  input {
    width: 50% !important;
  }

  .ant-input-affix-wrapper {
    width: 50% !important;

    input {
      width: 100% !important;
    }
  }

  .ant-checkbox-inner {
    width: 1.125rem;
    height: 1.125rem;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colorMain};
    border-color: ${colorMain};
  }

  .ant-checkbox-inner:hover,
  .ant-checkbox-inner:active {
    border-color: ${colorMain};
  }

  @media ${devices.tablet} {
    input {
      width: 100% !important;
    }
    .ant-input-affix-wrapper {
      width: 100% !important;
    }
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 1em;
`;

export const StyledSmallButton = styled(RoundedButton)`
  padding: 0 1em;
  line-height: 1rem;
`;

export const StyledSmallOutlinedButton = styled(RoundedOutlinedButton)`
  padding: 0 1em;
  line-height: 1rem;
  margin-left: 1.25rem;
`;

export const StyledDollarSign = styled.span`
  font-size: 1.125rem;
`;

export const InAnimation = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

export const OutAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
`;

type StyledQuestionProps = {
  showBlock?: boolean;
};

export const StyledQuestionsBlock = styled.div<StyledQuestionProps>`
  animation: ${({ showBlock }) =>
    showBlock
      ? css`
          ${InAnimation} 370ms ease-in
        `
      : css`
          ${OutAnimation} 370ms ease-out
        `};
`;

export const StyledLink = styled.a`
  text-decoration: underline;
`;
