import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  getSubT,
  LoadingComponent,
} from "@portittech/portit-react-common-components";

import { InterestedInvestmentCard } from "./interestedInvestmentCard";
import {
  StyledInterestedInvestmentContentWrapper,
  StyledIInterestedInvestmentMainWrapper,
  StyledViewAllLinkWrapper,
} from "./interestedInvestmentWidget.styles";

import { StyledRouterLink, StyledSmallWidgetTitle } from "../common.styles";
import { ElviriaPages } from "../../../shared/pages";

import { useRequest } from "../../../shared/hooks/useRequest";
import { getInterestedOpportunities } from "../../../shared/usecases";
import { OpportunityResponse } from "../../../domain/features/opportunities/entities";
import { getRandomInterested } from "../../../shared/helpers/getRandomInterested";

export const InterestedInvestmentWidget = () => {
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "interested_investment_widget");
  const [interestedOpportunitiesList, setInterestedOpportunitiesList] =
    useState<Array<OpportunityResponse>>([]);

  const { request: requestOpportunities, isFetching } = useRequest(
    getInterestedOpportunities
  );

  useEffect(() => {
    requestOpportunities().then((res) => {
      // Todo: remove filter and slice after decision on how should behave if more then 2 will be made
      const numberOfOpportunitiesToDisplay = 2;

      const opportunities = getRandomInterested(
        res.opportunityList,
        numberOfOpportunitiesToDisplay
      );
      setInterestedOpportunitiesList(opportunities);
    });

    return () => {
      setInterestedOpportunitiesList([]);
    };
  }, [requestOpportunities]);

  if (isFetching) return <LoadingComponent />;

  return (
    <StyledIInterestedInvestmentMainWrapper>
      <StyledSmallWidgetTitle fontSize={2} marginBottom="0">
        {tc("widget_heading")}
      </StyledSmallWidgetTitle>
      <StyledInterestedInvestmentContentWrapper>
        {interestedOpportunitiesList.map(
          (opportunity) =>
            opportunity && (
              <InterestedInvestmentCard key={opportunity.id} {...opportunity} />
            )
        )}
      </StyledInterestedInvestmentContentWrapper>

      <StyledViewAllLinkWrapper>
        <StyledRouterLink to={ElviriaPages.OPPORTUNITIES}>
          {tc("view_all_link")}
        </StyledRouterLink>
      </StyledViewAllLinkWrapper>
    </StyledIInterestedInvestmentMainWrapper>
  );
};
