import {
  AccountTransactionDetailsEntity,
  getSubT,
} from "@portittech/portit-react-common-components";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { formatCurrencyNumber } from "../../../../../shared/helpers/formatCurrencyNumber";
import { ElviriaPages } from "../../../../../shared/pages";
import { StyledAdminInnerPageTitle } from "../../../../../shared/styles/page.styles";
import {
  StyledInvestmentDetailPageBottomLinks,
  StyledInvestmentDetailPageDetailsWrapper,
  StyledInvestmentDetailPageDetailText,
  StyledInvestmentDetailPageMainWrapper,
} from "../../../../investments-page/components/investment-detail-page/investmentDetailPage.styles";
import {
  StyledExternalLink,
  StyledRouterLink,
  StyledSmallWidgetTitle,
  StyledSmallWidgetWrapper,
} from "../../../../widgets/common.styles";

export const TransferTransactionInvestment = (props: {
  accountTransaction: AccountTransactionDetailsEntity;
}) => {
  const history = useHistory();
  const { balanceId, transactionId } = useParams<{
    balanceId;
    transactionId;
  }>();
  const { t } = useTranslation(["accountPage"]);
  const tc = getSubT(t, "singleTransaction");
  return (
    <>
      <div>
        <StyledRouterLink to={ElviriaPages.ACCOUNT} isMargin>
          {tc("transactions_link_text")}
        </StyledRouterLink>
        <StyledAdminInnerPageTitle>{tc("title")}</StyledAdminInnerPageTitle>
      </div>
      <div></div>
      <div>
        <StyledSmallWidgetWrapper>
          <StyledInvestmentDetailPageMainWrapper>
            <StyledSmallWidgetTitle>
              {tc("your_transactions_title")}
            </StyledSmallWidgetTitle>
            <StyledInvestmentDetailPageDetailsWrapper>
              <StyledInvestmentDetailPageDetailText>
                {/* TODO: Add translations once what data have to shown is decided */}
                {"Reference"}
              </StyledInvestmentDetailPageDetailText>
              <StyledInvestmentDetailPageDetailText isbold>
                {props.accountTransaction.transaction.reference}
              </StyledInvestmentDetailPageDetailText>

              <StyledInvestmentDetailPageDetailText>
                {/* TODO: Add translations once what data have to shown is decided */}
                {"Status"}
              </StyledInvestmentDetailPageDetailText>
              <StyledInvestmentDetailPageDetailText isbold>
                {props.accountTransaction.transfer_detail.status}
              </StyledInvestmentDetailPageDetailText>

              <StyledInvestmentDetailPageDetailText>
                {/* TODO: Add translations once what data have to shown is decided */}
                {"Creation Date"}
              </StyledInvestmentDetailPageDetailText>
              <StyledInvestmentDetailPageDetailText isbold>
                {moment(props.accountTransaction.transaction.created_at).format(
                  "DD/MM/YYYY"
                )}
              </StyledInvestmentDetailPageDetailText>

              <StyledInvestmentDetailPageDetailText>
                {/* TODO: Add translations once what data have to shown is decided */}
                {"Completion Date"}
              </StyledInvestmentDetailPageDetailText>
              <StyledInvestmentDetailPageDetailText isbold>
                {props.accountTransaction.transaction.completed_at
                  ? moment(
                      props.accountTransaction.transaction.completed_at
                    ).format("DD/MM/YYYY")
                  : "-"}
              </StyledInvestmentDetailPageDetailText>

              <StyledInvestmentDetailPageDetailText>
                {/* TODO: Add translations once what data have to shown is decided */}
                {"Amount"}
              </StyledInvestmentDetailPageDetailText>
              <StyledInvestmentDetailPageDetailText isbold isColored>
                {formatCurrencyNumber(
                  props.accountTransaction.transfer_detail.amount,
                  props.accountTransaction.transfer_detail.currency
                )}
              </StyledInvestmentDetailPageDetailText>
            </StyledInvestmentDetailPageDetailsWrapper>
          </StyledInvestmentDetailPageMainWrapper>
        </StyledSmallWidgetWrapper>

        <StyledInvestmentDetailPageBottomLinks>
          <StyledExternalLink
            type="link"
            onClick={() => {
              history.goBack();
            }}
          >
            {tc("back_action")}
          </StyledExternalLink>
          <StyledExternalLink
            type="link"
            onClick={() => {
              history.push(
                `${ElviriaPages.ACCOUNT}/${balanceId}/transactions/${transactionId}/receipt`
              );
            }}
          >
            {tc("view_transaction_receipt")}
          </StyledExternalLink>
        </StyledInvestmentDetailPageBottomLinks>
      </div>
    </>
  );
};
