import styled from "styled-components";

import { colorMain, fontFamilyMontserrat } from "./generic";

interface MontserratSubtitleProps {
  margin?: string;
  color?: string;
}

export const MontserratSubtitle = styled.div<MontserratSubtitleProps>`
  margin: ${({ margin }) => margin || "50px 0"};
  font-family: ${fontFamilyMontserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
  letter-spacing: 1px;
  color: ${({ color }) => color || colorMain};
`;

export const MontserratLightGreyDescription = styled.div`
  color: #9a9a9a;
  font-family: ${fontFamilyMontserrat};
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3125rem;
`;
