import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {Logger} from "@portittech/portit-react-common-components";

i18n
  // load translation using http -> see /public/locales
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    lng: "en",
    fallbackLng: "en",
    react: {
      useSuspense: true,
    },
    debug: false,
    interpolation: {
      // prevent i18n from escaping, since it causes html entities not being rendered
      escape: (value) => value,
    },
  })
  .catch(Logger.error);

export default i18n;
