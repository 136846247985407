import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getSubT,
  RoundedButton,
} from "@portittech/portit-react-common-components";
import { StyledAdminInnerPageHeading } from "../help-page/helpPage.styles";
import { Styled404Text } from "./pageNotFound.styles";
import { ElviriaPages } from "../../shared/pages";

const PageNotFound: React.FC = () => {
  const { t } = useTranslation(["errors"]);
  const tc = getSubT(t, "webApp");

  const history = useHistory();

  return (
    <>
      <StyledAdminInnerPageHeading>
        {tc("pageNotFound_message")}
      </StyledAdminInnerPageHeading>
      <Styled404Text>{tc("pageNotFound_title")}</Styled404Text>
      <RoundedButton onClick={() => history.push(ElviriaPages.DASHBOARD)}>
        {tc("pageNotFound_action")}
      </RoundedButton>
    </>
  );
};

export default PageNotFound;
