import styled from "styled-components";
import {
  colorGrey,
  colorMainDark,
  fontFamilyMontserrat,
} from "../../../../shared/styles/generic";

export const StyledOpportunityValueWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: ${fontFamilyMontserrat};
  color: ${colorMainDark};
  padding: 1.125rem 1.25rem 0;
  box-shadow: -5px 5px 40px 0px rgba(0, 0, 0, 0.1);
`;

export const StyledOpportunityValueWidgetValuationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.8rem 0 0 0;
`;

export const StyledOpportunityValueWidgetValuationTitle = styled.p`
  font-size: 0.75rem;
  line-height: 1.3125rem;
`;

export const StyledOpportunityValueWidgetValuationValue = styled.p`
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 600;
`;

export const StyledOpportunityValueWidgetStartAndEndDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${colorGrey};
  padding: 0.875rem 0;
`;

export const StyledOpportunityValueWidgetStartAndEndDateTitle = styled.p`
  font-size: 0.75rem;
  font-family: ${fontFamilyMontserrat};
  color: ${colorMainDark};
  line-height: 1.3125rem;
  margin-bottom: 0;
`;
