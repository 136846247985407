import { isLeft } from "fp-ts/Either";
import { distinctUntilChanged, map } from "rxjs/operators";

import {
  CustomerCubit,
  ListModel,
  ValueModel,
  BasePageModel,
  deleteMessageUseCase,
  fetchMessagesUseCase,
  readMessageUseCase,
  MessageEntity,
  MessageStatusType,
  ProfileEntity,
  HashMap,
} from "@portittech/portit-react-common-components";

interface MessagingMap {
  profile: ProfileEntity;
  messages: Array<MessageEntity>;
}

export class MessagingPageModel extends BasePageModel {
  /* Model that represents all profiles, each one with all messages */
  readonly profilesModel = ListModel.idle<MessagingMap>();

  /* Model that represents the selected profile */
  readonly selectedProfileModel = ValueModel.idle<ProfileEntity>();

  /* Model that represents only the selected messages of the selected profile */
  readonly selectedMessagesModel = ListModel.seed<MessageEntity>({
    values: [],
  });

  /* Model that represents the messages of the selected profile */
  readonly messagesModel = ListModel.idle<MessageEntity>();

  /* Map for internal use */
  private readonly profilesAndMessages = new HashMap<
    ProfileEntity,
    Array<MessageEntity>
  >({
    hashCodeGetter: (profile) => profile.partyId,
  });

  constructor(private readonly customerCubit: CustomerCubit) {
    super();
  }

  async onLoading() {
    const customerRes = await this.customerCubit.read();
    if (isLeft(customerRes)) {
      this.notifyLoadFailed({ failure: customerRes.left });
      return;
    }

    await Promise.all(
      customerRes.right.allProfiles.map(async (profile) => {
        this.profilesAndMessages.set(
          profile,
          await this._fetchProfileMessages(profile.partyId)
        );
      })
    );

    this.selectedProfileModel.update({
      value: customerRes.right.profile,
    });
    this._notifyMapChange();

    // fetchMessages when the selectedProfile changes
    this.closer.add(
      this.selectedProfileModel.onLastChange
        .pipe(
          map((it) => it.value),
          distinctUntilChanged((p, c) => {
            return p.partyId === c.partyId;
          })
        )
        .subscribe(async (it) => {
          this.messagesModel.update({
            value: this.profilesAndMessages.get(it),
          });
          this.selectedMessagesModel.update({ value: [] });
        })
    );
    this.notifyLoaded();
  }

  /**
   * Update the status of a given message
   * @param message the message to update
   * @param status the new status of the message
   */
  async updateMessageStatus(message: MessageEntity, status: MessageStatusType) {
    if (message.message_status === "DELETED") return;

    if (status === message.message_status) return;

    const readMessageResponse = await readMessageUseCase.run({
      messageId: message.message_id,
      status: status,
    });
    if (isLeft(readMessageResponse)) {
      this.notifyLoadFailed({ failure: readMessageResponse.left });
      return;
    }
    this._setMapMessage(readMessageResponse.right);
    this._notifyMapChange();
  }

  /**
   * Soft-delete a message
   * @param message
   */
  async deleteMessage(message: MessageEntity) {
    const deleteMessageResponse = await deleteMessageUseCase.run({
      messageId: message.message_id,
    });

    if (isLeft(deleteMessageResponse)) {
      this.notifyLoadFailed({ failure: deleteMessageResponse.left });
      return;
    }

    this._deleteMapMessage(deleteMessageResponse.right);
    this._notifyMapChange();
  }

  private async _fetchProfileMessages(
    partyId?: number
  ): Promise<undefined | Array<MessageEntity>> {
    const messagesRes = await fetchMessagesUseCase.run({
      pagination: { size: 1000 },
      partyId: partyId,
    });
    if (isLeft(messagesRes)) {
      this.notifyLoadFailed({ failure: messagesRes.left });
      return [];
    }
    return messagesRes.right.values;
  }

  private _notifyMapChange() {
    this.profilesModel.update({
      value: this.profilesAndMessages
        .entriesToArray()
        .map(([profile, messages]) => {
          return { profile, messages };
        }),
    });
    this.messagesModel.update({
      value: this.profilesAndMessages.get(this.selectedProfileModel.value),
    });
  }

  private _setMapMessage(message: MessageEntity) {
    this.profilesAndMessages.set(
      this.selectedProfileModel.value,
      this.profilesAndMessages
        .get(this.selectedProfileModel.value)
        .map((m) => (m.message_id === message.message_id ? message : m))
    );

    // after updating message(s) status, updating it in selected messages list also
    this.selectedMessagesModel.update({
      value: this.selectedMessagesModel.values.map((m) =>
        m.message_id === message.message_id ? message : m
      ),
    });
  }

  private _deleteMapMessage(message: MessageEntity) {
    this.profilesAndMessages.set(
      this.selectedProfileModel.value,
      this.profilesAndMessages
        .get(this.selectedProfileModel.value)
        .filter((m) => m.message_id !== message.message_id)
    );
  }
}
