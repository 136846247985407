import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  scrollToTop,
  useModelChanges,
  useModelCreatorEffect,
} from "@portittech/portit-react-common-components";
import { StyledAdminPageWrapper, StyledContent } from "./adminPage.style";
import { NavigationModelBuilder } from "./nav-bar/navigationModelBuilder";
import { NavigationModel } from "./nav-bar/navigationModel";
import { DashboardHeader } from "../dashboard/header/dashboardHeader.component";
import { ElviriaPages } from "../../shared/pages";
import { Footer } from "../footer/footer.component";

export const AdminPage: React.FC = (props) => {
  const container = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const showGreetingMessage = location.pathname === ElviriaPages.DASHBOARD;

  const navModel = useModelCreatorEffect(() => {
    return new NavigationModel(location.pathname);
  });

  useEffect(() => {
    scrollToTop.container = container.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, container.current]);

  useModelChanges(navModel);
  if (!navModel) return null;

  return (
    <StyledAdminPageWrapper>
      <NavigationModelBuilder navigationModel={navModel} />

      <StyledContent ref={container}>
        <DashboardHeader
          showGreetingMessage={showGreetingMessage}
          isDashboardPage
        />
        <div className="body">{props.children}</div>
      </StyledContent>
      <Footer />
    </StyledAdminPageWrapper>
  );
};
