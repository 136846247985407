import React from "react";
import { useTranslation } from "react-i18next";
import { StyledSmallText } from "../../../widgets/common.styles";

export const NoInvestments = () => {
  const { t } = useTranslation(["investment"]);
  return (
    <StyledSmallText textAlign="center">{t("no_investments")}</StyledSmallText>
  );
};
