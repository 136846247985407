import React from "react";

import { getSubT } from "@portittech/portit-react-common-components";

import {
  StyledFooterNavbar,
  StyledFooter,
  StyledFooterLink,
  StyledFooterDescription,
} from "./footer.style";
import { footerNavItemsData } from "./config";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t: pt } = useTranslation(["adminPage"]);
  const t = getSubT(pt, "footer");
  return (
    <StyledFooter>
      <StyledFooterNavbar>
        {footerNavItemsData.map(({ href, text }, index) => (
          <StyledFooterLink
            key={index}
            href={href}
            target="_blank"
            rel="noreferrer"
          >
            {t(text)}
          </StyledFooterLink>
        ))}
      </StyledFooterNavbar>
      <StyledFooterDescription>
        {`${new Date().getFullYear()} ${t("by_ephelia_label")}`}
      </StyledFooterDescription>
      <div>Version: {process.env.REACT_APP_VERSION}</div>
    </StyledFooter>
  );
};
