import styled from "styled-components";

interface StyledLogoProps {
  size?: number;
}

export const StyledLogoSpinWrapper = styled.div<StyledLogoProps>`
  display: grid;
  place-items: center;

  height: ${({ size }) => (size ? size : 2.5)}rem;
  width: ${({ size }) => (size ? size : 2.5)}rem;
`;

export const StyledLgoImg = styled.img<StyledLogoProps>`
  height: ${({ size }) => (size ? size : 2.5)}rem;
  width: ${({ size }) => (size ? size : 2.5)}rem;
`;
