import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  Context,
  useCubitBuilder,
} from "@portittech/portit-react-common-components";

import { BoUserBannerWrapper, StyledBoUserKycLink } from "./boUserBanner.style";
import { useBackOffice } from "../../shared/hooks/useBackOffice";
import { ElviriaPages } from "../../shared/pages";

export const BoUserBanner = () => {
  const { customerCubit } = useContext(Context);
  const { t } = useTranslation(["boBanner"]);
  const { isBackOfficeUser } = useBackOffice();

  const customerState = useCubitBuilder({
    cubit: customerCubit,
  });

  const profileName = customerState?.tryData?.profileName;
  return (
    isBackOfficeUser && (
      <BoUserBannerWrapper active={Boolean(profileName)}>
        {profileName && (
          <>
            <span>{`${t("on_behalf")} ${profileName}`}</span>
            <StyledBoUserKycLink to={ElviriaPages.KYC}>
              {t("kyc_link")}
            </StyledBoUserKycLink>
          </>
        )}
      </BoUserBannerWrapper>
    )
  );
};
