import React from "react";
import { useTranslation } from "react-i18next";

import { getSubT } from "@portittech/portit-react-common-components";

import {
  StyledOpportunitiesMainWrapper,
  StyledOpportunitiesContentWrapper,
  StyledOpportunityFlexOneWrapper,
  StyledSmallWidgetTitle,
  StyledOpportunitiesTitleRowWrapper,
  StyledOpportunityColumnTitle,
  StyledViewAllBtnWrapper,
} from "../common.styles";
import { OpportunitiesCard } from "./opportunitiesCard.component";

import { ElviriaPages } from "../../../shared/pages";
import { SquareButton } from "../../../shared/styles/button";

import { useHistory } from "react-router-dom";
import { useOpportunitiesStore } from "../../opportunitiesStore";

interface InvestmentOpportunitiesInterface {
  withPortfolio?: boolean;
}

export const InvestmentOpportunitiesWidget = ({
  withPortfolio,
}: InvestmentOpportunitiesInterface) => {
  const history = useHistory();
  const { t } = useTranslation(["dashboard"]);
  const tc = getSubT(t, "investment_opportunities_widget");

  const { opportunitiesList } = useOpportunitiesStore();

  return (
    <StyledOpportunitiesMainWrapper>
      {!withPortfolio && (
        <StyledSmallWidgetTitle>{tc("widget_heading")}</StyledSmallWidgetTitle>
      )}

      <StyledOpportunitiesContentWrapper>
        <StyledOpportunitiesTitleRowWrapper>
          <StyledOpportunityFlexOneWrapper />
          <StyledOpportunityColumnTitle>
            {tc("name_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("asset_class_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("token_price_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityColumnTitle>
            {tc("token_number_title")}
          </StyledOpportunityColumnTitle>
          <StyledOpportunityFlexOneWrapper />
        </StyledOpportunitiesTitleRowWrapper>
        {/* Show only first 5 opportunities from the list in the dashboard  */}
        {opportunitiesList
          .slice(0, 5)
          .map(
            (opportunity) =>
              opportunity && (
                <OpportunitiesCard key={opportunity.id} {...opportunity} />
              )
          )}
      </StyledOpportunitiesContentWrapper>
      <StyledViewAllBtnWrapper>
        <SquareButton onClick={() => history.push(ElviriaPages.OPPORTUNITIES)}>
          {tc("view_all_btn")}
        </SquareButton>
      </StyledViewAllBtnWrapper>
    </StyledOpportunitiesMainWrapper>
  );
};
