import { Checkbox } from "antd";
import styled from "styled-components";

import {
  colorBlue,
  colorDarkBlue,
  colorMain,
  colorMainDark,
  colorMainWhite,
  fontFamilyMontserrat,
} from "../../../shared/styles/generic";

export const StyledMessagesCheckboxGroup = styled(Checkbox.Group)`
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colorBlue};
    border-radius: 10px;
    border: 5px solid transparent;
  }
`;

export const StyledMessagesList = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  margin-left: -1.37rem;

  img {
    margin: 0 0.5rem;
    cursor: pointer;
  }

  .anticon {
    margin: 0 0.5rem;
  }

  .ant-checkbox {
    margin-right: 1.5rem;
  }
  .ant-checkbox-wrapper .ant-checkbox-group-item:hover {
    border-color: ${colorDarkBlue};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colorMainWhite};
    &::after {
      border-color: ${colorDarkBlue};
    }
  }

  .ant-checkbox-wrapper {
    margin-bottom: 1rem;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .checkmark {
    width: 1.25rem;
    height: 1.25rem;
    top: 0.1875rem;
  }
`;

export const StyledTopOptions = styled.div`
  flex-direction: row;
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 1.375rem;
`;

export const StyledTopOptionsIconsWrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: flex-end;
`;

export const StyledMessageItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 0.625rem 0;
`;

export const StyledMessagesListTitleRow = styled.div`
  display: flex;
`;

export const StyledMessagesListTitle = styled.h3`
  font-family: ${fontFamilyMontserrat};
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 2rem;
  color: ${colorMainDark};
  margin-bottom: 2rem;
  padding-left: 1.375rem;

  span {
    font-weight: 400;
  }
`;

interface UnreadMessageCircleProps {
  isVisible?: boolean;
}

export const StyledUnreadMessageCircle = styled.span<UnreadMessageCircleProps>`
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  background-color: ${colorMain};
  align-self: center;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;
