import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import {
  colorBlue,
  colorGrey,
  colorMain,
  colorMainDark,
  colorMainWhite,
  fontFamilyEncodeSansExpanded,
  fontFamilyMontserrat,
} from "../../shared/styles/generic";
import { cubicBezier } from "../../shared/styles/publicPage.style";

import { devices } from "../config/devices";

export const StyledFullWidthGridColumn = styled.div`
  grid-column: 1/-1;
`;

interface SmallWidgetWrapperProps {
  isHorizontal?: boolean;
}

export const StyledSmallWidgetWrapper = styled.div<SmallWidgetWrapperProps>`
  box-shadow: -5px 5px 40px rgba(0, 0, 0, 0.07);
  background-color: ${colorMainWhite};
  padding: 1.125rem 1.25rem;

  ${({ isHorizontal }) =>
    !isHorizontal &&
    css`
      flex: 1;
    `}

  svg {
    transform: scale(1.5);
  }
`;

interface SmallWidgetTitleProps {
  fontSize?: number;
  marginBottom?: string;
}

export const StyledSmallWidgetTitle = styled.h4<SmallWidgetTitleProps>`
  color: ${colorMain};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 1.375)}rem;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : 2.25}rem;
  line-height: 110%;
  font-family: ${fontFamilyEncodeSansExpanded};
  text-transform: capitalize;

  span {
    font-weight: 600;
  }
`;

export const StyledSmallWidgetTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledExternalLink = styled.a`
  text-decoration: underline;
`;

export const StyledBackWithIconLinkButton = styled.a`
  span {
    text-decoration: underline;
  }

  :hover span {
    text-decoration: none;
  }
`;

interface StyledRouterLinkProps {
  isMargin?: boolean;
}

export const StyledRouterLink = styled(Link)<StyledRouterLinkProps>`
  font-weight: 400;
  text-decoration: underline;
  color: ${colorBlue};
  font-size: 0.875rem;
  ${({ isMargin }) =>
    isMargin &&
    css`
      margin-bottom: 0.9375rem;
      display: inline-block;
    `}
`;

interface NumberProps {
  withAccentColor?: boolean;
}

export const StyledNumber = styled.div<NumberProps>`
  font-weight: 600;
  font-size: 2rem;

  ${({ withAccentColor }) =>
    withAccentColor &&
    css`
      color: ${colorMain};
    `}

  @media ${devices.smallLaptop} {
    font-size: 1.625rem;
  }
`;

export const StyledDescription = styled.div`
  font-family: ${fontFamilyMontserrat};
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5rem;
`;

interface SmallTextProps {
  textAlign?: string;
}

export const StyledSmallText = styled.p<SmallTextProps>`
  font-size: 0.875rem;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
  margin-top: 1.875rem;
`;

/* Style for two button in same div horizontaly */

interface DoubleButtonsWrapperProps {
  forInterestInvestmentsCard?: boolean;
}

export const StyledDoubleButtonsWrapper = styled.div<DoubleButtonsWrapperProps>`
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;

  ${({ forInterestInvestmentsCard }) =>
    forInterestInvestmentsCard
      ? css`
          padding: 0.625rem;
        `
      : css`
          margin-top: 2.1875rem;
        `}
`;

export const StyledWatchlistLinkWrapper = styled.div`
  text-align: center;
  padding: 1rem 0 1.5rem 0;
  cursor: pointer;

  svg {
    margin-right: 0.625rem;
  }

  span {
    font-weight: 400;
    text-decoration: underline;
    color: ${colorBlue};
    font-size: 0.875rem;
  }
`;

/* Opportunities widget(s) styles starts*/
/* Styles for Opportunities card column titles */
export const StyledOpportunitiesTitleRowWrapper = styled.div`
  display: flex;
  padding: 0 0.9375rem;
  align-items: flex-end;
`;
export const StyledOpportunityColumnTitle = styled.h4`
  flex: 3;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${colorMain};
  text-transform: uppercase;
  padding: 0 0.33em;
`;

/* Styles for opportunities card starts*/
export const StyledOpportunitiesMainWrapper = styled.div`
  font-family: ${fontFamilyMontserrat};
  color: ${colorMainDark};
  padding: 1.125rem 0;
`;

export const StyledOpportunitiesContentWrapper = styled.div`
  padding: 1.375rem 0;
`;

interface OpportunitiesCardProps {
  withBigFont?: boolean;
}

export const StyledOpportunitiesCardWrapper = styled.div<OpportunitiesCardProps>`
  display: flex;
  border: 0.0625rem solid ${colorGrey};
  justify-content: space-between;
  align-items: center;
  padding: 0.9375rem;
  margin: 0 0 0.9375rem 0;
  font-family: ${fontFamilyMontserrat};
  font-weight: 600;
  font-size: ${({ withBigFont }) => (withBigFont ? 1.125 : 0.875)}rem;
`;

export const StyledOpportunityFlexOneWrapper = styled.div`
  flex: 1;
`;

interface OpportunitiesColumnTextProps {
  isAssetClass?: boolean;
}

export const StyledOpportunityColumnText = styled.div<OpportunitiesColumnTextProps>`
  flex: 3;
  padding: 0 0.33em;
  margin-bottom: 0;
  font-size: 1rem;

  ${({ isAssetClass }) =>
    isAssetClass &&
    css`
      font-size: 0.75rem;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
    `}
`;

export const StyledViewAllBtnWrapper = styled.div`
  max-width: 25rem;
  margin: 0 auto;
`;
/* Styles for opportunities card ends */

/* Styles for search field starts */
interface SearchFieldWrapperProps {
  isFullWidth?: boolean;
}

export const StyledSearchFieldWrapper = styled.div<SearchFieldWrapperProps>`
  width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "65%")};

  .ant-btn-icon-only {
    background-color: ${colorMain};
    transition: all 0.3s ${cubicBezier};
  }

  .ant-btn-icon-only:hover {
    img {
      transform: scale(1.2);
    }
  }
`;

export const StyledStickyRightWidgetsWrapper = styled.div`
  position: sticky;
  top: 3.125rem;
  display: flex;
  flex-direction: column;
  gap: 1.5625rem;
`;

/* Styles for search field ends */
