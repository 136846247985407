import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  colorBackgroundGrey,
  colorBlue,
  colorMainDark,
  colorMainWhite,
  fontFamilyEncodeSansExpanded,
  fontFamilyMontserrat,
} from "../../../shared/styles/generic";
import { devices } from "../../config/devices";

export const StyledEmptyPortfolioWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  color: ${colorMainDark};
  font-family: ${fontFamilyMontserrat};

  @media ${devices.smallLaptop} {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }
`;

export const StyledEmptyChartCircle = styled.div`
  width: 21.875rem;
  height: 21.875rem;
  border-radius: 50%;
  background-color: ${colorBackgroundGrey};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledEmptyChartInnerCircle = styled.div`
  width: 11.25rem;
  height: 11.25rem;
  border-radius: 50%;
  background-color: ${colorMainWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledNumber = styled.div`
  font-family: ${fontFamilyMontserrat};
  font-weight: 600;
  font-size: 2.625rem;
`;

export const StyledText = styled.div`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.5rem;
`;

export const StyledDescriptionWrapper = styled.div`
  max-width: 50%;
  padding-left: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${devices.smallLaptop} {
    max-width: initial;
    margin-left: 0;
  }
`;

export const StyledSubtitle = styled.div`
  font-family: ${fontFamilyEncodeSansExpanded};
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 110%;
`;

export const StyledParagraph = styled.p`
  font-size: 0.875rem;
  line-height: 1.3125rem;
  margin-top: 1rem;
`;

export const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${colorBlue};
`;
