import styled from "styled-components";
import { colorLightGrey, colorMain } from "../../../shared/styles/generic";

export const StyledIncrementInputFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 0.0625rem solid ${colorLightGrey};

  > div {
    flex: 1;
  }

  .ant-input-number-input {
    border: none;
    text-align: center;
    font-weight: 600;
    font-size: 1.25rem;
  }

  .ant-btn {
    padding: 0 1rem;
    display: flex;
    align-items: center;

    span {
      font-size: 1.625rem;
    }
  }

  .ant-btn:hover,
  .ant-btn:focus {
    border: 1px solid transparent !important;
  }

  .ant-btn-link {
    color: ${colorMain};
  }
`;

export const StyledIncrementDecrementButton = styled.p``;
