import React from "react";
import { useTranslation } from "react-i18next";

import {
  FundingInfoEntity,
  InfoText,
  FixedLinearLayout,
  getSubT,
  toCamelCase,
} from "@portittech/portit-react-common-components";
import {
  StyledDepositCircleSymbol,
  StyledDepositNote,
  StyledFundingInfoHeading,
  StyledFundingInfoMainWrapper,
  StyledFundingInfoTitle,
  StyledFundingInfoValue,
  StyledNoteWrapper,
} from "./deposit.styles";

interface FundingInfoComponentProps {
  fundingInfo: FundingInfoEntity;
}

export const FundingInfoComponent = ({
  fundingInfo,
}: FundingInfoComponentProps): React.ReactElement => {
  const { t: pt } = useTranslation(["dashboard"]);
  const t = getSubT(pt, "wallets.funding_info");

  const fundingInfoT = getSubT(
    t,
    `${fundingInfo.currency}.${fundingInfo.payment_type}`
  );

  const table: Array<Array<{ head: React.ReactNode; body: React.ReactNode }>> =
    [
      [
        {
          head: t("account_holder_name_title"),
          body: fundingInfo.account_holder_name,
        },
        {
          head: t("bank_name_title"),
          body: fundingInfo.bank_name,
        },
      ],
      [
        {
          head: t("account_number_title"),
          body: fundingInfo.account_number,
        },
        {
          head: t("bank_address_title"),
          body: fundingInfo.bank_address,
        },
      ],
      [
        {
          head: fundingInfoT(
            `${toCamelCase(fundingInfo.routing_code_type)}_header`
          ),
          body: fundingInfo.routing_code,
        },

        {
          head: t("bank_country_title"),
          body: fundingInfo.bank_country,
        },
      ],
      [
        {
          head: t("reference"),
          body: fundingInfo.reference,
        },
      ],
    ];

  return (
    <StyledFundingInfoMainWrapper>
      <FixedLinearLayout direction="vertical">
        <InfoText
          tooltip={fundingInfoT(
            `${toCamelCase(fundingInfo.routing_code_type)}_tooltip`
          )}
        >
          <StyledFundingInfoHeading>
            {fundingInfoT(
              `${toCamelCase(fundingInfo.routing_code_type)}_title`
            )}
          </StyledFundingInfoHeading>
        </InfoText>
        <FixedLinearLayout direction="vertical">
          {table.map((column, index) => {
            return (
              <FixedLinearLayout key={index} direction="horizontal">
                {column.map(({ head, body }, index) => {
                  return (
                    <FixedLinearLayout key={index} direction="vertical">
                      <StyledFundingInfoTitle>{head}</StyledFundingInfoTitle>
                      <StyledFundingInfoValue>{body}</StyledFundingInfoValue>
                    </FixedLinearLayout>
                  );
                })}
              </FixedLinearLayout>
            );
          })}
          <StyledNoteWrapper>
            <div>
              <StyledDepositCircleSymbol>i</StyledDepositCircleSymbol>
            </div>
            <StyledDepositNote>{t("note")}</StyledDepositNote>
          </StyledNoteWrapper>
        </FixedLinearLayout>
      </FixedLinearLayout>
    </StyledFundingInfoMainWrapper>
  );
};
