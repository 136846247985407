import {
  AsyncFieldValidator,
  FieldValidator,
  SingleFieldModel,
} from "@portittech/portit-react-common-components";

export class BooleanFieldModel<ED = any> extends SingleFieldModel<boolean, ED> {
  constructor(
    args: {
      value?: boolean;
      isGhost?: boolean;
      validators?: Array<FieldValidator<boolean>>;
      asyncValidators?: Array<AsyncFieldValidator<boolean>>;
    } = {}
  ) {
    super({
      ...args,
      value: args.value,
    });
  }

  updateInitialValue(value: boolean) {
    super.updateInitialValue(value);
  }

  updateValue(value: boolean) {
    super.updateValue(value);
  }
}
