import React from "react";
import { StyledPageWrapper } from "./investmentPage.styles";

import { InvestmentsList } from "./components/investments-list/investmentsList.component";

export const InvestmentsPage = () => {
  return (
    <StyledPageWrapper>
      <InvestmentsList />
    </StyledPageWrapper>
  );
};
